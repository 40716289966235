<template>
  <v-card class="elevation-0 py-3">
    <div class="flex-center">
      <v-btn-toggle v-model="type_" mandatory class="btn-group w-100 elevation-0">
        <v-btn small :disabled="viewMode" flat value="reward" active-class="btn-selected">
          Remise
        </v-btn>
        <v-btn small :disabled="viewMode" flat value="pool" active-class="btn-selected">
          Points
        </v-btn>
      </v-btn-toggle>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['setRewardType'])
  },
  computed: {
    ...mapGetters('campaignBuilder', ['viewMode']),
    type_: {
      get() {
        return this.type
      },
      set(value) {
        this.setRewardType(value)
      }
    }
  }
}
</script>
