<template>
  <v-container fluid grid-list-lg>
    <v-layout v-if="!(rolesLength === 1 || (rolesLength === 2 && isRetailer))" column wrap class="absolute">
      <v-flex sm6 xs12 pt-1 class="flex-div align-center">
        <v-btn
          v-if="hasFid"
          :dark="customersFilter === 'member'"
          :color="customersFilter === 'member' ? 'blue darken-3' : 'white'"
          class="ml-0"
          @click="customersFilter = 'member'"
        >
          <v-icon small class="mr-2">person</v-icon>
          {{ $t('nav.loyalty') }}
        </v-btn>
        <v-btn
          v-if="hasConquest"
          :dark="customersFilter === 'nonmember'"
          :color="customersFilter === 'nonmember' ? 'blue darken-3' : 'white'"
          @click="customersFilter = 'nonmember'"
        >
          <v-icon small class="mr-2">store</v-icon>Ad-to-store
        </v-btn>
        <v-btn
          v-if="isPublisher"
          :dark="customersFilter === 'publisher'"
          :color="customersFilter === 'publisher' ? 'blue darken-3' : 'white'"
          @click="customersFilter = 'publisher'"
        >
          <v-icon small class="mr-2">publish</v-icon>
          {{ $t('nav.publishers') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <transition name="component-fade" mode="out-in">
      <component class="mt-1" :is="dashboardComponent"></component>
    </transition>
    <WelcomeDialog v-model="welcomeDialog" />
  </v-container>
</template>

<script>
import WelcomeDialog from '@/components/WelcomeDialog'
import DashboardFid from '@/components/Dashboard/DashboardFid.vue'
import DashboardConq from '@/components/Dashboard/DashboardConq.vue'
import DashboardPub from '@/components/Dashboard/DashboardPub.vue'

export default {
  components: {
    WelcomeDialog,
    DashboardFid,
    DashboardConq,
    DashboardPub
  },
  data() {
    return {
      customersFilter: '',
      welcomeDialog: false
    }
  },
  computed: {
    rolesLength() {
      let count = 0
      for (const cap in this.$store.state.user.capabilities) {
        if (this.$store.state.user.capabilities[cap] === true) count += 1
      }
      return count
    },
    userCompany() {
      return this.$store.state.user.company
    },
    userAccount() {
      return this.$store.state.user.account
    },
    hasFid() {
      return this.$store.getters.isFidelity
    },
    hasConquest() {
      return this.$store.getters.isAdToStore
    },
    isPublisher() {
      return this.$store.state.user.capabilities.publisher
    },
    isRetailer() {
      return this.$store.state.user.capabilities.retailer
    },
    dashboardComponent() {
      if (this.customersFilter === 'member') return 'DashboardFid'
      else if (this.customersFilter === 'nonmember') return 'DashboardConq'
      else return 'DashboardPub'
    }
  },
  methods: {
    defaultDashboard() {
      if (this.hasFid) return 'member'
      else return 'nonmember'
    }
  },
  created() {
    this.customersFilter = this.defaultDashboard()
    const welcomeSession = sessionStorage.getItem('welcomeDialog')

    if ((this.userAccount?.completed_at || this.userAccount?.created_at) && this.userCompany?.name === '3CI' && !welcomeSession) {
      const completed_at = this.$moment(this.userAccount?.completed_at || this.userAccount?.created_at)
      const welcomeDialog = localStorage.getItem('welcomeDialog')
      if (this.$moment().diff(completed_at, 'days') <= 9 && !welcomeDialog) {
        this.welcomeDialog = true
      }
    }
  },
  watch: {
    userCompany() {
      this.customersFilter = this.defaultDashboard()
    }
  }
}
</script>
