<template>
  <v-card width="140">
    <v-card-title class="d-flex pb-0">
      <span class="caption" color="gray">{{ header }}</span>
      <v-icon :color="color">{{ icon }}</v-icon>
    </v-card-title>
    <v-card-text>{{ value }}</v-card-text>
  </v-card>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BoxWithIcon extends Vue {
  @Prop(String) icon;
  @Prop(Number) value;
  @Prop(String) header;
  @Prop(String) color;
}
</script>
