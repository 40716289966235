import CompanyService from '@/services/CompanyService'
import UserService from '@/services/UserService'
import LoyaltyCurrencyService from '@/services/LoyaltyCurrencyService'
import FidelityService from '@/services/FidelityService'

import Company from '@/models/Company'
import Account from '@/models/Account'

interface UserState {
  companyLoading: boolean
  capabilitiesLoading: boolean
  loyaltyCurrencyLoading: boolean
  accountLoading: boolean
  company: Company
  account: Account
  capabilities: any
  loyaltyCurrency: any
  tokenPresence: boolean
  notificationsInterval: number
  routerViewKey: number
  builder: string
}

const state: UserState = {
  companyLoading: false,
  capabilitiesLoading: false,
  loyaltyCurrencyLoading: false,
  accountLoading: false,
  company: {} as Company,
  account: {} as Account,
  capabilities: [],
  loyaltyCurrency: {},
  tokenPresence: false,
  notificationsInterval: -1,
  routerViewKey: 0,
  builder: 'campaignBuilder'
}

const mutations = {
  INCREMENT_ROUTER_VIEW_KEY: (state: UserState) => state.routerViewKey += 1,
  SET_ACCOUNT: (state: UserState, payload: Account) => state.account = payload,
  SET_ACCOUNT_LOADING: (state: UserState, payload: boolean) => state.accountLoading = payload,
  SET_COMPANY: (state: UserState, payload: Company) => state.company = payload,
  SET_LOYALTY_CURRENCY: (state: UserState, payload: any) => state.loyaltyCurrency = payload,
  SET_LOYALTY_CURRENCY_LOADING: (state: UserState, payload: boolean) => state.loyaltyCurrencyLoading = payload,
  SET_COMPANY_LOADING: (state: UserState, payload: boolean) => state.companyLoading = payload,
  SET_CAPABILITIES: (state: UserState, payload: string[]) => state.capabilities = payload,
  SET_CAPABILITIES_LOADING: (state: UserState, payload: boolean) => state.capabilitiesLoading = payload,
  SET_TOKEN_PRESENCE: (state: UserState, payload: boolean) => state.tokenPresence = payload,
  SET_NOTIFICATIONS_INTERVAL: (state: UserState, payload: number) => state.notificationsInterval = payload,
  SET_BUILDER: (state: UserState, payload: string) => state.builder = payload,

  CLEAR_NOTIFICATIONS_INTERVAL(state: UserState) {
    clearInterval(state.notificationsInterval)
    state.notificationsInterval = -1
  }
}

const actions = {
  incrementRouterViewKey: ({ commit }: any) => commit('INCREMENT_ROUTER_VIEW_KEY'),
  setTokenPresence: ({ commit }: any, payload: boolean) => commit('SET_TOKEN_PRESENCE', payload),
  setBuilder: ({ commit }: any, payload: string) => commit('SET_BUILDER', payload),
  setNotificationsInterval: ({ commit }: any, payload: number) => commit('SET_NOTIFICATIONS_INTERVAL', payload),
  clearNotificationsInterval: ({ commit }: any) => commit('CLEAR_NOTIFICATIONS_INTERVAL'),

  async fetchCompany({ commit }: any) {
    commit('SET_COMPANY_LOADING', true)
    const res = await CompanyService.getCompany()

    commit('SET_COMPANY', res.data)
    commit('SET_COMPANY_LOADING', false)
  },
  async fetchAccount({ commit }: any) {
    commit('SET_ACCOUNT_LOADING', true)
    const res = await UserService.getUser()

    commit('SET_ACCOUNT', res.data)
    commit('SET_ACCOUNT_LOADING', false)
  },

  async fetchCapabilities({ commit }: any) {
    commit('SET_CAPABILITIES_LOADING', true)
    const res = await CompanyService.getCapabilities()

    commit('SET_CAPABILITIES', res.data)
    commit('SET_CAPABILITIES_LOADING', false)
  },

  async fetchLoyaltyCurrency({ commit, getters }: any) {
    commit('SET_LOYALTY_CURRENCY_LOADING', true)

    try {
      const res = await LoyaltyCurrencyService.getLoyaltyCurrency()

      commit('SET_LOYALTY_CURRENCY', res.data)
      commit('SET_LOYALTY_CURRENCY_LOADING', false)
    } catch {
      commit('SET_LOYALTY_CURRENCY_LOADING', false)
    }
  },
  clearUser({ commit }: any) {
    commit('SET_COMPANY', {})
    commit('SET_TOKEN_PRESENCE', false)
  }
}

const getters = {
  hasCoreProgram: (state: UserState) => state.capabilities.core_program,
  getBrandAdvertiser: (state: UserState) => {
    return state.company.advertisers.find((e: any) => e.kind == 'brand')
  },
  getDistributorAdvertiser: (state: UserState) => {
    return state.company.advertisers.find((e: any) => e.kind == 'distributor')
  },
  isFidelity: (state: UserState) => state.company.usages.includes('fidelity'),
  isAdToStore: (state: UserState) => state.company.usages.includes('conquest')
}

export default {
  state,
  mutations,
  actions,
  getters
}
