<template>
  <v-container fluid grid-list-lg>
    <div class="grid-settings">
      <div class="w-100">
        <v-list class="elevation-2 pa-0">
          <v-list-tile :class="{'menu-settings-active': item.active }" v-for="item in settingsMenu" :key="item.title" @click="(settingsComponent = item.componentName); activeClass(item, settingsMenu)">
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </div>

      <div class="bg-white elevation-2">
        <v-layout row wrap>
          <v-flex xs12>
            <keep-alive>
              <transition name="component-fade" mode="out-in">
                <component v-if="company" :is="settingsComponent" :user="account" :company="userCompany"></component>
              </transition>
            </keep-alive>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </v-container>
</template>

<script>
import req from '@/packs/request.js'
import SettingsAccount from './SettingsAccount'
import SettingsCompany from './SettingsCompany'
import SettingsDonation from './SettingsDonation'
import SettingsCGU from './SettingsCGU'
import SettingsPass from './SettingsPass'
import SettingsPlateform from './SettingsPlateform'
import SettingsPlateformAd from './SettingsPlateformAd'

export default {
  components: {
    SettingsAccount,
    SettingsCompany,
    SettingsDonation,
    SettingsCGU,
    SettingsPass,
    SettingsPlateform,
    SettingsPlateformAd
  },
  data: function () {
    return {
      userCompany: {},
      settingsComponent: 'SettingsAccount',
      settingsMenu: [
        {
          title: this.$t('settings.account.title'),
          icon: 'account_circle',
          componentName: 'SettingsAccount',
          active: true
        },
        {
          title: this.$t('settings.company.title'),
          icon: 'work',
          componentName: 'SettingsCompany',
          active: false
        }
      ],
      fidelityItems: [
        {
          title: this.$t('settings.personalData.title'),
          icon: 'business_center',
          componentName: 'SettingsPlateformAd',
          active: false
        },
        {
          title: this.$t('settings.pass.title'),
          icon: 'smartphone',
          componentName: 'SettingsPass',
          active: false
        },
        {
          title: this.$t('settings.donationOrganization.title'),
          icon: 'business_center',
          componentName: 'SettingsDonation',
          active: false
        },
        {
          title: this.$t('settings.termsConditions.title'),
          icon: 'format_align_justify',
          componentName: 'SettingsCGU',
          active: false
        }
      ],
      publisherItems: [
        // {
        //   title: this.$t('settings.settingsPlateform'),
        //   icon: 'business_center',
        //   componentName: 'SettingsPlateform',
        //   active: false
        // },
        {
          title: this.$t('settings.walletSettings'),
          icon: 'phonelink_setup',
          componentName: 'SettingsPass',
          active: false
        }
      ],
      right: null
    }
  },
  methods: {
    activeClass(item, settingsMenu) {
      settingsMenu.forEach(element => (element.active = false))
      item.active = !item.active
    },
    async fetchCompany() {
      try {
        const res = await req.get('/user/company')
        Object.assign(this.userCompany, res.data)
      } catch (err) {
        console.log(err)
      }
    }
  },
  computed: {
    account() {
      return this.$store.state.user.account
    },
    company() {
      return this.$store.state.user.company
    },
    isPublisher() {
      return this.$store.state.user.capabilities.publisher
    },
    hasFid() {
      return this.$store.getters.isFidelity
    }
  },
  created() {
    this.fetchCompany()
  },
  mounted() {
    if (this.isPublisher) this.settingsMenu.push(...this.publisherItems)
    if (this.hasFid) this.settingsMenu.push(...this.fidelityItems)
  }
}
</script>
