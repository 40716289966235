<template>
  <v-container fluid class="transactions">
    <TransactionsTable :api-url="transactionsUrl"></TransactionsTable>
  </v-container>
</template>

<script>
import TransactionsTable from '@/components/TransactionsTable'

export default {
  components: {
    TransactionsTable
  },
  data() {
    return {
      transactionsUrl: '/transactions?by_usage=conquest'
    }
  }
}
</script>
