<template>
  <v-flex class="pb-3">
    <v-layout px-3 pt-2 row>
      <template>
        <v-flex md6 v-if="kind === 'permanent'">
          <h3>{{ $t('campaignListItem.generosity') }}</h3>
          <p class="mt-2">
            {{ $t('campaignListItem.publisherAcceptMessage') }}
          </p>
          <v-slider
            v-model="generosityPart"
            :thumb-size="24"
            thumb-label="always"
            :min="minimumGenerosityAmount"
            :max="findersFee()"
            always-dirty
            append-icon="euro"
            hide-details
            class="mt-5"
          />
        </v-flex>
      </template>
      <template v-if="Object.keys(loyaltyCurrency).length > 0">
        <v-flex md6>
          <div style="display: flex">
            <h3 :style="`color: ${acceptAsPool ? 'black' : 'grey'}`">
              Cagnotte
            </h3>
            <v-switch class="pt-0 mt-0 ml-3" v-model="acceptAsPool" hide-details />
          </div>
          <p :style="`color: ${acceptAsPool ? 'black' : 'grey'}`" class="mt-2">
            Convertissez la générosité en unité de fidélité pour vos clients.<br />
            Exemple: Pour un achat de <b>100€</b> et une générosité de
            <b>{{ exampleAmount }}%</b>
            le consomateur sera crédité de
            <b>
              {{ exampleAmount * poolExchangeRate }}
              {{ loyaltyCurrency.name }}
            </b>
          </p>
          <v-slider
            v-model="poolExchangeRate"
            :thumb-size="24"
            thumb-label="always"
            :disabled="!acceptAsPool"
            :min="1"
            :max="nationalCurrency ? 1 : 100"
            always-dirty
            hide-details
            class="mt-5"
            prepend-icon="clear"
          />
        </v-flex>
      </template>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-btn color="primary" @click="submit()"> {{ $t('common.accept') }}</v-btn>
        <v-btn @click="$emit('close', true)" class="ml-0" color="red" dark outline>
          <v-icon class="mr-2" small>close</v-icon>{{ $t('common.cancel') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex'
import CommissionContractService from '@/services/CommissionContractService'

export default {
  props: {
    reward: {
      type: Object
    },
    commissionContract: {
      type: Object,
      required: true
    },
    kind: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      acceptAsPool: false,
      generosityPart: 1,
      poolExchangeRate: 1
    }
  },
  created() {
    this.generosityPart = this.minimumGenerosityAmount
  },
  computed: {
    ...mapState({
      loyaltyCurrency: state => state.user.loyaltyCurrency
    }),
    nationalCurrency() {
      return this.loyaltyCurrency.national_currency
    },
    commissionRuleAmount() {
      return this.commissionContract.rate_card?.commission_rules[0]?.amount
    },
    exampleAmount() {
      return this.kind === 'permanent' ? this.generosityPart : 1
    },
    minimumGenerosityAmount() {
      const amount = this.commissionContract.rate_card.minimum_generosity_amount
      return amount === 0 ? 1 : amount.toCurrency()
    }
  },
  methods: {
    findersFee() {
      const cc = this.commissionContract
      if (cc && cc.rate_card && cc.rate_card.pricing_model.kind === 'cpa' && cc.rate_card.commission_rules[0])
        return cc.rate_card.commission_rules[0].amount.toCurrency()
      else return '..'
    },
    async submit() {
      const params = {
        commission_contract: {
          generosity_part: this.generosityPart.toCents(),
          pool_exchange_rate: this.poolExchangeRate,
          pool_kind: 'earn'
        }
      }
      const actionName = this.acceptAsPool ? 'acceptAsPool' : 'accept'
      const res = await CommissionContractService[actionName](this.commissionContract.id, params)

      this.$emit('update', res.data)
      this.$emit('close')
    }
  }
}
</script>
