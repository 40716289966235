import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@/stylesheets/main.sass'

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.use(Vuetify, {
  iconfont: 'md',
})

import i18n from './i18n'

Vue.config.productionTip = false

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://069bbe543984472381cefe446e7b378c@sentry.io/1504787',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    environment: process.env.VUE_APP_ENV,
    beforeSend(event, hint) {
      const error = hint?.originalException
      const ignore_patterns = [
        /Request failed with status/i,
        /this\.src is null/i,
      ]

      if (event && event.message) {
        const ignored = ignore_patterns.find(pat => event?.message?.match(pat))

        return ignored ? null : event
      } else {
        return event
      }
    }
  })
}

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueProgressBar from 'vue-progressbar'
const progressBarOptions = {
  color: '#7cade4',
  failedColor: '#d03939',
  thickness: '3px',
  transition: {
    speed: '0.3s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, progressBarOptions)

// VUE SWEET ALERT
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2)

import VueMoment from 'vue-moment'
import moment from 'moment'

Vue.use(VueMoment, { moment })

import VuePluralize from 'vue-pluralize'
Vue.use(VuePluralize)

import request from '@/packs/request'
request.init()

import '@/packs/monetizer.js'

Vue.filter('capitalize', function(value: any) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('uppercase', function(value: any) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('lowercase', function(value: any) {
  if (!value) return ''
  value = value.toString()
  return value.toLowerCase()
})

Vue.filter('monetize', function(value: any) {
  if (!value && value !== 0) return null
  return Number((value / 100).toFixed(2))
})

Vue.filter('unmonetize', function(value: any) {
  if (!value && value !== 0) return null
  return Number((value * 100).toFixed(0))
})

Vue.filter('numberTo3', function(value: any) {
  if (!value && value !== 0) return null
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

Vue.filter('truncate', function(string: string, value: any) {
  return string.length > value ? `${string.substring(0, value)} ...` : string
})

Vue.filter('formatDate', function(this: any, value: any) {
  if (value) {
    return this.$moment(String(value)).format('MM/dd/yyyy hh:mm')
  }
})

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

//Loading spinner
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
Vue.component('clip-loader', ClipLoader)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
