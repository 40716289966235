<template>
  <v-dialog :value="value" @input="$emit('input', false)" max-width="900">
    <v-card>
      <v-card-text>
        <CampaignDetailsPublishersList v-if="value" :id="id" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="$emit('input', false)">
          {{ $t('pointsOfSale.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CampaignDetailsPublishersList from '@/components/Campaigns/CampaignDetailsPublishersList'

export default {
  components: {
    CampaignDetailsPublishersList
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  }
}
</script>
