export default class TimeSlot {
  fullWeek: boolean
  fullDay: boolean
  days: any[]
  start_hour: number
  start_minutes: number
  end_hour: number
  end_minutes: number

  constructor(
    fullWeek = false,
    fullDay = true,
    days = [],
    start_hour = 0,
    start_minutes = 0,
    end_hour = 23,
    end_minutes = 59
  ) {
    this.fullWeek = fullWeek
    this.fullDay = fullDay
    this.days = days
    this.start_hour = start_hour
    this.start_minutes = start_minutes
    this.end_hour = end_hour
    this.end_minutes = end_minutes
  }
}
