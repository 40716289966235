<template>
  <div class="wrapper-top-bar-five">
    <div class="text-left my-auto">
      <v-chip :color="defineColor" dense small :text-color="defineText">
        <span class="body-1 font-weight-bold">{{
          formatSource(data.source)
        }}</span>
      </v-chip>
    </div>

    <div class="pt-3">
      <h3 class="font-weight-regular info-label body-2">
        Date :
      </h3>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="data-label body-1 font-weight-bold">
            {{ formatDate }}
          </div>
        </template>
        <span>{{ data.created_at }}</span>
      </v-tooltip>
    </div>

    <div v-if="data.source === 'in_store'" class="pt-3">
      <h3 class="font-weight-regular info-label body-2">
        Montant payé :
      </h3>
      <div class="data-label body-1 font-weight-bold">
        <span :class="{'old-price': checkPrice}">
          {{ data.cart.amount >= 0 ? data.cart.amount.toCurrency() : '-' }}€
        </span>
        <span v-if="checkPrice">
          <v-icon class="arrow-transformation">arrow_right</v-icon>
          <span style="color: #4AD991"
            >{{ data.cart.discounted_amount ? data.cart.discounted_amount.toCurrency() : '-' }}€</span
          >
        </span>
      </div>
    </div>

    <div v-else-if="data.source === 'out_store'" class="pt-3">
      <h3 class="font-weight-regular info-label body-2">
        Type :
      </h3>
      <div class="data-label body-1 font-weight-bold">
        {{ $t(`transactions.type.${data.event}`) | capitalize }}
      </div>
    </div>

    <div class="pt-3">
      <h3 class="font-weight-regular info-label body-2">
        Solde
        {{
          data.transaction_wallets[0]
            ? '(' + data.transaction_wallets[0].loyalty_currency_name + ')'
            : ''
        }}
        :
      </h3>
      <div class="data-label body-1 font-weight-bold">
        {{
          data.transaction_wallets[0]
            ? data.transaction_wallets[0].previous_balance
            : '-'
        }}
        <span
          v-if="
            data.transaction_wallets[0] &&
              data.transaction_wallets[0].previous_balance !==
                data.transaction_wallets[0].balance
          "
        >
          <v-icon class="arrow-transformation">arrow_right</v-icon>
          <span :class="colorBalance">{{
            data.transaction_wallets[0].balance
          }}</span>
        </span>
      </div>
    </div>

    <div class="pt-3">
      <h3 class="font-weight-regular info-label body-2">
        Point de vente :
      </h3>
      <div class="data-label body-1 font-weight-bold">
        {{
          data.point_of_sale && data.source === 'in_store'
            ? data.point_of_sale.name
            : '-'
        }}
      </div>
    </div>

    <div class="pt-3">
      <h3 class="font-weight-regular info-label body-2">
        Opération(s) :
      </h3>
      <div class="data-label body-1 font-weight-bold">
        <div class="circle-operations">
          <span style="color: #FFCA83">
            {{ operations() }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatSource(source) {
      if (source === 'in_store') return 'In store'
      else if (source === 'out_store') return 'Out of store'
      else return '-'
    },
    operations() {
      let nbOperations = 0

      if (this.data.cart.discounts.length > 0) nbOperations = this.data.cart.discounts.length
      if (this.data.cart.items.length > 0) {
        for (const item of this.data.cart.items)
          for (const discount of item.discounts) nbOperations++
      }
      return nbOperations
    }
  },
  computed: {
    defineColor() {
      if (this.data.source === 'in_store') return '#DAF7E8'
      else if (this.data.source === 'out_store') return '#ffe2e6'
      else return '#d2d2d2'
    },
    defineText() {
      if (this.data.source === 'in_store') return '#4AD991'
      else if (this.data.source === 'out_store') return '#FF7285'
      else return '#5e5d5d'
    },
    checkPrice() {
      return this.data.cart.discounted_amount && this.data.cart.amount !== this.data.cart.discounted_amount
    },
    formatDate() {
      const date = new Date(this.data.created_at)
      const ms = [
        'Jan',
        'Fev',
        'Mar',
        'Avr',
        'Mai',
        'Jui',
        'Jul',
        'Aou',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      let ret = date.getDate() + ' ' + ms[date.getMonth()] + ' ' + date.getFullYear()
      if (!this.readOnly) ret += ' - ' + this.data.created_at.slice(10)
      return ret
    },
    colorBalance() {
      if (this.data.transaction_wallets[0].previous_balance > this.data.transaction_wallets[0].balance)
        return 'text-red'
      else return 'text-green'
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/stylesheets/Transactions/TransactionsItemTopView"
</style>
