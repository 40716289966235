<template>
  <v-flex xs8 class="mt-auto mb-auto">
    <v-layout row wrap pb-2>
      <v-flex sm6 py-0>
        <h3
          class="title-info text-uppercase font-weight-regular green--text text--lighten-1"
        >
          {{ $t('dashboard.charts.issued') }}
        </h3>
        <v-layout row wrap align-center>
          <v-flex shrink py-0>
            <animated-number
              :value="issued"
              :duration="500"
              :formatValue="formatPrice"
              class="info-content indigo--text title font-weight-black"
            />
          </v-flex>
          <v-flex v-if="isBurnExchangeRate" grow py-0>
            <span class="mt-1 grey--text font-weight-regular">
              {{ formatValue(chart.data.issued) }}
              {{ chart.loyaltyUnits | lowercase }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex sm6 py-0>
        <h3 class="title-info text-uppercase font-weight-regular">
          {{ $t('dashboard.charts.inCirculation') }}
        </h3>
        <v-layout row wrap align-center>
          <v-flex shrink py-0>
            <animated-number
              :value="inCirculation"
              :duration="500"
              :formatValue="formatPrice"
              class="info-content indigo--text title font-weight-black"
            />
          </v-flex>
          <v-flex v-if="isBurnExchangeRate" grow py-0>
            <span class="mt-1 grey--text font-weight-regular">
              {{ formatValue(chart.data.circulation) }}
              {{ chart.loyaltyUnits | lowercase }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex sm6 py-0>
        <h3
          class="title-info text-uppercase font-weight-regular mt-2 red--text text--accent-2"
        >
          {{ $t('dashboard.charts.redeemed') }}
        </h3>
        <v-layout row wrap align-center>
          <v-flex shrink py-0>
            <animated-number
              :round="true"
              :value="redeemed"
              :duration="500"
              :formatValue="formatPrice"
              class="info-content indigo--text title font-weight-black"
            />
          </v-flex>
          <v-flex v-if="isBurnExchangeRate" grow py-0>
            <span class="mt-1 grey--text font-weight-regular">
              {{ formatValue(chart.data.redeemed) }}
              {{ chart.loyaltyUnits | lowercase }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex sm6 py-0>
        <h3
          class="title-info text-uppercase font-weight-regular blue--text text-lighten-2 mt-2"
        >
          {{ $t('dashboard.charts.expired') }}
        </h3>
        <v-layout row wrap align-center>
          <v-flex shrink py-0>
            <animated-number
              :value="expired"
              :duration="500"
              :formatValue="formatPrice"
              class="info-content indigo--text title font-weight-black"
            />
          </v-flex>
          <v-flex v-if="isBurnExchangeRate" grow py-0>
            <span class="mt-1 grey--text font-weight-regular">
              {{ formatValue(chart.data.expired) }}
              {{ chart.loyaltyUnits | lowercase }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'

export default {
  components: {
    AnimatedNumber
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    poolProgram: {
      type: Object,
      required: true
    }
  },
  computed: {
    isBurnExchangeRate() {
      return this.poolProgram?.burn_exchange_rate
    },
    issued() {
      return this.isBurnExchangeRate
        ? this.chart.data.issuedAmount
        : this.chart.data.issued
    },
    inCirculation() {
      return this.isBurnExchangeRate
        ? this.chart.data.circulationAmount
        : this.chart.data.circulation
    },
    redeemed() {
      return this.isBurnExchangeRate
        ? this.chart.data.redeemedAmount
        : this.chart.data.redeemed
    },
    expired() {
      return this.isBurnExchangeRate
        ? this.chart.data.expiredAmount
        : this.chart.data.expired
    }
  },
  methods: {
    formatPrice(value) {
      return this.isBurnExchangeRate
        ? Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
          }).format(value)
        : `${value} ${this.chart.loyaltyUnits}`
    },
    formatValue(value) {
      const number = `${Number(value).toFixed(2)}`
      const format = Intl.NumberFormat('fr-FR').format(number)
      return format
    }
  }
}
</script>
