<template>
  <v-container fluid grid-list-xl>
    <v-card class="px-3 py-4 mb-4">
      <label class="font-weight-light subheading align-self-center">
        Configuration des <b class="font-weight-medium">diffuseur(s)</b> de
        votre campagne
      </label>
    </v-card>

    <v-card class="w-100">
      <div class="py-2 px-4 justify-space-between" style="display: flex">
        <h4 class="align-self-end font-weight-medium">
          Vos diffuseur(s) :
          <span class="font-weight-light ml-3">{{
            `${selectedPublishers ? selectedPublishers.length : 0} selectionné(s)`
          }}</span>
        </h4>
        <v-text-field
          v-model="search"
          class="pt-0"
          append-icon="search"
          label="Rechercher un diffuseur"
          :loading="loading"
          single-line
          hide-details
          clearable
          style="max-width: 300px"
        ></v-text-field>
      </div>
      <v-divider class="my-3"></v-divider>
      <v-data-table
        ref="dataTable"
        :headers="headers"
        :items="publishers"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        :rows-per-page-text="$t('common.rowsPerPage')"
        item-key="name"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th width="30">
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ma-0 pt-0 mt-0"
                    hide-details
                    flat
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon small>keyboard_arrow_down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-tile
                    @click="
                      allPublishersIsSelected ? deselectAll() : selectAll()
                    "
                  >
                    <v-list-tile-title>{{
                      allPublishersIsSelected ? 'Delesect all' : 'Select all'
                    }}</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="selectFiltered()">
                    <v-list-tile-title>Select page</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </th>
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="text-xs-left"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr
            :active="isSelected(props.item.id)"
          >
            <td>
              <v-checkbox
                @click.stop="addOrDeletePublishers(props.item.id)"
                :input-value="isSelected(props.item.id)"
                hide-details
              ></v-checkbox>
            </td>
            <td class="flex-div align-center">
              <img
                v-show="props.item.company.logo_url"
                :src="props.item.company.logo_url"
                alt="Logo"
                width="30"
                class="mr-3"
              />
              <span>{{ props.item.company.name | capitalize}}</span>
            </td>
            <td>{{ props.item.pricing_models.map(e => e.kind).join(', ') }}</td>
            <td>
              <template>
                <v-select
                  :disabled="!isSelected(props.item.id)"
                  :value="isSelected(props.item.id) ? getPublisherRateCardId(props.item.id) : null"
                  @change="setPublisherRateCard({ pubId: props.item.id, value: $event })"
                  class="selectRatecard"
                  :items="rateCards"
                  item-text="name"
                  item-value="id"
                  label="Grille tarifaire à appliquer"
                ></v-select>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import CompanyService from '@/services/CompanyService'
import PublisherService from '@/services/PublisherService'

export default {
  data() {
    return {
      loading: false,
      publishers: [],
      rateCards: [],
      search: null,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      headers: [
        {
          text: this.$t('createCampaign.publisher.company'),
          value: 'name',
          sortable: 'false'
        },
        {
          text: this.$t('publisher.pricingModel'),
          value: 'pricing_model',
          sortable: 'false'
        },
        {
          text: this.$t('publisher.rateCard'),
          value: 'rate_card',
          sortable: 'false'
        }
      ]
    }
  },
  created() {
    this.fetchPublishers()
    this.fetchRateCards()
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'addPublishers',
      'deletePublisher',
      'clearPublishers',
      'setPublisherRateCard'
    ]),
    async fetchPublishers() {
      this.loading = true
      const res = await PublisherService.get()
      this.publishers = res.data
      this.loading = false
    },
    async fetchRateCards() {
      const res = await CompanyService.getRateCards()
      this.rateCards = res.data
    },
    addOrDeletePublishers(id) {
      this.isSelected(id) ? this.deletePublisher(id) : this.addPublishers(id)
    },
    isSelected(id) {
      if (this.selectedPublishers?.length > 0)
        return !!this.selectedPublishers.find(e => e.publisher_id === id)
      else return false
    },
    selectAll() {
      this.addPublishers(this.publishers.map(e => e.id))
    },
    deselectAll() {
      this.clearPublishers()
    },
    selectFiltered() {
      this.addPublishers(this.filteredPublishers.map(e => e.id))
    },
    getPublisherRateCardId(id) {
      return this.selectedPublishers.find(e => e.publisher_id === id)?.rate_card_id
    }
  },
  computed: {
    ...mapState({
      selectedPublishers: state => state.campaignBuilder.campaign.commission_contracts
    }),
    filteredPublishers() {
      return this.$refs.dataTable.filteredItems
    },
    allPublishersIsSelected() {
      return this.publishers?.length === this.selectedPublishers?.length
    }
  }
}
</script>
