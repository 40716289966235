<template>
  <v-container fluid grid-list-lg>
    <clip-loader class="my-4" :loading="loading" color="#78909c" width="60px" height="60px"></clip-loader>
    <div v-if="!loading" class="single-campaign-wrapper">
      <div>
        <Campaign :campaign="coreProgram"></Campaign>
      </div>
      <div>
        <Campaign v-if="welcome" :campaign="welcome"></Campaign>
        <v-card v-else class="elevation-0 h-100" @click="$router.push('/campaigns/event/new')" :class="{'no-activate-actions': true}">
          <v-card-title class="align-center justify-center h-100">
            <div class="text-center">
              <div class="font-weight-light title">Créer une campagne de bienvenue</div>
              <v-icon large class="mt-3" @click.self="null">add_circle</v-icon>
            </div>
          </v-card-title>
        </v-card>
      </div>
      <div>
        <Campaign v-if="event" :campaign="event"></Campaign>
        <v-card v-else class="elevation-0 h-100" @click="$router.push('/campaigns/event/new')" :class="{'no-activate-actions': true}">
          <v-card-title class="align-center justify-center h-100">
            <div class="text-center">
              <div class="font-weight-light title">Créer une campagne d'anniversaire</div>
              <v-icon large class="mt-3" @click.self="null">add_circle</v-icon>
            </div>
          </v-card-title>
        </v-card>
      </div>
    </div>
    <transition name="slide-y-reverse-transition" class="full-width" tag="div" v-if="hasCoreProgram">
      <CampaignList class="mt-3" fidelity />
    </transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import CampaignList from '@/components/CampaignList'
import FidelityService from '@/services/FidelityService'
import SubFidelityService from '@/services/SubFidelityService'
import Campaign from '@/components/Campaign'

export default {
  components: {
    CampaignList,
    Campaign
  },
  data() {
    return {
      coreProgram: null,
      welcome: null,
      event: null,
      loading: false
    }
  },
  methods: {
    async fetchCoreProgram() {
      const res = await FidelityService.get()
      this.coreProgram = res.data
    },
    async fetchWelcome() {
      const res = await SubFidelityService.getWelcome()
      this.welcome = res.data.collection[0]
    },
    async fetchAnniversary() {
      const res = await SubFidelityService.getAnniversary()
      this.event = res.data.collection[0]
    }
  },
  computed: {
    ...mapGetters(['hasCoreProgram'])
  },
  created() {
    this.loading = true
    Promise.all([this.fetchCoreProgram(), this.fetchWelcome(), this.fetchAnniversary()]).then(() => {
      this.loading = false
    })
  }
}
</script>
