<template>
  <v-container class="campaign-list" fluid grid-list-lg>
    <CampaignList class="transition-div" />
  </v-container>
</template>

<script>
import CampaignList from '@/components/CampaignList'

export default {
  components: {
    CampaignList
  }
}
</script>
