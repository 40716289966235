export default class SettingsLocation {
  line_1: string
  line_2: string
  state: string
  city: string
  zipcode: string

  constructor(
    line_1 = '',
    line_2 = '',
    state = '',
    city = '',
    zipcode = ''
  ) {
    this.line_1 = line_1,
      this.line_2 = line_2,
      this.state = state,
      this.city = city,
      this.zipcode = zipcode
  }
}
