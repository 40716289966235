<template>
  <div>
    <v-layout row wrap justify-end>
      <v-flex class="flex-div align-center py-0">
        <div class="flex-div align-center justify-end">
          <v-flex xs4 pr-0>
            <v-card class="pr-2 blue-grey lighten-5">
              <v-select
                flat
                v-model="calculationPeriod"
                :items="calculationPeriodItems"
                mandatory
                solo
                append-outer-icon="date_range"
                menu-props="offsetY"
                hide-details
                @change="periodReference()"
              ></v-select>
            </v-card>
          </v-flex>
        </div>
      </v-flex>
    </v-layout>
    <!-- <v-layout row wrap>
      <v-flex md3 sm6>
        <v-card class="px-4 py-4 tile-dashboard">
          <v-layout row>
            <v-flex grow>
              <div class="flex-div flex-column">
                <h3 class="title-info text-uppercase font-weight-regular mb-1">New members</h3>
                <animated-number :value="newMembers" :duration="500" :formatValue="formatValue"
                  class="info-content title font-weight-black" />
              </div>
            </v-flex>
            <v-flex shrink>
              <div class="icon-shape deep-purple accent-4 flex-div align-center justify-center">
                <v-icon dark>person</v-icon>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row mt-2>
            <v-flex>
              <p class="subheading mb-0"><span class="green--text text--darken-1 font-weight-bold">
                  <v-icon small class="mb-1 green--text text--darken-1">arrow_upward</v-icon>5,6%
                </span><span class="title-info">{{ periodSentence }}</span></p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex md3 sm6>
        <v-card class="px-4 py-4 tile-dashboard">
          <v-layout row>
            <v-flex grow>
              <div class="flex-div flex-column">
                <h3 class="title-info text-uppercase font-weight-regular mb-1">Order transactions</h3>
                <animated-number :value="orders" :duration="500" :formatValue="formatValue"
                  class="info-content title font-weight-black" />
              </div>
            </v-flex>
            <v-flex shrink>
              <div class="icon-shape light-green flex-div align-center justify-center">
                <v-icon dark>trending_up</v-icon>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row mt-2>
            <v-flex>
              <p class="subheading mb-0"><span class="red--text text--darken-1 font-weight-bold">
                  <v-icon small class="mb-1 red--text text--darken-1">arrow_downward</v-icon>3%
                </span> <span class="title-info">{{ periodSentence }}</span></p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex md3 sm6>
        <v-card class="px-4 py-4 tile-dashboard">
          <v-layout row>
            <v-flex grow>
              <div class="flex-div flex-column">
                <h3 class="title-info text-uppercase font-weight-regular mb-1">Net revenue</h3>
                <animated-number :value="earnings" :duration="500" :formatValue="formatPrice"
                  class="info-content title font-weight-black" />
              </div>
            </v-flex>
            <v-flex shrink>
              <div class="icon-shape light-blue flex-div align-center justify-center">
                <v-icon dark>account_balance</v-icon>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row mt-2>
            <v-flex>
              <p class="subheading mb-0"><span class="green--text text--darken-1 font-weight-bold">
                  <v-icon small class="mb-1 green--text text--darken-1">arrow_upward</v-icon>2,3%
                </span> <span class="title-info">{{ periodSentence }}</span></p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex md3 sm6>
        <v-card class="px-4 py-4 tile-dashboard">
          <v-layout row>
            <v-flex grow>
              <div class="flex-div flex-column">
                <h3 class="title-info text-uppercase font-weight-regular mb-1">Cash back</h3>
                <animated-number :value="cashback" :duration="500" :formatValue="formatPrice"
                  class="info-content title font-weight-black" />
              </div>
            </v-flex>
            <v-flex shrink>
              <div class="icon-shape amber flex-div align-center justify-center">
                <v-icon dark>shopping_basket</v-icon>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row mt-2>
            <v-flex>
              <p class="subheading mb-0"><span class="green--text text--darken-1 font-weight-bold">
                  <v-icon small class="mb-1 green--text text--darken-1">arrow_upward</v-icon>7,5%
                </span> <span class="title-info">{{ periodSentence }}</span></p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout> -->
    <v-layout row wrap>
      <v-flex xs12>
        <v-card v-if="!dataWaiting">
          <v-toolbar flat color="white">
            <v-toolbar-title>Last Transactions</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="transactions"
            :rows-per-page-text="$t('common.rowsPerPage')"
            :loading="dataWaiting"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td class="date-width">{{ props.item.created_at }}</td>
              <td>
                {{
                  props.item.company && props.item.company.name
                    ? props.item.company.name
                    : '-'
                }}
              </td>
              <td>
                {{
                  props.item.point_of_sale && props.item.point_of_sale.name
                    ? props.item.point_of_sale.name
                    : '-'
                }}
              </td>
              <td>
                {{
                  props.item.discounts.length > 0 &&
                  props.item.discounts[0].consumable &&
                  props.item.discounts[0].consumable.target
                    ? props.item.discounts[0].consumable.target.name
                    : 'Cart'
                }}
              </td>
              <td>{{ props.item.operation | capitalize }}</td>
              <td>
                {{
                  props.item.amount_cart
                    ? (props.item.amount_cart | monetize)
                    : '-'
                }}€
              </td>
              <template v-if="props.item.discounts.length > 0">
                <td>
                  {{
                    props.item.discounts &&
                    props.item.discounts[0].consumable &&
                    props.item.discounts[0].consumable.amount
                      ? props.item.discounts[0].consumable.amount.toCurrency()
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    props.item.discounts &&
                    props.item.discounts[0].consumable &&
                    props.item.discounts[0].consumable.amount_kind ===
                      'percentage'
                      ? '%'
                      : '€'
                  }}
                </td>
                <td>
                  {{ props.item.discounts[0].computed_amount | monetize }}€
                </td>
                <td>
                  {{ props.item.discounts[0].finders_fee_amount | monetize }}€
                </td>
              </template>
              <template v-else>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </template>
              <td>{{ revenue(props.item) | monetize }}€</td>
              <td>{{ props.item.customer_identifiers[0].identifier }}</td>
              <td>
                {{
                  props.item.source === 'in_store' ? 'In store' : 'Out store'
                }}
              </td>
            </template>
          </v-data-table>
        </v-card>
        <clip-loader
          v-else
          class="my-4"
          :loading="dataWaiting"
          color="#78909c"
          width="60px"
          height="60px"
        >
        </clip-loader>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import req from '@/packs/request.js'
import Utils from '@/packs/utils.js'

// import AnimatedNumber from 'animated-number-vue'
// import BarChart from '@/components/Charts/BarChart.js'
// import LineChart from '@/components/Charts/LineChart.js'

export default {
  components: {
    // AnimatedNumber,
    // BarChart,
    // LineChart,
  },
  data() {
    return {
      dataWaiting: false,
      finishFillData: false,
      //Fakedata
      newMembers: 3,
      orders: 8,
      earnings: 529,
      cashback: 66,

      // Graph
      datacollection: null,

      pointsEarned: 500,
      pointsRedeemed: 230,

      voucherEarned: 3,
      voucherExpired: 0,
      transactions: [],

      voucherToissued: 3,

      // -------
      calculationPeriodItems: [
        'Today',
        'Yesterday',
        'Last 7 days',
        'Last 30 days',
        'Yearly',
        'From the beginning',
      ],
      calculationPeriod: 'Today',
      periodSentence: 'today',
      customersFilter: 'member',
      headers: [
        {
          text: 'Date',
          value: 'created_at',
        },
        {
          text: 'Retailer',
          value: 'advertiser.company.name',
        },
        {
          text: 'Pos name',
          value: 'point_of_sale.name',
        },
        {
          text: 'Item/cart',
          value: 'item',
        },
        {
          text: 'Offer type',
          value: 'OfferType',
        },
        {
          text: 'Order amount',
          value: 'amount_cart',
        },
        {
          text: 'Offer amount',
          value: 'offerAmount',
        },
        {
          text: 'Currency or %',
          value: 'currency',
        },
        {
          text: 'Discount/Cashback applied',
          value: 'discount',
        },
        {
          text: "Finder's fee",
          value: 'findersfee',
        },
        {
          text: 'Net revenue',
          value: 'revenue',
        },
        {
          text: 'Customer ID',
          value: 'CustomerId',
        },
        {
          text: 'Data source',
          value: 'datasource',
        },
      ],
    }
  },
  methods: {
    revenue(operation) {
      if (operation.discounts.length === 0 || !operation.discounts.consumable)
        return '-'
      else if (
        operation.discounts[0].consumable.type.toLowerCase() == 'permanent'
      )
        return (
          operation.discounts[0].finders_fee_amount -
          operation.discounts[0].computed_amount
        )
      else return operation.discounts[0].finders_fee_amount
    },
    formatValue(value) {
      const number = `${Number(value).toFixed(0)}`
      const format = Intl.NumberFormat('fr-FR').format(number)
      return format
    },
    formatPrice(value) {
      // let price = Intl.NumberFormat().format(value)
      const price = Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(value)
      return price
    },
    periodReference() {
      switch (this.calculationPeriod) {
        case 'Today':
          this.periodSentence = 'today'
          this.newMembers = 3
          this.orders = 8
          this.earnings = 529
          this.cashback = 66
          break
        case 'Yesterday':
          this.periodSentence = 'yesterday'
          this.newMembers = 2
          this.orders = 5
          this.earnings = 454
          this.cashback = 58
          break
        case 'Last 7 days':
          this.periodSentence = 'since last week'
          this.newMembers = 20
          this.orders = 56
          this.earnings = 2068
          this.cashback = 432
          break
        case 'Last 30 days':
          this.periodSentence = 'since last month'
          this.newMembers = 70
          this.orders = 321
          this.earnings = 7941
          this.cashback = 2789
          break
        case 'Yearly':
          this.periodSentence = 'from the beginning of the year'
          this.newMembers = 3012
          this.orders = 12894
          this.earnings = 34891
          this.cashback = 12903
          break
        case 'From the beginning':
          this.periodSentence = 'from the beginning'
          this.newMembers = 6817
          this.orders = 26738
          this.earnings = 79184
          this.cashback = 33019
          break
      }
    },
    fillData() {
      ;(this.registrationData = {
        labels: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        datasets: [
          {
            type: 'bar',
            label: 'Full registration',
            backgroundColor: '#311B92',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
          },
          {
            type: 'bar',
            label: 'Basic registration',
            backgroundColor: '#9575CD',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
          },
        ],
      }),
        (this.loyaltyData = {
          labels: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
          datasets: [
            {
              type: 'line',
              label: 'Earn points activity',
              borderColor: '#64B5F6',
              yAxisID: 'y-axis-0',
              backgroundColor: 'transparent',
              data: [
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
              ],
            },
            {
              type: 'line',
              borderColor: '#1976D2',
              backgroundColor: 'transparent',
              yAxisID: 'y-axis-0',
              label: 'Burn points activity',
              data: [
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
              ],
            },
            {
              type: 'line',
              borderColor: '#4DB6AC',
              backgroundColor: 'transparent',
              yAxisID: 'y-axis-1',
              label: 'Redemption voucher',
              data: [
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
                this.getRandomInt(),
              ],
            },
          ],
        }),
        (this.loyaltyOptions = {
          tooltips: {
            mode: 'point',
          },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: false,
                position: 'left',
                id: 'y-axis-0',
                ticks: {
                  fontColor: '#1876D1',
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Points',
                  fontColor: '#1876D1',
                },
              },
              {
                stacked: false,
                position: 'right',
                id: 'y-axis-1',
                ticks: {
                  fontColor: '#009688',
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Voucher redeemed',
                  fontColor: '#009688',
                },
              },
            ],
          },
          scaleLabel: {
            display: true,
            labelString: 'testtt',
          },
        }),
        (this.finishFillData = true)
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 7
    },
  },
  mounted() {
    ;(this.finishFillData = false), (this.dataWaiting = true)
    this.fillData()
    req
      .get('/transactions')
      .then(response => {
        this.transactions = response.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.dataWaiting = false
      })
  },
}
</script>
<style>
.date-width {
  display: flex;
  align-items: center;
  width: 200px;
}
</style>
