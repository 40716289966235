<template>
  <v-card class="d-flex mt-3 pa-3 relative level-block" color="#f7f7f7">
    <div class="absolute btn-delete-level" v-if="statusSystem.levels.length > 2" @click="deleteLevel()">
      <v-icon class="white--text">close</v-icon>
    </div>
    <div>
      <label class="font-weight-medium">Statut #{{ index + 1 }}</label>
      <div class="d-flex">
        <v-text-field v-model="level_.name" type="text" label="Nom du statut" hide-details></v-text-field>
      </div>
    </div>
    <div class="mx-4" style="width: 2px">
      <v-divider vertical></v-divider>
    </div>
    <div class="w-100">
      <label class="font-weight-medium">Configuration :</label>
      <div class="d-flex">
        <div class="d-flex" v-if="statusSystem.counter === 'award'">
          <v-text-field v-model.number="level_.unit_threshold" label="Minimum to enter tier" type="number" hide-details></v-text-field>
          <div class="ml-1 no-wrap align-self-end">{{ getCurrencyName }}</div>
        </div>
        <v-text-field v-else v-model.number="membership_fee_" label="Membership fee" type="number" append-icon="euro_symbol" hide-details></v-text-field>
        <v-text-field v-model.number="award_multiplier_" label="Pool award multiplier" type="number" class="ml-4" hide-details></v-text-field>
        <div class="d-flex ml-4" v-if="statusSystem.counter === 'award'">
          <v-checkbox v-model="maintenance" class="ma-0 align-self-end" hide-details></v-checkbox>
          <v-text-field :disabled="!maintenance" v-model="level_.unit_sustain" type="number" label="Minimum maintenance" hide-details min="0"></v-text-field>
          <div class="ml-1 no-wrap align-self-end">{{ getCurrencyName }}</div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    level: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
    }
  },
  data() {
    return {
      level_: {...this.level}
    }
  },
  methods: {
    updateLevel() {
      this.$emit('updateLevel', { index: this.index, data: this.level_ })
    },
    deleteLevel() {
      this.$emit('deleteLevel', this.index)
    }
  },
  computed: {
    ...mapState({
      statusSystem: state => state.campaignBuilder.campaign.status_system,
      statusSystemCounter: state => state.campaignBuilder.campaign.status_system.counter,
      currencyName: state => state.campaignBuilder.campaign.loyalty_currency.name,
      currencyOther: state => state.campaignBuilder.campaign.loyalty_currency.other,
    }),
    getCurrencyName() {
      return this.currencyName !== 'Other' ? this.currencyName : this.currencyOther
    },
    membership_fee_: {
      get() {
        return this.level_.membership_fee ? this.level_.membership_fee.toCurrency() : this.level_.membership_fee
      },
      set(value) {
        this.level_.membership_fee = value ? value.toCents() : 0
      }
    },
    award_multiplier_: {
      get() {
        return this.level_.award_multiplier ? this.level_.award_multiplier.toCurrency() : this.level_.award_multiplier
      },
      set(value) {
        this.level_.award_multiplier = value ? value.toCents() : 0
      }
    },
    maintenance: {
      get() {
        return this.level_.maintenance
      },
      set(value) {
        if (!value) this.level_.unit_sustain = null
        this.level_.maintenance = value
      }
    }
  },
  watch: {
    level_: {
      deep: true,
      handler() {
        console.log('update status level')
        this.updateLevel()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/coreProgram"
</style>
