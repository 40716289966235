<template>
  <div>
    <v-card class="w-100">
      <div class="header-card pa-3">
        <h3 class="font-weight-medium">
          <v-icon small class="align-icon">filter_list</v-icon>
          Segmentation de la base client
        </h3>
      </div>
      <v-layout row class="pa-3">
        <v-flex xs12>
          <CustomersGeneralFilterClassic
            :segments="segmentsSplit"
            :create="create"
            @UpdateSegment="getSegmentSelected($event)"
          ></CustomersGeneralFilterClassic>
          <CustomersGeneralFilterAdvanced
            :customer_base="customer_base"
            :status="status"
            :selected="selected"
            @canCreateSegment="createSegment($event)"
          ></CustomersGeneralFilterAdvanced>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import CustomersGeneralFilterAdvanced from './CustomersGeneralFilterAdvanced'
import CustomersGeneralFilterClassic from './CustomersGeneralFilterClassic'

export default {
  components: {
    CustomersGeneralFilterClassic,
    CustomersGeneralFilterAdvanced,
  },
  props: {
    segments: {
      type: Array,
      required: true,
    },
    customer_base: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      status: [],
      selected: {},
      segmentsSplit: [],
      create: { can: false }
    }
  },
  methods: {
    getSegmentSelected(segment) {
      this.selected = segment
    },
    createSegment(permission) {
      this.create = permission
    },
    splitSegments() {
      if (this.segments.length > 0)
        for (const each of this.segments) {
          if (each.filters[0]) {
            if (each.filters[0].kind === 'status')
              this.status.push(each.filters[0])
            this.segmentsSplit.push(each)
          }
        }
    },
  },
  created() {
    this.splitSegments()
  },
  watch: {
    segments: {
      deep: true,
      handler() {
        this.status = []
        this.segmentsSplit = []
        this.splitSegments()
      },
    },
  },
}
</script>

<style scoped lang="css">
.header-card {
  background: #eceff1;
  border-bottom: 1px solid #cfd8dc;
  color: #37474f;
}

.align-icon {
  font-size: 16px;
  vertical-align: middle;
  padding-bottom: 4px;
}
</style>
