<template>
  <v-btn dark small color="success lighten-1 ma-0" @click="downloadFiles">
    <v-icon left dark>cloud_download</v-icon>
    {{ $t('customers.export') }}
  </v-btn>
</template>

<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'

export default {
  props: {
    base_id: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  methods: {
    downloadFiles() {
      const url = `/customer_bases/${this.base_id}/customers.csv`
      const date = new Date()
      const filename = `customers_${date.getFullYear()}_${date.getMonth()}_${date.getDay()}.csv`
      req.download(url, filename)

      EventBus.$emit('showSnackbar', {
        text: 'Téléchargement de votre de la base clients en cours, merci de patienter. Temps estimé < 2 minutes.',
        color: 'success'
      })
    }
  }
}
</script>
