import { Module } from 'vuex';

interface PaymentState {
  IBAN: string;
  id: string;
  loading: boolean;
}

const SET_IBAN = 'SET_IBAN';
const SET_ID = 'ID';
const SET_LOADING = 'SET_LOADING';

export const payment: Module<PaymentState, any> = {
  namespaced: true,
  state: {
    loading: false,
    IBAN: '',
    id: ''
  },
  getters: {
    id(state) {
      return state.id;
    },

    IBAN(state) {
      return state.IBAN;
    },

    loading(state) {
      return state.loading;
    }
  },

  mutations: {
    [SET_LOADING](state, loading) {
      state.loading = loading;
    },

    [SET_IBAN](state, IBAN) {
      state.IBAN = IBAN;
    },

    [SET_ID](state, id) {
      state.id = id;
    }
  },

  actions: {
    setLoading({ commit }, loading) {
      commit(SET_LOADING, loading);
    },

    setIBAN({ commit }, IBAN) {
      commit(SET_IBAN, IBAN);
    },

    setId({ commit }, id) {
      commit(SET_ID, id);
    }
  }
};
