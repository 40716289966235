import { Module } from 'vuex';
import { IUsageHistory } from '../interfaces';

interface HistoryState {
  usage: IUsageHistory[];
}

const SET_USAGE = 'SET_USAGE';

export const history: Module<HistoryState, any> = {
  namespaced: true,
  state: {
    usage: []
  },

  getters: {
    usage(state) {
      return state.usage;
    }
  },

  mutations: {
    [SET_USAGE](state, usage) {
      state.usage = usage;
    }
  },

  actions: {
    setUsage({ commit }, usage) {
      commit(SET_USAGE, usage);
    }
  }
};
