<template>
  <div class="h-100">
    <div class="relative">
      <v-stepper v-model="step" non-linear class="elevation-0" style="min-height: calc(100vh - 226px)">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" editable step="1">
            {{ $t('campaignCreate.basicParameters') }}
          </v-stepper-step>
          <v-divider v-if="!payWithPool"></v-divider>
          <v-stepper-step v-if="!payWithPool" :complete="step > 2" editable step="2">
            {{ $t('campaignCreate.offers') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > (payWithPool ? 3 : 2)" editable :step="payWithPool ? 2 : 3">
            {{ $t('campaignCreate.pointOfSales') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :step="payWithPool ? 3 : 4" editable>
            {{ $t('campaignCreate.publishing') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items mode="out-in">
          <v-stepper-content step="1" class="pt-1">
            <v-container fluid grid-list-xl>
              <v-layout row wrap v-if="!loading">
                <CoreProgramCampaignSettings :campaign="campaign"></CoreProgramCampaignSettings>
              </v-layout>
              <v-layout row wrap v-else-if="loading" justify-center>
                <clip-loader class="my-4" :loading="loading" color="#78909c" width="60px" height="60px"></clip-loader>
              </v-layout>
            </v-container>
          </v-stepper-content>

          <v-stepper-content v-if="!payWithPool && !loading" step="2" class="pt-1">
            <CampaignActionsSelection :campaign="campaign"></CampaignActionsSelection>
          </v-stepper-content>

          <v-stepper-content :step="payWithPool ? 2 : 3" class="pt-1">
            <CampaignPointsOfSaleSelection />
          </v-stepper-content>

          <v-stepper-content v-if="!loading" :step="payWithPool ? 3 : 4" class="pt-1">
            <CoreProgramCampaignStep4 :campaign="campaign"></CoreProgramCampaignStep4>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <CampaignBuilderNavigationBar
        :step.sync="step"
        :stepCount="payWithPool ? 3 : 4"
        :editMode="editMode"
        @submit="submit()"
      />
    </div>
    <DialogSaveCampaignDraft :edit-mode="editMode" :dialog="showDialog" @close="closeDialog()" @discard="discardChanges()" @save="saveChanges()" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CampaignBuilder from '@/store/modules/campaignBuilder/campaignBuilder.store'
import CampaignBuilderNavigationBar from '@/components/CampaignBuilderNavigationBar'
import CoreProgramCampaignSettings from '@/components/CoreProgramCampaignSettings'
import CampaignActionsSelection from '@/components/CampaignActionsSelection'
import CampaignPointsOfSaleSelection from '@/components/CampaignPointsOfSaleSelection'
import CoreProgramCampaignStep4 from '@/components/CoreProgramCampaignStep4'
import DialogSaveCampaignDraft from '@/components/DialogSaveCampaignDraft'
import EventBus from '@/packs/event-bus'

export default {
  components: {
    CoreProgramCampaignSettings,
    CampaignBuilderNavigationBar,
    CampaignActionsSelection,
    CampaignPointsOfSaleSelection,
    CoreProgramCampaignStep4,
    DialogSaveCampaignDraft
  },
  data() {
    return {
      step: 1,
      showDialog: false,
      to: null,
      allowChangeRoute: false
    }
  },
  beforeCreate() {
    this.$store.registerModule('campaignBuilder', CampaignBuilder)
  },
  beforeRouteLeave(to, from, next) {
    let shouldNext = false

    if (this.campaign.status !== 'draft' || to.path === '/signin') this.allowChangeRoute = true

    if (!this.allowChangeRoute) {
      if (this.to) shouldNext = true
      else {
        this.to = to
        this.showDialog = true
      }
    } else shouldNext = true

    if (shouldNext) {
      if (to?.params?.company) EventBus.$emit('refreshCompany', to.params.company)
      this.resetState()
      this.$store.unregisterModule('campaignBuilder')
      next()
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'fetchDraft',
      'setMode',
      'setEditMode',
      'fetchCampaign',
      'persist',
      'resetState'
    ]),
    closeDialog() {
      this.showDialog = false
      this.to = null
    },
    saveChanges() {
      this.showDialog = false
      this.allowChangeRoute = true
      if (this.to) this.$router.push(this.to)
      else this.$router.go(-1)
    },
    async discardChanges() {
      this.showDialog = false
      this.allowChangeRoute = true
      if (!this.editMode) await this.destroy()
      if (this.to) this.$router.push(this.to)
      else this.$router.go(-1)
    },
    checkEditMode() {
      if (this.$route.params.id === 'new') this.setEditMode(false)
      else this.setEditMode(true)
    },
    async submit() {
      try {
        await this.persist()
        this.allowChangeRoute = true
        this.$router.push({ path: '/core-program/campaigns' }).catch(err => err)
      } catch (e) {
        this.allowChangeRoute = false
      }
    }
  },
  computed: {
    ...mapState({
      campaign: state => state.campaignBuilder.campaign,
      editMode: state => state.campaignBuilder.editMode,
      loading: state => state.campaignBuilder.loading,
      payWithPool: state => state.campaignBuilder.payWithPool
    })
  },
  created() {
    this.checkEditMode()
    this.setMode('fidelity')

    if (this.editMode) this.fetchCampaign()
    else this.fetchDraft()
  }
}
</script>
