<template>
  <div>
    <v-btn flat class="text-capitalize ma-0 h-100" style="min-width: 120px" @click.stop="updateState()" :disabled="!campaign.mine">
      <v-icon class="material-icons-round mr-2" :style="{ color: campaign.state === 'active' ? '#4ad991' : '#ff7285' }">
        {{ campaign.state === 'active' ? 'toggle_on' : 'toggle_off' }}
      </v-icon>
      <span :style="{ color: colorBtn }">
        {{ campaign.state === 'active' ? 'Active' : 'Inactive' }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import ConquestService from '@/services/ConquestService'
import SubfidelityService from '@/services/SubFidelityService'

export default {
  props: {
    campaign: {
      type: Object,
      required: true
    },
    colorBtn: {
      type: String,
      required: false,
      default: 'black'
    }
  },
  methods: {
    async updateState() {
      const functionName = this.campaign.state === 'active' ? 'deactivate' : 'activate'
      const service = this.campaign.type === 'conquest' ? ConquestService : SubfidelityService
      const res = await service[functionName](this.campaign.id)
      this.$emit('update', res.data.state)
    }
  }
}
</script>

<style></style>
