<template>
  <v-layout row wrap class="py-2">
    <v-flex xs12>
      <div class="white elevation-1 w-100">
        <v-layout class="px-3 pt-2">
          <v-flex xs12>
            <h2>New workplace</h2>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="px-3">
          <v-flex md2>
            <h3>Workplace name</h3>
            <v-text-field v-model="name" label="Name"></v-text-field>
          </v-flex>
          <v-flex md3>
            <h3>Email list</h3>
            <div class="flex-div align-center">
              <v-checkbox
                v-model="emailType"
                color="primary"
                value="domains"
                label="Domains"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="emailType"
                color="primary"
                value="addresses"
                label="Addresses"
                hide-details
              >
              </v-checkbox>
            </div>
            <v-btn
              v-if="emailType.length > 0"
              @click="addEmail = true"
              class="ml-0 mt-3 elevation-0"
              color="secondary"
            >
              <v-icon class="mr-2">mail_outline</v-icon>Add<span
                class="ml-1"
                v-for="(type, index) in emailType"
                :key="index"
              >
                {{ type
                }}<span class="mr-1" v-if="index < emailType.length - 1"
                  >,
                </span></span
              >
            </v-btn>
          </v-flex>
          <v-flex md2 v-if="segmentsWorkplace.length > 0">
            <h3>Client group</h3>
            <v-select
              v-model="segment"
              label="Selection client group"
              :items="segmentsWorkplace"
              item-text="name"
              menu-props="offsetY"
              return-object
            ></v-select>
          </v-flex>
          <v-flex md2>
            <h3>Type</h3>
            <v-select
              disabled
              label="Corporation, Association, Works Council, Provider"
              :items="[
                'Corporation',
                'Association',
                'Works Council',
                'Provider',
              ]"
              item-text="name"
              menu-props="offsetY"
              item-value="id"
            ></v-select>
          </v-flex>
          <v-flex ml-auto class="flex-div justify-end">
            <v-btn @click="close()" outline color="error">
              <v-icon class="mr-2">close</v-icon>Cancel
            </v-btn>
            <v-btn @click="addCompany()" color="primary">
              <v-icon class="mr-2">check</v-icon>Ok
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
    <dialog-add-email
      v-model="addEmail"
      :emailType="emailType"
      :domains="domains"
      :emails="emails"
    ></dialog-add-email>
  </v-layout>
</template>
<script>
import req from '@/packs/request.js'
import DialogAddEmail from './DialogAddEmail'
import EventBus from '@/packs/event-bus'
export default {
  components: {
    DialogAddEmail,
  },
  data: () => {
    return {
      name: '',
      emailType: ['domains'],
      addEmail: false,
      domains: [],
      emails: [],
      segment: {},
      segmentItems: [],
    }
  },
  props: {
    segmentsWorkplace: Array,
    current_customer_base_id: String,
  },
  methods: {
    close() {
      this.$emit('closeEvent', false)
    },
    createEditSegment(workplace) {
      if (this.segment) {
        if (this.segment.id) {
          //update existing segment
          const segment = this.segment
          segment.filters.push({
            id: workplace.filter.id,
          })
          req
            .put(
              '/customer_bases/' +
                this.current_customer_base_id +
                '/segments/' +
                this.segment.id,
              {
                segment,
              }
            )
            .catch(error => {
              console.error(error)
            })
        } else {
          //create segment with new workplace
          this.segment['filters'] = this.createSegmentFilters(workplace)
          const segment = this.segment
          req
            .post(
              '/customer_bases/' +
                this.current_customer_base_id +
                '/segments',
              {
                segment,
              }
            )
            .catch(error => {
              console.error(error)
            })
        }
      }
    },
    createSegmentFilters(workplace) {
      const filters = []
      filters.push({
        id: workplace.filter.id,
      })
      return filters
    },
    addCompany() {
      const object = {
        id: '',
        name: this.name,
        domains: this.domains,
        emails: this.emails,
      }
      req.post('/workplaces', object).then(response => {
        this.createEditSegment(response.data)
        this.$emit('closeEvent', false)
        EventBus.$emit('showSnackbar', {
          text: 'The workplace ' + this.name + ' has been created',
          color: 'success',
        })
        this.$emit('refreshList', true)
      })
    },
  },
}
</script>
