<template>
  <v-container fluid grid-list-lg>
    <v-layout>
      <v-flex xs12 class="headline mb-4">Email templates</v-flex>
    </v-layout>
    <v-layout>
      <v-card-text>
        <v-layout row>
          <v-flex xs3 pa-1 class="flex-div align-center">Layouts</v-flex>
          <v-flex xs3 pa-1 class="flex-div align-center">User templates</v-flex>
          <v-flex xs6 pa-1 class="flex-div align-center"
            ><span class="text-xs-center">Action</span></v-flex
          >
        </v-layout>
        <v-layout row>
          <v-flex xs3 pa-1 class="flex-div align-center">
            <v-select
              v-model="selected_layout"
              @change="load_components()"
              :items="default_templates"
              item-value="id"
              item-text="title"
            ></v-select>
          </v-flex>
          <v-flex xs3 pa-1 class="flex-div align-center">
            <v-select
              v-model="selected_template"
              @change="load_texts()"
              :items="custom_templates"
              item-value="id"
              item-text="title"
            ></v-select>
          </v-flex>
          <v-flex xs6>
            <v-btn
              v-if="selected_template != ''"
              color="primary"
              @click="email_preview()"
              >View Preview</v-btn
            >
            <v-btn v-else color="primary">Create Template</v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs6>
            <div v-for="component in components" :key="component.id">
              <div v-if="component.kind.indexOf('image') < 0">
                <h5>{{ component.kind | capitalize }}</h5>
                <v-text-field :value="texts[component.kind]" />
              </div>
              <div v-else class="mt-1 mb-3">
                <h5>{{ component.kind | capitalize }}</h5>
                <img
                  :src="texts[component.kind]"
                  width="100"
                  height="100"
                  class=""
                />
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-layout>
  </v-container>
</template>

<script>
import req from '@/packs/request'

export default {
  data() {
    return {
      default_templates: [],
      custom_templates: [],
      selected_layout: '',
      selected_template: '',
      components: [],
      texts: {},
      preview:
        'http://localhost:3000/rails/mailers/campaign_mailer/reward_email',
    }
  },
  methods: {
    email_preview() {
      const win = window.open(this.preview, '_blank')
      win.focus()
    },
    load_components() {
      this.selected_template = ''
      this.texts = {}

      this.$nextTick(() => {
        const selected = this.default_templates.find(e => {
          return e.id == this.selected_layout
        })
        if (selected) {
          Object.assign(this.components, selected.components)
        }
      })
    },
    load_texts() {
      this.selected_layout = ''

      this.$nextTick(() => {
        const selected = this.custom_templates.find(e => {
          return e.id == this.selected_template
        })
        if (selected) {
          this.texts = {}
          const url = req.base_url + '/' + selected.preview_path
          this.preview = url
          this.components = []
          selected.components.forEach(e => {
            this.components.push(e)

            if (e.kind.indexOf('image') == -1) this.texts[e.kind] = e.content
            else this.texts[e.kind] = e.image_url
          })
        }
      })
    },
  },
  mounted: function() {
    req
      .get('/email/templates')
      .then(response => {
        response.data.forEach(elem => {
          if (elem.default) this.default_templates.push(elem)
          else this.custom_templates.push(elem)
        })
      })
      .catch(error => {
        console.error(error)
      })
  },
}
</script>
