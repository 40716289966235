<template>
  <div>
    <div class="disp-flex">
      <b>#{{ index + 1 }}</b>
      <span class="mx-1 disp-flex">
        {{ $t('customers.data.cardIdentifier') }} ({{ identifier.kind | capitalize }}) :
      </span>
      <b v-if="!edit">{{ identifier.identifier }}</b>
      <template v-else>
        <v-text-field type="number" v-model="id" class="pt-0 mt-0 input-id" style="max-width: 150px" hide-details />
        <v-icon style="color: #2e7d32" class="cursor-pointer" @click="editIdentifier(identifier)">check</v-icon>
        <v-icon style="color: red" class="cursor-pointer" @click="edit = false">clear</v-icon>
      </template>
    </div>
    <div class="disp-flex caption" v-if="!idDelete">
      <a v-if="identifier.kind === 'extern'" class="mr-2" @click="edit = true">{{ $t('common.edit') }}</a>
      <a v-if="canDelete" style="color: red" @click="idDelete = true">{{ $t('common.delete') }}</a>
    </div>
    <div class="caption" v-else>
      {{ $t('customers.data.deleteIdentifier') }}
      <a class="mr-2" @click="deleteIdentifier(identifier)">{{ $t('common.boolYes') }}</a>
      <a style="color: red" @click="idDelete = false">{{ $t('common.boolNo') }}</a>
    </div>
  </div>
</template>

<script>
import req from '@/packs/request'

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    identifier: {
      type: Object,
      required: true
    },
    customer_id: {
      type: String,
      required: true
    },
    canDelete: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      idDelete: false,
      edit: false,
      id: ''
    }
  },
  methods: {
    async editIdentifier(identifier) {
      await req.put(`/customers/${this.customer_id}/identifiers/${identifier.id}`, {
        identifier: { identifier: this.id, kind: identifier.kind }
      })
      this.edit = false
      this.$emit('update')
    },
    async deleteIdentifier(identifier) {
      await req.delete(`/customers/${this.customer_id}/identifiers/${identifier.id}`)
      this.idDelete = false
      this.$emit('update')
    }
  },
  created() {
    this.id = this.identifier.identifier
  },
  watch: {
    identifier: {
      deep: true,
      handler() {
        this.id = this.identifier.identifier
      }
    }
  }
}
</script>

<style lang="sass">
.input-id
  input
    padding: 0 !important
    padding-top: 3px !important
  .v-select__selection--comma, .v-input__append-inner
    margin: 0 !important
</style>
