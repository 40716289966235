export default class LoyaltyCurrency {
  name: string
  national_currency: boolean
  inactivity_time: number | null
  expiration_mode: string
  expiration_value: number | null

  constructor(
    name = 'Points',
    national_currency = false,
    inactivity_time = null,
    expiration_mode = 'permanent',
    expiration_value = null
  ) {
    ;(this.name = name),
      (this.inactivity_time = inactivity_time),
      (this.national_currency = national_currency),
      (this.expiration_mode = expiration_mode),
      (this.expiration_value = expiration_value)
  }
}
