<template>
  <div>
    <v-btn @click="decrement()" :disabled="value <= 1" class="v-pagination__navigation">
      <v-icon>chevron_left</v-icon>
    </v-btn>
    <v-btn @click="increment()" :disabled="disableNext" class="v-pagination__navigation"
      ><v-icon>chevron_right</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    disableNext: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    increment() {
      this.$emit('input', this.value + 1)
    },
    decrement() {
      this.$emit('input', this.value - 1)
    }
  }
}
</script>

<style></style>
