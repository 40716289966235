<template>
  <div>
    <TransactionsItemFilter class="mb-4" @filters="updateFilters($event)" v-if="dFilters"></TransactionsItemFilter>
    <clip-loader class="my-5" :loading="loading" color="#78909c" width="60px" height="60px"></clip-loader>
    <v-card class="overflow-transactions custom-scrollbar" v-if="!loading && transactions.length > 0">
      <template v-for="transaction in transactions">
        <TransactionsItem
          :transaction="transaction"
          :see-customer="seeCustomer"
          :key="transaction.id"
          :read-only="readOnly"
          :disable="disable"
        ></TransactionsItem>
      </template>
    </v-card>
    <v-flex v-else-if="!loading" class="flex-div justify-center mt-4">
      Aucune transaction disponible
    </v-flex>

    <v-flex class="flex-div justify-center mt-4" v-if="dPagination && transactions.length > 0">
      <PaginationInfinite v-model="pagination.page" :disable-next="pagination.lastPage === this.pagination.page" />
    </v-flex>
  </div>
</template>

<script>
import TransactionsItemFilter from '@/components/TransactionsItemFilter'
import TransactionsItem from '@/components/TransactionsItem'
import PaginationInfinite from '@/components/PaginationInfinite'
import req from '@/packs/request.js'
import eventBus from '@/packs/event-bus'

export default {
  components: {
    TransactionsItem,
    TransactionsItemFilter,
    PaginationInfinite
  },
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    perPage: {
      type: Number
    },
    customView: {
      type: Array
    },
    dFilters: {
      type: Boolean,
      default: true
    },
    dPagination: {
      type: Boolean,
      default: true
    },
    disable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    seeCustomer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pagination: {
        perPage: this.perPage ? this.perPage : 10,
        page: 1,
        lastPage: null
      },
      queryParams: {
        page: 1
      },
      transactions: [],
      loading: true,
      nbTransactions: 0,
      filters: {}
    }
  },
  methods: {
    updateFilters(data) {
      this.filters = data

      if (this.filters.date) {
        this.queryParams = {
          ...this.queryParams,
          ...{ start_date: this.filters.date.start, end_date: this.filters.date.end }
        }
      } else this.queryParams = { page: this.pagination.page }

      this.fetchTransactions()
    },
    async fetchTransactions(nextPage = false) {
      this.loading = true

      const res = await req.get(
        this.apiUrl + '?per_page=' + this.pagination.perPage + '&page=' + this.pagination.page + this.moreFilters()
      )

      if (res.data.collection.length === 0 && nextPage) {
        if (this.pagination.page > 1) this.pagination.page -= 1
        this.pagination.lastPage = this.pagination.page
        this.loading = false
        return
      } else {
        this.transactions = []
        for (const each of res.data.collection) {
          if (this.defineView(each.source)) this.transactions.push(each)
        }
        if (res.data.collection.length < this.pagination.perPage) this.pagination.lastPage = this.pagination.page
        this.loading = false
      }
    },
    defineView(source) {
      if (!this.customView) return true
      for (const each of this.customView) {
        if (source === each) return true
      }
      return false
    },
    moreFilters() {
      let range = ''

      if (this.filters.date && this.filters.date.start && this.filters.date.end) {
        let startAt = this.filters.date.start
        let endAt = this.filters.date.end
        if (this.filters.startTime) startAt += `T${this.filters.startTime}`
        if (this.filters.endTime) endAt += `T${this.filters.endTime}`

        range = `&by_created_at=${startAt},${endAt}`
      }
      const pos =
        this.filters.selector && this.filters.selector.id ? '&by_point_of_sale_id=' + this.filters.selector.id : ''
      return range + pos
    }
  },
  created() {
    eventBus.$on('refreshTransactions', () => {
      this.fetchTransactions()
    })

    if (this.$route.query) {
      if (this.$route.query.start_date)
        this.filters.date = { start: this.$route.query.start_date, end: this.$route.query.end_date }
      if (this.$route.query.page) this.pagination.page = Number(this.$route.query.page)
    } else if (this.dPagination) this.$router.replace({ query: this.queryParams })

    this.fetchTransactions()
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        this.$router.replace({ query: this.queryParams })
      }
    },
    'pagination.page': {
      handler() {
        if (this.$route.query.page !== this.pagination.page) this.queryParams.page = this.pagination.page
        this.fetchTransactions(true)
      }
    },
    'filters.date': {
      deep: true,
      handler() {
        this.pagination.lastPage = null
        this.pagination.page = 1
      }
    }
  }
}
</script>
