<template>
  <v-container fluid grid-list-lg class="pa-0">
    <v-layout row wrap>
      <v-flex xs12 md12 lg6>
        <CustomersSegmentsFilterCustom
          :segmentsCustom="segmentsCustom"
          :customer_base="customer_base"
        ></CustomersSegmentsFilterCustom>
      </v-flex>
      <v-flex xs12 md12 lg6>
        <CustomersSegmentsFilterRFM
          :segmentsRFM="segmentsRFM"
        ></CustomersSegmentsFilterRFM>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CustomersSegmentsFilterCustom from './CustomersSegmentsFilterCustom'
import CustomersSegmentsFilterRFM from './CustomersSegmentsFilterRFM'

export default {
  components: {
    CustomersSegmentsFilterCustom,
    CustomersSegmentsFilterRFM,
  },
  props: {
    segments: {
      type: Array,
      required: true,
    },
    customer_base: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      segmentsRFM: [],
      segmentsCustom: [],
    }
  },
  methods: {
    splitSegments() {
      for (const each of this.segments) {
        if (!each.custom && each.tags.includes('rfm')) {
          this.segmentsRFM.push(each)
        } else if (each.custom && each.tags.includes('custom')) {
          this.segmentsCustom.push(each)
        }
      }
    },
  },
  created() {
    this.splitSegments()
  },
  watch: {
    segments: {
      deep: true,
      handler() {
        this.segmentsRFM = []
        this.segmentsCustom = []
        this.splitSegments()
      },
    },
  },
}
</script>
