<template>
  <v-container fluid grid-list-md>
    <v-layout>
      <v-flex>
        <h1 class="headline mt-2">{{ $t('nav.pointsOfSale') }}</h1>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-tabs
          dense
          color="grey lighten-5"
          v-model="selectedTab"
          left
          @change="selectedTab = $event"
        >
          <v-tab>
            <img
              src="@/assets/images/icons/key.svg"
              alt="icon"
              width="26"
              class="mr-2"
            />
            Propriétaires
          </v-tab>
          <v-tab :v-show="false">
            <img
              src="@/assets/images/icons/herited.svg"
              alt="icon"
              width="24"
              class="mr-2"
            />
            Enfants
          </v-tab>
          <v-tab v-if="incompletePointOfSales.length > 0">
            <v-icon class="mr-1">done</v-icon>
            A activer
            <v-badge class="pr-2" color="red">
              <template v-slot:badge>
                <span>{{ incompletePointOfSales.length }}</span>
              </template>
            </v-badge>
          </v-tab>
        </v-tabs>
      </v-flex>
    </v-layout>
    <v-layout pt-3 v-show="selectedTab == 0" column wrap mb-3>
      <PointOfSaleList
        :pointOfSales="pointOfSales"
        :pointOfSalesCount="pointOfSalesCount"
        :loading="!loadedPointOfSales"
        @pagination="fetchPointOfSales($event.page, $event.rowsPerPage)"
      />
    </v-layout>
    <v-layout pt-3 v-show="selectedTab == 1" column wrap mb-3>
      <PointOfSaleList
        :pointOfSales="childsPointOfSales"
        :pointOfSalesCount="childsPointOfSalesCount"
        :loading="!loadedChildsPointOfSales"
        @pagination="fetchChildsPointOfSales($event.page, $event.rowsPerPage)"
      />
    </v-layout>
    <v-layout pt-3 v-show="selectedTab == 2" column wrap mb-3>
      <IncompletePointOfSales
        :pointOfSales="incompletePointOfSales"
        :loading="!loadedIncompletePointOfSales"
        @onComplete="completePointOfSale($event)"
      />
    </v-layout>
  </v-container>
</template>

<script>
import req from '@/packs/request.js'
import PointOfSaleList from '@/components/PointsOfSale/PointOfSaleList'
import IncompletePointOfSales from '@/components/IncompletePointOfSales'

export default {
  components: {
    PointOfSaleList,
    IncompletePointOfSales,
  },
  data() {
    return {
      selectedTab: 0,
      pointOfSales: [],
      pointOfSalesCount: 0,
      childsPointOfSales: [],
      childsPointOfSalesCount: 0,
      incompletePointOfSales: [],
      loadedPointOfSales: false,
      loadedChildsPointOfSales: false,
      loadedIncompletePointOfSales: false,
    }
  },
  computed: {
    loadedPOS() {
      return this.loaded.pointOfsales
    },
  },
  created() {
    this.fetchIncompletePointOfSales()
  },
  methods: {
    async fetchPointOfSales(page, per_page) {
      const res = await req.get(
        '/company/point_of_sales?by_completion=true&with_discarded=true&page=' +
          page +
          '&per_page=' +
          per_page
      )

      this.pointOfSales = res.data.collection
      this.pointOfSalesCount = res.data.count
      this.loadedPointOfSales = true
    },
    async fetchChildsPointOfSales(page, per_page) {
      const res = await req.get(
        '/company/companies/point_of_sales?with_discarded=true&page=' +
          page +
          '&per_page=' +
          per_page
      )

      this.childsPointOfSales = res.data.collection
      this.childsPointOfSalesCount = res.data.count
      this.loadedChildsPointOfSales = true
    },
    async fetchIncompletePointOfSales() {
      const res = await req.get('/company/point_of_sales/incompleted')

      this.incompletePointOfSales = res.data
      this.loadedIncompletePointOfSales = true
      if (this.incompletePointOfSales.length > 0) this.selectedTab = 2
    },
    async completePointOfSale(pointOfSale) {
      const res = await req.put(
        `/company/point_of_sales/${pointOfSale.id}/complete`
      )
      await req.put(`/company/point_of_sales/${pointOfSale.id}`, {
        point_of_sale: pointOfSale,
      })

      this.incompletePointOfSalesLoaded = false
      this.fetchIncompletePointOfSales()
      this.fetchPointOfSales()
    },
  },
}
</script>
