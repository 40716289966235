<template>
  <v-card class="pa-3 w-100" style="min-height: calc(100vh - 350px)">
    <v-card-title class="mb-2">
      <div class="text-xs-center headline w-100 mb-2">Assistant de création de campagne</div>
      <div class="text-xs-center font-weight-light title w-100">{{ $t('createCampaign.generalInformations') }}</div>
    </v-card-title>
    <v-divider></v-divider>
    <div style="max-width: 600px" class="ml-auto mr-auto mt-5 mb-3">
      <div class="mt-4">
        <v-text-field v-model="name" :rules="nameRules" :disabled="canEdit" :label="$t('createCampaign.campaignSettings.inputCampaignName.label')"/>
      </div>
      <div class="mt-4">
        <label class="font-weight-medium subheading">Sélectionner le type de votre campagne :</label>
        <div class="mt-4 wrapper-campaign-type">
          <v-card class="block-events p-re" hover @click="changeDraft('welcome')" :class="{'select-channel': campaignKind === 'welcome', 'disabled-channel': capabilities.welcome || editMode}">
            <div class="triangle">
              <v-icon class="icon-check">check</v-icon>
            </div>
            <div class="title-channel">
              <div class="w-100 text-xs-center mb-1">
                <v-icon>meeting_room</v-icon>
              </div>
              Bienvenue
            </div>
          </v-card>
          <v-card class="block-events p-re" hover @click="changeDraft('event')" :class="{'select-channel': campaignKind === 'event', 'disabled-channel': capabilities.event || editMode}">
            <div class="triangle">
              <v-icon class="icon-check">check</v-icon>
            </div>
            <div class="title-channel">
              <div class="w-100 text-xs-center mb-1">
                <v-icon>cake</v-icon>
              </div>
              Anniversaire
            </div>
          </v-card>
          <v-card class="block-events p-re" hover @click="changeDraft('happyhour')" :class="{'select-channel': campaignKind === 'happyhour', 'disabled-channel': editMode}">
            <div class="triangle">
              <v-icon class="icon-check">check</v-icon>
            </div>
            <div class="title-channel">
              <div class="w-100 text-xs-center mb-1">
                <v-icon>schedule</v-icon>
              </div>
              Happy hour
            </div>
          </v-card>
        </div>
      </div>
      <AnticipationSelection v-if="campaignKind === 'event' && !loading" />
      <TimeSlotSelection v-else-if="campaignKind === 'happyhour' && !loading" />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AnticipationSelection from '@/components/AnticipationSelection'
import TimeSlotSelection from '@/components/TimeSlotSelection'

export default {
  components: {
    TimeSlotSelection,
    AnticipationSelection
  },
  data() {
    return {
      nameRules: [
        v => !!v || this.$t('createCampaign.campaignSettings.inputCampaignName.required'),
        v => (v && v.length >= 3) || this.$t('createCampaign.campaignSettings.inputCampaignName.characters')
      ]
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setName'
    ]),
    changeDraft(draft) {
      this.$emit('changeDraft', draft)
    }
  },
  computed: {
    ...mapState({
      campaign: state => state.campaignBuilder.campaign,
      campaignKind: state => state.campaignBuilder.campaign.kind,
      campaignName: state => state.campaignBuilder.campaign.name,
      loading: state => state.campaignBuilder.loading,
      capabilities: state => state.user.capabilities,
      editMode: state => state.campaignBuilder.editMode
    }),
    name: {
      get() {
        return this.campaignName
      },
      set(value) {
        this.setName(value)
      }
    },
    canEdit() {
      return this.campaign.status === 'draft' && this.editMode
    }
  }
}
</script>
