<template>
  <v-menu transition="slide-y-transition" offset-y close-on-click>
    <v-btn class="v-btn--flat" active-class="active" slot="activator">
      {{ $t('nav.loyalty') }}
    </v-btn>
    <v-list>
      <v-list-tile @click="to('/core-program/campaigns')" v-if="hasCoreProgram">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/fidelity.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.manageLoyaltyCampaigns') }}</span>
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="to('/core-program/new/')" v-else>
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/fidelity.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.createCoreProgram') }}</span>
        </v-list-tile-title>
      </v-list-tile>

      <v-menu class="w-100" offset-x right open-on-hover close-on-click :disabled="!hasCoreProgram">
        <v-list-tile slot="activator" class="w-100">
          <v-list-tile-title class="disp-flex align-center">
            <img src="@/assets/images/icons/offer.svg" width="24" class="mr-3" alt="icon" />
            <span>{{ $t('nav.createCampaign') }}</span>
          </v-list-tile-title>
          <v-list-tile-action class="justify-end">
            <v-icon>keyboard_arrow_right</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-list>
          <v-list-tile @click="to('/campaigns/event/new/'); $emit('reload')">
            <v-list-tile-title class="disp-flex align-center">
              <img src="@/assets/images/icons/loyalty_core.svg" width="18" class="mr-3" alt="icon" />
              <span>{{ $t('nav.eventCampaign') }}</span>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="to('/campaigns/communication/new'); $emit('reload')">
            <v-list-tile-title class="disp-flex align-center">
              <v-icon class="mr-3">textsms</v-icon>
              <span>Campagne communication</span>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="to('/campaigns/custom/new'); incrementRouterViewKey(); $emit('reload')">
            <v-list-tile-title class="disp-flex align-center">
              <img src="@/assets/images/icons/controls.svg" width="18" class="mr-3" alt="icon" />
              <span>{{ $t('nav.customCampaign') }}</span>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>

      <v-list-tile @click="to('/core-program/transactions')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/transactions.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.seeTransactions') }}</span>
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="to('/customers')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/customers.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.customers') }}</span>
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="to('/targets')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/procustomers.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.targets') }}</span>
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-if="company.usages.includes('fidelity') && company.donor" @click="to('/donations')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/donate.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.donations') }}</span>
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="to('/communications')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/email_template.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.communications') }}</span>
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions(['incrementRouterViewKey']),
    to(link) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push(link).catch(() => {})
    }
  },
  computed: {
    ...mapGetters(['hasCoreProgram']),
    company() {
      return this.$store.state.user.company
    }
  }
}
</script>
