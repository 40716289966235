<template>
  <v-layout row wrap>
    <GroupList />
  </v-layout>
</template>

<script>
import GroupList from '@/components/GroupList'
export default {
  components: {
    GroupList
  }
}
</script>

<style></style>
