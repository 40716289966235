<template>
  <v-layout wrap row>
    <v-flex xs12>
      <v-card color="blue-grey lighten-5">
        <v-data-table
          :headers="headers"
          :items="pointOfSales"
          :total-items="pointOfSalesCount"
          :pagination.sync="pagination"
          select-all
          :loading="loading"
          :no-data-text="loading ? $t('common.loading') : $t('common.noData')"
          item-key="name"
          class="elevation-1"
          :rows-per-page-text="$t('common.rowsPerPage')"
        >
          <template slot="headers" slot-scope="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="[
                  'column sortable',
                  'text-xs-left',
                  pagination.descending ? 'desc' : 'asc',
                  header.value === pagination.sortBy ? 'active' : '',
                ]"
                @click="changeSort(header.value)"
              >
                {{ header.text }}
                <v-icon small>arrow_upward</v-icon>
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr :active="props.item.selected">
              <td>{{ props.item.name }}</td>
              <td>
                <div class="flex-div align-center">
                  <span>{{ props.item.phone ? props.item.phone : '-' }}</span>
                  <v-icon
                    small
                    @click="editItem(props.item, 'phone')"
                    class="ml-2"
                    >edit</v-icon
                  >
                </div>
              </td>
              <td class="flex-div align-center">
                <div
                  v-if="props.item.location && props.item.location.line_1"
                  class="text-truncate"
                  style="max-width:20rem"
                >
                  {{
                    (props.item.location
                      ? props.item.location.line_1 + props.item.location.line_2
                      : '') | capitalize
                  }}
                  {{ props.item.location ? props.item.location.zipcode : '' }}
                </div>
                <span v-else>{{ $t('pointsOfSale.noLocation') }}</span>
                <div class="ml-2">
                  <v-icon small @click="editItem(props.item, 'address')"
                    >edit</v-icon
                  >
                </div>
              </td>
              <td
                v-if="
                  props.item.affiliate_contract_url ||
                    (company && company.affiliate_contract_url)
                "
              >
                <v-btn
                  v-if="props.item.affiliate_contract_url"
                  small
                  @click.stop=""
                  :href="props.item.affiliate_contract_url"
                  target="_blank"
                  flat
                  color="primary"
                  class="ml-0"
                >
                  <v-icon class="mr-2">picture_as_pdf</v-icon
                  >{{ fileNameFromUrl(props.item.affiliate_contract_url) }}
                </v-btn>
                <v-btn
                  v-else-if="company.affiliate_contract_url"
                  small
                  @click.stop=""
                  :href="company.affiliate_contract_url"
                  target="_blank"
                  flat
                  color="primary"
                  class="ml-0"
                >
                  <v-icon class="mr-2">picture_as_pdf</v-icon
                  >{{ fileNameFromUrl(company.affiliate_contract_url) }}
                </v-btn>
              </td>
              <td v-else>-</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <PointOfSaleListEdit
      v-if="editDialog"
      :value="editDialog"
      @input="closeEdit"
      :fromEnrollement="fromEnrollement"
      :point_of_sale="editedItem"
      :editType="editType"
    />
  </v-layout>
</template>

<script>
import PointOfSaleListEdit from '@/components/PointsOfSale/PointOfSaleListEdit'
import req from '@/packs/request.js'

export default {
  components: {
    PointOfSaleListEdit,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    pointOfSales: {
      type: Array,
      required: true,
      default: () => [],
    },
    pointOfSalesCount: {
      type: Number,
      required: true,
    },
    company: {
      type: Object,
      required: false,
    },
    fromEnrollement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: {},
      editedItem: {},
      editedIndex: null,
      editDialog: false,
      editType: '',
      pagination: {
        page: 1,
        sortBy: 'active',
        descending: true,
        rowsPerPage: 10,
      },
      headers: [
        {
          text: this.$t('pointsOfSale.name'),
          value: 'name',
        },
        {
          text: this.$t('pointsOfSale.phone'),
          value: 'phone',
        },
        {
          text: this.$t('pointsOfSale.address'),
          value: 'city',
        },
        {
          text: this.$t('pointsOfSale.contract'),
          value: 'affiliate_contract_url',
        },
      ],
    }
  },
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    editItem(item, type) {
      this.editType = type
      this.editedIndex = this.pointOfSales.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editDialog = true
    },
    closeEdit(value) {
      if (value) {
        if (value.location && !value.location.line_1) value.location = null
        Object.assign(this.pointOfSales[this.editedIndex], value)
      }
      this.editDialog = false
    },
    fileNameFromUrl(url) {
      return url.split('/').pop()
    },
  },
  watch: {
    pagination: {
      handler() {
        this.$emit('pagination', this.pagination)
      },
      deep: true,
    },
  },
}
</script>
