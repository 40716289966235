<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="500">
    <v-card class="point-of-sale-modal">
      <v-card-text mx-4>
        <v-layout row wrap justify-center>
          <v-flex md10>
            <v-text-field
              v-if="editType === 'phone'"
              v-model="point_of_sale_edit.phone"
              :mask="phoneMask"
              :label="$t('pointsOfSale.phone')"
            >
            </v-text-field>

            <template v-else>
              <div class="w-100">
                <v-text-field
                  type="text"
                  hide-details
                  class="px-2 edit-input"
                  :label="$t('pointsOfSale.addressL1')"
                  v-model="point_of_sale_edit.location.line_1"
                >
                </v-text-field>
                <v-text-field
                  type="text"
                  hide-details
                  class="px-2 edit-input"
                  :label="$t('pointsOfSale.addressL2')"
                  v-model="point_of_sale_edit.location.line_2"
                >
                </v-text-field>
              </div>
              <div class="w-100 flex-div">
                <v-text-field
                  type="text"
                  hide-details
                  class="px-2 edit-input"
                  :label="$t('pointsOfSale.zipcode')"
                  v-model="point_of_sale_edit.location.zipcode"
                >
                </v-text-field>
                <v-text-field
                  type="text"
                  hide-details
                  class="px-2 edit-input"
                  :label="$t('pointsOfSale.city')"
                  v-model="point_of_sale_edit.location.city"
                >
                </v-text-field>
              </div>
            </template>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="close()">{{
          $t('pointsOfSale.close')
        }}</v-btn>
        <v-btn color="primary" depressed @click="savePos()">
          <v-icon small class="mr-2">save</v-icon>{{ $t('pointsOfSale.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'
export default {
  props: {
    value: Boolean,
    point_of_sale: {
      type: Object,
      required: true,
    },
    editType: String,
    complete_token: {
      type: String,
      required: false,
    },
    fromEnrollement: Boolean,
  },
  data() {
    return {
      phoneMask: '## ## ## ## ##',
      point_of_sale_edit: {},
      defaultLocation: {
        line_1: '',
        line_2: '',
        city: '',
        zipcode: '',
      },
    }
  },
  methods: {
    close() {
      this.$emit('input')
    },
    savePos() {
      const point_of_sale = this.point_of_sale_edit
      if (
        !point_of_sale.location.line_1 ||
        !point_of_sale.location.zipcode ||
        !point_of_sale.location.city
      )
        point_of_sale.location = null
      if (this.fromEnrollement) this.$emit('input', point_of_sale)
      else {
        req
          .put(`/company/point_of_sales/${point_of_sale.id}`, {
            point_of_sale: point_of_sale,
          })
          .then(response => {
            this.$emit('input', point_of_sale)
            EventBus.$emit('showSnackbar', {
              text: 'Le point de vente a été édité avec succès',
              color: 'success',
            })
            this.$emit('input', point_of_sale)
          })
          .catch(error => {
            this.$emit('input')
            EventBus.$emit('showSnackbar', {
              text: 'Point de vente non modifiable',
              color: 'error',
            })
            this.close()
          })
      }
    },
  },
  created() {
    this.point_of_sale_edit = JSON.parse(JSON.stringify(this.point_of_sale))
    if (this.point_of_sale_edit.location === null)
      this.point_of_sale_edit.location = this.defaultLocation
  },
}
</script>
