<template>
  <v-layout row justify-center>
    <div class="text-xs-center">
      <v-dialog :value="true" @input="$router.go(-1)" max-width="800px">
        <v-card>
          <v-card-title primary-title>
            <div>
              <div class="headline">
                <v-icon class="mb-1">settings</v-icon> Group Settings
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-layout align-center justify-space-around row wrap>
              <v-flex xs4>
                <v-text-field
                  label="Group Name"
                  v-model="group.name"
                  :loading="!loaded.group"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 sm4>
                <v-select
                  :items="concurrencySelectionMode"
                  v-model="group.concurrency_selection_mode"
                  :loading="!loaded.group"
                  prepend-icon="low_priority"
                  label="Concurrency Mode"
                ></v-select>
              </v-flex>
              <v-flex xs8>
                <v-subheader class="pl-0"
                  >Minimum campaign to accept</v-subheader
                >
                <v-slider
                  v-model="group.minimum_campaign_to_accept"
                  :thumb-size="24"
                  thumb-label="always"
                  :loading="!loaded.group"
                  :max="20"
                  disabled
                ></v-slider>
              </v-flex>
              <v-flex xs12>
                <v-combobox
                  :loading="!loaded.groupedCampaigns"
                  v-model="groupedCampaigns"
                  :items="campaigns"
                  item-text="name"
                  item-value="id"
                  label="Add campaigns to group"
                  chips
                  clearable
                  prepend-icon="filter_list"
                  solo
                  multiple
                >
                  <template slot="item" slot-scope="data">
                    <v-layout row align-center>
                      <v-flex xs2>
                        <v-checkbox v-model="data.tile.props.value" />
                      </v-flex>
                      <v-flex xs8>
                        {{ data.item.name }}
                      </v-flex>
                      <v-flex xs2>
                        <v-chip
                          :color="
                            data.item.type == 'conquest' ? 'green' : 'orange'
                          "
                          text-color="white"
                          >{{ data.item.type }}</v-chip
                        >
                      </v-flex>
                    </v-layout>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      :selected="data.selected"
                      close
                      text-color="white"
                      :color="data.item.type == 'conquest' ? 'green' : 'orange'"
                      @input="remove(data.item)"
                    >
                      <v-avatar>
                        <img
                          :src="data.item.advertiser.logo_url"
                          alt="trevor"
                        />
                      </v-avatar>
                      <strong>{{ data.item.name }}</strong
                      >&nbsp;
                      <!-- <span>(interest)</span> -->
                    </v-chip>
                  </template>
                </v-combobox>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="save()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import req from '@/packs/request'

export default {
  data() {
    return {
      concurrencySelectionMode: [
        { text: 'More Generous', value: 'more_generous' },
        { text: 'Less Generous', value: 'less_generous' },
        { text: 'Rotation', value: 'rotation' },
      ],
      initialGroupedCampaigns: [],
      groupedCampaigns: [],
      group: {},
      campaigns: [],
      loaded: {
        group: false,
        groupedCampaigns: false,
        campaigns: false,
      },
    }
  },
  async created() {
    await this.fetchGroup()
    await this.fetchGroupedCampaigns()
    this.fetchCampaigns()
  },
  computed: {
    campaignsNames() {
      return this.campaigns.map(e => e.name)
    },
    campaignsIds() {
      return this.campaigns.map(e => e.id)
    },
  },
  methods: {
    async fetchGroup() {
      const res = await req.get(`/company/groups/${this.$route.params.id}`)

      this.group = res.data
      this.loaded.group = true
    },
    async fetchCampaigns() {
      const conquestCampaigns = await req.get('/campaigns/ungrouped')
      const fidelityCampaigns = await req.get(
        '/core_program/campaigns/ungrouped'
      )

      this.campaigns.push(...conquestCampaigns.data, ...fidelityCampaigns.data)
      this.loaded.campaigns = true
    },
    async fetchGroupedCampaigns() {
      const res = await req.get(
        `/company/groups/${this.group.id}/campaigns`
      )

      this.groupedCampaigns.push(...res.data)
      this.initialGroupedCampaigns.push(...res.data)
      this.campaigns.push(...res.data)
      this.loaded.groupedCampaigns = true
    },
    async updateGroup() {
      const res = await req.put(`/company/groups/${this.group.id}`, {
        group: this.group,
      })

      return res.data
    },
    async save() {
      const groupUpdated = await this.updateGroup()
      await this.dissociate()
      await this.associate()

      this.$emit('refresh', groupUpdated)
      this.$router.go(-1)
    },
    async associate() {
      this.groupedCampaigns.forEach(async campaign => {
        if (!this.initialGroupedCampaigns.includes(campaign))
          await req.put(
            `/company/groups/${this.group.id}/campaigns/${campaign.id}/associate`
          )
      })
    },
    async dissociate() {
      if (this.initialGroupedCampaigns.length == 0) return

      this.initialGroupedCampaigns.forEach(async initialCampaign => {
        if (!this.groupedCampaigns.includes(initialCampaign))
          await req.put(
            `/company/groups/${this.group.id}/campaigns/${initialCampaign.id}/dissociate`
          )
      })
    },
    remove(item) {
      this.groupedCampaigns.splice(this.groupedCampaigns.indexOf(item), 1)
      this.groupedCampaigns = [...this.groupedCampaigns]
    },
  },
}
</script>

<style></style>
