<template>
  <div class="account-info-header">
    <v-menu transition="slide-y-transition" offset-y class="w-100 hidden-sm-and-down" close-on-click>
      <v-btn slot="activator" class="v-btn--flat w-100" active-class="active">
        <v-layout row align-center class="account-content">
          <v-flex shrink v-if="company.logo_url">
            <v-avatar class="mr-3 bg-white avatar" size="48">
              <img :src="company.logo_url" alt="" />
            </v-avatar>
          </v-flex>
          <v-flex
            grow
            :class="{ 'company-avatar': company.logo_url, 'company-container': !company.logo_url }"
            class="flex-div flex-column align-start company-container"
          >
            <v-tooltip bottom :disabled="company.name.length < 25">
              <template v-slot:activator="{ on }">
                <span class="company-name text-truncate text-left subheading text-capitalize" v-on="on">
                  {{ company.name }}
                </span>
              </template>
              <span>{{ company.name }}</span>
            </v-tooltip>
            <div>
              <span class="text-none caption">{{ account.firstname ? account.firstname : '' }}</span>
              <span class="caption">{{ " " }}</span>
              <span class="caption">{{ account.lastname ? account.lastname : '' }} </span>
            </div>
          </v-flex>
          <v-flex shrink ml-2>
            <v-icon>keyboard_arrow_down</v-icon>
          </v-flex>
        </v-layout>
      </v-btn>
      <v-list>
        <v-subheader>
          {{ $t('nav.account') | capitalize }}
        </v-subheader>
        <v-list-tile @click="to('/settings')">
          <v-list-tile-title class="body-1">{{ account.email }}</v-list-tile-title>
        </v-list-tile>
        <v-divider></v-divider>
        <v-subheader v-if="companies.length > 0">
          {{ $t('nav.myCompanies') | capitalize }}
        </v-subheader>
        <template v-if="companies.length > 0">
          <v-list-tile
            class="pointer"
            v-for="(company, i) in companies"
            :key="i"
            @click="changeCompanySession(company.id)"
            :class="company.id === company.id ? [selectedClasses] : ''"
          >
            <v-list-tile-content v-if="company.logo_url" class="shrink mr-2 avatar-companies">
              <img :src="company.logo_url" alt="" />
            </v-list-tile-content>
            <v-list-tile-content>
              <v-list-tile-title>{{ company.name | capitalize }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <v-divider></v-divider>
        <v-list-tile @click="logoutUser()">
          <v-list-tile-title class="grey---text text--darken-3">
            <v-icon class="mr-3">settings_power</v-icon>{{ $t('nav.signOut') }}
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'
import req from '@/packs/request'
import { mapActions } from 'vuex'
import EventBus from '@/packs/event-bus'

export default {
  props: {
    companies: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedClasses: ['grey', 'lighten-3']
    }
  },
  methods: {
    ...mapActions(['incrementRouterViewKey']),
    to(link) {
      this.$router.push(link).catch(err => err)
    },
    async logoutUser() {
      await AuthService.logout()
      this.$router.push({ path: '/signin' }).catch(err => err)
    },
    async changeCompanySession(id) {
      await this.$router
        .push({ name: 'dashboard', params: { company: id } })
        .then(() => {
          EventBus.$emit('refreshCompany', id)
        })
        .catch(() => {
          EventBus.$emit('refreshCompany', id)
        })
    }
  },
  computed: {
    account() {
      return this.$store.state.user.account
    },
    company() {
      return this.$store.state.user.company
    }
  }
}
</script>
