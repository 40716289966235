<template>
  <v-container fluid grid-list-lg>
    <h1 class="headline">Batches History</h1>
    <v-layout row my-2>
      <v-flex sm4>
        <v-btn class="ml-0" outline color="primary" @click="back()">
          <v-icon small>arrow_back_ios</v-icon> Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-data-table
          :headers="tableHeaders"
          :items="pdfBatches"
          :total-items="totalBatches"
          :pagination.sync="pagination"
          :loading="loading"
          :rows-per-page-text="$t('common.rowsPerPage')"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.size }}</td>
            <td class="text-xs">
              <v-icon v-if="props.item.downloaded" small class="mr-2"
                >check</v-icon
              >
              <v-icon v-else small class="mr-2">close</v-icon>
            </td>
            <td class="text-xs">{{ props.item.created_at }}</td>
            <td>
              <v-icon small class="mr-2" @click="downloadBatch(props.item)"
                >cloud_download</v-icon
              >
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import req from '@/packs/request.js'

export default {
  data() {
    return {
      tableHeaders: [
        {
          text: this.$t('BatchesHistory.numberOfVoucher'),
          align: 'left',
          sortable: false,
          value: 'size'
        },
        {
          text: this.$t('BatchesHistory.downloaded'),
          value: 'downloaded'
        },
        {
          text: this.$t('BatchesHistory.creationDate'),
          value: 'created_at'
        },
        {
          text: this.$t('BatchesHistory.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        sortBy: 'created_at',
        descending: true
      },
      pdfBatches: [],
      totalBatches: 0,
      loading: true
    }
  },
  watch: {
    pagination: {
      handler() {
        this.fetchBatches()
      },
      deep: true
    }
  },
  mounted() {
    this.fetchBatches()
  },
  methods: {
    async fetchBatches() {
      const page = this.pagination.page
      const perPage = this.pagination.rowsPerPage
      this.loading = true

      try {
        const res = await req.get(
          '/pdf_batches?page=' + page + '&per_page=' + perPage
        )
        this.pdfBatches = res.data.collection
        this.totalBatches = res.data.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    downloadBatch(batch) {
      const date = batch.created_at.slice(0, 10)
      req.download(
        '/pdf_batches/' + batch.id,
        'pdf-batches-' + date + '.pdf'
      )
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
