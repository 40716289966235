<template>
  <v-dialog :value="displayModal" max-width="350px" @input="$emit('close')">
    <v-card>
      <v-card-text>
              <v-btn-toggle v-model="action" mandatory class="btn-group w-100 elevation-0">
        <v-btn flat value="add" class="grow" active-class="btn-selected">
          Ajout
        </v-btn>
        <v-btn flat value="remove" class="grow" active-class="btn-selected">
          Retrait
        </v-btn>
      </v-btn-toggle>

        <v-text-field v-model="loyaltyUnits" :label="textFieldLabel" :min="1" />
      </v-card-text>
      <v-card-actions class="flex-div">
        <v-btn flat color="red" class="ml-auto" @click="$emit('close')">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="updateBalance()">
          {{ $t('common.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import req from '@/packs/request'
import EventBus from '@/packs/event-bus'
import { mapState } from 'vuex'

export default {
  props: {
    displayModal: {
      required: true,
      type: Boolean
    },
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loyaltyUnits: null,
      action: 'add'
    }
  },
  computed: {
    ...mapState({
      loyaltyCurrency: state => state.user.loyaltyCurrency
    }),
    textFieldLabel() {
      return this.action === 'add'
        ? this.$t('customers.data.numberPointsGranted')
        : this.$t('customers.data.numberPointsRemoved')
    }
  },
  methods: {
    async updateBalance() {
      const endpoint = this.action === 'add' ? 'grant_points' : 'remove_points'
      await req.post(`/customers/${this.id}/${endpoint}`, { loyalty_units: this.loyaltyUnits })

      this.$emit('refresh')
      const word = this.action === 'add' ? 'granted' : 'removed'
      EventBus.$emit('refreshTransactions')
      EventBus.$emit('showSnackbar', {
        text: `You have ${word} ${this.loyaltyUnits} ${this.loyaltyCurrency.name}`,
        color: 'success'
      })
      this.$emit('close')
    }
  }
}
</script>
