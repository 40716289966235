import CampaignState from '@/models/states/CampaignListState'

enum Mode {
  Fidelity = 'fidelity',
  AdToStore = 'conquest',
  Both = 'both'
}

const FIDELITY_TABS = ['customs', 'communications', 'drafted']
const CONQUEST_TABS = ['created', 'inherited', 'tradeMarketing', 'publishing', 'drafted']

const selectedCampaigns = (state: any) => {
  const tabsName = state.mode === Mode.Fidelity ? FIDELITY_TABS : CONQUEST_TABS
  return state[state.mode][tabsName[state.tabIndex]]
}

const getters = {
  campaignsByTabIndex: (state: CampaignState) => {
    const tabs = state.mode === Mode.Fidelity ? FIDELITY_TABS : CONQUEST_TABS
    return state[state.mode][tabs[state.tabIndex]]
  },

  filtersCampaigns: (state: CampaignState) => {
    return selectedCampaigns(state).filters
  },

  tabsCounters: (state: CampaignState) => {
    if (state.mode === Mode.Fidelity) {
      return [state.fidelity.customs.count, state.fidelity.communications.count]
    } else {
      return [
        state.conquest.created.count,
        state.conquest.inherited.count,
        state.conquest.tradeMarketing.count,
        state.conquest.publishing.count
      ]
    }
  }
}

export default getters
