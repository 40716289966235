<template>
  <v-menu transition="slide-y-transition" offset-y close-on-click>
    <v-btn class="v-btn--flat" active-class="active" slot="activator">
      Ad-to-store
    </v-btn>
    <v-list>
      <v-menu class="w-100" offset-x right open-on-hover close-on-click>
        <v-list-tile slot="activator" class="w-100">
          <v-list-tile-title class="disp-flex align-center">
            <img src="@/assets/images/icons/store.svg" width="24" class="mr-3" alt="icon" />
            <span> {{ $t('nav.campaigns') }}</span>
          </v-list-tile-title>
          <v-list-tile-action class="justify-end">
            <v-icon>keyboard_arrow_right</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-list>
          <v-list-tile @click="to('/campaigns'); $emit('reload')">
            <v-list-tile-title class="disp-flex align-center">
              <img src="@/assets/images/icons/manage.svg" width="18" class="mr-3" alt="icon" />
              <span>{{ $t('nav.manageCampaigns') }}</span>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile v-if="canCreateCampaign()" @click="to('campaigns/new/'); incrementRouterViewKey(); $emit('reload')">
            <v-list-tile-title class="disp-flex align-center">
              <img src="@/assets/images/icons/add.svg" width="18" class="mr-3" alt="icon" />
              <span>{{ $t('nav.createCampaign') }}</span>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>

      <v-list-tile v-if="canCreateCampaign()" @click="to('/ratecards')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/ratecards.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.ratecards') }}</span>
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="to('/groups')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/points-of-sale.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.groups') }}</span>
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['incrementRouterViewKey']),
    canCreateCampaign() {
      if (this.$store.state.role) return this.$store.state.role.can_create_campaign
      else return true
    },
    to(link) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push(link).catch(() => {})
    }
  }
}
</script>
