import axios from 'axios'
import jwt_helper from 'vue-jwt-decode'
import EventBus from './event-bus'
import store from '@/store/index'
import * as Sentry from '@sentry/browser'

/*
** rest : axios object with user auth headers
--------------------------------------------
** clear() : erase all auth data from session
---------------------------------------------
** logged() : verify if user is logged in session
**** @return boolean
---------------------------------------------
** login(#params) : log user
**** #params object : role with email and password { role: { email: 'a@a.com', password: '****' } }
**** @return promise
****** THEN: resolve(response) : user is logged
****** THEN: reject(error) : user is not authorized
****** CATCH: function(error) : error with server
---------------------------------------------
** logout() : delete user session
**** @return promise
****** THEN: resolve(response) : user signout sucessfully
****** CATCH: function(error) : error with server
---------------------------------------------
*/

export default {
  base_url: process.env.VUE_APP_API_BASE_URL,
  rest: axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
  }),
  roles: ['account', 'customer'],
  environment: process.env.NODE_ENV,
  axios: axios,
  init() {
    if (this.environment === 'production' && this.base_url.includes('staging')) {
      this.environment = 'staging'
    }
    /**
     * it works but it's not ideal
     * Interceptors should send a mutation to the VueX store
     * @TODO : Use VueX and return axios promise
     */
    this.rest.interceptors.response.use(null, err => {
      const res = err.response

      if (
        res &&
        res.status === 401 &&
        !res.request.responseURL.includes('sign_in')
      ) {
        if (this.token_presence()) {
          store.dispatch('clearUser')
          this.clear()
        }
        EventBus.$emit('forbidden')
      } else if (res.status !== 404) {
        let messages
        if (Array.isArray(res.data.errors))
          messages = res.data.errors.join(', ')
        else messages = res.data.error
        EventBus.$emit('showSnackbar', {
          text: messages,
          color: 'error'
        })
      }
      return Promise.reject(err)
    })
    this.rest.defaults.headers.common['Accept'] = 'application/json'
    this.rest.defaults.headers.post['Content-Type'] = 'application/json'
    this.rest.defaults.headers.put['Content-Type'] = 'application/json'
    this.assignToken()
  },
  assignToken() {
    this.rest.defaults.headers.common['Authorization'] = this.token()
    if (typeof this.token() == 'string') console.log(this.token().slice(7))
  },
  token(_token) {
    if (typeof _token === 'undefined') return sessionStorage.getItem('bearer')
    else return sessionStorage.setItem('bearer', _token)
  },
  clear() {
    console.log('token was cleared')
    this.token('')
    // TO FIX
    delete this.rest.defaults.headers.common['Authorization']
    // sessionStorage.removeItem('bearer')
    sessionStorage.clear()
  },
  token_presence() {
    const bearer = this.token()
    if (!bearer || bearer === '') return false

    const jwt = jwt_helper.decode(bearer.split(' ')[1])
    if (jwt.exp * 1000 <= Date.now()) {
      this.clear()
      return false
    } else {
      return true
    }
  },
  logged() {
    this.assignToken()
    return this.get('/user/logged').then(function(r) {
      return r.code === 200
    })
  },
  /** DOWNLOAD **/
  download(url, filename, body = null) {
    this.assignToken()
    const headers = {
      Authorization: this.token()
    }
    axios({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      url: url,
      method: body ? 'POST' : 'GET',
      headers: headers,
      data: body,
      responseType: 'blob' // important
    }).then(response => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename) //or any other extension
      link.setAttribute('type', response.headers['content-type'])
      document.body.appendChild(link)
      link.click()
    })
  },
  /** GET **/
  get(url, params) {
    const parameters = []
    for (const k in params) parameters.push(String(k) + '=' + String(params[k]))
    if (parameters.length > 0) url += '?' + parameters.join('&')

    return this.rest.get(url)
  },
  /** POST **/
  post(url, body) {
    return this.rest.post(url, body)
  },
  /** PUT **/
  put(url, body) {
    return this.rest.put(url, body)
  },
  /** DELETE **/
  delete(url) {
    return this.rest.delete(url)
  },
  /** OBJECT **/
  push(url, params, collection = true) {
    let update_id = ''
    for (const key in params) {
      if (typeof params[key].id != 'undefined' && params[key].id) {
        update_id = params[key].id
      }
    }

    if (update_id !== '' && collection) url += '/' + update_id
    return update_id !== '' ? this.put(url, params) : this.post(url, params)
  },
  register(params) {
    let role = ''
    if ('advertiser' in params) role = 'advertiser'
    else if ('publisher' in params) role = 'publisher'
    else if ('customer' in params) role = 'customer'

    return this.rest.post('/' + role + 's/sign_up', params).then(
      function(response) {
        this.token(response.headers.authorization)
        this.rest.defaults.headers.common['Authorization'] = this.token()
        response.data.role = role

        Sentry.configureScope(scope => {
          scope.setUser({
            id: response.data.id,
            email: response.data.email,
            brand: response.data.brand,
            distributor: response.data.distributor,
            role: role
          })
        })

        return response
      },
      function(error) {
        this.clear()
        throw error
      }
    )
  },
  async login(params) {
    let role = ''
    if ('account' in params) role = 'account'
    else if ('customer' in params) role = 'customer'

    const res = await this.rest.post('/' + role + 's/sign_in', params)
    this.token(res.headers.authorization)
    this.rest.defaults.headers.common['Authorization'] = this.token()
    res.data.role = role

    Sentry.configureScope(scope => {
      scope.setUser({
        id: res.data.id,
        email: res.data.email,
        brand: res.data.brand,
        distributor: res.data.distributor,
        role: role
      })
    })
    return res
  },
  logout() {
    return this.rest.delete('/accounts/sign_out').then(response => {
      // window.sessionStorage.removeItem('bearer')
      window.sessionStorage.clear()
      return response
    })
  },
  build_messages(messages) {
    const s = []
    for (const key in messages) {
      if (Array.isArray(messages[key])) {
        messages[key].forEach(message => {
          if (key === 'base') s.push(message)
          else s.push(key + ' ' + message)
        })
      }
    }
    console.log(s)
    return s.join(', ')
  }
}
