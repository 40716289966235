<template>
  <v-card class="px-4 pt-2 pb-3" :elevation="2">
    <h3 class="mb-2">Filtres :</h3>
    <v-container grid-list-md fluid class="pa-0">
      <v-layout row wrap>
        <v-flex xs12 sm4 md4 lg4>
          <PointsOfSalesSelector @update="updateSelect($event)" />
        </v-flex>
        <v-flex xs12 sm4 md4 lg4>
          <DateRangePicker @update="updateDateRange($event)" />
        </v-flex>
        <v-flex xs12 sm2 md2 lg2>
          <TimePicker label="Heure début" :disabled="disableTimePicker" @update="updateStartTime($event)" />
        </v-flex>
        <v-flex xs12 sm2 md2 lg2>
          <TimePicker label="Heure fin" :disabled="disableTimePicker" @update="updateEndTime($event)" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import DateRangePicker from '@/components/custom/DateRangePicker'
import TimePicker from '@/components/TimePicker'
import PointsOfSalesSelector from '@/components/custom/PointsOfSalesSelector'

export default {
  components: {
    DateRangePicker,
    PointsOfSalesSelector,
    TimePicker
  },
  data() {
    return {
      filters: {
        selector: Array,
        date: Object
      }
    }
  },
  computed: {
    disableTimePicker() {
      return this.filters.date === null
    }
  },
  methods: {
    updateStartTime(startTime) {
      this.filters.startTime = startTime
      this.$emit('filters', this.filters)
    },
    updateEndTime(endTime) {
      this.filters.endTime = endTime
      this.$emit('filters', this.filters)
    },
    updateDateRange(dateRange) {
      this.filters.date = dateRange
      this.$emit('filters', this.filters)
    },
    updateSelect(selector) {
      this.filters.selector = selector
      this.$emit('filters', this.filters)
    }
  }
}
</script>
