<template>
  <table align="center" class="container float-center px-0 py-0" v-if="loaded">
    <tbody>
      <tr>
        <td>
          <table align="center" class="float-center w-100">
            <tbody>
              <tr>
                <td>
                  <table class="wrapper header" align="center">
                    <tr>
                      <td class="wrapper-inner">
                        <table align="center" class="container">
                          <tbody>
                            <tr>
                              <td>
                                <table class="row collapse">
                                  <tbody>
                                    <tr>
                                      <th
                                        class="small-6 large-6 columns first"
                                        valign="middle"
                                      >
                                        <table>
                                          <tr>
                                            <th v-if="userCompany">
                                              <center data-parsed="">
                                                <img
                                                  :src="userCompany.logo_url"
                                                  align="center"
                                                  width="100"
                                                  class="float-center"
                                                />
                                              </center>
                                            </th>
                                          </tr>
                                        </table>
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>

                  <table class="row w-100">
                    <tbody>
                      <tr>
                        <th class="small-12 large-12 columns first last">
                          <table class="w-100">
                            <tr>
                              <th>
                                <h1
                                  class="text-xs-center break-all"
                                  v-if="title"
                                  v-html="title"
                                ></h1>
                                <table class="spacer">
                                  <tbody>
                                    <tr>
                                      <td
                                        height="20px"
                                        style="font-size:20px;line-height:20px;"
                                      >
                                        &#xA0;
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <center data-parsed="">
                                  <img
                                    v-if="!imageChanged && imageHeader"
                                    height="220"
                                    :src="imageHeader"
                                    align="center"
                                    class="float-center"
                                  />

                                  <img
                                    v-else-if="imageChanged"
                                    height="220"
                                    :src="image"
                                    align="center"
                                    class="float-center"
                                  />

                                  <img
                                    v-else
                                    src="@/assets/images/png/default-template-img.png"
                                    height="220"
                                    align="center"
                                    class="float-center"
                                  />
                                </center>
                                <table class="spacer">
                                  <tbody>
                                    <tr>
                                      <td
                                        height="25px"
                                        style="font-size:25px;line-height:25px;"
                                      >
                                        &#xA0;
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <template v-if="p1">
                                  <p
                                    class="text-xs-center break-all mb-2"
                                    style="font-weight: normal;"
                                  >
                                    <span v-html="p1"></span>
                                  </p>
                                </template>
                                <template v-else>
                                  <p
                                    v-if="template.kind === 'welcome'"
                                    class="text-xs-center"
                                    style="font-weight: normal;"
                                  >
                                    <i>Message d'introduction ici..</i>
                                  </p>
                                  <p
                                    v-else
                                    class="text-xs-center"
                                    style="font-weight: normal;"
                                  >
                                    <i>Offre ici..</i>
                                  </p>
                                </template>
                                <table class="spacer">
                                  <tbody>
                                    <tr>
                                      <td
                                        height="15px"
                                        style="font-size:15px;line-height:15px;"
                                      >
                                        &#xA0;
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <template v-if="p2">
                                  <p class="text-xs-center break-all mb-2">
                                    <span
                                      style="font-weight: normal;"
                                      v-html="p2"
                                    ></span>
                                  </p>
                                </template>
                                <template v-else>
                                  <p
                                    v-if="template.kind === 'welcome'"
                                    class="text-xs-center"
                                    style="font-weight: normal;"
                                  >
                                    <i>Paragraphe 2 ici...</i>
                                  </p>
                                  <p
                                    v-else
                                    class="text-xs-center"
                                    style="font-weight: normal;"
                                  >
                                    <i>Message associé ici..</i>
                                  </p>
                                </template>

                                <table class="spacer">
                                  <tbody>
                                    <tr>
                                      <td
                                        height="15px"
                                        style="font-size:15px;line-height:15px;"
                                      >
                                        &#xA0;
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <center
                                  data-parsed=""
                                  v-if="template.kind === 'reward'"
                                >
                                  <img
                                    src="@/assets/images/standard/barcode.gif"
                                    alt="barcode"
                                    align="center"
                                    class="float-center"
                                  />
                                </center>
                                <table class="spacer">
                                  <tbody>
                                    <tr>
                                      <td
                                        height="15px"
                                        style="font-size:15px;line-height:15px;"
                                      >
                                        &#xA0;
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <hr />
                                <p
                                  v-if="footer"
                                  class="break-all text-xs-center"
                                >
                                  <small v-html="footer"></small>
                                </p>
                                <p v-else class="break-all text-xs-center">
                                  <small
                                    >Mentions légales et informations
                                    ici..</small
                                  >
                                </p>
                              </th>
                              <th class="expander"></th>
                            </tr>
                          </table>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <table class="spacer">
                    <tbody>
                      <tr>
                        <td
                          height="16px"
                          style="font-size:16px;line-height:16px;"
                        >
                          &#xA0;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Format from '@/services/formatter'

export default {
  data: function() {
    return {
      loaded: false,
      imageChanged: false,
    }
  },
  props: {
    template: {
      type: Object,
    },
    image: {
      type: String,
    },
  },
  watch: {
    image() {
      this.imageChanged = true
    },
  },
  mounted() {
    if (this.template) this.loaded = true
  },
  computed: {
    components() {
      return this.template.components
    },
    title() {
      const title = this.components.find(e => {
        return e.kind == 'title'
      })
      return title ? Format.toEditor(title.content) : null
    },
    p1() {
      const p = this.components.filter(e => {
        return e.kind == 'paragraph'
      })
      return p.length > 0 ? Format.toEditor(p[0].content) : null
    },
    p2() {
      const p = this.components.filter(e => {
        return e.kind == 'paragraph'
      })
      return p.length > 1 ? Format.toEditor(p[1].content) : null
    },
    subtitle() {
      const subtitle = this.components.find(e => {
        return e.kind == 'subtitle'
      })
      return subtitle ? Format.toEditor(subtitle.content) : null
    },
    footer() {
      const footer = this.components.find(e => {
        return e.kind == 'footer'
      })
      return footer ? Format.toEditor(footer.content) : null
    },
    imageHeader() {
      const cp = this.template.components.find(e => {
        return e.kind == 'image'
      })
      let url = cp ? cp.image_url : null

      if (cp && cp.image && cp.image.url && cp.image.url.size > 0) {
        url = cp.image.url
      }
      return url ? url : null
    },
    userCompany() {
      return this.$store.getters.userCompany
    },
  },
}
</script>
