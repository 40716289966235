<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout row wrap justify-center align-center>
      <v-flex xs12 sm7 md6>
        <v-card>
          <v-layout row wrap class="w-100 mx-0">
            <v-flex xs12>
              <v-card-text class="d-flex flex-column align-center">
                <h1 class="text-xs-center primary--text font-weight-regular">
                  Cette page n'existe pas
                </h1>
                <img
                  src="@/assets/images/404.svg"
                  alt="404 image"
                  width="80%"
                  class="mt-5"
                />
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {}
</script>
