<template>
  <v-container class="gray">
    <header>
      <h1 class="my-4">{{ $t('communications.advertiser.availableQuantity') }}</h1>
      <v-data-table hide-actions class="elevation-1 datatable" :headers="headers" :items="balances">
        <template slot="headerCell" slot-scope="props">
          {{ $t(props.header.text) }}
        </template>
        <template v-slot:items="props">
          <td class="text-xs-left">{{ props.item.kind | capitalize }}</td>
          <td class="text-xs-left">{{ props.item.available }}</td>
          <td class="text-xs-left">{{ Number(props.item.price.toFixed(2)).toCurrency() }} €</td>
          <td class="text-xs-left">{{ Number(props.item.average_unit_price.toFixed(2)).toCurrency() }} €</td>
          <td class="text-xs-left">{{ props.item.reserved }}</td>
        </template>
      </v-data-table>
    </header>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AdvertiserService from '@/services/AdvertiserService'

export default {
  data() {
    return {
      headers: [
        {
          text: 'communications.type',
          sortable: false
        },
        {
          text: 'communications.advertiser.availableQuantity',
          sortable: false
        },
        {
          text: 'communications.advertiser.purchaseValue',
          sortable: false
        },
        {
          text: 'communications.advertiser.averageUnitValue',
          sortable: false
        },
        {
          text: 'communications.advertiser.reservedQuantity',
          sortable: false
        }
      ],
      balances: []
    }
  },
  created() {
    this.fetchCommunications()
  },
  computed: {
    ...mapState({
      company: state => state.user.company
    })
  },
  methods: {
    async fetchCommunications() {
      const res = await AdvertiserService.getBalances(this.company.advertisers[0].id)
      this.balances = [res.data.email, res.data.sms]
    }
  }
}
</script>
