<template>
  <v-layout row wrap justify-space-between align-center>
    <v-flex xs12 sm6 md4 lg3 ml-auto>
      <PointOfSalesMultipleSelect :children=true :value="pos" @input="updatePOS($event)" preselect-all with-discarded />
    </v-flex>
    <v-flex xs12 sm6 md4 lg3>
      <DateRangePicker
        :clearable="false"
        init-with="month"
        @update="updateDateRange($event)"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import PointOfSalesMultipleSelect from '@/components/PointOfSalesMultipleSelect'
import DateRangePicker from '@/components/custom/DateRangePicker'

export default {
  components: {
    PointOfSalesMultipleSelect,
    DateRangePicker
  },
  props: {
    pos: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    updatePOS(event) {
      this.$emit('updatePOS', event)
    },
    updateDateRange(event) {
      this.$emit('updateDateRange', event)
    }
  }
}
</script>
