import store from '@/store'
import CampaignList from '@/store/modules/campaignList/campaignList.store'

const provideModule = (path: string, module: any) => {
  let instanceCount = 0

  return {
    beforeCreate() {
      if (++instanceCount > 1) return

      store.registerModule(path, module)
    },
    beforeDestroy() {
      if (--instanceCount > 0) return

      store.unregisterModule(path)
    }
  }
}

const provideCampaignListModule = provideModule('campaignList', CampaignList)

export default provideCampaignListModule
