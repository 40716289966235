<template>
  <v-card>
    <transition name="fade" mode="out-in" :duration="500">
      <div>
        <div class="pa-3 disp-flex" style="min-height: 120px">
          <div class="disp-flex align-center mr-3">
            <img :src="imgSource" class="campaign-img elevation-2" />
          </div>
          <div class="w-100 overflow-hidden">
            <div class="disp-flex mb-2">
              <h2 class="font-weight-medium ellipsis">{{ campaign.name }}</h2>
              <v-spacer></v-spacer>
              <v-chip small disabled class="mx-0" outline :color="campaign.status === 'running' ? '#4AD991' : campaign.status === 'pending' ? 'yellow darken-3' : '#ff7285'">
                <strong> {{ $t(`filters.${campaign.status}`) }} </strong>
              </v-chip>
            </div>
            <div class="disp-flex">
              <div class="disp-flex flex-column flex">
                <div class="disp-flex caption mb-1" v-if="campaign.type === 'fidelity'">
                  <v-icon small class="mr-1">account_balance</v-icon>
                  1 euro =
                  <template v-if="campaign.pool_program">
                    {{ campaign.pool_program.earn_exchange_rate }} {{ campaign.loyalty_currency.name | lowercase }}
                  </template>
                  <template v-else>
                    Inconnu
                  </template>
                </div>
                <template v-if="rewards.length === 0">
                  <div class="caption disp-flex">
                    <v-icon small class="mr-1">emoji_events</v-icon> Aucune offre
                  </div>
                </template>
                <template v-else-if="rewards.length === 1">
                  <CampaignRewardMessage v-for="(reward, index) in rewards" :reward="reward" :index="index" :key="reward.id" />
                </template>
                <template v-else>
                  <CampaignRewardMessageSeeAll :rewards="rewards" />
                </template>
              </div>
              <template v-if="campaign.type === 'fidelity'">
                <div class="disp-flex flex-column flex">
                  <div class="disp-flex caption mb-1 ellipsis">
                    <v-icon small class="mr-1">schedule</v-icon>
                    {{
                      campaign.loyalty_currency
                        ? campaign.loyalty_currency.expiration_mode === 'permanent'
                          ? $t('coreProgram.neverExpire')
                          : campaign.loyalty_currency.kind
                        : 'Inconnu'
                    }}
                  </div>
                  <div class="disp-flex caption mb-1 ellipsis">
                    <v-icon small class="mr-1">monetization_on</v-icon>
                    {{ campaign.rewards.length > 0 ? (campaign.rewards[0].splittable ? 'Dons autorisé' : 'Dons non autorisé') : 'Dons non autorisé'}}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="disp-flex" style="min-height: 40px; background: #3f51b5 !important; color: #fff;">
          <div class="font-weight-medium disp-flex mt-auto mb-auto mx-3 align-center ellipsis" v-if="campaign.type === 'fidelity'">
            <v-icon class="material-icons-round mr-1 white--text">verified</v-icon>
            Programme de fidélité
          </div>
          <div class="font-weight-medium disp-flex mt-auto mb-auto mx-3 align-center ellipsis" v-else-if="campaign.kind === 'welcome'">
            <v-icon class="material-icons-round mr-1 white--text">how_to_reg</v-icon>
            Campagne de bienvenue
          </div>
          <div class="font-weight-medium disp-flex mt-auto mb-auto mx-3 align-center ellipsis" v-else>
            <v-icon class="material-icons-round mr-1 white--text">cake</v-icon>
            Campagne d'anniversaire
          </div>
          <v-spacer></v-spacer>
          <v-divider vertical></v-divider>
          <CampaignSwitchState v-if="campaign.status !== 'draft'" :campaign="campaign" color-btn="white" @update="updateState($event)" />
          <template v-if="campaign.mine && campaign.status !== 'draft'">
            <v-divider vertical></v-divider>
            <CampaignButtonStatus :campaign="campaign" color-btn="white" @update="updateStatus($event)" />
          </template>
          <v-divider vertical></v-divider>
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-btn flat icon class="ma-0" color="white" style="height: auto; width: 50px;" v-on="on">
                <v-icon class="material-icons-round" style="font-size: 20px">
                  settings
                </v-icon>
              </v-btn>
            </template>
            <div class="white text-align-selector disp-flex flex-column">
              <CampaignButtonSeeTransactions class="ma-0" v-if="campaign.status !== 'draft'" :campaign="campaign" />
              <v-btn v-if="campaign.status !== 'finished'" @click="editCampaign()" flat color="blue-grey darken-1" class="ma-0">
                <v-icon left>edit</v-icon>
                {{ $t('common.edit') }}
              </v-btn>
              <CampaignButtonDelete v-if="campaign.mine && ['pending', 'draft'].includes(campaign.status)" :campaign="campaign"/>
            </div>
          </v-menu>
        </div>
      </div>
    </transition>
  </v-card>
</template>

<script>
import CampaignSwitchState from '@/components/CampaignSwitchState'
import CampaignButtonStatus from '@/components/CampaignButtonStatus'
import CampaignButtonSeeTransactions from '@/components/CampaignButtonSeeTransactions'
import CampaignRewardMessage from '@/components/CampaignRewardMessage'
import CampaignRewardMessageSeeAll from '@/components/CampaignRewardMessageSeeAll'
import CampaignButtonDelete from '@/components/CampaignButtonDelete'

export default {
  components: {
    CampaignSwitchState,
    CampaignButtonStatus,
    CampaignButtonSeeTransactions,
    CampaignButtonDelete,
    CampaignRewardMessage,
    CampaignRewardMessageSeeAll
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fidelity: true
    }
  },
  computed: {
    imgSource() {
      if (this.campaign.kind === 'welcome') return require('@/assets/images/icons/welcome.png')
      else if (this.campaign.kind === 'event') return require('@/assets/images/icons/birthday-cake.png')
      else return require('@/assets/images/icons/loyalty.png')
    },
    rewards() {
      return this.campaign.rewards.filter(reward => reward.type !== 'core')
    }
  },
  methods: {
    editCampaign() {
      let path = ''

      if (this.fidelity) {
        if (this.campaign.type === 'fidelity') path = `/core-program/${this.campaign.id}`
        else if (['limited', 'permanent'].includes(this.campaign.kind)) path = `/campaigns/custom/${this.campaign.id}`
        else if (this.campaign.kind === 'communication') path = `/campaigns/communication/${this.campaign.id}`
        else path = `/campaigns/event/${this.campaign.id}`
      } else path = `/campaigns/${this.campaign.id}`

      this.$router.push(path)
    },
    updateState(state) {
      this.campaign.state = state
    },
    updateStatus(status) {
      this.campaign.status = status
    }
  }
}
</script>
