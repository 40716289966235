<template>
  <v-layout wrap row>
    <v-flex xs12>
      <v-card color="blue-grey lighten-5">
        <v-data-table
          :headers="headers"
          :items="childs"
          :pagination.sync="pagination"
          select-all
          :loading="loading"
          :total-items="childsCount"
          :rows-per-page-text="$t('common.rowsPerPage')"
          :no-data-text="loading ? $t('common.loading') : $t('common.noData')"
          item-key="id"
          class="elevation-1"
        >
          <template slot="headers" slot-scope="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="[
                  'column sortable',
                  'text-xs-left',
                  pagination.descending ? 'desc' : 'asc',
                  header.value === pagination.sortBy ? 'active' : '',
                ]"
                @click="changeSort(header.value)"
              >
                {{ header.text }}
                <v-icon small>arrow_upward</v-icon>
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr :active="props.item.selected" @click="showDetails(props.item)">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.siret }}</td>
              <td>{{ props.item.account ? props.item.account.email : '' }}</td>
              <td>
                {{
                  props.item.account ?
                  props.item.account.firstname +
                    ' ' +
                    props.item.account.lastname : ''
                }}
              </td>
              <td>{{ enrollmentStatus(props.item) }}</td>
              <td>{{ duration(props.item.completed_at || props.item.account.confirmation_reminded_at || props.item.created_at) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <router-view v-if="!loading" :childs="childs" />
  </v-layout>
</template>

<script>
export default {
  props: {
    childs: {
      type: Array,
      required: true,
      default: () => [],
    },
    childsCount: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchBar: '',
      pagination: {
        page: 1,
        sortBy: 'active',
        descending: true,
        rowsPerPage: 10,
      },
      headers: [
        {
          text: this.$t('childs.name'),
          value: 'name',
        },
        {
          text: 'Siret',
          value: 'siret',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: this.$t('childs.contactName'),
          value: 'contact',
        },
        {
          text: this.$t('childs.enrollementStatus'),
          value: 'status',
        },
        {
          text: this.$t('childs.daysStatus'),
          value: 'time',
          width: '100px',
        },
      ],
    }
  },
  methods: {
    showDetails(item) {
      this.$router.push(`/childs/${item.id}`)
    },
    duration(created_at) {
      const date = new Date(created_at)

      return this.$moment(date).fromNow()
    },
    enrollmentStatus(company) {
      if (company.completed_at) {
        return `Activated (${company.completed_at.slice(0, 10)})`
      } else if (company.account.confirmation_reminding_count > 0) {
        return this.$tc('childs.emailReminded', company.account.confirmation_reminding_count, { count: company.account.confirmation_reminding_count })
      } else {
        return this.$t('childs.emailSent')
      }
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.$emit('pagination', this.pagination)
      },
      deep: true,
    },
  },
}
</script>
