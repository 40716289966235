<template>
  <div class="mb-3">
    <v-card class="w-100">
      <div class="header-card pa-3">
        <h3 class="font-weight-medium">
          <v-icon small class="align-icon">folder_open</v-icon>
          {{ $t('customers.customerBase') }}
        </h3>
      </div>
      <v-layout row class="pa-3">
        <v-flex xs12>
          <v-select
            :items="customers_base"
            v-model="base"
            item-value="id"
            item-text="name"
            class="mt-0 pt-0"
            hide-details
            menu-props="offsetY"
            return-object
          ></v-select>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    customers_base: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {
      base: Object,
    }
  },
  methods: {
    updateCustomersBase() {
      this.$emit('UpdateBase', this.base)
    },
  },
  created() {
    this.base = { ...this.customers_base[0] }
  },
  watch: {
    base: {
      deep: true,
      handler() {
        this.updateCustomersBase()
      },
    },
  },
}
</script>

<style scoped lang="css">
.header-card {
  background: #eceff1;
  border-bottom: 1px solid #cfd8dc;
  color: #37474f;
}

.align-icon {
  font-size: 16px;
  vertical-align: middle;
  padding-bottom: 4px;
}
</style>
