<template>
  <v-container fluid grid-list-lg>
    <v-content class="gray">
      <AvailableItemsTable :orders="orders" />
    </v-content>
    <v-content class="white">
      <v-container class="white">
        <Navigation :tabs="['purchase', 'purchaseHistory', 'usageHistory']">
          <template #purchase>
            <Purchase />
            <v-content class="white mt-5">
              <v-container class="blue-gradient">
                <OrderSummary @save-order="addToOrder" />
              </v-container>
            </v-content>
          </template>
          <template #purchaseHistory>
            <PurchaseHistoryTable />
          </template>
          <template #usageHistory>
            <UsageHistory />
          </template>
        </Navigation>
      </v-container>
    </v-content>
  </v-container>
</template>

<script>
import { Action } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import AvailableItemsTable from '@/components/Communications/tables/AvailableItemsTable.vue'
import Navigation from '@/components/Communications/Navigation.vue'
import Purchase from '@/components/Communications/Purchase.vue'
import OrderSummary from '@/components/Communications/OrderSummary.vue'
import PurchaseHistoryTable from '@/components/Communications/tables/PurchaseHistoryTable.vue'
import UsageHistory from '@/components/Communications/UsageHistory.vue'

@Component({
  components: {
    AvailableItemsTable,
    Navigation,
    Purchase,
    PurchaseHistoryTable,
    OrderSummary,
    UsageHistory
  }
})
export default class Communications extends Vue {
  @Action('order/updateEmailMin') updateEmailMin
  @Action('order/selectEmails') selectEmails
  @Action('order/selectSms') selectSms

  orders = []

  addToOrder(order) {
    this.orders.unshift(order)
  }
}
</script>

<style lang="scss">
@import '@/scss/variables.scss';
@import '@/scss/datatable.scss';

.blue-gradient {
  background: $blue-gradient;
}
</style>
