<template>
  <v-card class="config elevation-0 px-4">
    <div class="font-weight-light title disp-flex align-center mb-4">
      <div v-if="mode !== 'fidelity'" class="font-weight-bold disp-flex align-center justify-center config-number mr-3">
        1
      </div>
      {{ $t('createCampaign.triggerReward.loyaltyCurrency') }} :
    </div>
    <div class="w-100 mt-3">
      <v-btn-toggle v-model="transformation_" mandatory class="btn-group w-100 elevation-0 flex-column">
        <div class="font-weight-medium mb-1">En manuel :</div>
        <div class="disp-flex w-100 mb-3">
          <v-btn flat :disabled="viewMode" value="in_store" class="grow" active-class="btn-selected">
            {{ $t('createCampaign.triggerReward.manualCheckOut') }}
          </v-btn>
          <v-btn flat :disabled="viewMode" value="out_store_manual" class="grow" active-class="btn-selected">
            {{ $t('createCampaign.triggerReward.manualOutOfStore') }}
          </v-btn>
        </div>
        <div class="font-weight-medium mb-1">Automatique :</div>
        <div class="disp-flex w-100">
          <v-btn flat :disabled="viewMode" value="out_store_automatic" class="grow" active-class="btn-selected">
            {{ $t('createCampaign.triggerReward.automaticOutOfStore') }}
          </v-btn>
          <v-btn flat :disabled="viewMode" value="in_store_instant" class="grow" active-class="btn-selected">
            In store instant
          </v-btn>
        </div>
      </v-btn-toggle>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    transformation: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['setAwardTransformation'])
  },
  computed: {
    ...mapGetters('campaignBuilder', ['viewMode']),
    ...mapState({
      mode: state => state.campaignBuilder.mode
    }),
    transformation_: {
      get() {
        return this.transformation
      },
      set(value) {
        this.setAwardTransformation(value)
      }
    }
  }
}
</script>
