import AxiosWrapper from '@/services/AxiosWrapper'

const END_POINT = '/loyalty_currency'

const getLoyaltyCurrency = () => AxiosWrapper.get(`${END_POINT}`)
const getPoolPrograms = () => AxiosWrapper.get(`${END_POINT}/pool_programs`)

export default {
	getLoyaltyCurrency,
	getPoolPrograms
}