<template>
  <div class="caption disp-flex" style="align-items: center">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon :style="{ color: iconColor }" small class="mr-1 cursor-pointer" v-bind="attrs" v-on="on">
          {{ rewardIcon }}
        </v-icon>
      </template>
      <strong>{{ reward.voucher ? $t('coreProgram.voucher') : 'Lié à la carte' }}</strong>
    </v-tooltip>
    <v-tooltip open-delay="700" bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on" v-if="reward && Object.keys(reward).length > 0" class="ml-1 ellipsis">
          {{ messagePerLocale ? messagePerLocale : 'Informations indisponible' }}
        </span>
      </template>
      <span>{{ messagePerLocale }}</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon :style="{ color: iconColor }" class="ml-1 cursor-pointer" small v-bind="attrs" v-on="on">info</v-icon>
      </template>
      <span class="d-block">
        {{ $t('coreProgram.rewardType') }}: <strong>{{ consumptionModeLabel }}</strong>
      </span>
      <span class="d-block">
        {{ $t('coreProgram.usePerCustomer') }}: <strong>{{ numberOfUseLabel }}</strong>
      </span>
      <span class="d-block">
        {{ $t('coreProgram.repeatability') }}: <strong>{{ numberOfUsePerTransactionLabel }}</strong>
      </span>
      <span class="d-block">
        {{ $t('coreProgram.transformation') }}: <strong>{{ transformation }}</strong>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    reward: {
      type: Object,
      required: true
    },
    iconColor: {
      type: String,
      required: false,
      default: '#0000008a'
    }
  },
  computed: {
    messagePerLocale() {
      return this.$i18n.locale === 'fr' ? this.reward.messages.fr : this.reward.messages.en
    },
    rewardIcon() {
      return this.reward.voucher ? 'loyalty' : 'card_membership'
    },
    consumptionModeLabel() {
      if (this.reward.consumption_mode === 'price_override') return this.$t('coreProgram.priceOverride')
      else if (this.reward.consumption_mode === 'deffered_cash') return this.$t('coreProgram.defferedCash')
      else return this.$t('coreProgram.instantDiscount')
    },
    numberOfUseLabel() {
      if (this.reward.number_of_use < 0) return 'Unlimited'
      else return this.reward.number_of_use
    },
    numberOfUsePerTransactionLabel() {
      if (this.reward.number_of_use_per_transaction > 1)
        return `${String(this.reward.number_of_use_per_transaction)} ${this.$t('coreProgram.times')}`
      else if (this.reward.number_of_use_per_transaction === 1) return `1 ${this.$t('coreProgram.time')}`
      else return this.$t('coreProgram.noRepeatable')
    },
    transformation() {
      if (this.reward.transformation === 'in_store') return this.$t('createCampaign.triggerReward.manualCheckOut')
      else if (this.reward.transformation === 'out_store_manual') return this.$t('createCampaign.triggerReward.manualOutOfStore')
      else if (this.reward.transformation === 'out_store_automatic') return this.$t('createCampaign.triggerReward.automaticOutOfStore')
      else return 'In store instant'
    }
  }
}
</script>
