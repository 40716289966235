<template>
  <v-dialog :value="show" persistent width="300">
    <v-card color="#3f51b5" dark>
      <v-card-text>
        Chargement de votre campagne
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean
    }
  }
}
</script>
