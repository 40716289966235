export default {
  // WRAPPER for filereader to use with promises
  readAsString(file) {
    const fr = new FileReader()

    return new Promise((resolve, reject) => {
      fr.onerror = () => {
        fr.abort()
        reject(new Error('Problem parsing input file.'))
      }

      fr.onload = () => {
        resolve(fr.result)
      }

      fr.readAsArrayBuffer(file)
    })
  },
}
