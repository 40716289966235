<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md5>
        <v-layout justify-center>
          <v-flex class="mb-2 langage-selection">
            <img
              @click="$i18n.locale = 'fr'"
              src="@/assets/images/standard/france_flag.png"
              width="30px"
              class="mx-1"
            />
            <img
              @click="$i18n.locale = 'en'"
              src="@/assets/images/standard/united-kingdom_flag.png"
              width="30px"
              class="mx-1"
            />
          </v-flex>
        </v-layout>
        <v-alert color="success" outline type="success" :value="fromEnrollment">
          <span
            >{{ successEnrollmentMessage }}
            <b>({{ $route.query.account }})</b></span
          >
        </v-alert>
        <v-card hover class="login-card">
          <v-card-text class="justify-content-center">
            <img
              class="d-block mx-auto mt-5"
              src="@/assets/images/png/logo.png"
              width="40%"
            />
          </v-card-text>
          <div>
            <v-card-text class="px-4 py-4">
              <v-form v-model="valid">
                <v-text-field
                  v-model="email"
                  :rules="loginRules"
                  prepend-icon="person"
                  name="login"
                  label="Email"
                  type="email"
                  required
                  @keyup.enter.prevent="submit"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  id="password"
                  prepend-icon="lock"
                  name="password"
                  label="Password"
                  type="password"
                  required
                  @keyup.enter.prevent="submit"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="pb-5 column d-flex">
              <v-btn large color="primary" :disabled="!valid" @click="submit">
                <v-icon left small>arrow_forward</v-icon>Sign in
              </v-btn>
              <span
                class="d-block caption text-xs-center mt-2"
                @click="showResetPasswordDialog()"
                ><a>
                  {{ $t('login.forgotPassword') }}
                </a>
              </span>
            </v-card-actions>
          </div>
        </v-card>
        <router-view />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import EventBus from '@/packs/event-bus'
import AuthService from '@/services/AuthService'

export default {
  data: () => ({
    account: '',
    valid: false,
    email: '',
    loginRules: [
      v => !!v || 'Login is required',
      v => /.+@.+/.test(v) || 'Login must be a valid email',
    ],
    password: '',
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length > 7 || 'Password not long enough',
    ]
  }),
  computed: {
    ...mapState({
      tokenPresence: state => state.user.tokenPresence
    }),
    successEnrollmentMessage() {
      const message =
        this.$route.query.account_completed === 'true'
          ? this.$t('login.successEnrollmenCompletedAccount')
          : this.$t('login.successEnrollmenIncompletedAccount')

      return message.replace('@', this.$route.query.account)
    },
    fromEnrollment() {
      return this.$route.query.account_completed != null
    }
  },
  created() {
    if (this.tokenPresence) this.$router.push('/dashboard').catch()
    else {
      this.$store.dispatch('clearUser')
      if (this.$route.query.account != null)
        this.email = this.$route.query.account
    }
  },
  methods: {
    showResetPasswordDialog() {
      this.$router.push('/signin/forgot_password').catch()
    },
    async submit() {
      await AuthService.login(this.email, this.password)

      this.$router.push('/')
    },
  }
}
</script>
