<template>
  <div class="disp-flex relative" style="min-height: 250px">
    <div class="align-self-center">
      <v-btn @click="tab--" icon class="ma-0 white--text" color="#3f51b5" :disabled="tab === 1">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
    </div>
    <v-tabs-items v-model="tab" class="w-100">
      <v-tab-item :value="1" class="h-100 disp-flex justify-center">
        <AwardTransformation
            :transformation="action.transformation"
        />
      </v-tab-item>
      <v-tab-item :value="2" v-if="mode !== 'fidelity'" class="h-100 disp-flex justify-center">
        <AwardUse
          :number_of_use="action.number_of_use"
          :number_of_use_per_transaction="action.number_of_use_per_transaction"
        />
      </v-tab-item>
    </v-tabs-items>
    <div class="align-self-center">
      <v-btn @click="tab++" icon class="ma-0 white--text" color="#3f51b5" :disabled="mode === 'fidelity' || tab === 2">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
    </div>
    <div class="absolute paginate-config" v-if="mode !== 'fidelity'">
      <v-pagination v-model="tab" :length="2" circle :total-visible="2" color="#3f51b5"></v-pagination>
    </div>
  </div>
</template>

<script>
import AwardTransformation from '@/components/Actions/Awards/AwardTransformation'
import AwardUse from '@/components/Actions/Awards/AwardUse'
import { mapState } from 'vuex'

export default {
  components: {
    AwardTransformation,
    AwardUse
  },
  props: {
    action: {
      type: Object,
      required: true
    },
    campaign_type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tab: 1
    }
  },
  computed: {
    ...mapState({
      mode: state => state.campaignBuilder.mode
    })
  }
}
</script>
