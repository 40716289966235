<template>
  <div>
    <v-btn flat dark color="blue-grey darken-1" class="ma-0 w-100" @click="dialog = true">
      <v-icon left>store</v-icon>{{ $t('campaignListItem.seeParticipatingPOS') }}
    </v-btn>
    <CampaignDialogParticipantPos v-model="dialog" :id="id" :isBroadcasterCampaign="isBroadcasterCampaign" />
  </div>
</template>

<script>
import CampaignDialogParticipantPos from '@/components/CampaignDialogParticipantPos'

export default {
  components: {
    CampaignDialogParticipantPos
  },
  props: {
    id: {
      type: String,
      required: true
    },
    isBroadcasterCampaign: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    }
  }
}
</script>
