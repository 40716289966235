import AxiosWrapper from '@/services/AxiosWrapper'
import Axios from 'axios';

const END_POINT = '/purchase_rates'

const getSmsRate = () => AxiosWrapper.get(`${END_POINT}/sms_rate`)
const getEmailRate = () => AxiosWrapper.get(`${END_POINT}/email_rate`)

export default {
	getSmsRate,
	getEmailRate
}
