export default class StatusSystem {
  name: string
  counter: string
  calculation_period: string
  counter_frequency: string
  calculation_period_value: number
  counter_frequency_value: number
  levels: any[]

  constructor(
    name = '',
    counter = 'award',
    calculation_period = 'moving_month',
    counter_frequency = 'every_month',
    calculation_period_value = 1,
    counter_frequency_value = 1,
    levels = []
  ) {
    this.name = name
    this.counter = counter
    this.calculation_period = calculation_period
    this.counter_frequency = counter_frequency
    this.calculation_period_value = calculation_period_value
    this.counter_frequency_value = counter_frequency_value
    this.levels = levels
  }
}
