<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="400">
    <v-card>
      <v-card-text>
        <h2>Email list</h2>
        <v-tabs flat>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="(type, index) in emailType"
            :key="index"
            :href="'#tab-' + type"
          >
            {{ type }}
          </v-tab>

          <v-tabs-items>
            <v-tab-item
              v-for="(type, index) in emailType"
              :key="index"
              :value="'tab-' + type"
            >
              <v-card flat>
                <v-card-text class="px-0">
                  <div class="flex-div align-center">
                    <v-text-field
                      @keypress.enter="addElem(newEmail, type)"
                      :prepend-inner-icon="type === 'domains' ? '@' : ''"
                      :label="
                        'New ' + (type === 'domains' ? 'domain' : 'address')
                      "
                      v-model="newEmail"
                    >
                    </v-text-field>
                    <v-btn
                      icon
                      @click="addElem(newEmail, type)"
                      flat
                      color="primary"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </div>
                  <v-list
                    v-if="
                      type === 'domains'
                        ? domains.length > 0
                        : emails.length > 0
                    "
                  >
                    <template
                      v-for="(elem, index) in type === 'domains'
                        ? domains
                        : emails"
                    >
                      <div :key="index">
                        <v-list-tile>
                          <v-list-tile-content>
                            <v-list-tile-title
                              class="font-weight-medium"
                              v-text="'@' + elem"
                            ></v-list-tile-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-icon
                              @click="
                                type === 'domains'
                                  ? domains.splice(index, 1)
                                  : emails.splice(index, 1)
                              "
                            >
                              delete</v-icon
                            >
                          </v-list-tile-action>
                        </v-list-tile>
                        <v-divider></v-divider>
                      </div>
                    </template>
                  </v-list>
                  <template v-else>
                    <v-alert outline icon="info" :value="true" color="info"
                      >Email list is empty, please add at least one domain or
                      specific email.</v-alert
                    >
                  </template>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="$emit('input')">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'

export default {
  data() {
    return {
      newEmail: '',
    }
  },
  props: {
    value: {
      type: Boolean,
    },
    emailType: {
      type: Array,
    },
    domains: {
      type: Array,
    },
    emails: {
      type: Array,
    },
  },
  methods: {
    addElem(elem, type) {
      if (elem) {
        if (type === 'domains') this.domains.push(elem)
        else this.emails.push(elem)
        this.newEmail = ''
      } else {
        EventBus.$emit('showSnackbar', {
          text: this.$t('message.inputError'),
          color: 'error',
        })
      }
    },
  },
}
</script>
