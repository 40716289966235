<template>
  <v-layout row wrap v-if="template">
    <v-flex md6>
      <v-layout row wrap>
        <v-flex xs12 v-if="showInfoVariables">
          <v-alert
            transition="scale-transition"
            :value="showInfoVariables"
            type="info"
            color="blue lighten-5 blue-grey--text text--darken-2"
          >
            <v-layout>
              <v-flex>
                Variables can be added in text area, to add any click on the
                insertion point of your choice in the text, then click on
                <b>@</b> in the menu above the text area, and select the right
                variable among those listed.
              </v-flex>
              <v-flex>
                <v-icon
                  @click="infoVariables = !infoVariables"
                  color="blue-grey lighten-1"
                  >close</v-icon
                >
              </v-flex>
            </v-layout>
          </v-alert>
        </v-flex>
        <v-flex xs12>
          <v-card class="pa-3">
            <div v-for="component in template.components" :key="component.id">
              <h3 class="mb-2">{{ component.label | capitalize }}</h3>
              <div class="flex-div mb-2">
                <bg-editor
                  :sms="true"
                  class="w-100 mt-1 mb-2"
                  :variables="template.available_variables"
                  v-model="component.content"
                ></bg-editor>
              </div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex md6 class="pb-1">
      <v-card class="w-100">
        <PreviewSMSTemplate
          v-if="template && template.components"
          :template="template"
        ></PreviewSMSTemplate>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import BgEditor from './Editor/BgEditor'
import PreviewSMSTemplate from './PreviewSMSTemplate'

export default {
  components: {
    BgEditor,
    PreviewSMSTemplate,
  },
  data() {
    return {
      infoVariables: true,
    }
  },
  props: {
    template: {
      type: Object,
    },
  },
  computed: {
    showInfoVariables() {
      return this.infoVariables && this.template.available_variables.length > 0
    },
  },
}
</script>
