<template>
  <div class="h-100 w-100 elevation-2">
    <div class="header-card header-btn">
      <h3 class="font-weight-medium">
        <v-icon small class="align-icon">data_usage</v-icon>
        {{ $t('customers.segmentCustom') }}
      </h3>
      <v-btn dense small color="primary" class="ma-0" @click="newCampaign()" :disabled="Object.keys(selected).length === 0">
        {{ $t('customers.createCampaign') }}
      </v-btn>
    </div>
    <v-list v-if="segmentsCustom.length > 0" class="container-segment py-0">
      <div v-for="(custom, index) in segmentsCustom" :key="index">
        <v-tooltip top>
          <v-list-tile :key="custom.id" @click="selectSegment(custom)" slot="activator" :class="{ segmentSelected: custom.id === checkSelected }">
            <v-list-tile-content>
              <v-list-tile-title v-text="custom.name"></v-list-tile-title>
            </v-list-tile-content>
            {{ custom.statistics.customers_count }}
            <v-icon small>person</v-icon>
            <v-divider vertical inset class="ml-3"></v-divider>
            <v-list-tile-action class="text-xs-center">
              <v-btn icon ripple class="mr-0" @click="deleteSegment(custom.id, custom.name)">
                <v-icon small color="red lighten-1">delete</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <span>
            {{ $t('customers.tooltip.description') }}
            {{ custom.description ? custom.description : 'Aucune description.' }}
            <br />Paramètre(s) :
            <template v-for="(each, nested_index) of formatParameters(custom)">
              <br :key="nested_index" />&emsp;- {{ each | capitalize }}
            </template>
          </span>
        </v-tooltip>
        <v-divider v-if="index + 1 < segmentsCustom.length" :key="index"></v-divider>
      </div>
    </v-list>
    <div v-else class="container-segment">
      <div class="text-xs-center py-3">{{ $t('customers.segmentError') }}</div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/packs/event-bus'

export default {
  props: {
    segmentsCustom: {
      type: Array,
      required: true
    },
    customer_base: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      selected: Object
    }
  },
  methods: {
    deleteSegment(id, name) {
      this.$router.push({
        name: 'deleteSegments',
        params: { id: id, name: name, customer_base: this.customer_base.id }
      })
    },
    selectSegment(segment) {
      if (segment === this.selected) this.selected = {}
      else this.selected = segment
      eventBus.$emit('manualSelectSegment', this.selected)
    },
    newCampaign() {
      this.$router.push({ path: 'campaigns/custom/new?segment=' + this.selected.id })
    },
    formatParameters(segment) {
      const parameters = []

      for (const each of segment.filters) {
        if (each.kind === 'custom')
          for (const params of Object.keys(each.values))
            parameters.push(`${params}: ${each.values[params]}`)
        else if (each.kind === 'status' || each.kind === 'rfm')
          parameters.push(`${each.kind}: ${each.name}`)
      }
      return parameters
    }
  },
  computed: {
    checkSelected() {
      return this.selected && this.selected.id ? this.selected.id : null
    }
  },
  created() {
    eventBus.$on('selectSegment', segment => {
      this.selected = segment
    })
  }
}
</script>

<style scoped lang="scss">
.header-card {
  background: #eceff1;
  border-bottom: 1px solid #cfd8dc;
  color: #37474f;
}

.container-segment {
  max-height: 256px;
  overflow-x: auto;
}

.segmentSelected {
  background-color: #e3edf8;
}

.segmentSelected .v-list__tile__title {
  color: rgb(24, 103, 192);
  font-weight: 500;
}

.align-icon {
  font-size: 16px;
  vertical-align: middle;
  padding-bottom: 4px;
}

.header-btn {
  display: flex;
  justify-content: space-between;
  padding: 14px !important;
}
</style>
