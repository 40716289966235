import { Module } from 'vuex'
import { IPartialOrder, IFinalizedOrder } from '../interfaces'
import { initialOrderState } from '../initialState'
import { sumProperty, calculatePartialOrder } from '../helpers'
import flatten from 'lodash.flatten'

interface SmsEmails {
  sms: number
  emails: number
}

interface OrderState {
  loading: boolean
  selected: {
    sms: number
    emails: number
  }
  min: SmsEmails
  max: SmsEmails
  step: SmsEmails
  lastStepBeforePayment: boolean
  emailOrder: IPartialOrder[]
  smsOrder: IPartialOrder[]
  finalizedOrders: IFinalizedOrder[]
}

const UPDATE_EMAIL_ORDER = 'UPDATE_EMAIL_ORDER'
const UPDATE_SMS_ORDER = 'UPDATE_SMS_ORDER'
const CONFIRM = 'CONFIRM'
const RESET_PARTIAL_ORDERS = 'RESET_PARTIAL_ORDERS'
const SELECT_EMAILS = 'SELECT_EMAILS'
const SELECT_SMS = 'SELECT_SMS'
const SET_LOADING = 'SET_LOADING'
const UPDATE_SMS_MAX = 'UPDATE_SMS_MAX'
const UPDATE_EMAIL_MAX = 'UPDATE_EMAIL_MAX'
const UPDATE_SMS_MIN = 'UPDATE_SMS_MIN'
const UPDATE_EMAIL_MIN = 'UPDATE_EMAIL_MIN'
const UPDATE_LAST_STEP_BEFORE_PAYMENT = 'UPDATE_LAST_STEP_BEFORE_PAYMENT'

export const order: Module<OrderState, any> = {
  namespaced: true,
  state: {
    selected: {
      sms: 0,
      emails: 0
    },
    min: {
      sms: 0,
      emails: 0
    },
    max: {
      sms: 250000,
      emails: 500000
    },
    step: {
      sms: 1000,
      emails: 1000
    },
    lastStepBeforePayment: false,
    loading: false,
    emailOrder: [],
    smsOrder: [],
    finalizedOrders: []
  },
  getters: {
    loading(state) {
      return state.loading
    },
    
    lastStepBeforePayment(state) {
      return state.lastStepBeforePayment
    },

    smsMax(state) {
      return state.max.sms
    },

    smsMin(state) {
      return state.min.sms
    },

    smsStep(state) {
      return state.step.sms
    },

    emailsMin(state) {
      return state.min.emails
    },

    emailsMax(state) {
      return state.max.emails
    },

    emailsStep(state) {
      return state.step.emails
    },

    selectedEmails(state) {
      return state.selected.emails
    },

    selectedSms(state) {
      return state.selected.sms
    },

    emailOrder(state) {
      return state.emailOrder
    },

    smsOrder(state) {
      return state.smsOrder
    },

    finalizedOrders(state) {
      return state.finalizedOrders
    },

    summary(state) {
      const { smsOrder, emailOrder } = state
      const sms = {
        type: 'sms',
        cost: smsOrder.reduce(
          (previous, current) => previous + current.cost,
          0
        ),
        quantity: smsOrder.reduce(
          (previous, current) => previous + current.orderQuantity,
          0
        )
      }

      const email = {
        type: 'email',
        cost: emailOrder.reduce(
          (previous, current) => previous + current.cost,
          0
        ),
        quantity: emailOrder.reduce(
          (previous, current) => previous + current.orderQuantity,
          0
        )
      }
      return [sms, email]
    },

    finalizedOrdersCount(state, getters) {
      return getters['finalizedOrders'].length
    },

    total(state, getters) {
      return getters['summary'].reduce(
        (prev: number, curr: { cost: number }) => prev + curr.cost,
        0
      )
    },

    availableItems(state) {
      const sms = flatten(state.finalizedOrders.map(item => item.sms))
      const emails = flatten(state.finalizedOrders.map(item => item.emails))
      const cost = {
        sms: sumProperty(sms, 'cost'),
        emails: sumProperty(emails, 'cost')
      }

      const quantity = {
        sms: sumProperty(sms, 'orderQuantity'),
        emails: sumProperty(emails, 'orderQuantity')
      }
      const availableSms = {
        type: 'SMS',
        availableQuantity: quantity.sms,
        purchaseValue: cost.sms,
        averageValue: quantity.sms > 0 ? cost.sms / quantity.sms : 0,
        reservedQuantity: 0
      }

      const availableEmails = {
        type: 'Emails',
        availableQuantity: quantity.emails,
        purchaseValue: cost.emails,
        averageValue: quantity.emails > 0 ? cost.emails / quantity.emails : 0,
        reservedQuantity: 0
      }

      return [availableSms, availableEmails]
    }
  },

  mutations: {
    [SET_LOADING](state, loading) {
      state.loading = loading
    },

    [RESET_PARTIAL_ORDERS](state) {
      state.emailOrder = JSON.parse(JSON.stringify(initialOrderState))
      state.smsOrder = JSON.parse(JSON.stringify(initialOrderState))
    },

    [UPDATE_EMAIL_ORDER](state, newOrder) {
      state.emailOrder = newOrder
    },

    [UPDATE_SMS_ORDER](state, newOrder) {
      state.smsOrder = newOrder
    },

    [UPDATE_SMS_MIN](state, min) {
      state.min.sms = min
    },

    [UPDATE_EMAIL_MIN](state, min) {
      state.min.emails = min
    },

    [UPDATE_SMS_MAX](state, max) {
      state.max.sms = max
    },

    [UPDATE_EMAIL_MAX](state, max) {
      state.max.emails = max
    },

    [CONFIRM](state, id) {
      const finalizedOrder = {
        id,
        date: new Date(),
        sms: JSON.parse(JSON.stringify(state.smsOrder)),
        emails: JSON.parse(JSON.stringify(state.emailOrder))
      }

      state.finalizedOrders.push(finalizedOrder)
    },

    [SELECT_EMAILS](state, amount) {
      state.selected.emails = amount
    },

    [SELECT_SMS](state, amount) {
      state.selected.sms = amount
    },

    [UPDATE_LAST_STEP_BEFORE_PAYMENT](state, payload) {
      state.lastStepBeforePayment = payload
    }
  },

  actions: {
    setPricing(
      { commit },
      pricing: { sms: IPartialOrder[]; emails: IPartialOrder[] }
    ) {
      commit(UPDATE_EMAIL_ORDER, pricing.emails)
      commit(UPDATE_SMS_ORDER, pricing.sms)
    },

    updateSmsMax({commit}, max: number) {
      commit(UPDATE_SMS_MAX, max)
    },

    updateEmailMax({commit}, max: number) {
      commit(UPDATE_EMAIL_MAX, max)
    },

    updateSmsMin({commit}, min: number) {
      commit(UPDATE_SMS_MIN, min)
    },

    updateEmailMin({commit}, min: number) {
      commit(UPDATE_EMAIL_MIN, min)
    },

    setLoading({ commit }, loading) {
      commit(SET_LOADING, loading)
    },

    calculateEmailOrder({ commit, getters }, amount) {
      const newOrder = calculatePartialOrder(getters['emailOrder'], amount)
      commit(UPDATE_EMAIL_ORDER, newOrder)
    },

    calculateSmsOrder({ commit, getters }, amount) {
      const newOrder = calculatePartialOrder(getters['smsOrder'], amount)
      commit(UPDATE_SMS_ORDER, newOrder)
    },

    confirm({ commit }, id: string) {
      commit(CONFIRM, id)
      commit(RESET_PARTIAL_ORDERS)
      commit(SELECT_EMAILS, 0)
      commit(SELECT_SMS, 0)
    },

    selectEmails({ commit }, amount) {
      commit(SELECT_EMAILS, amount)
    },

    selectSms({ commit }, amount) {
      commit(SELECT_SMS, amount)
    },

    updateLastStepBeforePayment({ commit }, payload) {
      commit(UPDATE_LAST_STEP_BEFORE_PAYMENT, payload)
    }
  }
}
