<template>
  <v-layout row wrap v-if="cardData">
    <v-flex md3 sm6 v-for="card in cardsModel.cards" :key="card.title">
      <DashboardStatsCard :stats="card"></DashboardStatsCard>
    </v-flex>
  </v-layout>
</template>

<script>
import DashboardStatsCard from './DashboardFidStatsCard'

export default {
  components: {
    DashboardStatsCard,
  },
  props: {
    cardData: {
      required: true,
    },
  },
  computed: {
    cardsModel() {
      return {
        cards: [
          {
            title: this.$t('dashboard.card.new'),
            icon: 'person',
            css: 'deep-purple',
            type: 'number',
            data: {
              value: this.cardData.new_customer_count
                ? this.cardData.new_customer_count
                : 0,
              trend: this.cardData.new_customer_count_trend
                ? this.cardData.new_customer_count_trend.toFixed(1)
                : 0,
            },
          },
          {
            title: this.$t('dashboard.card.orders'),
            icon: 'trending_up',
            css: 'light-green',
            type: 'number',
            data: {
              value: this.cardData.order_count ? this.cardData.order_count : 0,
              trend: this.cardData.order_count_trend
                ? this.cardData.order_count_trend.toFixed(1)
                : 0,
            },
          },
          {
            title: this.$t('dashboard.card.revenue'),
            icon: 'account_balance',
            css: 'light-blue',
            type: 'price',
            data: {
              value: this.cardData.revenue_amount
                ? this.cardData.revenue_amount.toCurrency()
                : 0,
              trend: this.cardData.revenue_amount_trend
                ? this.cardData.revenue_amount_trend.toFixed(1)
                : 0,
            },
          },
          {
            title: this.$t('dashboard.card.averageBasket'),
            icon: 'shopping_basket',
            css: 'amber',
            type: 'price',
            data: {
              value: this.cardData.average_basket
                ? this.cardData.average_basket.toCurrency()
                : 0,
              trend: this.cardData.average_basket_trend
                ? this.cardData.average_basket_trend.toFixed(1)
                : 0,
            },
          },
        ],
      }
    },
  },
}
</script>
