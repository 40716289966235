<template>
  <v-container fluid grid-list-lg>
    <v-layout>
      <v-flex xs12>
        <h2 class="headline mb-3">Registered workplaces</h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="my-0" align-end>
      <v-flex mb-2 md3 v-if="customer_bases.length > 1">
        <span class="subheading">Select a customer base: </span>
        <v-card class="pr-2 mt-1 blue-grey lighten-5">
          <v-select
            flat
            v-model="current_customer_base"
            :items="customer_bases"
            item-text="name"
            solo
            @change="getWordplaceSegments(current_customer_base.id)"
            append-outer-icon="dns"
            menu-props="offsetY"
            hide-details
            return-object
          >
          </v-select>
        </v-card>
      </v-flex>
      <v-flex md6 mb-2>
        <v-btn @click="addCompany = !addCompany" class="ml-0" color="primary">
          <v-icon class="mr-2">{{ addCompany ? 'remove' : 'add' }}</v-icon
          >Add workplace
        </v-btn>
        <v-btn
          @click="addClientGroup = !addClientGroup"
          class="ml-0"
          color="primary"
        >
          <v-icon class="mr-2">{{ addClientGroup ? 'remove' : 'add' }}</v-icon
          >Add workplace group
        </v-btn>
      </v-flex>
    </v-layout>
    <transition name="slide-y-transition" class="full-width" tag="div">
      <add-client-group
        v-if="addClientGroup"
        @closeEvent="closeAddClient()"
        :segmentsWorkplace="segmentsWorkplace"
      ></add-client-group>
    </transition>
    <transition name="slide-y-transition" class="full-width" tag="div">
      <add-company
        v-if="addCompany"
        @closeEvent="closeAddCompany()"
        :segmentsWorkplace="segmentsWorkplace"
        :current_customer_base_id="current_customer_base.id"
        @refreshList="refreshListCompanies($event)"
      ></add-company>
    </transition>
    <v-layout>
      <v-flex xs12>
        <companies-table :refreshList="refreshList"></companies-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import req from '@/packs/request.js'
import AddClientGroup from './AddClientGroup'
import AddCompany from './AddCompany'
import CompaniesTable from './CompaniesTable'
export default {
  components: {
    AddClientGroup,
    AddCompany,
    CompaniesTable,
  },
  data: () => {
    return {
      addCompany: false,
      addClientGroup: false,
      customer_bases: [],
      current_customer_base: {},
      segmentsWorkplace: [],
      refreshList: false,
    }
  },
  methods: {
    refreshListCompanies(refresh) {
      this.refreshList = refresh
    },
    getCustomerBases() {
      req
        .get('/customer_bases')
        .then(response => {
          if (response.data.length === 0) return
          this.customer_bases = response.data
          this.current_customer_base = this.customer_bases[0]
          this.getWordplaceSegments(this.current_customer_base.id)
        })
        .catch(function(error) {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getWordplaceSegments(id) {
      req
        .get('/customer_bases/' + id + '/segments?by_kind=workplace')
        .then(response => {
          this.segmentsWorkplace = []
          this.segmentsWorkplace = response.data
        })
    },
    closeAddCompany(value) {
      this.addCompany = value
    },
    closeAddClient(value) {
      this.addClientGroup = value
    },
  },
  created() {
    this.getCustomerBases()
  },
}
</script>
