<template>
  <div class="caption">
    <div class="mr-1 ellipsis"><span class="font-weight-medium">{{ $t('campaignListItem.findersFee') }} : </span>
      <template class="ellipsis" v-if="commissionContract.rate_card.commission_rules.length <= 1">
        {{ commissionContract.rate_card.commission_rules[0].amount | monetize }}%
      </template>
      <template v-else>
        <span v-for="(oneCommission, index) in commissionContract.rate_card.commission_rules" :key="index">
          {{ oneCommission.amount | monetize }}% ({{
            index === 0 ? '1' : (commissionContract.rate_card.commission_rules[index - 1].sales_count + 1) | numberTo3
          }}
          to {{ oneCommission.sales_count | numberTo3 }} sales)
          <span class="mx-1" v-if="index < commissionContract.rate_card.commission_rules.length - 1">|</span>
        </span>
      </template>
    </div>
    <template v-if="kind === 'permanent'">
      <div v-if="commissionContract.status === 'accepted'">
        <span class="font-weight-medium">{{ $t('campaignListItem.generosityPart') }}:</span>
        <span class="ml-1 ellipsis">
          {{ commissionContract.generosity_part ? commissionContract.generosity_part / 100 + '%' : 'Not set' }}
        </span>
      </div>
      <div>
        <span class="font-weight-medium">{{ $t('campaignListItem.generosityPartMin') }}:</span>
        <span class="ml-2 ellipsis">{{ commissionContract.rate_card.minimum_generosity_amount.toCurrency() }}%</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    commissionContract: {
      type: Object,
      required: true
    },
    kind: {
      type: String,
      required: true
    }
  }
}
</script>
