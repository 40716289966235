<template>
  <div v-scroll="onScroll" v-on:mousewheel="infiniteScroll">
    <v-layout row wrap>
      <v-flex xs12>
        <BaseTabs
          v-model="tabIndex"
          :labels="tabsLabels"
          :icons="['tune', 'campaign', 'receipt', 'campaign', 'campaign']"
          :showTabs="[isAdvertiser, isAdvertiser, isAdvertiser, isPublisher, isAdvertiser]"
          :tabsCounters="tabsCounters"
          @view="view = $event"
        >
          <template v-slot:[slotName]>
            <div v-show="!showLoading">
              <transition name="slide-y-reverse-transition" tag="div" mode="out-in">
                <v-layout row wrap>
                  <v-flex xs12 mt-2>
                    <v-layout class="mt-3" v-if="!loading && campaigns.length === 0">
                      <v-flex xs12 v-if="campaignHasFilters()">
                        <div class="font-weight-light title text-center black--text">
                          Aucune campagne avec les filtres choisis
                        </div>
                      </v-flex>
                      <v-flex xs4 v-else-if="fidelity || tabIndex === 0">
                        <v-card class="elevation-0 h-100 no-activate-actions" height="154px" @click="createCampaign">
                          <v-card-title class="flex-column align-center justify-center h-100">
                            <div class="font-weight-light title">
                              Créer une campagne {{ tabsLabels[tabIndex] | lowercase }}
                            </div>
                            <v-icon large class="mt-3">add_circle</v-icon>
                          </v-card-title>
                        </v-card>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <div class="font-weight-light title text-center black--text">
                          Aucunes campagnes {{ tabsLabels[tabIndex] | lowercase }}
                        </div>
                      </v-flex>
                    </v-layout>
                    <template v-else>
                      <div class="wrapper-card mt-3" :class="{ 'three-card': view === 3, 'two-card': view === 2, 'one-card': view === 1 }">
                        <CampaignListItem
                          v-for="(campaign, index) in campaigns"
                          :campaign="campaign"
                          :index="index"
                          :key="`item-${campaign.id}-${index}`"
                          :isBroadcasterCampaign="tabIndex === 3"
                        />
                      </div>
                    </template>
                  </v-flex>
                </v-layout>
              </transition>
              <v-layout row justify-center mt-5>
                <clip-loader class="my-4" :loading="showLoading" color="#78909c" width="60px" height="60px">
                </clip-loader>
              </v-layout>
              <v-fab-transition>
                <v-btn color="primary" v-show="offsetTop > 400" dark fixed bottom right fab @click="scrollTop">
                  <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-layout align-center justify-center>
                <v-chip v-if="!loading && campaigns.length < count" disabled outline small round color="primary" class="body-2 font-weight-light">Scroll to see more</v-chip>
                <clip-loader v-else-if="loading && campaigns.length > 0" class="my-4" :loading="true" color="#78909c" width="60px" height="60px"></clip-loader>
              </v-layout>
            </div>
          </template>
        </BaseTabs>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BaseTabs from '@/components/BaseTabs'
import CampaignListItem from '@/components/CampaignListItem'

import provideCampaignListModule from '@/mixins/provideModule'

export default {
  mixins: [provideCampaignListModule],
  props: {
    fidelity: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    CampaignListItem,
    BaseTabs
  },
  data() {
    return {
      offsetTop: 0,
      statusLabels: [
        this.$t('filters.all'),
        this.$t('filters.running'),
        this.$t('filters.pending'),
        this.$t('filters.finished')
      ],
      stateLabels: [this.$t('filters.all'), this.$t('filters.active'), this.$t('filters.inactive')],
      kindLabels: [this.$t('filters.all'), this.$t('filters.permanent'), this.$t('filters.oneTime')],
      view: 2
    }
  },
  created() {
    this.resetState()

    const mode = this.fidelity ? 'fidelity' : 'conquest'
    this.setMode(mode)
    this.fetchAllCampaigns()

    this.setDefaultTab()
    window.addEventListener('scroll', this.infiniteScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.infiniteScroll)
  },
  computed: {
    ...mapState({
      isAdvertiser: state => state.user.capabilities.advertiser,
      isPublisher: state => state.user.capabilities.publisher
    }),
    ...mapGetters('campaignList', ['campaignsByTabIndex', 'tabsCounters']),
    tabIndex: {
      get() {
        return this.$store.state.campaignList.tabIndex
      },
      set(value) {
        this.setTabIndex(value)
      }
    },
    loading() {
      return this.campaignsData.loading
    },
    campaignsData() {
      return this.campaignsByTabIndex
    },
    campaigns() {
      return this.campaignsData.collection
    },
    count() {
      return this.campaignsData.count
    },
    showLoading: {
      cache: false,
      get() {
        return this.loading && this.campaigns.length === 0
      }
    },
    slotName() {
      return `tab_${this.tabIndex}`
    },
    onlyPublisher() {
      return this.isPublisher && !this.isAdvertiser
    },
    canScroll() {
      return this.campaigns.length < this.count
    },
    tabsLabels() {
      return this.fidelity
        ? ['Personalisée(s)', 'Communication(s)']
        : [
            this.$t('campaigns.myCampaigns'),
            this.$t('campaigns.inherited'),
            this.$t('campaigns.tradeMarketing'),
            this.$t('campaigns.publishing')
          ]
    }
  },
  methods: {
    ...mapActions('campaignList', ['setMode', 'setTabIndex', 'fetchAllCampaigns', 'fetchNextPage', 'resetState']),
    onScroll() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
    },
    campaignHasFilters() {
      const filters = this.campaignsData.filters
      Object.entries(filters).forEach(e => {
        if (e[1] === 'all') delete filters[e[0]]
      })
      return Object.keys(filters).length > 1
    },
    canCreateCampaign() {
      if (this.$store.state.role) return this.$store.state.role.can_create_campaign
      else return true
    },
    infiniteScroll() {
      const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
      if (bottomOfWindow && this.canScroll && !this.loading) this.fetchNextPage()
    },
    scrollTop() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    setDefaultTab() {
      if (this.$route.query.tabIndex) {
        if (this.$route.query.tabIndex > 0) this.tabIndex = Number(this.$route.query.tabIndex)
      } else {
        const query = { ...this.$route.query, tabIndex: 0 }
        this.$router.replace({ query })
      }
      if (this.onlyPublisher) this.tabIndex = 3
    },
    createCampaign() {
      let path = ''
      if (this.fidelity) path = this.tabIndex === 0 ? '/campaigns/custom/new' : '/campaigns/communication/new'
      else path = '/campaigns/new'

      this.$router.push(path)
    }
  },
  watch: {
    tabIndex: {
      handler(newV) {
        const query = { ...this.$route.query }
        query.tabIndex = newV
        this.$router.replace({ query })
      }
    }
  }
}
</script>
