<template>
  <v-layout>
    <v-flex>
      <div class="img-container">
        <img :src="sourceImage" class="img-displayed" />
        <div
          class="editBlock flex-div align-center justify-center"
          @click="pickImage"
        >
          <div class="transition-grey"></div>
          <v-icon light medium class="far-away">edit</v-icon>
        </div>

        <!-- <div
          v-if="sourceImage"
          class="clearBlock flex-div align-center justify-center"
          @click="clearImage"
        >
          <div class="transition-grey"></div>
          <v-icon color="red" light medium class="far-away">close</v-icon>
        </div> -->

        <input
          type="file"
          style="display: none"
          ref="image"
          accept="image/*"
          @change="onImagePicked"
        />
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import Uploader from '@/packs/uploader'

export default {
  model: {
    prop: 'image',
    event: 'change',
  },
  props: {
    image: null,
    src: String,
    height: {
      type: String,
      default: '100',
    },
    prompt: {
      type: String,
      default: 'Select image',
    },
  },
  data: function() {
    return {
      image_url: null,
    }
  },
  computed: {
    sourceImage() {
      return this.image_url ? this.image_url : this.src
    },
  },
  methods: {
    pickImage() {
      this.$refs.image.click()
    },
    onImagePicked(event) {
      const files = event.target.files

      Array.from(files).forEach(file => {
        const fr = new FileReader()
        fr.readAsDataURL(file)
        fr.addEventListener('load', () => {
          this.image_url = fr.result
          this.$emit('imageChanged', fr.result)
          if (this.image) {
            this.image.image_url = this.image_url
          }
          this.uploadFile(file)
        })
      })
    },
    uploadFile(file) {
      Uploader.prepare(file)
        .then(data => {
          return Uploader.send(file, data).then(() => {
            this.$emit('change', data.signed_id)
          })
        })
        .catch(error => {
          console.error(error)
        })
    },
    // clearImage() {
    // this.image_url = null
    // this.$emit('imageChanged', null)
    // this.$emit('change', null)
    // }
  },
}
</script>

<style scoped>
.far-away {
  z-index: 5;
}

.editBlock {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.clearBlock {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  z-index: 3;
  width: 30px;
  height: 30px;
}

.transition-grey {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  width: 100%;
  height: 0;
  background: #cfd8dc;
  transition: all 0.15s ease-out;
}

.editBlock:hover .transition-grey {
  height: 100%;
  opacity: 0.8;
}

.img-container {
  display: flex;
  position: relative;
  max-height: 100px;
  min-height: 80px;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  background: #f5f5f5;
}

.img-displayed {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
}
</style>
