export default class PoolProgram {
  name: string
  mode: string
  kind: number
  earn_exchange_rate: number
  burn_exchange_rate: number | null

  constructor(name = 'Pool Program', mode = 'rate', kind = 1, earn_exchange_rate = 1, burn_exchange_rate = null) {
    ;(this.name = name),
      (this.mode = mode),
      (this.kind = kind),
      (this.earn_exchange_rate = earn_exchange_rate),
      (this.burn_exchange_rate = burn_exchange_rate)
  }
}
