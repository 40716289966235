<template>
  <v-layout column>
    <v-flex xs2 my-3>
      <h2 class="title mt-1">
        <v-icon class="mr-2">publish</v-icon
        >{{ $t('campaignListItem.publishers') }}
      </h2>
    </v-flex>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="commissionContracts"
        class="elevation-1"
        :loading="loading"
        :no-data-text="loading ? $t('common.loading') : $t('common.noData')"
        :rows-per-page-text="$t('common.rowsPerPage')"
      >
        <template v-slot:items="props">
          <td>
            <img
              style="width: 80px; height: auto;"
              :src="props.item.publisher.company.logo_url"
            />
          </td>
          <td>{{ props.item.publisher.name }}</td>
          <td>{{ props.item.rate_card.pricing_model.kind }}</td>
          <td>
            {{ props.item.rate_card.commission_rules[0].amount.toCurrency() }}
            {{
              props.item.rate_card.commission_rules[0].amount_kind == 'value'
                ? '€'
                : '%'
            }}
          </td>
          <td>
            <v-chip
              :color="
                props.item.status == 'accepted'
                  ? 'green'
                  : props.item.status == 'pending'
                  ? 'yellow darken-3'
                  : 'red'
              "
              small
              disabled
              outline
            >
              <v-avatar>
                <v-icon>{{ returnIcon(props.item.status) }}</v-icon>
              </v-avatar>
              {{ $t(`status.${props.item.status}`) }}
            </v-chip>
          </td>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import req from '@/packs/request.js'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      commissionContracts: [],
      loading: true,
      headers: [
        {
          text: '',
          value: 'logo',
          sortable: false,
        },
        {
          text: this.$t('publisher.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('publisher.pricingModel'),
          value: 'pricingModel',
          sortable: false,
        },
        {
          text: this.$t('publisher.rateCard'),
          value: 'rateCard',
          sortable: false,
        },
        {
          text: this.$t('publisher.status'),
          value: 'status',
          sortable: false,
        },
      ],
    }
  },
  created() {
    this.fetchCommissionContracts()
  },
  methods: {
    async fetchCommissionContracts() {
      const res = await req.get(`/campaigns/${this.id}/commission_contracts`)

      this.commissionContracts = res.data
      this.loading = false
    },
    returnIcon(state) {
      const icons = {
        accepted: 'check_circle',
        pending: 'watch_later',
        refused: 'remove_circle',
      }
      return icons[state]
    },
  },
}
</script>
