var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticStyle:{"height":"fit-content"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in","duration":500}},[(!_vm.expandAcceptAsPublisher)?_c('div',[_c('div',{staticClass:"px-3 pt-3 pb-1 disp-flex",staticStyle:{"min-height":"120px"}},[_c('div',{staticClass:"disp-flex align-center mr-3"},[(_vm.campaign.logo_url > 0)?_c('img',{staticClass:"campaign-img elevation-2",attrs:{"src":_vm.campaign.logo_url}}):(_vm.campaign.advertiser && _vm.campaign.advertiser.logo_url)?_c('img',{staticClass:"campaign-img elevation-2",attrs:{"src":_vm.campaign.advertiser.logo_url}}):_c('img',{staticClass:"campaign-img elevation-2",attrs:{"src":require("@/assets/images/standard/default-image.png")}})]),_c('div',{staticClass:"w-100 overflow-hidden"},[_c('div',{staticClass:"disp-flex mb-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h2',_vm._g({staticClass:"font-weight-medium ellipsis pr-3"},on),[_vm._v(_vm._s(_vm.campaign.name))])]}}],null,false,2270294357)},[_c('span',[_vm._v(_vm._s(_vm.campaign.name))])]),_c('v-spacer'),(_vm.commissionContract && _vm.isBroadcasterCampaign)?_c('v-chip',{staticClass:"mx-0",attrs:{"small":"","disabled":"","outline":"","color":_vm.commissionContract.status === 'accepted'
                    ? '#4ad991'
                    : _vm.commissionContract.status === 'pending'
                    ? 'yellow darken-3'
                    : '#ff7285'}},[_c('v-avatar',[_c('v-icon',[_vm._v(_vm._s(_vm.stateIcon))])],1),_vm._v(" "+_vm._s(_vm.$t(("status." + (_vm.commissionContract.status))))+" ")],1):_c('v-chip',{staticClass:"mx-0",attrs:{"small":"","disabled":"","outline":"","color":_vm.campaign.status === 'running'
                    ? '#4ad991'
                    : _vm.campaign.status === 'pending'
                    ? 'yellow darken-3'
                    : '#ff7285'}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t(("filters." + (_vm.campaign.status))))+" ")])])],1),_vm._l((_vm.rewards),function(reward,index){return _c('CampaignRewardMessage',{key:reward.id,attrs:{"reward":reward,"index":index}})}),(_vm.isBroadcasterCampaign && _vm.commissionContract)?_c('CampaignListItemPublisherFees',{attrs:{"commission-contract":_vm.commissionContract,"kind":_vm.campaign.kind}}):_vm._e(),(_vm.isEmailPublishing)?_c('a',{staticClass:"caption disp-flex align-center mt-2",staticStyle:{"color":"#3f51b5"},on:{"click":function($event){_vm.expandCard = !_vm.expandCard}}},[_vm._v(" Voir plus de détails "),_c('v-icon',{staticStyle:{"color":"#3f51b5"}},[_vm._v(_vm._s(_vm.expandCard ? 'keyboard_arrow_down' : 'keyboard_arrow_up'))])],1):_c('div',{staticClass:"my-3"})],2)]),_c('v-slide-y-transition',[(_vm.expandCard)?_c('v-card-text',{staticClass:"pt-0"},[_c('v-divider',{staticClass:"mb-3"}),_c('PublishingInfo',{attrs:{"campaign":_vm.campaign}})],1):_vm._e()],1),_c('div',{staticClass:"disp-flex blue-grey lighten-5",staticStyle:{"min-height":"40px"}},[_c('div',{staticClass:"font-weight-medium mt-auto mb-auto ml-3 ellipsis"},[_vm._v(" Temps restant : "+_vm._s(_vm.campaignTimeleft ? _vm.campaignTimeleft : _vm.campaign.status === 'finished' ? 'Terminé' : 'N/A')+" ")]),(
              _vm.isBroadcasterCampaign &&
                _vm.campaign.status !== 'finished' &&
                _vm.commissionContract &&
                _vm.commissionContract.status === 'pending'
            )?[_c('v-spacer'),_c('CampaignButtonAcceptAsPublisher',{attrs:{"commission-contract-id":_vm.commissionContract.id,"kind":_vm.campaign.kind},on:{"update":function($event){return _vm.setCommissionContract({ index: _vm.index, value: $event })},"expand":function($event){_vm.expandAcceptAsPublisher = true}}})]:(_vm.campaign.status !== 'finished')?[_c('v-spacer'),(_vm.campaign.status !== 'draft')?[_c('v-divider',{attrs:{"vertical":""}}),_c('CampaignSwitchState',{attrs:{"campaign":_vm.campaign},on:{"update":function($event){return _vm.setCampaignState({ index: _vm.index, value: $event })}}})]:_vm._e(),(_vm.showCampaignButtonStatus)?[_c('v-divider',{attrs:{"vertical":""}}),_c('CampaignButtonStatus',{attrs:{"campaign":_vm.campaign},on:{"update":function($event){return _vm.setCampaignStatus({ index: _vm.index, value: $event })}}})]:_vm._e()]:_c('v-spacer'),_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",staticStyle:{"height":"auto","width":"50px"},attrs:{"flat":"","icon":"","color":"#696969"}},on),[_c('v-icon',{staticClass:"material-icons-round",staticStyle:{"font-size":"20px"}},[_vm._v(" settings ")])],1)]}}],null,false,2885630075)},[_c('div',{staticClass:"white text-align-selector disp-flex flex-column"},[(_vm.showCampaignButtonSeeTransactions)?_c('CampaignButtonSeeTransactions',{staticClass:"ma-0",attrs:{"campaign":_vm.campaign}}):_vm._e(),(!_vm.fidelity && _vm.campaign.status !== 'draft')?_c('CampaignButtonParticipantPos',{attrs:{"id":_vm.campaign.id,"isBroadcasterCampaign":_vm.isBroadcasterCampaign}}):_vm._e(),(!_vm.fidelity && !_vm.isBroadcasterCampaign && _vm.campaign.status !== 'draft')?_c('CampaignButtonPublishers',{attrs:{"id":_vm.campaign.id}}):_vm._e(),(
                  (_vm.isBroadcasterCampaign &&
                    _vm.campaign.state === 'active' &&
                    _vm.campaign.status === 'running' &&
                    _vm.commissionContract &&
                    _vm.commissionContract.status === 'accepted') ||
                    (_vm.campaign.kind === 'communication' && _vm.campaign.status === 'pending')
                )?_c('v-btn',{staticClass:"ma-0 w-100",attrs:{"flat":"","dark":"","color":"blue-grey darken-1"},on:{"click":function($event){_vm.emailDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("email")]),_vm._v(" Test e-mail ")],1):_vm._e(),(_vm.campaign.mine && !_vm.isBroadcasterCampaign)?_c('v-btn',{staticClass:"ma-0",attrs:{"flat":"","dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.editCampaign()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("edit")]),_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")],1):_vm._e(),(_vm.campaign.mine && ['pending', 'draft'].includes(_vm.campaign.status))?_c('CampaignButtonDelete',{attrs:{"campaign":_vm.campaign},on:{"onDelete":function($event){return _vm.deleteCampaign(_vm.index)}}}):_vm._e()],1)])],2)],1):_c('CampaignExpandAcceptAsPublisher',{attrs:{"kind":_vm.campaign.kind,"commission-contract":_vm.commissionContract},on:{"update":function($event){return _vm.setCommissionContract({ index: _vm.index, value: $event })},"close":function($event){_vm.expandAcceptAsPublisher = false}}})],1)],1),_c('DialogDemoEmail',{attrs:{"campaign-id":_vm.campaign.id,"campaign-kind":_vm.campaign.kind},model:{value:(_vm.emailDialog),callback:function ($$v) {_vm.emailDialog=$$v},expression:"emailDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }