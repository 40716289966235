import Fr from '@/packs/file_reader'
import axios from 'axios'
import req from '@/packs/request'
import { FileChecksum } from '@rails/activestorage/src/file_checksum'

export default {
  checksum(file) {
    return new Promise((resolve, reject) => {
      FileChecksum.create(file, (error, checksum) => {
        if (error) {
          reject(new Error('Error while creating checksum'))
        } else {
          resolve(checksum)
        }
      })
    })
  },
  prepare(file) {
    return Promise.resolve()
      .then(() => {
        return this.checksum(file)
      })
      .then(checksum => {
        return req
          .post('/direct_upload', {
            blob: {
              filename: file.name,
              byte_size: file.size,
              checksum: checksum,
              content_type: file.type,
            },
          })
          .then(response => {
            return response.data
          })
      })
  },
  send(file, data) {
    return Promise.resolve()
      .then(() => {
        return Fr.readAsString(file)
      })
      .then(file_data => {
        return axios({
          method: 'put',
          url: data.direct_upload.url,
          headers: data.direct_upload.headers,
          data: file_data,
        })
      })
  },
}
