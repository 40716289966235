<template>
  <div>
    <div class="header-card pa-3 disp-flex">
      <h3 class="font-weight-medium">
        <v-icon small class="align-icon">business_center</v-icon>
        {{ $t('settings.donationOrganization.title') }}
      </h3>
      <v-switch :disabled="!donationExist" v-model="company.donor" :label="company.donor ? $t('common.enable') : $t('common.disable')" class="mt-0 pt-0 ml-4" hide-details></v-switch>
    </div>
    <div class="pt-3 px-5" v-if="!loaded">
      <v-layout row wrap v-if="company.donor && donationExist">
        <v-flex sm12 md6 lg5>
          <h3 class="font-weight-medium">{{ $t('settings.donationOrganization.logo') }}</h3>
          <image-loader v-model="donationOrganization.logo" :src="donationOrganization.logo_url" height="150"></image-loader>
          <v-text-field v-model="donationOrganization.name" :label="$t('settings.donationOrganization.name')" class="mt-3"></v-text-field>
          <v-text-field v-model="donationOrganization.description" label="Description"></v-text-field>
          <h3 class="font-weight-medium">{{ $t('settings.mainColor') }}</h3>
          <div class="flex-div align-center">
            <div class="square-color mr-3" :style="`background-color: ${donationOrganization.main_color}`" @click="changeColor = !changeColor"></div>
            <v-text-field @click="changeColor = !changeColor" v-model="donationOrganization.main_color" :label="$t('settings.color')" hide-details></v-text-field>
          </div>
          <v-card v-if="changeColor" class="color-picker-field flex-div flex-column">
            <sketch-picker v-model="donationOrganization.main_color" @input="updateValue" class="mr-0"/>
            <v-btn class="my-2 ml-auto primary" @click="changeColor = false">close</v-btn>
          </v-card>
        </v-flex>
        <v-flex sm12 md6 lg7>
          <h3 class="mb-3">{{ $t('settings.location') }}</h3>
          <v-text-field :label="$t('settings.address1')" v-model="donationOrganization.location.line_1"></v-text-field>
          <v-text-field :label="$t('settings.address2')" v-model="donationOrganization.location.line_2"></v-text-field>
          <v-text-field :label="$t('settings.zipCode')" v-model="donationOrganization.location.zipcode"></v-text-field>
          <v-text-field :label="$t('settings.city')" v-model="donationOrganization.location.city"></v-text-field>
          <v-text-field :label="$t('settings.website')" v-model="donationOrganization.website_url"></v-text-field>
        </v-flex>
      </v-layout>
      <div v-else class="text-xs-center my-5">
        <v-icon style="font-size: 50px">info</v-icon>
        <h2 class="font-weight-medium">{{ $t('settings.donationOrganization.disabled') }}.</h2>
      </div>
      <div class="pb-3">
        <v-btn color="primary" @click="saveChanges()" :disabled="!donationExist" class="mx-0">
          <v-icon small class="mr-2">save</v-icon>
          {{ $t('settings.saveChanges') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import req from '@/packs/request.js'
import ImageLoader from '@/components/Base/ImageLoader'
import { Sketch } from 'vue-color'
import { mapActions } from 'vuex'

export default {
  components: {
    ImageLoader,
    'sketch-picker': Sketch
  },
  data: () => {
    return {
      loaded: false,
      donationExist: true,
      changeColor: false,
      company: {
        donor: false
      },
      donationOrganization: {
        location: {}
      }
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['updateCompagnyDonor']),
    async fetchCompany() {
      this.loaded = true
      const res = await req.get('/user/company')
      this.company.donor = res.data.donor
    },
    async fetchDonationOrganization() {
      try {
        const res = await req.get('/user/company/donation_organization')
        this.donationOrganization = res.data
        if (!res.data.location) this.donationOrganization.location = {}
        this.loaded = false
      } catch (e) {
        this.loaded = false
        this.donationExist = false
      }
    },
    updateValue(value) {
      this.donationOrganization.main_color = value.hex
    },
    async saveCompany() {
      const company = this.company
      const res = await req.put('/user/company', { company })
      this.company.donor = res.data.donor
    },
    async saveDonationOrganization() {
      const donation_organization = this.donationOrganization
      let res = {}

      if (!this.donationOrganization.id) res = await req.post('/user/company/donation_organization', { donation_organization })
      else res = await req.put('/user/company/donation_organization', { donation_organization })
      this.donationOrganization = res.data
    },
    async saveChanges() {
      await this.saveCompany()
      this.updateCompagnyDonor(this.company.donor)
      if (this.company.donor) await this.saveDonationOrganization()
    }
  },
  created() {
    this.fetchCompany()
    this.fetchDonationOrganization()
  }
}
</script>
