<template>
  <v-layout column wrap>
    <v-flex py-0>
      <v-card color="blue-grey lighten-5">
        <v-data-table
          :headers="headersRatecards"
          :items="ratecards"
          :pagination.sync="pagination"
          item-key="name"
          class="elevation-1"
          :rows-per-page-text="$t('common.rowsPerPage')"
          :loading="loading"
        >
          <template slot="headersRatecards" slot-scope="props">
            <tr>
              <th>
                <v-checkbox color="primary" hide-details></v-checkbox>
              </th>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="[
                  'column sortable',
                  'text-xs-left',
                  pagination.descending ? 'desc' : 'asc',
                  header.value === pagination.sortBy ? 'active' : '',
                ]"
                @click="changeSort(header.value)"
              >
                {{ header.text }}
                <v-icon small>arrow_upward</v-icon>
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td class="text-uppercase">
                {{ props.item.pricing_model.kind }}
              </td>
              <td>
                <v-icon v-if="props.item.default">star</v-icon>
              </td>
              <td>
                <template v-if="props.item.commission_rules.length <= 1">
                  {{ props.item.commission_rules[0].amount | monetize }}%
                </template>
                <template v-else>
                  <span
                    v-for="(oneCommission, index) in props.item
                      .commission_rules"
                    :key="index"
                  >
                    {{ oneCommission.amount | monetize }}% ({{
                      index === 0
                        ? '1'
                        : (props.item.commission_rules[index - 1].sales_count +
                            1)
                          | numberTo3
                    }}
                    to {{ oneCommission.sales_count | numberTo3 }} sales)
                    <span
                      class="mx-1"
                      v-if="index < props.item.commission_rules.length - 1"
                      >|</span
                    >
                  </span>
                </template>
              </td>
              <td>
                <v-btn @click="editItem(props.item)" flat icon color="primary">
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn
                  @click="deleteRatecard(props.item)"
                  flat
                  icon
                  color="error"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'
export default {
  data() {
    return {
      valid: true,
      dialogEdit: false,
      pagination: {
        sortBy: 'name',
      },
      headersRatecards: [
        {
          text: this.$t('ratecards.name'),
          value: 'name',
        },
        {
          text: this.$t('ratecards.pricingModel'),
          value: 'pricing_model',
        },
        {
          text: this.$t('ratecards.default'),
          value: 'default',
        },
        {
          text: this.$t('ratecards.findersFeeVat'),
          value: 'findersfee',
        },
        {
          text: 'Actions',
          value: '',
          sortable: false,
          width: '15%',
        },
      ],
    }
  },
  props: {
    ratecards: Array,
    loading: Boolean,
  },
  methods: {
    editItem(item) {
      const newObj = JSON.parse(JSON.stringify(item))
      this.$emit('editRatecard', newObj)
    },
    deleteRatecard(item) {
      req
        .delete('/rate_cards/' + item.id)
        .then(() => {
          EventBus.$emit('showSnackbar', {
            text: 'The ratecard ' + item.name + ' has been deleted',
            color: 'success',
          })
          this.$emit('refresh')
        })
        .catch(error => {
          EventBus.$emit('showSnackbar', {
            text: this.$t('message.ratecardErrorDelete'),
            color: 'error',
          })
        })
    },
  },
}
</script>
