<template>
  <div>
    <v-icon class="mr-1" small dark>person</v-icon>
    <b>{{ rfm.statistics.customers_count }}</b> {{ $t('common.customers') }} ({{
      percentageBase(rfm.statistics.customers_count)
    }}% {{ $t('common.customersBase') }})
    <div class="flex-div align-center mt-1" v-if="info.length > 0">
      <v-icon class="mr-1" small dark>info</v-icon>
      {{ info }}
    </div>
    <div class="mt-2">
      <ul>
        <li>
          {{ $t('customers.averageRecency') }}
          <b> {{ recencyAverage(rfm.statistics.avg_recency) }}</b>
        </li>
        <li>
          {{ $t('customers.averageFrequency') }}
          <b>
            {{
              rfm.statistics.avg_frequency
                ? rfm.statistics.avg_frequency + '/' + $t('common.years')
                : '-'
            }}</b
          >
        </li>
        <li>
          {{ $t('customers.averageBasket') }}
          <b> {{ rfm.statistics.avg_monetary / 100 }}€</b>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rfm: {
      type: Object,
      required: true,
    },
    info: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    recencyAverage(average) {
      if (average === 0) return 0

      const avg_date = new Date(average * 1000)
      const calc = (new Date() - avg_date) / 1000 / 60 / 60 / 24

      if (calc < 1) return 1
      else if (calc < 90) return parseInt(calc) + ' ' + this.$t('common.days')
      else if (calc < 730)
        return parseInt(calc / 31) + ' ' + this.$t('common.months')
      else return parseInt(calc / 31 / 12) + ' ' + this.$t('common.years')
    },
    percentageBase(val) {
      if (this.total > 0) {
        const calc = (val / this.total) * 100
        if (calc <= 100) return Math.round(calc * 10) / 10
        else return '-'
      } else return 0
    },
  },
}
</script>
