<template>
  <div class="disp-flex relative" style="min-height: 250px">
    <div class="align-self-center">
      <v-btn @click="tab--" icon class="ma-0 white--text" color="#3f51b5" :disabled="disableLeftArrow">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
    </div>
    <v-tabs-items v-model="tab" class="w-100">
      <v-tab-item :value="1" class="h-100 disp-flex justify-center">
        <Scope
          :scope="action.scope"
          :quantity="action.quantity"
          :target_id="action.target_id"
          :action_type="action.card_type"
          :type="action.type"
          :kind="action.kind"
        />
      </v-tab-item>
      <v-tab-item :value="2" v-if="!action.gift" class="h-100 disp-flex justify-center">
        <RewardConsumption
          :consumption_mode="action.consumption_mode"
          :amount_kind="action.amount_kind"
          :amount="action.amount"
          :scope="action.scope"
          :type="action.type"
        />
      </v-tab-item>
      <v-tab-item v-if="!action.gift" :value="3" class="h-100 disp-flex justify-center">
        <RewardUse
          :scope="action.scope"
          :number-of-use="action.number_of_use"
          :number-of-use-per-transaction="action.number_of_use_per_transaction"
          :type="action.type"
        />
      </v-tab-item>
      <v-tab-item v-if="!action.gift" :value="4" class="h-100 disp-flex justify-center">
        <RewardValidity :availability_duration="action.availability_duration" />
      </v-tab-item>
    </v-tabs-items>
    <div class="align-self-center">
      <v-btn @click="tab++" icon class="ma-0 white--text" color="#3f51b5" :disabled="disableRightArrow">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
    </div>
    <div class="absolute paginate-config">
      <v-pagination v-model="tab" :length="displayValidity ? 4 : 3" circle :total-visible="displayValidity ? 4 : 3" color="#3f51b5"></v-pagination>
    </div>
  </div>
</template>

<script>
import Scope from '@/components/Actions/Common/Scope'
import RewardConsumption from '@/components/Actions/Rewards/RewardConsumption'
import RewardUse from '@/components/Actions/Rewards/RewardUse'
import RewardValidity from '@/components/Actions/Rewards/RewardValidity'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    Scope,
    RewardConsumption,
    RewardUse,
    RewardValidity
  },
  props: {
    action: {
      type: Object,
      required: true
    },
    campaign_type: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('campaignBuilder', ['hasAward']),
    ...mapState({
      li: state => state.campaignBuilder.li
    }),
    displayValidity() {
      return this.hasAward(this.li)
    },
    disableRightArrow() {
      return this.action.gift || (!this.displayValidity && this.tab === 3) || this.tab === 4
    },
    disableLeftArrow() {
      return this.action.gift || this.tab === 1
    }
  },
  data() {
    return {
      tab: 1
    }
  }
}
</script>
