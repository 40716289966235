<template>
  <v-tabs grow color="#3f51b5" :value="value" @change="$emit('input', $event)" class="tabs-header">
    <v-tabs-slider color="white"></v-tabs-slider>
    <v-tab v-for="(label, index) in labels" :key="`tab_${index}`" v-show="showTabs[index]">
      <v-icon class="material-icons-round mr-2">{{ icons[index] }}</v-icon>
      Campagne {{ label | lowercase }} {{ tabsCounters ? `(${tabsCounters[index]})` : '' }}
    </v-tab>
    <v-tab-item v-for="(label, index) in labels" :key="index">
      <v-card class="style-button">
        <div class="disp-flex align-center justify-center py-2" style="flex-wrap: wrap">
          <v-divider vertical class="my-2 mr-2"></v-divider>
          <v-btn-toggle mandatory v-model="_state" class="h-100 elevation-0">
            <v-btn flat active-class="active-view" class="text-transform-none" value="all">Tout</v-btn>
            <v-btn flat active-class="active-view" class="text-transform-none" value="active">Active</v-btn>
            <v-btn flat active-class="active-view" class="text-transform-none" value="inactive">Inactive</v-btn>
          </v-btn-toggle>
          <v-divider vertical class="my-2 mx-2"></v-divider>
          <v-btn-toggle mandatory v-model="_status" class="h-100 elevation-0">
            <v-btn flat active-class="active-view" class="text-transform-none" value="all">Tout</v-btn>
            <v-btn flat active-class="active-view" class="text-transform-none" value="running">En cours</v-btn>
            <v-btn flat active-class="active-view" class="text-transform-none" value="stopped">En pause</v-btn>
            <v-btn flat active-class="active-view" class="text-transform-none" value="pending">En attente</v-btn>
            <v-btn flat active-class="active-view" class="text-transform-none" value="finished">Terminé</v-btn>
            <v-btn flat active-class="active-view" class="text-transform-none" value="draft">Brouillon</v-btn>
          </v-btn-toggle>
          <v-divider vertical class="my-2 mx-2"></v-divider>
          <v-btn-toggle mandatory v-model="view" @change="$emit('view', $event)" class="h-100 elevation-0">
            <v-btn flat active-class="active-view" :value="1"><v-icon>view_agenda</v-icon></v-btn>
            <v-btn flat active-class="active-view" :value="2"><v-icon class="rotate-90">view_agenda</v-icon></v-btn>
            <v-btn flat active-class="active-view" :value="3"><v-icon class="font-size-32">view_week</v-icon></v-btn>
          </v-btn-toggle>
          <v-divider vertical class="my-2 mx-2"></v-divider>
          <v-btn small icon outline @click="refresh" style="color: #a6a6a6">
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-divider vertical class="my-2 ml-2"></v-divider>
        </div>
      </v-card>
      <slot :name="`tab_${index}`"></slot>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    labels: {
      type: Array,
      required: false,
      default: () => ['tab1']
    },
    showTabs: {
      type: Array,
      required: false,
      default: function() {
        return Array(this.labels.length).fill(true)
      }
    },
    tabsCounters: {
      type: Array,
      required: false
    },
    icons: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      view: 2
    }
  },
  computed: {
    ...mapGetters('campaignList', ['filtersCampaigns']),
    _state: {
      get() {
        return this.filtersCampaigns.by_state ? this.filtersCampaigns.by_state : 'all'
      },
      set(value) {
        this.filterCampaignsByState(value)
      }
    },
    _status: {
      get() {
        return this.filtersCampaigns.by_status ? this.filtersCampaigns.by_status : 'all'
      },
      set(value) {
        this.filterCampaignsByStatus(value)
      }
    }
  },
  methods: {
    ...mapActions('campaignList', [
      'filterCampaignsByStatus',
      'filterCampaignsByState',
      'refresh'
    ])
  }
}
</script>
