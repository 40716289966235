import _ from 'lodash'

export default {
  fetchFilters(filters) {
    let params = []

    if (this.checkChange(filters)) {
      for (const each of Object.keys(filters)) {
        if (each === 'custom' && this.checkValue(filters[each]))
          params.push({ values: this.deleteEmptyValue(filters[each]) })
        else if (
          (each === 'rfm' || each === 'status') &&
          Object.keys(filters[each]).length > 0
        )
          params.push({ id: filters[each].id })
      }
    } else params = filters['segment'].filters.map(e => ({ id: e.id }))
    return params
  },
  checkChange(check) {
    if (check['segment'] && Object.keys(check['segment']).length > 0) {
      for (const each of Object.keys(check)) {
        if (
          Object.keys(this.deleteEmptyValue(check[each])).length > 0 &&
          each !== 'segment'
        ) {
          if (check['segment'].filters.find(e => e.kind === each) === undefined)
            return true
          else
            for (const filter of check['segment'].filters) {
              if (check[filter.kind].id === filter.id) continue
              else if (
                filter.values &&
                _.isEqual(filter.values, this.deleteEmptyValue(check['custom']))
              )
                continue
              else return true
            }
        }
      }
    } else return true
    return false
  },
  checkValue(filter) {
    if (Object.keys(filter).length > 0)
      for (const each of Object.keys(filter)) if (filter[each]) return true
    return false
  },
  deleteEmptyValue(value) {
    for (const key of Object.keys(value))
      if (value[key] === null || value[key] === '') delete value[key]
    return value
  },
}
