<template>
  <v-card class="config elevation-0 px-4">
    <div class="font-weight-light title disp-flex align-center mb-4">
      <div class="font-weight-bold disp-flex align-center justify-center config-number mr-3">4</div>
      <span>La <span class="font-weight-medium">durée de validé</span> de l'offre est :</span>
    </div>
    <div class="disp-flex">
      <label class="font-weight-medium w-100">Validité :</label>
      <v-switch
        v-model="enabled"
        @change="onSwitch($event)"
        :label="enabled ? $t('common.enable') : $t('common.disable')"
        class="pt-0 mt-0"
        :disabled="viewMode"
        hide-details
      />
    </div>
    <div class="flex-div align-center mt-4">
      <div class="font-weight-medium align-self-end">Durée de la validité :</div>
      <v-spacer></v-spacer>
      <v-text-field
        v-model.number="validityValue"
        @input="setValidity()"
        :disabled="!enabled || viewMode"
        class="text-number-mid pt-0 mt-0"
        :label="$t('createCampaign.triggerReward.duration')"
        type="number"
        :min="1"
        :max="10"
        hide-details
      ></v-text-field>
      <v-select
        v-model="validityPeriod"
        @change="setValidity()"
        :items="periods"
        :disabled="!enabled || viewMode"
        class="text-number-mid pt-0 mt-0 ml-3"
        return-object
        item-text="name"
        item-value="value"
        :label="$t('createCampaign.triggerReward.unitOfTime')"
        hide-details
      ></v-select>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    availability_duration: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      periods: [
        { name: 'Jour(s)', value: 'days' },
        { name: 'Mois', value: 'months' },
        { name: 'Année(s)', value: 'years' }
      ],
      validityValue: 1,
      validityPeriod: { name: 'Jour(s)', value: 'days' },
      enabled: true
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['setRewardAvailabilityDuration']),
    setInitialValidityPeriod() {
      this.validityPeriod =
        this.$moment.duration(this.availability_duration, 'seconds').asYears() > 0.99 // Trick to handle non precision in conversion
          ? { name: 'Year(s)', value: 'years' }
          : this.$moment.duration(this.availability_duration, 'seconds').asMonths() > 0.98
          ? { name: 'Month(s)', value: 'months' }
          : { name: 'Day(s)', value: 'days' }
    },
    setInitialValidityValue() {
      const roundedValue = Math.round(
        Number(this.$moment.duration(this.availability_duration, 'seconds').as(this.validityPeriod.value))
      )
      this.validityValue = roundedValue
    },
    setValidity() {
      const seconds = this.$moment.duration(this.validityValue, this.validityPeriod.value).as('seconds')
      this.setRewardAvailabilityDuration(seconds)
    },
    onSwitch(event) {
      event ? this.setValidity() : this.setRewardAvailabilityDuration(null)
    }
  },
  computed: {
    ...mapGetters('campaignBuilder', ['viewMode'])
  },
  created() {
    if (!this.availability_duration) this.enabled = false
    else {
      this.setInitialValidityPeriod()
      this.setInitialValidityValue()
    }
  }
}
</script>
