<template>
  <div>
    <v-expansion-panel class="elevation-0" :readonly="readOnly" :disabled="disable">
      <v-expansion-panel-content :hide-actions="readOnly">
        <template v-slot:header>
          <TransactionsItemTopView
            :data="transaction"
            :read-only="readOnly"
          ></TransactionsItemTopView>
        </template>
        <template v-slot:default>
          <div class="px-4 pb-4">
            <v-divider class="mb-4 custom-divider"></v-divider>
            <div :class="{ 'multiple-grid': transaction.source === 'in_store' }">
              <TransactionsItemTimeline
                :cart="transaction.cart"
                :id="transaction.id"
                @hover="hover($event)"
                :class="{
                  'divider-vertical': transaction.source === 'in_store'
                }"
              >
              </TransactionsItemTimeline>
              <TransactionsItemCart
                :hovered-transaction="hoveredTransaction"
                v-if="transaction.source === 'in_store'"
                :data="transaction.cart"
                :see-customer="seeCustomer"
                :identifier="transaction.identifiers.length > 0 ? transaction.identifiers[0] : {}"
              >
              </TransactionsItemCart>
            </div>
          </div>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import TransactionsItemTopView from './TransactionsItemTopView'
import TransactionsItemTimeline from './TransactionsItemTimeline'
import TransactionsItemCart from './TransactionsItemCart'

export default {
  components: {
    TransactionsItemTopView,
    TransactionsItemTimeline,
    TransactionsItemCart,
  },
  data: function() {
    return {
      hoveredTransaction: {}
    }
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    disable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    seeCustomer: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    hover(transaction) {
      this.hoveredTransaction = transaction
    }
  }
}
</script>

<style lang="sass">
@import "@/stylesheets/Transactions/TransactionsItem"
</style>
