<template>
  <v-layout row wrap>
    <v-flex md4>
      <IncompletePointOfSalesList
        :loading="loading"
        :pointOfSales="pointOfSales"
        :selectedPos="selectedPos"
        @click="selectedPos = $event"
      />
    </v-flex>
    <v-flex pt-3 md8>
      <IncompletePointOfSalesCard v-if="selectedPos" :pointOfSale="selectedPos" @onComplete="complete($event)" />
    </v-flex>
  </v-layout>
</template>

<script>
import IncompletePointOfSalesList from '@/components/IncompletePointOfSalesList'
import IncompletePointOfSalesCard from '@/components/IncompletePointOfSalesCard'

export default {
  components: {
    IncompletePointOfSalesList,
    IncompletePointOfSalesCard
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
      required: true
    },
    pointOfSales: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      selectedPos: {}
    }
  },
  watch: {
    loading: {
      handler(newV, oldV) {
        if (oldV && !newV) this.selectedPos = this.pointOfSales[0]
      }
    }
  },
  methods: {
    complete(pointOfSale) {
      this.$emit('onComplete', pointOfSale)
      this.selectedPos = this.pointOfSales[0]
    }
  }
}
</script>
