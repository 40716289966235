<template>
  <v-menu transition="slide-y-transition" offset-y close-on-click>
    <v-btn class="v-btn--flat" active-class="active" slot="activator">
      {{ $t('nav.company') }}
    </v-btn>
    <v-list>
      <v-list-tile @click="to('/childs')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/child-companies.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.childCompanies') }}</span>
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="to('/points_of_sale')">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/points-of-sale.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.pointsOfSale') }}</span>
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="to('/retailers')" v-if="$store.getters.getBrandAdvertiser">
        <v-list-tile-title class="disp-flex align-center">
          <img src="@/assets/images/icons/advertisers.svg" width="24" class="mr-3" alt="icon" />
          <span>{{ $t('nav.retailers') }}</span>
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  methods: {
    to(link) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push(link).catch(() => {})
    }
  }
}
</script>
