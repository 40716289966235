export default class Level {
  name: string
  membership_fee: number | null
  frequency_threshold: number | null
  frequency_sustain: number | null
  monetary_threshold: number | null
  monetary_sustain: number | null
  service_threshold: number | null
  service_sustain: number | null
  unit_threshold: number | null
  unit_sustain: number | null
  maintenance: boolean

  constructor(
    name = '',
    membership_fee: number | null = null,
    frequency_threshold: number | null = null,
    frequency_sustain: number | null = null,
    monetary_threshold: number | null = null,
    monetary_sustain: number | null = null,
    service_threshold: number | null = null,
    service_sustain: number | null = null,
    unit_threshold: number | null = null,
    unit_sustain: number | null = null,
    maintenance = false
  ) {
      this.maintenance = maintenance,
      this.name = name,
      this.membership_fee = membership_fee,
      this.frequency_threshold = frequency_threshold,
      this.frequency_sustain = frequency_sustain,
      this.monetary_threshold = monetary_threshold,
      this.monetary_sustain = monetary_sustain,
      this.service_threshold = service_threshold,
      this.service_sustain = service_sustain,
      this.unit_threshold = unit_threshold,
      this.unit_sustain = unit_sustain
  }
}
