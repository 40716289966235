<template>
  <v-layout>
    <v-flex xs12 sm6 offset-sm3>
      <v-card width="600" height="600">
        <v-img :src="advertiser.logo_url" aspect-ratio="2.75"></v-img>

        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">{{ advertiser.name }}</h3>
          </div>
        </v-card-title>
        <v-flex>
          <retailer-list :retailers="retailers" :loading="loading" />
        </v-flex>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import RetailerList from '@/components/Retailers/RetailerList'

export default {
  props: {
    advertiser: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    retailers: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  components: {
    RetailerList,
  },
}
</script>
