<template>
  <v-dialog :value="value" @input="closeDialog()" max-width="800">
    <v-card class="px-5 py-4 relative">
      <div class="absolute actions-bar">
        <img src="@/assets/images/logo/powered_bgo.png" style="max-width: 175px; width: 100%" />
        <v-icon class="cursor-pointer" @click="closeDialog()">close</v-icon>
      </div>
      <div class="text-center">
        <img src="@/assets/images/logo/welcome_avantage_beaute.png" class="mt-5" style="max-width: 295px; width: 100%" />
        <h2 class="font-weight-medium green--text mt-4">
          <v-icon class="material-icons-outlined green--text">check_circle</v-icon>
          Votre compte est activé
        </h2>
      </div>
      <p class="mt-4">
        Bienvenue sur le premier générateur de ventes à la performance dédié au monde de la beauté et de la coiffure.<br><br>Nous avons créé et activé pour vous deux campagnes publicitaires permanentes :
      </p>
      <p class="ml-4 font-weight-medium">
        - Une campagne « Bienvenue » valable qu’une fois pour tout nouveau client.<br><br>
        - Une campagne « Retour (permanente) » destinée à faire revenir la clientèle des banques, assureurs, grands commerçants (Casino par exemple) ...
      </p>
      <p>Vous n'avez rien à faire de particulier tout est automatique.</p>
      <div class="d-flex justify-space-between align-center" style="flex-wrap: wrap">
        <v-btn flat color="primary" class="mx-0 pa-0 ellipsis" style="width: 300px; max-width: 300px" @click="displayAgain(true)" small>
          Ne plus afficher à la prochaine connexion
        </v-btn>
        <v-btn depressed class="mx-0 btn-campaign green--text" style="width: 190px; max-width: 190px" @click="closeDialog(); $router.push('/campaigns')">
          Voir mes campagnes
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeDialog() {
      sessionStorage.setItem('welcomeDialog', true)
      this.$emit('input', false)
    },
    displayAgain(value) {
      localStorage.setItem('welcomeDialog', value)
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="sass" scoped>
.actions-bar
  top: 20px
  right: 20px
  left: 20px
  display: flex
  justify-content: space-between

.btn-campaign
  background-color: #DAF7E8 !important
</style>
