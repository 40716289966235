<template>
  <div>
    <v-expansion-panel class="mb-4">
      <v-expansion-panel-content v-for="(item, i) in this.orders" :key="i">
        <template v-slot:header>
          <v-flex>
            <p class="font-weight-bold mb-0">Date :</p>
            <p class="mb-0">{{ $moment(item.created_at).format('LL') }}</p>
          </v-flex>
          <v-flex>
            <p class="font-weight-bold mb-0">ID :</p>
            <p class="mb-0">{{ item.order_number }}</p>
          </v-flex>
          <v-flex>
            <p class="font-weight-bold mb-0">{{ $t('communications.purchaseHistory.totalMail') }} :</p>
            <p class="mb-0">{{ $n(item.total_mail, 'decimal') }}</p>
          </v-flex>
          <v-flex>
            <p class="font-weight-bold mb-0">{{ $t('communications.purchaseHistory.totalSms') }} :</p>
            <p class="mb-0">{{ $n(item.total_sms, 'decimal') }}</p>
          </v-flex>
          <v-flex>
            <p class="font-weight-bold mb-0">{{ $t('communications.purchaseHistory.paymentMethod') }} :</p>
            <p class="mb-0">{{ item.payment_method | capitalize }}</p>
          </v-flex>
          <v-flex>
            <p class="font-weight-bold mb-0">{{ $t('communications.purchaseHistory.paymentStatus') }} :</p>
            <v-chip
              :color="item.payment_status === 'pending' ? 'orange' : 'green'"
              small
              text-color="white"
              class="font-weight-bold"
            >
              {{ $t(`communications.${item.payment_status}`) }}
            </v-chip>
          </v-flex>
          <v-flex>
            <p class="font-weight-bold mb-0">{{ $t('communications.purchaseHistory.totalAmount') }} :</p>
            <p class="mb-0">{{ $n(item.total_amount.toCurrency(), 'currency') }}</p>
          </v-flex>
        </template>
        <v-card>
          <v-card-text>
            <v-data-table hide-actions class="elevation-1 datatable" :headers="headers" :items="item.basket_items">
              <template slot="headerCell" slot-scope="props">
                {{ $t(props.header.text) }}
              </template>
              <template v-slot:items="props">
                <td class="text-xs-left">{{ props.item.kind | capitalize }}</td>
                <td class="text-xs-left">{{ props.item.unit_price.toCurrency().toFixed(2) / 1000 }} €</td>
                <td class="text-xs-left">{{ $n(props.item.balance, 'decimal') }}</td>
                <td class="text-xs-left">{{ $n(props.item.item_price.toCurrency(), 'currency') }}</td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <div class="text-center">
      <v-pagination
        v-model="pagination.page"
        :length="Math.ceil(nbOrders / this.pagination.perPage)"
        :total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import AdvertiserService from '@/services/AdvertiserService'
import EventBus from '@/packs/event-bus'

@Component
export default class PurchaseHistoryTable extends Vue {
  @State(state => state.user.company) company

  headers = [
    {
      text: 'Type',
      sortable: false
    },
    {
      text: 'communications.purchaseHistory.unitPrice',
      sortable: false
    },
    {
      text: 'communications.quantity',
      sortable: false
    },
    {
      text: 'communications.cost',
      sortable: false
    }
  ]
  pagination = {
    perPage: 10,
    page: 1
  }
  orders = []
  nbOrders = 0

  mounted() {
    this.fetchPurchaseHistory()
    EventBus.$on('updatePurchaseHistory', () => {
      this.$nextTick(() => {
        this.fetchPurchaseHistory()
      })
    })
  }

  async fetchPurchaseHistory() {
    try {
      const res = await AdvertiserService.getPurchaseHistory(
        this.company.advertisers[0].id,
        this.pagination.perPage,
        this.pagination.page
      )
      this.orders = res.data.collection
      this.nbOrders = res.data.count
    } catch (error) {
      console.log(error)
    }
  }

  type(items, kind) {
    const array = []

    items.forEach(item => {
      if (item.kind === kind) array.push(item)
    })
    return array
  }

  getTotalQuantity(items) {
    return items.reduce((previous, current) => previous + current.quantity, 0)
  }

  getPaymentTotal(items) {
    return items.reduce((prev, curr) => prev + curr.item_price, 0)
  }

  items(item) {
    return [
      ...item.sms.map(item => ({ type: 'SMS', ...item })),
      ...item.emails.map(item => ({ type: 'Email', ...item }))
    ]
  }

  @Watch('pagination', { deep: true })
  onPaginationChanged() {
    this.fetchPurchaseHistory()
  }
}
</script>
