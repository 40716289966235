<template>
  <v-app>
    <Header v-if="loaded && tokenPresence" @reload="headerReload" :key="`header_${keyHeader}`" />
    <router-view :key="`appRouterView_${routerViewKey}`" v-if="loaded" @showSnackbar="showSnackbar"></router-view>
    <app-footer v-if="loaded && tokenPresence"></app-footer>
    <vue-progress-bar></vue-progress-bar>
    <!-- SnackBar -->
    <v-snackbar :text="text" :timeout="timeout" top :color="color" v-model="snackbar" auto-height>
      {{ text }}
      <v-btn flat dark @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EventBus from '@/packs/event-bus'
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import req from '@/packs/request.js'

import { getTokenPresence } from '@/services/AxiosWrapper'
import eventBus from '@/packs/event-bus'

export default {
  components: {
    Header,
    'app-footer': Footer
  },
  data: () => {
    return {
      keyHeader: 1,
      //snackbar
      snackbar: false,
      text: '',
      color: '',
      timeout: 5000
    }
  },
  created() {
    EventBus.$on('showSnackbar', snackbar => {
      this.showSnackbar(snackbar.text, snackbar.color)
    })

    EventBus.$on('showPublisherSheet', publisherID => {
      this.publisherID = publisherID
    })

    eventBus.$on('refreshCompany', async id => {
      await this.changeCompany(id)
    })

    this.setTokenPresence(getTokenPresence())
    if (this.tokenPresence) {
      this.fetchAccount()
      this.fetchCompany()
      this.fetchCapabilities()
      this.fetchLoyaltyCurrency()
      this.fetchRole()
    }

    this.$i18n.locale = 'fr'
    this.$moment.locale('fr')

    // [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },
  computed: {
    ...mapState({
      routerViewKey: state => state.user.routerViewKey,
      tokenPresence: state => state.user.tokenPresence,
      companyLoading: state => state.user.companyLoading,
      capabilitiesLoading: state => state.user.capabilitiesLoading,
      loyaltyCurrencyLoading: state => state.user.loyaltyCurrencyLoading
    }),
    // Wait for store to be ready before render (if logged)
    loaded() {
      return (
        (!this.companyLoading && !this.capabilitiesLoading && !this.loyaltyCurrencyLoading && this.tokenPresence) ||
        !this.tokenPresence
      )
    }
  },
  mounted() {
    this.$Progress.finish()
    EventBus.$on('forbidden', _ => {
      this.$nextTick(_ => {
        this.showSnackbar('Your session has expired. Please reconnect', 'error')
      })
      this.$router.push('/signin').catch()
    })
  },
  methods: {
    ...mapActions(['setTokenPresence', 'fetchAccount', 'fetchCompany', 'fetchCapabilities', 'fetchLoyaltyCurrency', 'incrementRouterViewKey']),
    async changeCompany(id) {
      await req.put('/handled_companies/' + id).then(async () => {
        await this.$store.dispatch('fetchCompany')
        await this.$store.dispatch('fetchCapabilities')
        this.incrementRouterViewKey()
      })
    },
    headerReload() {
      this.keyHeader++
    },
    showSnackbar(text, color) {
      this.snackbar = true
      this.text = text
      this.color = color
    },
    async fetchRole() {
      const res = await req.get('/user/role')
      this.$store.commit('storeRole', res.data)
    }
  }
}
</script>
