<template>
  <v-container fluid grid-list-lg>
    <retailer-card
      :advertiser="advertiserBrand"
      :retailers="retailers"
      :loading="!loaded"
    />
  </v-container>
</template>

<script>
import req from '@/packs/request.js'
import RetailerCard from '@/components/Retailers/RetailerCard'

export default {
  components: {
    RetailerCard
  },
  data() {
    return {
      retailers: [],
      loaded: false
    }
  },
  created() {
    this.fetchRetailers()
  },
  computed: {
    advertiserBrand() {
      return this.$store.state.user.company.advertisers[0]
    }
  },
  methods: {
    async fetchRetailers() {
      const res = await req.get('/company/retailers')

      this.retailers = res.data
      this.loaded = true
    }
  }
}
</script>
