<template>
  <div class="relative">
    <div class="icon-details-action w-100 px-3">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" v-if="triggerCustom">stars</v-icon>
        </template>
        <span>
          Ce trigger est spécifique a ce type de campagne, il ne <br>nécessite donc pas de configuration particulière.
        </span>
      </v-tooltip>
      <div class="disp-flex justify-end w-100 icon-delete">
        <v-icon v-if="!isTriggerAward && (editMode && status === 'draft')" @click="deleteAction({action: action, line: lineIndex, index: actionIndex})">delete_forever</v-icon>
      </div>
    </div>
    <v-card
      :hover="!selected"
      :elevation="selected ? 7 : 0"
      class="card-actions pa-4 align-center relative cursor-pointer"
      @click.stop="selectAction({ li: lineIndex, ai: actionIndex })"
      :class="{
        trigger: action.card_type === 'trigger',
        award: action.card_type === 'award',
        reward: action.card_type === 'reward' || action.card_type === 'pool',
        'selected-actions': selected,
        'disable-click': triggerCustom
      }"
    >
      <v-icon v-if="action.card_type === 'trigger' || action.type === 'core'" large class="mb-3 mt-2">offline_bolt</v-icon>
      <v-icon v-else-if="action.card_type === 'award'" large class="mb-3 mt-2">golf_course</v-icon>
      <v-icon v-else large class="mb-3 mt-2">{{ action.voucher ? 'loyalty' : 'card_membership' }}</v-icon>

      <div class="title mb-2">{{ cardTitle }} <span class="subheading">{{ detailsReward }}</span></div>
      <div v-show="action.card_type === 'award'" class="body-2 subheading">(En Magasin)</div>
      <div class="px-2 w-100 mt-5">
        <template v-if="action.card_type === 'trigger' || action.type === 'core'">
          <template v-if="campaign_type === 'welcome' && !hasAlreadyTriggerCustom(lineIndex, action)">
            <div class="subheading disp-flex align-baseline justify-space-between">
              <label>Inscription : </label><label><b>{{ action.threshold === 0 ? 'Basique' : 'Complète' }}</b></label>
            </div>
          </template>

          <template v-else-if="campaign_type === 'event' && !hasAlreadyTriggerCustom(lineIndex, action)">
            <div class="subheading text-xs-center">
              <label class="custom-message disable-click">
                Ce trigger se déclenchera à la date d'anniversaire du client.
              </label>
            </div>
          </template>

          <template v-else-if="action.type === 'core'">
            <div class="subheading disp-flex align-baseline justify-space-between">
              <label>Type : </label>
              <label>
                <b>{{ $t(`common.${action.scope}`) | capitalize }}</b>
              </label>
            </div>
          </template>

          <template v-else>
            <div class="subheading disp-flex align-baseline justify-space-between">
              <label>Type : </label>
              <label>
                <b>{{ $t(`common.${action.scope}`) | capitalize }}</b>
                <span v-if="action.scope !== 'cart'"> x <b>{{ threshold_ }}</b></span>
              </label>
            </div>
            <template v-if="hasMinimumAmount">
              <v-divider class="my-1"></v-divider>
              <div class="subheading disp-flex align-baseline justify-space-between">
                <label>Montant minimum : </label>
                <label><b>{{ action.minimum_value.toCurrency() }} €</b></label>
              </div>
            </template>
            <template v-else-if="hasTriggerThreshold">
              <v-divider class="my-1"></v-divider>
              <div class="subheading disp-flex align-baseline justify-space-between">
                <label>Montant minimum : </label>
                <label><b>{{ action.threshold }} {{ getCurrencyName }}</b></label>
              </div>
            </template>
          </template>
        </template>

        <div v-if="action.card_type === 'award'">
          <div class="subheading">
            <label>{{ transformation }}</label>
          </div>
          <v-divider class="my-1"></v-divider>
          <div class="subheading disp-flex align-baseline justify-space-between">
            <label>Pour un même client : </label>
            <label>
              <b v-if="action.number_of_use >= 0">x {{ action.number_of_use }}</b>
              <b v-else>{{ $t('createCampaign.triggerReward.unlimited') }}</b>
            </label>
          </div>
          <div v-if="mode !== 'fidelity'" class="subheading disp-flex align-baseline justify-space-between">
            <label>Pour une même commande : </label>
            <label>
              <b v-if="action.number_of_use_per_transaction >= 0">x {{ action.number_of_use_per_transaction }}</b>
              <b v-else>{{ $t('createCampaign.triggerReward.unlimited') }}</b>
            </label>
          </div>
        </div>

        <div v-if="(action.card_type === 'reward' || action.card_type === 'pool') && action.type !== 'core'">
          <template v-if="rewardIsTypeReward">
            <div class="subheading disp-flex align-baseline justify-space-between">
              <label>Remise sur : </label>
              <label>
                <b>{{ $t(`common.${action.scope}`) | capitalize }}</b>
                <span v-if="action.scope !== 'cart'">  x <b>{{ action.quantity }}</b></span>
              </label>
            </div>
            <v-divider class="my-1"></v-divider>
          </template>
          <template v-else>
            <div class="subheading disp-flex align-baseline justify-space-between">
              <label>L'offre s'applique sur : </label>
              <label>
                <b>{{ $t(`common.${action.scope}`) | capitalize }}</b>
                <span v-if="action.scope !== 'cart'">  x <b>{{ action.quantity }}</b></span>
              </label>
            </div>
            <v-divider class="my-1"></v-divider>
          </template>
          <div v-if="action.amount >= 0" class="subheading disp-flex align-baseline justify-space-between">
            <label>
              {{ rewardIsTypeReward ? 'Valeur' : action.amount_kind === 'percentage' ? 'Multiplicateur de point(s)' : 'Gain' }} :
            </label>
            <label v-if="!action.gift">
              <b>
                {{ amount_ }}
                <span v-if="rewardIsTypeReward">{{ amountKind }} </span>
                <span v-else-if="action.amount_kind === 'percentage'">x</span>
                <span v-else>point(s)</span>
              </b>
            </label>
            <label v-else>
              <b>{{ $t('createCampaign.triggerReward.gift') }}</b>
            </label>
          </div>
          <div v-else class="subheading text-xs-center">
            La valeur de cette offre sera défini par la grille tarifaire du diffuseur
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    action: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    },
    campaign_type: {
      type: String,
      required: true
    },
    lineIndex: {
      type: Number,
      required: true
    },
    actionIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('campaignBuilder', ['hasAlreadyTriggerCustom']),
    ...mapState({
      mode: state => state.campaignBuilder.mode,
      currencyName: state => state.campaignBuilder.campaign.loyalty_currency.name,
      currencyOther: state => state.campaignBuilder.campaign.loyalty_currency.other,
      lines: state => state.campaignBuilder.lines,
      editMode: state => state.campaignBuilder.editMode,
      status: state => state.campaignBuilder.campaign.status
    }),
    cardTitle() {
      return this.action.type === 'core' ? this.$t(`common.trigger`) : this.$t(`common.${this.action.card_type}`)
    },
    transformation() {
      let text
      if (this.action.transformation === 'in_store') text = this.$t('createCampaign.triggerReward.manualCheckOut')
      else if (this.action.transformation === 'out_store_manual')
        text = this.$t('createCampaign.triggerReward.manualOutOfStore')
      else if (this.action.transformation === 'out_store_automatic')
        text = this.$t('createCampaign.triggerReward.automaticOutOfStore')
      else if (this.action.transformation === 'in_store_instant') text = 'In store instant'
      else text = '-'
      return text
    },
    amountKind() {
      return this.action.amount_kind === 'percentage' ? '%' : '€'
    },
    amount_() {
      if (this.action.card_type === 'pool') return (1 + (this.action.amount / 100)).toFixed(0)
      else return this.action.amount.toCurrency()
    },
    threshold_() {
      let threshold = 0
      threshold = this.action.scope === 'cart' ? this.action.threshold.toCurrency() : this.action.threshold
      return threshold ? threshold : 0
    },
    detailsReward() {
      return this.action.card_type === 'pool' ? '(Cagnottage)' : this.action.card_type === 'reward' ? '(Remise)' : ''
    },
    hasMinimumAmount() {
      return this.action.scope === 'category' || this.action.scope === 'brand'
    },
    hasTriggerThreshold() {
      return this.action.type === 'customer' && this.action.kind && this.action.kind === 'pool'
    },
    rewardIsTypeReward() {
      return this.action.type === 'reward'
    },
    getCurrencyName() {
      return this.currencyName !== 'Other' ? this.currencyName : this.currencyOther
    },
    triggerCustom() {
      return this.action.card_type === 'trigger' && this.action.kind === 'anniversary'
    },
    isTriggerAward() {
      return (
        this.lines[this.lineIndex].actions[this.actionIndex + 1] &&
        this.lines[this.lineIndex].actions[this.actionIndex + 1].card_type === 'award'
      )
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['selectAction', 'deleteAction'])
  }
}
</script>
