<template>
  <v-dialog :value="dialog" persistent max-width="500px">
    <v-card>
      <div class="py-3 px-4">
        <v-card-title class="disp-flex justify-space-between">
          <h2 class="font-weight-medium">{{ editMode ? 'Ne perdez pas votre progression' : 'Voulez-vous supprimer la campagne ?' }}</h2>
          <v-icon @click="$emit('close')" style="font-size: 30px">close</v-icon>
        </v-card-title>
        <div class="px-3 mt-2 subheading" style="color: #909090">
          Plus que quelques informations à ajouter pour finir la création de votre campagne.
          <template v-if="!editMode">
            En enregistrant la campagne vous pourrez la retrouvez dans l'onglet <b>Brouillon</b> de votre de campagne.
          </template>
        </div>
      </div>
      <div class="pa-3 dialog-container-save">
        <v-btn @click="$emit('discard')" outline color="error">{{ editMode ? 'Ne pas enregistrer' : 'Supprimer' }}</v-btn>
        <v-btn @click="saveChanges()" color="primary" :loading="loadingSave">
          Enregistrer {{ editMode ? 'le' : 'en' }} brouillon
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loadingSave: false
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['saveCampaign']),
    saveChanges() {
      this.loadingSave = true
      this.saveCampaign()
      this.loadingSave = false
      this.$emit('save')
    }
  }
}
</script>
