<template>
  <div>
    <h3 class="font-weight-regular info-label disp-flex">
      <v-menu open-on-hover top offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon class="mt-0 mr-0" v-on="on">
            <v-icon color="grey lighten-1">info</v-icon>
          </v-btn>
        </template>
        <span class="tooltip-custom">
          <v-btn small v-if="id" v-clipboard:copy="id">Copier</v-btn>
          {{ id ? id : 'Aucun ID' }}
        </span>
      </v-menu>
      <span class="mt-2 body-2">Détail de la transaction :</span>
    </h3>

    <section class="overflow-timeline custom-scrollbar">
      <ol>
        <li v-for="(details, index) in discounts()" :key="index">
          <span
            @mouseover="hoverTransaction(details)"
            @mouseleave="hoverTransaction({})"
            :class="{
              reward: (details.operation === 'reward') && !details.gift,
              burn: details.operation === 'burn',
              gain: details.operation === 'earn' || details.operation === 'award',
              gift: details.operation === 'reward' && details.gift
            }"
            class="point"
          >
          </span>
          <div class="details mx-auto">
            <h2 class="title mb-1">{{ getType(details) }}</h2>
            <p class="body-1 font-weight-regular">
              {{ details.message ? details.message : 'Aucune donnée' }}
            </p>
          </div>
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    cart: {
      type: Object
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    getType(type) {
      if (type.operation === 'reward') {
        return type.gift ? 'Cadeau' : 'Utilisation'
      } else if (type.operation === 'award') return 'Gain'
      else if (type.operation === 'burn' || type.operation === 'earn') return 'Cagnotte'
      else return 'Unknown'
    },
    hoverTransaction(transaction) {
      this.$emit('hover', transaction)
    },
    discounts() {
      const discounts = []
      if (this.cart.discounts.length > 0)
        for (const discount of this.cart.discounts) discounts.push(discount)
      if (this.cart.items.length > 0) {
        for (const item of this.cart.items)
          for (const discount of item.discounts) discounts.push(discount)
      }
      return discounts
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/stylesheets/Transactions/TransactionsItemTimeline"
</style>
