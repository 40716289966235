import { IPartialOrder, IUsageHistory } from './interfaces';

export const defaultPaymentId = 'PUB5678685';
export const initialOrderState: IPartialOrder[] = [
  {
    quantity: '1 - 5000',
    amount: 5000,
    unitCost: 0.07,
    orderQuantity: 0,
    cost: 0
  },
  {
    quantity: '5001 - 25 000',
    amount: 20000,
    unitCost: 0.065,
    orderQuantity: 0,
    cost: 0
  },
  {
    quantity: '25 001 - 100 000',
    amount: 75000,
    unitCost: 0.06,
    orderQuantity: 0,
    cost: 0
  },
  {
    quantity: '> 100 000',
    unitCost: 0.05,
    amount: 250000,
    orderQuantity: 0,
    cost: 0
  }
];

export const usageHistory: IUsageHistory[] = [
  {
    campaign: 'Custom activable',
    type: 'SMS',
    status: 'running',
    delivered: 0,
    failed: 0,
    clicked: 0,
    opened: 0,
    complaint: 0,
    pending: 0
  },
  {
    campaign: 'test tracking',
    type: 'SMS',
    status: 'pending',
    delivered: 0,
    failed: 0,
    clicked: 0,
    opened: 0,
    complaint: 0,
    pending: 1
  },
  {
    campaign: 'test tracking sms',
    type: 'Email',
    status: 'running',
    delivered: 1,
    failed: 0,
    clicked: 0,
    opened: 0,
    complaint: 0,
    pending: 0
  },
  {
    campaign: 'Welcome 1',
    type: 'Email',
    status: 'running',
    delivered: 0,
    failed: 0,
    clicked: 0,
    opened: 0,
    complaint: 0,
    pending: 0
  }
];
