<template>
  <v-card v-if="!loading">
    <template v-for="(chart, index) in chartsModel.charts">
      <div :key="index" v-if="showVoucherDoughnut(index)">
        <v-toolbar flat color="white" class="mb-1">
          <v-toolbar-title>{{ chart.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex shrink>
            <div class="icon-shape grey darken-3 flex-div align-center mt-4 justify-center">
              <img :src="require(`@/assets/images/icons/${chart.icon}`)" alt="icon" width="30" />
            </div>
          </v-flex>
        </v-toolbar>
        <div class="px-2 pb-2">
          <v-layout column>
            <v-flex px-4 py-2>
              <v-layout row wrap>
                <v-flex xs3>
                  <div class="px-2 mt-1">
                    <DoughnutChart :chart-data="chart.chartsData" :options="doughnutOptions"></DoughnutChart>
                  </div>
                </v-flex>
                <DashboardFidChartsData :chart="chart" :poolProgram="poolProgram" />
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </template>
    <DashboardFidChartsPDF v-if="isMoustacheCompany" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import FidelityService from '@/services/FidelityService'
import DoughnutChart from '@/components/Charts/DoughnutChart.js'
import DashboardFidChartsData from './DashboardFidChartsData'
import DashboardFidChartsPDF from './DashboardFidChartsPDF'

export default {
  components: {
    DoughnutChart,
    DashboardFidChartsData,
    DashboardFidChartsPDF
  },
  props: {
    dataCharts: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      poolProgram: null,
      loading: true,
      doughnutOptions: {
        legend: {
          display: false
        },
        responsive: true
      }
    }
  },
  created() {
    this.fetchPoolProgram()
  },
  methods: {
    async fetchPoolProgram() {
      const res = await FidelityService.getPoolProgram()
      this.poolProgram = res.data
      this.loading = false
    },
    showVoucherDoughnut(index) {
      return index < 1 || this.isMoustacheCompany
    }
  },
  computed: {
    ...mapState({
      loyaltyCurrency: state => state.user.loyaltyCurrency,
      companyName: state => state.user.company.name
    }),
    isMoustacheCompany() {
      return this.companyName.search(/moustache/i) >= 0
    },
    chartsModel() {
      return {
        charts: [
          {
            title: this.$t('dashboard.loyaltyCurrency'),
            icon: 'piggy-bank.svg',
            loyaltyUnits: this.loyaltyCurrency?.name,
            chartsData: {
              labels: [
                this.$t('dashboard.charts.issued'),
                this.$t('dashboard.charts.redeemed'),
                this.$t('dashboard.charts.inCirculation'),
                this.$t('dashboard.charts.expired')
              ],
              datasets: [
                {
                  backgroundColor: ['#66BB6A', '#FF5252', '#546E7A', '#4FC3F7'],
                  data: [
                    this.dataCharts.loyalty_currency_issued,
                    this.dataCharts.loyalty_currency_redeemed,
                    this.dataCharts.loyalty_currency_in_circulation,
                    this.dataCharts.loyalty_currency_expired
                  ]
                }
              ]
            },
            data: {
              issued: this.dataCharts.loyalty_currency_issued,
              issuedAmount: this.dataCharts.loyalty_currency_issued_amount,
              redeemed: this.dataCharts.loyalty_currency_redeemed,
              redeemedAmount: this.dataCharts.loyalty_currency_redeemed_amount,
              expired: this.dataCharts.loyalty_currency_expired,
              expiredAmount: this.dataCharts.loyalty_currency_expired_amount,
              circulation: this.dataCharts.loyalty_currency_in_circulation,
              circulationAmount: this.dataCharts.loyalty_currency_in_circulation_amount
            }
          },
          {
            title: this.$t('dashboard.vouchersValue'),
            icon: 'voucher.svg',
            loyaltyUnits: this.$t('dashboard.voucher'),
            chartsData: {
              labels: [
                this.$t('dashboard.charts.issued'),
                this.$t('dashboard.charts.redeemed'),
                this.$t('dashboard.charts.expired')
              ],
              datasets: [
                {
                  backgroundColor: ['#66BB6A', '#FF5252', '#546E7A', '#4FC3F7'],
                  data: [
                    this.dataCharts.voucher_issued_amount.toCurrency(),
                    this.dataCharts.voucher_redeemed_amount.toCurrency(),
                    this.dataCharts.voucher_expired_amount.toCurrency()
                  ]
                }
              ]
            },
            data: {
              issued: this.dataCharts.voucher_issued,
              issuedAmount: this.dataCharts.voucher_issued_amount.toCurrency(),
              redeemed: this.dataCharts.voucher_redeemed,
              redeemedAmount: this.dataCharts.voucher_redeemed_amount.toCurrency(),
              expired: this.dataCharts.voucher_expired,
              expiredAmount: this.dataCharts.voucher_expired_amount.toCurrency(),
              circulation: this.dataCharts.voucher_in_circulation,
              circulationAmount: this.dataCharts.voucher_in_circulation_amount.toCurrency()
            }
          }
        ]
      }
    }
  }
}
</script>
