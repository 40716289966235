import CampaignListState from '@/models/states/CampaignListState'
import actions from '@/store/modules/campaignList/actions'
import mutations from '@/store/modules/campaignList/mutations'
import getters from '@/store/modules/campaignList/getters'
import initialState from '@/store/modules/campaignList/initialState'

const state: CampaignListState = initialState()

const namespaced = true

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
