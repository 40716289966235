<template>
  <div>
    <v-layout mt-1>
      <v-flex>
        <h2 class="primary--text">Business settings</h2>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap>
            <v-flex xs12 md6>
                <v-text-field label="Last name" v-model="user.lastname"></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
                <v-text-field label="First name" v-model="user.firstname"></v-text-field>
            </v-flex>
        </v-layout> -->
    <v-layout wrap row>
      <v-flex xs8>
        <h3>Pricing models</h3>
        <div class="flex-div">
          <v-checkbox
            @change="changePricingModel()"
            v-model="pricing_models"
            label="CPA"
            value="cpa"
            color="primary"
          >
          </v-checkbox>
          <v-checkbox
            @change="changePricingModel()"
            v-model="pricing_models"
            label="CPM"
            value="cpm"
            color="primary"
          >
          </v-checkbox>
          <v-checkbox
            @change="changePricingModel()"
            v-model="pricing_models"
            label="CPC"
            value="cpc"
            color="primary"
          >
          </v-checkbox>
          <v-checkbox
            @change="changePricingModel()"
            v-model="pricing_models"
            label="CPMA"
            value="cpma"
            color="primary"
          >
          </v-checkbox>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6>
        <h3>Target</h3>
        <div class="flex-div">
          <v-radio-group v-model="user.target" row>
            <v-radio
              label="Search & Display"
              value="audience"
              color="primary"
            ></v-radio>
            <v-radio
              label="Third party customer Base"
              value="customerbase"
              color="primary"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-flex>
      <v-flex xs6>
        <h3>Reach</h3>
        <v-select
          :items="user.target === 'audience' ? audienceSize : customerSize"
          :label="
            user.target === 'audience' ? 'Audience size' : 'Customer base size'
          "
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout wrap row>
      <v-flex xs10>
        <h3>Consumption Mode</h3>
        <div class="flex-div">
          <v-checkbox
            v-model="user.instant_discount"
            label="Instant Discount"
            color="primary"
          ></v-checkbox>
          <v-checkbox
            v-model="user.pool_award"
            label="Deferred Cash or Pool Award"
            color="primary"
          ></v-checkbox>
          <v-checkbox
            v-model="user.pool_reward"
            label="Pay with Pool"
            color="primary"
          ></v-checkbox>
        </div>
      </v-flex>
    </v-layout>
    <v-layout wrap row v-if="user.pool_award || user.pool_reward">
      <v-flex xs10>
        <h3>Loyalty currency:</h3>
        <v-radio-group
          row
          v-model="loyaltyCurrency.name"
          @change="changeLoyaltyCurrency()"
        >
          <v-radio label="Points" value="points" color="primary"></v-radio>
          <v-radio label="Miles" value="miles" color="primary"></v-radio>
          <v-radio label="Euros" value="euros" color="primary"></v-radio>
        </v-radio-group>
      </v-flex>
    </v-layout>

    <v-layout column>
      <v-flex xs12 class="py-0">
        <h3>Attributes</h3>
        <p>Select below your activities:</p>
      </v-flex>
      <v-layout mx-0 my-0 pb-3 row wrap>
        <v-flex md4 v-for="(tagsCategory, i) in orderedTagsCategories" :key="i">
          <v-expansion-panel>
            <v-expansion-panel-content
              :style="{ background: tagsCategory.color }"
            >
              <div slot="header" class="body-2 tags-category">
                {{ tagsCategory.title }}
              </div>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    hide-details
                    v-for="tags in tagsCategory.tags"
                    :key="tags.id"
                    v-model="user.tags_ids"
                    :label="tags.title"
                    :value="tags.id"
                    color="primary"
                    class="py-2 my-0"
                  ></v-checkbox>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs-12>
        <v-btn color="primary" @click="saveUser()" class="ml-0">
          <v-icon small class="mr-2">save</v-icon>
          Save changes
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'
import _ from 'lodash'

export default {
  props: {
    user: {
      type: Object,
    },
  },
  data: function() {
    return {
      audienceSize: [
        '0 - 500 views / month',
        '500 - 1000 views / month',
        '1000 - 5000 views / month',
        '5000 - 10 000 views / month',
        '10 000 and more views / month',
      ],
      customerSize: [
        '0 - 500',
        '500 - 1000',
        '1000 - 5000',
        '5000 - 10 000',
        '10 000 and more',
      ],
      tags: [],
      tagsCategories: [],
      pricing_models: [],
      pricing_formated: [],
      loyaltyCurrency: {
        id: '',
        name: '',
      },
    }
  },
  computed: {
    orderedTagsCategories: function() {
      return _.orderBy(this.user.tagsCategories, 'title')
    },
    userCompany() {
      return this.$store.state.user.company
    },
  },
  methods: {
    async fetchTags() {
      try {
        const res = await req.get('/tags')
        this.tags = res.data
      } catch (err) {
        console.log(err)
      }
    },
    changePricingModel() {
      this.pricing_formated = []
      for (let i = 0; i < this.pricing_models.length; i++) {
        this.pricing_formated.push({
          id: '',
          kind: this.pricing_models[i],
        })
      }
      EventBus.$emit('changePriceModel', this.pricing_formated)
    },
    changeLoyaltyCurrency() {
      req
        .push(
          '/loyalty_currency',
          {
            loyalty_currency: this.loyaltyCurrency,
          },
          false
        )
        .then(function(response) {
          Object.assign(this.loyaltyCurrency, response.data)
        })
    },
    saveUser() {
      const publisher = this.user

      publisher.pricing_models = this.pricing_formated

      req
        .put('/user', {
          publisher,
        })
        .then(function(putResponse) {
          EventBus.$emit('changeUser', publisher)

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })

          EventBus.$emit('showSnackbar', {
            text: 'Modifications has been saved',
            color: 'success',
          })
        })
        .catch(function(error) {
          console.log(error)
        })
    },
  },
  created() {
    this.fetchTags()

    req.get('/loyalty_currency').then(response => {
      Object.assign(this.loyaltyCurrency, response.data)
    })

    // this.pricing_models = []
    // for (var i = 0; i < this.user.pricing_models.length; i++) {
    //   this.pricing_models.push(this.user.pricing_models[i].kind)
    // }
  },
}
</script>
