<template>
  <v-footer class="footer py-3 px-4" inset height="auto">
    <v-layout row wrap align-center>
      <v-flex py-1>
        <img src="@/assets/images/logo/logo_color.svg" alt="logo" width="150" />
      </v-flex>
      <v-flex py-1 shrink class="flex-div grey--text text--darken-3 subheading">
        <span @click="showReportDialog()">
          <a>{{ $t('footer.reportBug') }}</a>
        </span>
        <v-divider class="mx-3" vertical></v-divider>
        <span><a href="https://browseandgo.fr/contact/" target="_blank">Contact</a></span>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
import * as Sentry from '@sentry/browser'

export default {
  methods: {
    showReportDialog() {
      const eventIdSentry = Sentry.captureMessage('User Feedback')
      Sentry.showReportDialog({
        eventId: eventIdSentry,
        lang: this.$i18n.locale,
        title: this.$t('footer.dialogTitle')
      })
    }
  }
}
</script>
