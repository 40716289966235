<template>
  <v-card class="transparent elevation-0 ml-auto mr-auto" style="width: fit-content">
    <v-card-title class="headline white--text pt-0">
      {{ $t('communications.purchaseHistory.paymentMethod') }} :
    </v-card-title>
    <v-card-text>
      <v-radio-group v-model="paymentMode" class="mt-0 pt-0">
        <v-radio
          disabled
          value="creditCard"
          :label="$t('communications.purchaseHistory.creditCard')"
          class="white--text"
          color="white"
        ></v-radio>
        <p class="pl-4 body" style="color: #ffffff8c">
          {{ $t('communications.purchaseHistory.forTheMoment') }}
        </p>
        <v-radio
          value="bankTransfer"
          :label="$t('communications.purchaseHistory.bankTransfer')"
          class="white--text"
          color="white"
        ></v-radio>
      </v-radio-group>
      <p class="white--text">{{ $t('communications.purchaseHistory.bankTransferMsg') }} :</p>

      <p v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </p>
      <p v-else>
        <strong class="title white--text">{{ paymentId }}</strong>
      </p>
    </v-card-text>
    <v-divider class="white lighten-4"></v-divider>

    <v-card-actions class="px-0 pt-4">
      <v-btn flat outline @click="cancel()" color="grey" class="mx-0">
        {{ $t('communications.purchaseHistory.cancelPurchase') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="white" flat @click="accept()" class="mx-0">
        {{ $t('communications.purchaseHistory.acceptPurchase') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Component, Vue, PropSync } from 'vue-property-decorator'
import { State, Action, Getter } from 'vuex-class'
import AdvertiserService from '@/services/AdvertiserService'
import eventBus from '@/packs/event-bus'

@Component
export default class PaymentInfo extends Vue {
  @PropSync('visible') visibleSync

  @State(state => state.user.company) company

  @Action('order/confirm') confirm
  @Action('order/updateLastStepBeforePayment') updateLastStepBeforePayment
  @Action('payment/setLoading') setLoading

  @Getter('payment/loading') loading
  @Getter('payment/id') paymentId
  @Getter('order/finalizedOrders') finalizedOrder

  paymentMode = 'bankTransfer'

  async accept() {
    this.setLoading(true)

    this.confirm(this.paymentId)
    await this.createOrder()

    eventBus.$emit('updatePurchaseHistory')
    this.visibleSync = false

    this.setLoading(false)
  }

  cancel() {
    this.visibleSync = false
    this.updateLastStepBeforePayment(false)
  }

  async createOrder() {
    try {
      const order = this.finalizedOrder[this.finalizedOrder.length - 1]
      const smsQuantity = order.sms.reduce((acc, current) => acc + current.orderQuantity, 0)
      const emailsQuantity = order.emails.reduce((acc, current) => acc + current.orderQuantity, 0)

      const res = await AdvertiserService.createOrder(this.company.advertisers[0].id, {
        intern_order: {
          payment_method: 'transfer',
          order_number: this.paymentId,
          email: emailsQuantity,
          sms: smsQuantity
        }
      })
      return res
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="sass">
div .white--text
  label
    color: white !important
</style>
