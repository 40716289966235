<template>
  <div style="max-width: 850px" class="ml-auto mr-auto mt-4 mb-5">
    <label class="font-weight-medium subheading align-self-center">Configuration des status de votre programme</label>
    <hr class="mt-3 mb-5" style="border-color: #ffffff52" />
    <v-layout wrap row>
      <v-flex xs12 class="d-flex">
        <div style="width: 33%">
          <label class="font-weight-medium">N-tiers status system :</label><br><br>
          <label class="body-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Vivamus vestibulum pretium aliquam.
          </label>
        </div>
        <div style="width: 66%" class="pl-5">
          <div class="mb-4">
            <v-text-field v-model="statusSystemName_" class="mt-0 pt-0" label="Name of n-tier status system " hide-details></v-text-field>
          </div>
          <div>
            <label class="font-weight-regular under-title-color body-2">Counter(s) used to evaluate customer engagement in the tiered loyalty program :</label>
            <v-radio-group v-model="statusSystemCounter_" class="mt-2" hide-details>
              <v-radio label="Pool award" value="award"></v-radio>
              <v-radio label="Membership fee" value="membership"></v-radio>
            </v-radio-group>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 class="d-flex">
        <div style="width: 33%">
          <label class="body-1">Counter or membership fee is reset at the end of the period.</label>
        </div>
        <div style="width: 66%" class="pl-5">
          <div class="mb-4">
            <label class="font-weight-regular under-title-color body-2">Entrance or maintenance evaluation period :</label>
            <v-radio-group v-model="statusSystemCalculationPeriod_" class="mt-2" hide-details>
              <v-radio label="X month(s) from customer date of entry into a tier level" value="moving_month"></v-radio>
              <v-radio label="X year(s) from customer date of entry into a tier level" value="moving_year"></v-radio>
              <v-radio label="Calendar year (31st december)" value="fixed_year"></v-radio>
            </v-radio-group>
            <transition name="slide-y-transition" v-if="statusSystemCalculationPeriod !== 'fixed_year'">
              <div class="mt-3 pt-2 pb-3 px-3 input-details-radio">
                <label class="font-weight-medium">{{ `${dateEnterTierLevel} :` }}</label>
                <v-text-field v-model.number="statusSystemCalculationPeriodValue_" class="mt-0 pt-0" min="0" type="number" :placeholder="`Number of ${dateEnterTierLevel}`" hide-details></v-text-field>
              </div>
            </transition>
          </div>
          <div class="mb-4">
            <label class="font-weight-regular under-title-color body-2">Calculation frequency :</label>
            <div class="d-flex mt-2">
              <span class="align-self-center">Every</span>
              <v-text-field v-model.number="statusSystemCounterFrequencyValue_" :min="0" type="number" class="mx-3 mt-0 pt-0"></v-text-field>
              <v-select v-model="statusSystemCounterFrequency_" :items="counterFrequencyItems" item-value="value" item-text="text" label="Period" class="mt-0 pt-0"></v-select>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <hr class="my-3" style="border-color: #ffffff52" />
    <v-layout wrap row>
      <v-flex xs12>
        <CoreProgramCampaignSettingsStatusList />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import CoreProgramCampaignSettingsStatusList from '@/components/CoreProgramCampaignSettingsStatusList'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    CoreProgramCampaignSettingsStatusList
  },
  data() {
    return {
      counterFrequencyItems: [
        {
          text: 'Day',
          value: 'every_day'
        },
        {
          text: 'Week',
          value: 'every_week'
        },
        {
          text: 'Month',
          value: 'every_month'
        }
      ]
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setStatusSystemName',
      'setStatusSystemCounter',
      'setStatusSystemCalculationPeriod',
      'setStatusSystemCalculationPeriodValue',
      'setStatusSystemCounterFrequency',
      'setStatusSystemCounterFrequencyValue'
    ])
  },
  computed: {
    ...mapState({
      statusSystemName: state => state.campaignBuilder.campaign.status_system.name,
      statusSystemCounter: state => state.campaignBuilder.campaign.status_system.counter,
      statusSystemCalculationPeriod: state => state.campaignBuilder.campaign.status_system.calculation_period,
      statusSystemCalculationPeriodValue: state => state.campaignBuilder.campaign.status_system.calculation_period_value,
      statusSystemCounterFrequency: state => state.campaignBuilder.campaign.status_system.counter_frequency,
      statusSystemCounterFrequencyValue: state => state.campaignBuilder.campaign.status_system.counter_frequency_value,

    }),
    statusSystemName_: {
      get() {
        return this.statusSystemName
      },
      set(value) {
        this.setStatusSystemName(value)
      }
    },
    statusSystemCounter_: {
      get() {
        return this.statusSystemCounter
      },
      set(value) {
        this.setStatusSystemCounter(value)
      }
    },
    statusSystemCalculationPeriod_: {
      get() {
        return this.statusSystemCalculationPeriod
      },
      set(value) {
        this.setStatusSystemCalculationPeriod(value)
      }
    },
    statusSystemCalculationPeriodValue_: {
      get() {
        return this.statusSystemCalculationPeriodValue
      },
      set(value) {
        this.setStatusSystemCalculationPeriodValue(value)
      }
    },
    statusSystemCounterFrequency_: {
      get() {
        return this.statusSystemCounterFrequency
      },
      set(value) {
        this.setStatusSystemCounterFrequency(value)
      }
    },
    statusSystemCounterFrequencyValue_: {
      get() {
        return this.statusSystemCounterFrequencyValue
      },
      set(value) {
        this.setStatusSystemCounterFrequencyValue(value)
      }
    },
    dateEnterTierLevel() {
      return this.statusSystemCalculationPeriod_ === 'moving_month' ? 'Month(s)' : 'Year(s)'
    }
  },
  created() {
    this.statusSystemCalculationPeriod_ = 'moving_month'
  }
}
</script>

<style scoped lang="sass">
@import "../stylesheets/coreProgram"
</style>
