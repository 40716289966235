<template>
  <div class="h-100">
    <div class="relative">
      <v-stepper v-model="step" non-linear class="elevation-0" style="min-height: calc(100vh - 226px)">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ $t('common.campaignSettings') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            Trigger / Reward
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            {{ $t('common.publishing') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items mode="out-in">
          <v-stepper-content step="1">
            <v-container>
              <v-layout row>
                <EventsCampaignCreateStep1 @changeDraft="changeDraft($event)" />
              </v-layout>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="2">
            <CampaignActionsSelection :campaign="campaign" v-if="!loading" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <EventsCampaignPublishing :campaign="campaign" v-if="!loading" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <CampaignBuilderNavigationBar
        :step.sync="step"
        :stepCount="3"
        :editMode="editMode"
        @submit="submit()"
      />
    </div>
    <CampaignDialogLoading :show="loading" />
    <DialogSaveCampaignDraft :edit-mode="editMode" :dialog="showDialog" @close="closeDialog()" @discard="discardChanges()" @save="saveChanges()"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CampaignBuilder from '@/store/modules/campaignBuilder/campaignBuilder.store'
import CampaignBuilderNavigationBar from '@/components/CampaignBuilderNavigationBar'
import EventsCampaignCreateStep1 from '@/components/EventsCampaignCreateStep1'
import CampaignActionsSelection from '@/components/CampaignActionsSelection'
import EventsCampaignPublishing from '@/components/CampaignPublishing'
import CampaignDialogLoading from '@/components/CampaignDialogLoading'
import DialogSaveCampaignDraft from '@/components/DialogSaveCampaignDraft'
import EventBus from '@/packs/event-bus'

export default {
  components: {
    CampaignBuilderNavigationBar,
    EventsCampaignCreateStep1,
    CampaignActionsSelection,
    EventsCampaignPublishing,
    CampaignDialogLoading,
    DialogSaveCampaignDraft
  },
  data() {
    return {
      step: 1,
      showDialog: false,
      to: null,
      allowChangeRoute: false,
      draft: String
    }
  },
  beforeCreate() {
    this.$store.registerModule('campaignBuilder', CampaignBuilder)
  },
  beforeRouteLeave(to, from, next) {
    let shouldNext = false

    if (this.campaign.status !== 'draft' || to.path === '/signin') this.allowChangeRoute = true

    if (!this.allowChangeRoute) {
      if (this.to) shouldNext = true
      else {
        this.to = to
        this.showDialog = true
      }
    } else shouldNext = true

    if (shouldNext) {
      if (to?.params?.company) EventBus.$emit('refreshCompany', to.params.company)
      this.resetState()
      this.$store.unregisterModule('campaignBuilder')
      next()
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setMode',
      'setEditMode',
      'fetchCampaign',
      'fetchDraft',
      'destroy',
      'persist',
      'resetState'
    ]),
    closeDialog() {
      this.showDialog = false
      this.to = null
    },
    saveChanges() {
      this.showDialog = false
      this.allowChangeRoute = true
      if (this.to) this.$router.push(this.to).catch(err => err)
      else this.$router.go(-1)
    },
    async discardChanges() {
      this.showDialog = false
      this.allowChangeRoute = true
      if (!this.editMode) await this.destroy()
      if (this.to) this.$router.push(this.to).catch(err => err)
      else this.$router.go(-1)
    },
    checkEditMode() {
      if (this.$route.params.id === 'new') this.setEditMode(false)
      else this.setEditMode(true)
    },
    async submit() {
      try {
        await this.persist()
        this.allowChangeRoute = true
        this.$router.push({ path: '/core-program/campaigns' }).catch(err => err)
      } catch (e) {
        this.allowChangeRoute = false
      }
    },
    changeDraft(draft) {
      this.draft = draft
      this.destroy()
      this.fetchDraft(this.draft)
    },
    checkDraft() {
      if (!this.capabilities.welcome) this.draft = 'welcome'
      else if (!this.capabilities.event) this.draft = 'event'
      else this.draft = 'happyhour'
    }
  },
  computed: {
    ...mapState({
      campaign: state => state.campaignBuilder.campaign,
      editMode: state => state.campaignBuilder.editMode,
      loading: state => state.campaignBuilder.loading,
      capabilities: state => state.user.capabilities
    }),
    running() {
      return this.campaign.state === 'active' && this.campaign.status === 'running'
    }
  },
  created() {
    this.checkEditMode()
    this.setMode('subfidelity')

    if (!this.editMode) this.checkDraft()

    if (this.editMode) this.fetchCampaign(this.$route.params.id)
    else this.fetchDraft(this.draft)
  }
}
</script>
