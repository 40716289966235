export const calculatePartialOrder = (
  orderToCalculate: any,
  amount: number
) => {
  const order = JSON.parse(JSON.stringify(orderToCalculate));
  const orderThreshold = order.findIndex((item: any) => amount <= item.amount);

  for (let i = orderThreshold; i < order.length; i++) {
    order[i].orderQuantity = 0;
  }

  if (orderThreshold === 0) {
    order[orderThreshold].orderQuantity = amount;
  } else {
    let rest = amount;
    for (const item of order) {
      if (rest >= 0) {
        item.orderQuantity = rest > item.amount ? item.amount : rest;
        rest = rest - item.amount;
      }
    }
  }

  return order.map((item: any) => ({
    ...item,
    cost: item.orderQuantity * item.unitCost
  }));
};

export const sumProperty = (items: any[], property: string): number => {
  return items.reduce((previous, current) => previous + current[property], 0);
};
