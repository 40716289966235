import CampaignBuilderState from '@/models/states/CampaignBuilderState'
import actions from '@/store/modules/campaignBuilder/actions'
import mutations from '@/store/modules/campaignBuilder/mutations'
import getters from '@/store/modules/campaignBuilder/getters'
import initialState from '@/store/modules/campaignBuilder/initialState'

const state: CampaignBuilderState = initialState()

const namespaced = true

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
