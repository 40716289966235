<template>
  <div class="h-100">
    <div class="relative">
      <v-stepper v-model="step" non-linear class="elevation-0" style="min-height: calc(100vh - 226px)">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ $t('campaignCreate.basicParameters') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            {{ $t('campaignCreate.offers') }}
          </v-stepper-step>
          <v-divider v-if="isRetailer"></v-divider>
          <v-stepper-step v-if="isRetailer" :complete="step > 3" step="3">
            {{ $t('campaignCreate.pointOfSales') }}
          </v-stepper-step>
          <v-divider v-if="isBrand"></v-divider>
          <v-stepper-step v-if="isBrand" :complete="step > 3" step="3">
            Retailers
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :step="3 + stepShift" :complete="step > 3 + stepShift">
            Publishers
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items mode="out-in">
          <v-stepper-content step="1">
            <v-container v-if="!loading">
              <v-layout row>
                <AdToStoreCampaignCreateStep1 :campaign="campaign" />
              </v-layout>
            </v-container>
          </v-stepper-content>

          <v-stepper-content v-if="!loading" step="2">
            <CampaignActionsSelection :campaign="campaign" />
          </v-stepper-content>

          <v-stepper-content v-if="isRetailer" step="3">
            <CampaignPointsOfSaleSelection />
          </v-stepper-content>

          <v-stepper-content v-if="isBrand" step="3">
            <CampaignRetailersSelection/>
          </v-stepper-content>

          <v-stepper-content :step="3 + stepShift">
            <CampaignBuilderPublishersStep />
          </v-stepper-content>
        </v-stepper-items>
        <v-layout row wrap justify-center>
          <clip-loader class="my-4" :loading="loading" color="#78909c" width="60px" height="60px"></clip-loader>
        </v-layout>
      </v-stepper>
      <CampaignBuilderNavigationBar
        :step.sync="step"
        :stepCount="3 + stepShift"
        :editMode="editMode"
        @submit="submit()"
      />
    </div>
    <DialogSaveCampaignDraft :edit-mode="editMode" :dialog="showDialog" @close="closeDialog()" @discard="discardChanges()" @save="saveChanges()" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CampaignBuilder from '@/store/modules/campaignBuilder/campaignBuilder.store'

import CampaignBuilderNavigationBar from '@/components/CampaignBuilderNavigationBar'
import CampaignActionsSelection from '@/components/CampaignActionsSelection'
import CampaignBuilderPublishersStep from '@/components/CampaignBuilderPublishersStep'
import AdToStoreCampaignCreateStep1 from '../components/AdToStoreCampaignCreateStep1'
import CampaignRetailersSelection from '@/components/CampaignRetailersSelection'
import CampaignPointsOfSaleSelection from '@/components/CampaignPointsOfSaleSelection'
import DialogSaveCampaignDraft from '@/components/DialogSaveCampaignDraft'
import EventBus from '@/packs/event-bus'

export default {
  components: {
    CampaignBuilderNavigationBar,
    CampaignActionsSelection,
    CampaignBuilderPublishersStep,
    CampaignRetailersSelection,
    AdToStoreCampaignCreateStep1,
    CampaignPointsOfSaleSelection,
    DialogSaveCampaignDraft
  },
  data() {
    return {
      step: 1,
      showDialog: false,
      to: null,
      allowChangeRoute: false
    }
  },
  beforeCreate() {
    if (!this.$store.hasModule('campaignBuilder'))
      this.$store.registerModule('campaignBuilder', CampaignBuilder)
  },
  beforeRouteLeave(to, from, next) {
    let shouldNext = false

    if (this.campaign.status !== 'draft' || to.path === '/signin') this.allowChangeRoute = true

    if (!this.allowChangeRoute) {
      if (this.to) shouldNext = true
      else {
        this.to = to
        this.showDialog = true
      }
    } else shouldNext = true

    if (shouldNext) {
      if (to?.params?.company) EventBus.$emit('refreshCompany', to.params.company)
      this.resetState()
      this.$store.unregisterModule('campaignBuilder')
      next()
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'fetchDraft',
      'fetchCampaign',
      'setMode',
      'setEditMode',
      'saveCampaign',
      'destroy',
      'persist',
      'resetState'
    ]),
    closeDialog() {
      this.showDialog = false
      this.to = null
    },
    saveChanges() {
      this.showDialog = false
      this.allowChangeRoute = true
      if (this.to) this.$router.push(this.to)
      else this.$router.go(-1)
    },
    async discardChanges() {
      this.showDialog = false
      this.allowChangeRoute = true
      if (!this.editMode) await this.destroy()
      if (this.to) this.$router.push(this.to).catch(err => err)
      else this.$router.go(-1)
    },
    checkEditMode() {
      if (this.$route.params.id === 'new') this.setEditMode(false)
      else this.setEditMode(true)
    },
    async submit() {
      try {
        await this.persist()
        this.allowChangeRoute = true
        this.$router.push({ path: '/campaigns' }).catch(err => err)
      } catch (e) {
        this.allowChangeRoute = false
      }
    }
  },
  computed: {
    ...mapState({
      campaign: state => state.campaignBuilder.campaign,
      editMode: state => state.campaignBuilder.editMode,
      loading: state => state.campaignBuilder.loading
    }),
    stepShift() {
      let shift = 0
      if (this.isRetailer) shift++
      if (this.isBrand) shift++
      return shift
    },
    isBrand() {
      return !!this.$store.getters.getBrandAdvertiser
    },
    isRetailer() {
      return !!this.$store.getters.getDistributorAdvertiser
    }
  },
  created() {
    this.checkEditMode()
    this.setMode('conquest')

    if (this.editMode) this.fetchCampaign(this.$route.params.id)
    else this.fetchDraft()
  }
}
</script>
