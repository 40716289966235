<template>
  <v-card class="pr-2 blue-grey lighten-5 w-100">
    <v-select
      class="w-100"
      flat
      :items="pointsOfSales"
      item-text="name"
      return-object
      solo
      append-outer-icon="store"
      menu-props="offsetY"
      hide-details
      @change="updateSelect"
      v-model="selector"
    >
    </v-select>
  </v-card>
</template>

<script>
import req from '@/packs/request.js'

export default {
  data: function() {
    return {
      pointsOfSales: [
        {
          name: this.$t('common.allPOS'),
          id: null,
        },
      ],
      selector: {
        name: this.$t('common.allPOS'),
        id: null,
      },
    }
  },
  methods: {
    async fetchPointOfSales() {
      const res = await req.get('/point_of_sales?with_discarded=true')
      for (const each of res.data) {
        this.pointsOfSales.push(each)
      }
    },
    updateSelect() {
      this.$emit('update', this.selector)
    },
  },
  created() {
    this.fetchPointOfSales()
  },
}
</script>
