<template>
  <v-card class="config elevation-0 px-4">
    <div class="font-weight-light title disp-flex align-center mb-4">
      <div class="font-weight-bold disp-flex align-center justify-center config-number mr-3">3</div>
      <span>Le <span class="font-weight-medium">nombre de fois</span> où l'offre est utilisable pour :</span>
    </div>
    <div class="mt-4 disp-flex align-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon class="font-size-icon cursor-pointer mr-1" v-on="on">info</v-icon>
        </template>
        <span>
          {{ $t('createCampaign.triggerReward.maxConsumerDescr') }}<br />
          {{ $t('createCampaign.triggerReward.maxConsumerDescrBut') }}
        </span>
      </v-tooltip>
      <div class="font-weight-medium align-self-center mr-4">
        {{ $t('createCampaign.triggerReward.maxRedemptionConsumer') }}
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        v-if="number_of_use_ !== -1"
        class="mt-0 pt-0 text-number-short mb-1"
        type="number"
        :min="0"
        required
        :disabled="viewMode"
        v-model="number_of_use_"
        hide-details
      ></v-text-field>
      <div v-else class="mt-1 mx-2 headline">&#8734;</div>
      <v-checkbox
        :label="$t('createCampaign.triggerReward.unlimited')"
        class="ml-3 my-0 py-0 align-self-center w-max-fit"
        hide-details
        :disabled="viewMode"
        v-model="number_of_use_unlimited"
      ></v-checkbox>
    </div>
    <div class="mt-2 disp-flex align-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon class="font-size-icon cursor-pointer mr-1" v-on="on">info</v-icon>
        </template>
        <span>{{ $t('createCampaign.triggerReward.maxOrderDescr') }}</span>
      </v-tooltip>
      <div class="font-weight-medium align-self-center mr-4">
        {{ $t('createCampaign.triggerReward.maxRedemptionOrder') }} :
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        v-model.number="number_of_use_per_transaction_"
        v-if="number_of_use_per_transaction_ !== -1"
        :disabled="disabled || viewMode || type === 'pool'"
        type="number"
        :min="0"
        class="mt-0 pt-0 text-number-short mb-1"
        hide-details
      ></v-text-field>
      <div v-else class="mt-1 mx-2 headline">&#8734;</div>
      <v-checkbox
        :label="$t('createCampaign.triggerReward.unlimited')"
        :disabled="disabled || viewMode || type === 'pool'"
        class="ml-3 mt-0 pt-0 align-self-center w-max-fit"
        hide-details
        v-model="number_of_use_per_transaction_unlimited"
      ></v-checkbox>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    scope: {
      type: String,
      required: true
    },
    numberOfUse: {
      type: Number,
      required: true
    },
    numberOfUsePerTransaction: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setRewardNumberOfUse',
      'setRewardNumberOfUsePerTransaction'
    ])
  },
  computed: {
    ...mapGetters('campaignBuilder', ['hasTrigger', 'viewMode']),
    disabled() {
      return this.scope === 'cart' && !this.hasTrigger()
    },
    number_of_use_: {
      get() {
        return this.numberOfUse
      },
      set(value) {
        this.setRewardNumberOfUse(value)
      }
    },
    number_of_use_unlimited: {
      get() {
        return this.number_of_use_ === -1
      },
      set(value) {
        this.number_of_use_ = value ? -1 : 1
      }
    },
    number_of_use_per_transaction_: {
      get() {
        return this.numberOfUsePerTransaction
      },
      set(value) {
        this.setRewardNumberOfUsePerTransaction(value)
      }
    },
    number_of_use_per_transaction_unlimited: {
      get() {
        return this.number_of_use_per_transaction_ === -1
      },
      set(value) {
        this.number_of_use_per_transaction_ = value ? -1 : 1
      }
    }
  }
}
</script>
