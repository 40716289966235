import moment from 'moment'
import { getService as getServiceHelper } from '@/services/Helpers'

const getService = (state: any) => {
  return getServiceHelper(state.mode)
}

const actions = {
  /* ------------------------------------------------------------------------------------------
                                    CAMPAIGN SETTINGS ACTIONS
  --------------------------------------------------------------------------------------------*/
  setEditMode: ({ commit }: any, payload: boolean) => commit('SET_EDIT_MODE', payload),
  setMode: ({ commit }: any, payload: string) => commit('SET_MODE', payload),
  setName: ({ commit }: any, payload: string) => commit('SET_NAME', payload),
  setStartAt: ({ commit }: any, payload: string) => commit('SET_START_AT', payload),
  setEndAt: ({ commit }: any, payload: string) => commit('SET_END_AT', payload),
  setKind: ({ commit }: any, payload: string) => {
    const endAt = payload === 'permanent' ? undefined : moment().add(2, 'days').format('YYYY-MM-DD')
    commit('SET_KIND', payload)
    commit('SET_END_AT', endAt)
  },
  setInheritable: ({ commit }: any, payload: boolean) => commit('SET_INHERITABLE', payload),
  setSegment: ({ commit }: any, payload: any) => commit('SET_SEGMENT', payload),

  setEventAnticipation: ({ commit }: any, payload: number) => commit('SET_EVENT_ANTICIPATION', payload),

  addTimeSlot: ({ commit }: any, payload: any) => commit('ADD_TIME_SLOT', payload),
  deleteTimeSlot: ({ commit, state }: any, payload: any) => {
    const index = state.campaign.time_slots.findIndex((e: any) => e === payload.timeSlot)
    commit('DELETE_TIME_SLOT', index)
  },
  setDayTimeSlot: ({ commit, state }: any, payload: { index: number, day: number }) => {
    const dayIndex = state.campaign.time_slots[payload.index].days.findIndex((e: number) => e === payload.day)
    if (dayIndex < 0) commit('ADD_DAY_TIME_SLOT', { index: payload.index, day: payload.day })
    else commit('DELETE_DAY_TIME_SLOT', { index: payload.index, dayIndex: dayIndex })

    if (state.campaign.time_slots[payload.index].days.length === 7) commit('SET_FULL_WEEK_TIME_SLOT', { index: payload.index, isFullWeek: true})
    else commit('SET_FULL_WEEK_TIME_SLOT', { index: payload.index, isFullWeek: false })
  },
  setFullWeekTimeSlot: ({ commit }: any, payload: { index: number, isFullWeek: boolean, resetDay: boolean }) => commit('SET_FULL_WEEK_TIME_SLOT', { index: payload.index, isFullWeek: payload.isFullWeek, resetDay: payload.resetDay }),
  setFullDay: ({ commit }: any, payload: { index: number, isFullDay: boolean }) => {
    commit('SET_FULL_DAY_TIME_SLOT', { index: payload.index, isFullDay: payload.isFullDay })
    if (payload.isFullDay) {
      commit('SET_START_TIME_SLOT', { index: payload.index, hour: { dhour: 0, dminutes: 0 } })
      commit('SET_END_TIME_SLOT', { index: payload.index, hour: { dhour: 23, dminutes: 59 } })
    }
  },
  setStartTimeSlot: ({ commit }: any, payload: { index: number, hour: any }) => commit('SET_START_TIME_SLOT', { index: payload.index, hour: payload.hour }),
  setEndTimeSlot: ({ commit }: any, payload: { index: number, hour: any }) => commit('SET_END_TIME_SLOT', { index: payload.index, hour: payload.hour }),

  setTriggerScope: ({ commit }: any, payload: string) => {
    commit('SET_TRIGGER_SCOPE', payload)

    if (payload === 'cart') commit('SET_TRIGGER_THRESHOLD', 100)
    else commit('SET_TRIGGER_THRESHOLD', 1)
  },
  setTriggerThreshold: ({ commit }: any, payload: number) => commit('SET_TRIGGER_THRESHOLD', payload),
  setTriggerMinimumValue: ({ commit }: any, payload: number) => commit('SET_TRIGGER_MINIMUM_VALUE', payload),
  setTriggerTargetId: ({ commit }: any, payload: string) => commit('SET_TRIGGER_TARGET_ID', payload),

  setRewardScope: ({ commit, getters }: any, payload: string) => {
    commit('SET_REWARD_SCOPE', payload)

    commit('SET_REWARD_TARGET_ID', null)
    if (payload === 'cart') {
      commit('SET_REWARD_CONSUMPTION_MODE', 'instant_discount')
      if (!getters.hasTrigger()) {
        commit('SET_REWARD_NUMBER_OF_USE', 1)
        commit('SET_REWARD_NUMBER_OF_USE_PER_TRANSACTION', 1)
        commit('SET_REWARD_AMOUNT_KIND', 'percentage')
      }
    }
  },

  setRewardType: ({ commit }: any, payload: string) => commit('SET_REWARD_TYPE', payload),
  setRewardAmount: ({ commit }: any, payload: number) => commit('SET_REWARD_AMOUNT', payload),
  setRewardAmountKind: ({ commit }: any, payload: string) => commit('SET_REWARD_AMOUNT_KIND', payload),
  setRewardQuantity: ({ commit }: any, payload: number) => commit('SET_REWARD_QUANTITY', payload),
  setRewardTargetId: ({ commit }: any, payload: string) => commit('SET_REWARD_TARGET_ID', payload),
  setRewardAvailabilityDuration: ({ commit }: any, payload: number) => commit('SET_REWARD_AVAILABILITY_DURATION', payload),
  setRewardNumberOfUse: ({ commit }: any, payload: number) => commit('SET_REWARD_NUMBER_OF_USE', payload),
  setRewardNumberOfUsePerTransaction: ({ commit }: any, payload: number) => commit('SET_REWARD_NUMBER_OF_USE_PER_TRANSACTION', payload),
  setRewardGift: ({ commit }: any, payload: boolean) => commit('SET_REWARD_GIFT', payload),
  setRewardClaimMethod: ({ commit }: any, payload: string) => commit('SET_REWARD_CLAIM_METHOD', payload),
  setRewardConsumptionMode: ({ commit }: any, payload: string) => {
    commit('SET_REWARD_CONSUMPTION_MODE', payload)
    if (payload === 'price_override') commit('SET_REWARD_AMOUNT_KIND', 'value')
  },
  setRewardRequireActivation: ({ commit }: any, payload: boolean) => commit('SET_REWARD_REQUIRE_ACTIVATION', payload),
  setRewardVoucher: ({ commit }: any, payload: boolean) => {
    commit('SET_REWARD_VOUCHER', payload)
    if (payload) commit('SET_REWARD_REQUIRE_ACTIVATION', false)
  },
  setRewardVoucherName: ({ commit }: any, payload: string) => commit('SET_REWARD_VOUCHER_NAME', payload),

  setAwardTransformation: ({ commit }: any, payload: string) => commit('SET_AWARD_TRANSFORMATION', payload),
  setAwardNumberOfUse: ({ commit }: any, payload: number) => commit('SET_AWARD_NUMBER_OF_USE', payload),
  setAwardNumberOfUsePerTransaction: ({ commit }: any, payload: number) => commit('SET_AWARD_NUMBER_OF_USE_PER_TRANSACTION', payload),

  addContracts({ commit, state }: any, payload: string | string[]) {
    const array = Array.isArray(payload) ? payload : [payload]
    const toAdd: any = []

    array.forEach(posId => {
      if (!state.campaign.contracts.find((e: any) => e.point_of_sale_id === posId))
        toAdd.push({ point_of_sale_id: posId })
    })
    if (toAdd.length > 0) commit('ADD_CONTRACTS', toAdd)
  },

  deleteContract({ commit, state }: any, payload: string) {
    const index = state.campaign.contracts.findIndex((e: any) => e.point_of_sale_id === payload)
    commit('DELETE_CONTRACT', index)
  },
  clearContracts: ({ commit }: any) => commit('CLEAR_CONTRACTS'),

  addCampaignParticipants({ commit, state }: any, payload: string | string[]) {
    const array = Array.isArray(payload) ? payload : [payload]
    const toAdd: any = []

    array.forEach(id => {
      if (!state.campaign.campaign_participants.find((e: any) => e.company_id === id))
        toAdd.push({ company_id: id })
    })
    if (toAdd.length > 0) commit('ADD_CAMPAIGN_PARTICIPANTS', toAdd)
  },
  deleteCampaignParticipant({ commit, state }: any, payload: string) {
    const index = state.campaign.campaign_participants.findIndex((e: any) => e.company_id === payload)
    commit('DELETE_CAMPAIGN_PARTICIPANT', index)
  },
  clearCampaignParticipants: ({ commit }: any) => commit('CLEAR_CAMPAIGN_PARTICIPANTS'),

  addPublishers({ commit, state }: any, payload: string | string[]) {
    const array = Array.isArray(payload) ? payload : [payload]
    const toAdd: any = []

    array.forEach(id => {
      if (!state.campaign.commission_contracts.find((e: any) => e.publisher_id === id))
        toAdd.push({ publisher_id: id })
    })
    if (toAdd.length > 0) commit('ADD_PUBLISHERS', toAdd)
  },

  deletePublisher({ commit, state }: any, payload: string) {
    const index = state.campaign.commission_contracts.findIndex((e: any) => e.publisher_id === payload)
    commit('DELETE_PUBLISHER', index)
  },
  clearPublishers: ({ commit }: any) => commit('CLEAR_PUBLISHERS'),
  setPublisherRateCard: ({ commit, state }: any, payload: { pubId: string, value: string }) => {
    const index = state.campaign.commission_contracts.findIndex((e: any) => e.publisher_id === payload.pubId)
    if (index != -1) commit('SET_PUBLISHER_RATE_CARD', { index: index, value: payload.value })
  },

  addPublishing: ({ commit }: any, payload: string) => commit('ADD_PUBLISHING', payload),
  deletePublishing: ({ commit }: any, payload: number) => commit('DELETE_PUBLISHING', payload),
  addTemplate: ({ commit }: any, payload: any) => commit('PUSH_TEMPLATE', payload),
  clearTemplates: ({ commit }: any) => commit('CLEAR_TEMPLATES'),

  /* ------------------------------------------------------------------------------------------
                                    CORE PROGRAM ACTIONS
  --------------------------------------------------------------------------------------------*/

  createLoyaltyCurrency: ({ commit }: any) => commit('CREATE_LOYALTY_CURRENCY'),
  clearLoyaltyCurrency: ({ commit }: any) => commit('CLEAR_LOYALTY_CURRENCY'),
  createPoolProgram: ({ commit }: any) => commit('CREATE_POOL_PROGRAM'),
  clearPoolProgram: ({ commit }: any) => commit('CLEAR_POOL_PROGRAM'),
  setLoyaltyCurrencyName: ({ commit }: any, payload: string | null) => commit('SET_LOYALTY_CURRENCY_NAME', payload),
  setLoyaltyCurrencyOther: ({ commit }: any, payload: string | null) => commit('SET_LOYALTY_CURRENCY_OTHER', payload),
  setLoyaltyCurrencyExpirationValue: ({ commit }: any, payload: number | null) => commit('SET_LOYALTY_EXPIRATION_VALUE', payload),
  setLoyaltyCurrencyExpirationMode: ({ commit }: any, payload: string) => commit('SET_LOYALTY_EXPIRATION_MODE', payload),

  setPoolProgramMode: ({ commit }: any, payload: string) => commit('SET_POOL_PROGRAM_MODE', payload),
  setPoolProgramEarnExchangeRate: ({ commit }: any, payload: number) => commit('SET_POOL_PROGRAM_EARN_EXCHANGE_RATE', payload),
  setPoolProgramBurnExchangeRate: ({ commit }: any, payload: number | null) => commit('SET_POOL_PROGRAM_BURN_EXCHANGE_RATE', payload),
  setPayWithPool: ({ commit }: any, payload: boolean) => commit('SET_PAY_WITH_POOL', payload),

  setStatusSystem: ({ commit }: any, payload: any) => commit('SET_STATUS_SYSTEM', payload),
  setStatusSystemName: ({ commit }: any, payload: string) => commit('SET_STATUS_SYSTEM_NAME', payload),
  setStatusSystemCounter: ({ commit }: any, payload: string) => commit('SET_STATUS_SYSTEM_COUNTER', payload),
  setStatusSystemCalculationPeriod: ({ commit }: any, payload: string) => commit('SET_STATUS_SYSTEM_CALCULATION_PERIOD', payload),
  setStatusSystemCalculationPeriodValue: ({ commit }: any, payload: number) => commit('SET_STATUS_SYSTEM_CALCULATION_PERIOD_VALUE', payload),
  setStatusSystemCounterFrequency: ({ commit }: any, payload: string) => commit('SET_STATUS_SYSTEM_COUNTER_FREQUENCY', payload),
  setStatusSystemCounterFrequencyValue: ({ commit }: any, payload: number) => commit('SET_STATUS_SYSTEM_COUNTER_FREQUENCY_VALUE', payload),

  createStatusSystemLevel: ({ commit }: any, payload: any) => commit('PUSH_STATUS_SYSTEM_LEVEL', payload),
  deleteStatusSystemLevel: ({ commit }: any, payload: number) => commit('SPLICE_STATUS_SYSTEM_LEVEL', payload),
  deleteStatusSystemAllLevel: ({ commit }: any) => commit('DELETE_STATUS_SYSTEM_ALL_LEVEL'),
  updateStatusSystemLevel: ({ commit }: any, payload: { index: number, level: any }) => commit('UPDATE_STATUS_SYSTEM_LEVEL', { index: payload.index, level: payload.level }),

  setDescriptionCoreProgram: ({ commit }: any, payload: string) => commit('SET_DESCRIPTION_CORE_PROGRAM', payload),
  setImageDescriptionUrlCoreProgram: ({ commit }: any, payload: string) => commit('SET_IMAGE_DESCRIPTION_URL_CORE_PROGRAM', payload),
  setImageDescriptionTextCoreProgram: ({ commit }: any, payload: string) => commit('SET_IMAGE_DESCRIPTION_TEXT_CORE_PROGRAM', payload),

  setEcardCoreProgram: ({ commit }: any, payload: boolean) => commit('SET_ECARD_CORE_PROGRAM', payload),

  /* ------------------------------------------------------------------------------------------
                                    COMPONENTS ACTIONS
  --------------------------------------------------------------------------------------------*/

  initActions({ commit, dispatch }: any) {
    commit('RESET_LINES')
    dispatch('checkActions')
  },

  checkActions({ commit, state }: any) {
    if (state.campaign.rewards.length === 0 && state.campaign.awards.length === 0) {
      commit('PUSH_LINE')
      return
    }

    const awards = [...state.campaign.awards]
    let line = 0
    if (state.campaign?.rewards?.length > 0) {
      for (const reward of state.campaign.rewards) {
        commit('PUSH_LINE')
        if (reward.triggers?.length > 0)
          commit('PUSH_ACTION', { index: line, value: { ...reward.triggers[0], ...{ card_type: 'trigger' } }})
        commit('PUSH_ACTION', { index: line, value: { ...reward, ...{ card_type: 'reward' } }})
        if (reward.award_id) {
          const awardIndex = state.campaign.awards.findIndex((e: any) => e.id === reward.award_id)
          const award = state.campaign.awards[awardIndex]
          commit('SPLICE_ACTION', { lineIndex: line, actionIndex: 0, deleteCount: 0, value: { ...award, ...{ card_type: 'award' } }})
          commit('SPLICE_ACTION', { lineIndex: line, actionIndex: 0, deleteCount: 0, value: { ...award.triggers[0], ...{ card_type: 'trigger' } }})
          awards.splice(awardIndex, 1)
        }
        line++
      }
    }
    if (awards.length > 0) {
      for (const award of awards) {
        commit('PUSH_LINE')
        commit('SPLICE_ACTION', { lineIndex: line, actionIndex: 0, deleteCount: 0, value: { ...award, ...{ card_type: 'award' } }})
        commit('SPLICE_ACTION', { lineIndex: line, actionIndex: 0, deleteCount: 0, value: { ...award.triggers[0], ...{ card_type: 'trigger' } }})
        line++
      }
    }
  },

  selectAction: ({ commit, state }: any, payload: { li: number, ai: number }) => {
    const deselect = state.li == payload.li && state.ai == payload.ai
    commit('SET_LINE_INDEX', deselect ? -1 : payload.li)
    commit('SET_ACTION_INDEX', deselect ? -1 : payload.ai)
  },

  async fetchDraft({ commit, state }: any, payload: string) {
    commit('SET_DRAFT_LOADING', true)
    const res = await getService(state).getDraft({ kind: payload })

    commit('SET_DRAFT_CAMPAIGN', res.data)
    commit('SET_DRAFT_LOADING', false)
  },

  async fetchCampaign({ commit, state }: any, payload: string) {
    commit('SET_DRAFT_LOADING', true)
    const res = await getService(state).get(payload)
    commit('SET_DRAFT_CAMPAIGN', res.data)
    commit('SET_DRAFT_LOADING', false)
  },

  async createConsumable({ commit, state, getters, dispatch }: any, payload: { index: number, action: any, actionKind?: string | undefined }) {
    const action = payload.action

    const params: any = {
      consumable: {
        type: action.cardType,
        amount: 100,
        require_activation: false,
        number_of_use: 1,
        number_of_use_per_transaction: 1
      }
    }
    if (action.cardType === 'reward' || action.cardType === 'pool') {
      commit('SET_CAN_CREATE_OTHER_ACTION', { index: payload.index, value: false })

      if (!action.withTrigger) params.consumable.amount_kind = 'percentage'
      if (state.mode === 'subfidelity' && ['permanent', 'limited'].includes(state.campaign.kind) && action.cardType !== 'pool')
        params.consumable.require_activation = true
    } else if (action.cardType === 'award') {
      if (state.mode === 'fidelity') params.consumable.number_of_use = -1
    }

    params.consumable.award_id = getters.hasAward(payload.index)?.id
    const res = await getService(state).createConsumable(state.campaign.id, params)

    commit('PUSH_ACTION', { index: payload.index, value: { ...res.data, ...{ card_type: action.cardType } } })
    if (action.withTrigger) dispatch('createTrigger', { index: payload.index, rewardId: res.data.id, actionKind: payload.actionKind })
  },

  async createTrigger({ commit, state }: any, payload: { index: number, rewardId: string, actionKind: string | undefined }) {
    let params: any
    if (payload.actionKind === 'welcome')
      params = { trigger: { type: 'customer', threshold: 1, kind: 'welcome', scope: 'cart' } }
    else
      params = { trigger: { type: 'cart', threshold: 100 } }

    const res = await getService(state).createTrigger(state.campaign.id, payload.rewardId, params)

    const actionLength = state.lines[payload.index].actions?.length
    commit('SPLICE_ACTION', { lineIndex: payload.index, actionIndex: actionLength - 1, deleteCount: 0, value: { card_type: 'trigger', ...res.data } })
  },

  addLine({ commit }: any) {
    commit('PUSH_LINE')
  },

  async saveActionDraftCampaign({ commit, state }: any) {
    if (state.lines?.length > 0) {
      for (const line of state.lines) {
        line.actions.forEach(async (action: any, index: number) => {
          if (action.card_type === 'trigger' && line.actions[index + 1]) {
            await getService(state).updateTriggerConsumable(state.campaign.id, line.actions[index + 1].id, action.id, { trigger: action })
          } else if (action) {
            await getService(state).updateConsumable(state.campaign.id, action.id, { consumable: action })
          }
        })
      }
    }
    commit('SET_SAVE_LOADING', false)
  },

  async saveCampaign({ commit, state, dispatch }: any) {
    commit('SET_SAVE_LOADING', true)
    const campaign = { ...state.campaign }
    campaign.rewards = null
    campaign.awards = null
    await getService(state).update(state.campaign.id, { campaign: campaign })
    dispatch('saveActionDraftCampaign')
  },

  async destroy({ commit, state }: any) {
    commit('SET_DESTROY_LOADING', true)
    await getService(state).destroy(state.campaign.id)
    commit('SET_DESTROY_LOADING', false)
  },

  async deleteAction({ commit, state }: any, payload: { action: any, line: number, index: number }) {
    if (payload.action.card_type !== 'trigger')
      await getService(state).deleteConsumable(state.campaign.id, payload.action.id)
    else if (payload.action.card_type === 'trigger' && Object.keys(payload.action).length > 1) {
      const consumable = state.lines[payload.line].actions[payload.index + 1]
      await getService(state).deleteTrigger(state.campaign.id, consumable.id, payload.action.id)
    }
    commit('DELETE_ACTION', { lineIndex: payload.line, actionIndex: payload.index })
    if (payload.index !== 0 && state.lines[payload.line].actions[payload.index - 1].card_type === 'trigger')
      commit('DELETE_ACTION', { lineIndex: payload.line, actionIndex: payload.index - 1 })

    commit('SET_LINE_INDEX', -1)
    commit('SET_ACTION_INDEX', -1)
  },

  async deleteLine({ commit, state, dispatch }: any, payload: number) {
    await state.lines[payload].actions.map(async (e: any) => {
      if (e.card_type !== 'trigger') {
        const actionIndex = state.lines[payload].actions.findIndex((action: any) => action.id === e.id)
        await dispatch('deleteAction', { action: e, line: payload, index: actionIndex })
      }
    })
    commit('DELETE_LINE', payload)
  },

  async persist({ commit, state, dispatch }: any) {
    commit('SET_CREATE_CAMPAIGN', true)
    await dispatch('saveCampaign')
    if (state.campaign.status === 'draft')
      await getService(state).persist(state.campaign.id)
    commit('SET_CREATE_CAMPAIGN', false)
  },

  setCantCreateOtherAction({ commit }: any, payload: number) {
    commit('SET_CAN_CREATE_OTHER_ACTION', { index: payload, value: false })
  },

  checkPublishersSelected({ commit, state }: any, payload: string) {
    const pubIndex = state.campaign.commission_contracts.findIndex((e: any) => e.id === payload)
    if (pubIndex < 0) commit('ADD_PUBLISHER', payload)
    else commit('DELETE_PUBLISHER', pubIndex)
  },

  checkRetailersSelected({ commit, state }: any, payload: string) {
    const posIndex = state.campaign.campaign_participants.findIndex((e: any) => e.retailer_id === payload)
    if (posIndex < 0) commit('ADD_RETAILER', payload)
    else commit('DELETE_RETAILER', posIndex)
  },

  checkNotificationSelected({ commit, state }: any, payload: string) {
    const notifIndex = state.campaign.notifications.findIndex((e: any) => e === payload)
    if (notifIndex < 0) commit('ADD_NOTIFICATION_CORE_PROGRAM', payload)
    else commit('DELETE_NOTIFICATION_CORE_PROGRAM', notifIndex)
  },

  resetState({ commit }: any) {
    commit('RESET_STATE')
  }
}

export default actions
