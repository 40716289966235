<template>
  <div class="mt-5">
    <label class="font-weight-medium subheading">{{ $t('createCampaign.birthday.anticipation') }} :</label>
    <div class="anticipation-selector">
      <v-text-field v-model.number="duration" @change="anticipationTime()" type="number" :label="$t('createCampaign.triggerReward.duration')"></v-text-field>
      <v-select v-model="period" :items="periods" @change="anticipationTime()" item-text="name" item-value="value" :label="$t('createCampaign.triggerReward.unitOfTime')"></v-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      periods: [
        {
          name: this.$t('createCampaign.triggerReward.days'),
          value: 1
        },
        {
          name: this.$t('createCampaign.triggerReward.months'),
          value: 30
        },
        {
          name: this.$t('createCampaign.triggerReward.years'),
          value: 365
        }
      ]
    }
  },
  computed: {
    ...mapState({ eventAnticipation: state => state.campaignBuilder.campaign.event_anticipation }),
    duration: {
      get() {
        return Math.trunc(this.eventAnticipation / 365) || Math.trunc((this.eventAnticipation % 365) / 30) || Math.trunc((this.eventAnticipation % 365) % 30)
      },
      set(value) {
        this.setEventAnticipation(value * this.period)
      }
    },
    period: {
      get() {
        return Math.trunc(this.eventAnticipation / 365) ? 365 : Math.trunc((this.eventAnticipation % 365) / 30) ? 30 : 1
      },
      set(value) {
        this.setEventAnticipation(this.duration * value)
      }
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['setEventAnticipation']),
    anticipationTime() {
      this.setEventAnticipation(this.duration * this.period)
    }
  }
}
</script>
