<template>
  <v-data-table
    :headers="companiesHeader"
    :loading="dataWaiting"
    :items="companies"
    :pagination.sync="pagination"
    class="elevation-1"
    :rows-per-page-text="$t('common.rowsPerPage')"
  >
    <template v-slot:items="props">
      <td>{{ props.item.name }}</td>
      <td></td>
      <td>
        <span v-for="(email, index) in props.item.domains" :key="index"
          >{{ email }}<span v-if="index < props.item.domains.length - 1">, </span></span
        >
      </td>
      <td>
        <span v-for="(email, index) in props.item.emails" :key="index"
          >{{ email }}<span v-if="index < props.item.emails.length - 1">, </span></span
        >
      </td>
      <td></td>
      <td></td>
      <td>
        <v-btn @click="deleteCompany(props.item.id)" flat icon color="error">
          <v-icon>delete</v-icon>
        </v-btn>
      </td>
    </template>
  </v-data-table>
</template>
<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'
export default {
  props: {
    refreshList: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      dataWaiting: false,
      companies: [],
      companiesHeader: [
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Email Domain',
          value: 'domains'
        },
        {
          text: 'Specific emails',
          value: 'domains'
        },
        {
          text: 'Number of End-users',
          value: 'users'
        },
        {
          text: 'Client group',
          value: ''
        },
        {
          text: 'Actions',
          value: ''
        }
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        sortBy: 'created_at',
        descending: true
      }
    }
  },
  watch: {
    havetoRefresh(val) {
      if (val) {
        this.getCompanies()
        this.refreshList = false
      }
    }
  },
  computed: {
    havetoRefresh() {
      return this.refreshList
    }
  },
  methods: {
    async getCompanies() {
      this.dataWaiting = true
      const workplaces = await req.get('/workplaces')
      this.companies = workplaces.data
      this.dataWaiting = false
    },
    deleteCompany(id) {
      req.delete('/workplaces/' + id).then(() => {
        EventBus.$emit('showSnackbar', {
          text: 'The company has been deleted',
          color: 'success'
        })
        this.getCompanies()
      })
    }
  },
  created() {
    this.getCompanies()
  }
}
</script>
