<template>
  <v-container fluid grid-list-xl>
    <v-card class="px-3 py-4 mb-4">
      <label class="font-weight-light subheading align-self-center">
        Configuration des <b class="font-weight-medium">distributeur(s)</b> de
        votre campagne
      </label>
    </v-card>

    <v-card class="w-100">
      <div class="py-2 px-4 justify-space-between" style="display: flex">
        <h4 class="align-self-end font-weight-medium">
          Vos distributeur(s) :
          <span class="font-weight-light ml-3">{{
            `${selectedCampaignParticipants ? selectedCampaignParticipants.length : 0} selectionné(s)`
          }}</span>
        </h4>
        <v-text-field
          v-model="search"
          class="pt-0"
          append-icon="search"
          label="Rechercher un diffuseur"
          :loading="loading"
          single-line
          hide-details
          clearable
          style="max-width: 300px"
        ></v-text-field>
      </div>
      <v-divider class="my-3"></v-divider>
      <v-data-table
        ref="dataTable"
        :headers="headers"
        :items="retailers"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        :rows-per-page-text="$t('common.rowsPerPage')"
        item-key="name"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th width="30">
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ma-0 pt-0 mt-0"
                    hide-details
                    flat
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon small>keyboard_arrow_down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-tile
                    @click="
                      allCampaignParticipantsIsSelected ? deselectAll() : selectAll()
                    "
                  >
                    <v-list-tile-title>{{
                      allCampaignParticipantsIsSelected ? 'Delesect all' : 'Select all'
                    }}</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="selectFiltered()">
                    <v-list-tile-title>Select page</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </th>
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="text-xs-left"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr
            :active="isSelected(props.item.id)"
          >
            <td>
              <v-checkbox
                @click.stop="addOrDeleteCampaignParticipants(props.item.id)"
                :input-value="isSelected(props.item.id)"
                hide-details
              ></v-checkbox>
            </td>
            <td class="flex-div align-center">
              <img
                v-show="props.item.logo_url"
                :src="props.item.logo_url"
                alt="Logo"
                width="30"
                class="mr-3"
              />
              <span>{{ props.item.name | capitalize}}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import CompanyService from '@/services/CompanyService'

export default {
  data() {
    return {
      loading: false,
      retailers: [],
      search: null,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      headers: [
        {
          text: this.$t('createCampaign.publisher.company'),
          value: 'name',
          sortable: 'false'
        }
      ]
    }
  },
  created() {
    this.fetchRetailers()
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'addCampaignParticipants',
      'deleteCampaignParticipant',
      'clearCampaignParticipants'
    ]),
    async fetchRetailers() {
      this.loading = true
      const res = await CompanyService.getRetailers()
      this.retailers = res.data
      this.loading = false
    },
    addOrDeleteCampaignParticipants(id) {
      this.isSelected(id) ? this.deleteCampaignParticipant(id) : this.addCampaignParticipants(id)
    },
    isSelected(id) {
      if (this.selectedCampaignParticipants?.length > 0)
        return !!this.selectedCampaignParticipants.find(e => e.company_id === id)
      else return false
    },
    selectAll() {
      this.addCampaignParticipants(this.retailers.map(e => e.id))
    },
    deselectAll() {
      this.clearCampaignParticipants()
    },
    selectFiltered() {
      this.addCampaignParticipants(this.filteredCampaignParticipants.map(e => e.id))
    }
  },
  computed: {
    ...mapState({
      selectedCampaignParticipants: state => state.campaignBuilder.campaign.campaign_participants
    }),
    filteredCampaignParticipants() {
      return this.$refs.dataTable.filteredItems
    },
    allCampaignParticipantsIsSelected() {
      return this.retailers?.length === this.selectedCampaignParticipants?.length
    }
  }
}
</script>
