<template>
  <div>
    <v-toolbar color="indigo" scroll-off-screen dark class="flex-header">
      <v-toolbar-side-icon class="hidden-md-and-up" dark @click="mobileMenu = !mobileMenu"></v-toolbar-side-icon>

      <v-toolbar-title class="ml-0 h-100" style="">
        <router-link to="/" class="align-center h-100 w-100">
          <div v-if="company.advertisers.length > 0" class="d-flex px-2 py-1 h-100 align-center">
            <img v-if="company.advertisers[0].logo_url" :src="company.advertisers[0].logo_url" class="advertiser-logo" alt="logo" />
            <h4 class="ml-3 text-none text--white font-weight-regular" style="flex: 0">
              {{ company.advertisers[0].name | capitalize }}
            </h4>
          </div>
          <img v-else src="@/assets/images/logo/logo.svg" alt="logo" width="150" />
        </router-link>
      </v-toolbar-title>

      <v-toolbar-items v-if="company" class="hidden-sm-and-down" style="">
        <router-link to="/dashboard" active-class="active" tag="v-btn" class="v-btn--flat">
          {{ $t('nav.dashboard') }}
        </router-link>

        <CompanyMenu v-if="advertiser" />

        <LoyaltyMenu v-if="company.usages.includes('fidelity')" @reload="$emit('reload')" />

        <AdToStoreMenu v-if="company.usages.includes('conquest') && advertiser" @reload="$emit('reload')" />
        <router-link v-else-if="!advertiser && publisher" to="/campaigns" tag="v-btn" active-class="active" class="v-btn--flat">
          {{ $t('nav.campaigns') }}
        </router-link>

        <router-link to="/settings" tag="v-btn" active-class="active" class="v-btn--flat">
          {{ $t('nav.settings') }}
        </router-link>
      </v-toolbar-items>

      <div class="d-flex align-center">
        <NotificationsBadge />
        <UserMenu :companies="companies" />
      </div>
    </v-toolbar>
    <v-navigation-drawer v-model="mobileMenu" light absolute temporary>
      <HeaderMobile />
    </v-navigation-drawer>
  </div>
</template>

<script>
import req from '@/packs/request'
import CompanyMenu from '@/components/Header/HeaderMenu/CompanyMenu'
import LoyaltyMenu from '@/components/Header/HeaderMenu/LoyaltyMenu'
import AdToStoreMenu from '@/components/Header/HeaderMenu/AdToStoreMenu'
import UserMenu from '@/components/Header/HeaderMenu/UserMenu'
import HeaderMobile from '@/components/Header/HeaderMobile'
import NotificationsBadge from '@/components/NotificationsBadge'

export default {
  components: {
    CompanyMenu,
    LoyaltyMenu,
    AdToStoreMenu,
    UserMenu,
    HeaderMobile,
    NotificationsBadge
  },
  data() {
    return {
      mobileMenu: false,
      companies: []
    }
  },
  methods: {
    async getCompanies() {
      const res = await req.get('/handled_companies')
      this.companies = res.data
    }
  },
  computed: {
    account() {
      return this.$store.state.user.account
    },
    company() {
      return this.$store.state.user.company
    },
    advertiser() {
      return this.$store.state.user.capabilities.advertiser
    },
    publisher() {
      return this.$store.state.user.capabilities.publisher
    }
  },
  created() {
    this.getCompanies()
  }
}
</script>

<style lang="sass">
.flex-header .v-toolbar__content
  justify-content: space-between
</style>
