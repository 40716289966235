<template>
  <div>
    <label class="font-weight-medium mr-4">{{ $t('createCampaign.campaignSettings.filter.title') }}</label>
    <div class="flex-center">
      <v-radio-group v-model="segment_" row hide-details :disabled="disabled">
        <v-radio :label="$t('common.boolYes')" :value="true"></v-radio>
        <v-radio :label="$t('common.boolNo')" :value="false"></v-radio>
      </v-radio-group>
    </div>
    <div v-if="segment_" class="ml-auto mr-auto" style="max-width: 500px">
      <v-flex class="pb-0 mt-3">
        <v-select v-model="selectedId" :disabled="disabled" :label="$t('createCampaign.customSegment')" :items="segments" item-text="name" item-value="id" hide-details solo menu-props="offsetY" @change="onChange($event)">
          <template v-slot:item="{ item }">
            <v-chip v-if="!item.custom" color="green" small text-color="white" class="mr-2">RFM</v-chip>
            <v-chip v-else color="orange" small text-color="white" class="mr-2">Custom</v-chip>
            <span>{{ item.name }}</span>
          </template>
        </v-select>
      </v-flex>
      <v-flex class="pb-0 mt-4" v-if="(detailsSegment && detailsSegment.length > 0) ||(detailsSegment && Object.entries(detailsSegment).length > 0)">
        <div style="background-color: #f5f5f5;" class="pa-2">
          <div class="disp-flex">
            <label class="font-weight-medium w-100">{{ $t('common.target') }}</label>
            <div class="w-100 text-xs-right">
              <v-icon>supervisor_account</v-icon>
              {{ detailsSegment.statistics.customers_count }}
              {{ $t('common.consumers') }}
            </div>
          </div>
          <div class="disp-flex">
            <label class="font-weight-medium w-100">
              {{ $t('createCampaign.campaignSettings.parameters') }}
            </label>
            <div class="w-100 text-xs-right">
              <v-tooltip top>
                <span slot="activator" class="cursor-pointer" style="text-decoration: underline">
                  {{ $t('createCampaign.campaignSettings.see') }}
                </span>
                <span v-if="detailsSegment.filters.length > 0">
                  {{ $t('createCampaign.campaignSettings.parameters') }}
                  <template v-for="(each, index) of detailsSegment.filters">
                    <br :key="index"/>- {{ each | capitalize }}
                  </template>
                </span>
                <span v-else>
                  Aucun paramètre(s)
                </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import req from '@/packs/request.js'

export default {
  props: {
    segment: {
      type: Object,
      required: false
    },
    noStore: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      segments: [],
      selectedId: null
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['setSegment']),
    async fetchSegments() {
      const res = await req.get('/customer_bases')

      if (res.data.length <= 0) return
      for (const each of res.data) {
        const seg = await req.get('/customer_bases/' + each.id + '/segments')
        for (const eachSeg of seg.data) {
          if (eachSeg.tags.length > 0 && eachSeg.tags[0] !== 'status')
            this.segments.push(eachSeg)
        }
      }
      if (this.segment)
        this.selectedId = this.segments.find(e => e.id === this.segment.id).id
    },
    onChange(event) {
      this.selectedId = event
      !this.noStore ? this.setSegment(this.selected) : this.$emit('updateSegments', this.selected)
    },
    formatParameters(segment) {
      const parameters = []

      for (const each of segment.filters) {
        if (each.kind === 'custom')
          for (const params of Object.keys(each.values))
            parameters.push(`${params}: ${each.values[params]}`)
        else if (each.kind === 'status' || each.kind === 'rfm')
          parameters.push(`${each.kind}: ${each.name}`)
      }
      return parameters
    }
  },
  computed: {
    ...mapGetters('campaignBuilder', ['hasSegment']),
    selected() {
      return this.segments.find(e => e.id === this.selectedId)
    },
    segment_: {
      get() {
        return !this.noStore ? this.hasSegment : !!this.segment
      },
      set(value) {
        if (!this.noStore) this.setSegment(value ? { id: this.selectedId } : undefined)
        else this.$emit('updateSegments', value ? { id: this.selectedId } : undefined)
      }
    },
    detailsSegment() {
      const segment = this.selected
      if (segment) segment.filters = this.formatParameters({ ...segment })
      return segment
    }
  },
  created() {
    this.fetchSegments()
  }
}
</script>
