<template>
  <div>
    <v-btn flat dark color="blue-grey darken-1" class="ma-0 w-100" @click="dialog = true">
      <v-icon left>publish</v-icon>{{ $t('campaignListItem.publishers') }}
    </v-btn>
    <DialogPublishers v-model="dialog" :id="id" />
  </div>
</template>

<script>
import DialogPublishers from '@/components/CampaignForm/Common/DistributorsPublishers/DialogPublishers'

export default {
  components: {
    DialogPublishers
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    }
  }
}
</script>
