<template>
  <v-container fluid grid-list-lg>
    <v-layout row wrap mb-3>
      <v-flex class="flex-div align-center">
        <h2 class="headline">{{ $t('nav.ratecards') }}</h2>
        <v-btn
          @click="dialogRatecard = true"
          class="ml-auto mr-0"
          color="primary"
        >
          <v-icon class="mr-2">add</v-icon>{{ $t('ratecards.createRatecard') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- Ratecards -->
    <RatecardsList
      @refresh="getRatecards"
      @editRatecard="showEdit"
      :ratecards="ratecards"
      :loading="ratecardLoading"
    />
    <CreateEditRatecard
      @refresh="getRatecards"
      v-if="dialogRatecard"
      v-model="dialogRatecard"
      :ratecardEdit="editItem"
      @close="closedDialog"
      :key="keyRefresh"
    />
  </v-container>
</template>
<script>
import req from '@/packs/request.js'
import RatecardsList from './RatecardsList'
import CreateEditRatecard from './CreateEditRatecard'
export default {
  components: {
    RatecardsList,
    CreateEditRatecard,
  },
  data: () => {
    return {
      dialogRatecard: false,
      ratecards: [],
      ratecardLoading: true,
      editItem: {},
      keyRefresh: 1,
    }
  },
  methods: {
    getRatecards() {
      this.ratecards = []
      req
        .get('/rate_cards')
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            this.ratecards.push(response.data[i])
          }
          this.ratecardLoading = false
        })
        .catch(error => {
          console.error(error)
        })
    },
    showEdit(item) {
      this.editItem = item
      this.dialogRatecard = true
    },
    closedDialog() {
      this.dialogRatecard = false
      this.editItem = {}
      this.keyRefresh++
    },
  },
  created() {
    this.getRatecards()
  },
}
</script>
