var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"font-weight-regular info-label disp-flex"},[_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-0 mr-0",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("info")])],1)]}}])},[_c('span',{staticClass:"tooltip-custom"},[(_vm.id)?_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.id),expression:"id",arg:"copy"}],attrs:{"small":""}},[_vm._v("Copier")]):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? _vm.id : 'Aucun ID')+" ")],1)]),_c('span',{staticClass:"mt-2 body-2"},[_vm._v("Détail de la transaction :")])],1),_c('section',{staticClass:"overflow-timeline custom-scrollbar"},[_c('ol',_vm._l((_vm.discounts()),function(details,index){return _c('li',{key:index},[_c('span',{staticClass:"point",class:{
            reward: (details.operation === 'reward') && !details.gift,
            burn: details.operation === 'burn',
            gain: details.operation === 'earn' || details.operation === 'award',
            gift: details.operation === 'reward' && details.gift
          },on:{"mouseover":function($event){return _vm.hoverTransaction(details)},"mouseleave":function($event){return _vm.hoverTransaction({})}}}),_c('div',{staticClass:"details mx-auto"},[_c('h2',{staticClass:"title mb-1"},[_vm._v(_vm._s(_vm.getType(details)))]),_c('p',{staticClass:"body-1 font-weight-regular"},[_vm._v(" "+_vm._s(details.message ? details.message : 'Aucune donnée')+" ")])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }