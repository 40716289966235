<template>
  <div style="max-width: 850px" class="ml-auto mr-auto mt-4 mb-5">
    <label class="font-weight-medium subheading align-self-center">{{ $t('CoreProgramCampaignSettingsPool.earningRules') }}</label>
    <hr class="mt-3 mb-5" style="border-color: #ffffff52"/>
    <v-layout wrap row>
      <v-flex xs12 class="d-flex">
        <div style="width: 33%">
          <label class="font-weight-medium">{{ $t('CoreProgramCampaignSettingsPool.payWithPool') }} :</label><br><br>
          <label class="body-1">
            {{ $t('CoreProgramCampaignSettingsPool.payWithPoolDescription') }}
          </label>
        </div>
        <div style="width: 66%" class="pl-5">
          <v-checkbox v-model="payWithPool" :value="true" disabled hide-details :label="$t('CoreProgramCampaignSettingsPool.payWithPoolCheckbox')"></v-checkbox>
        </div>
      </v-flex>
      <v-flex xs12 class="d-flex mt-5">
        <div style="width: 33%">
          <label class="font-weight-medium">Pool award :</label><br><br>
          <label class="body-1">
            Loyalty earning rules
          </label>
        </div>
        <div style="width: 66%" class="pl-5">
          <v-radio-group hide-details class="mt-0" v-model="poolProgramMode_">
            <v-radio :label="$t('CoreProgramCampaignSettingsPool.fixedMode')" value="fixed"></v-radio>
            <v-radio :label="$t('CoreProgramCampaignSettingsPool.rateMode')" value="rate"></v-radio>
          </v-radio-group>
          <transition name="slide-y-transition">
            <div v-if="poolProgramMode === 'fixed'" class="mt-3 pt-2 pb-3 px-3 input-details-radio">
              <label class="font-weight-medium">Number: </label>
              <div class="flex-div align-center">
                <v-text-field type="number" class="mt-0 pt-0 mr-3" v-model.number="poolProgramEarnExchangeRate_" :placeholder="$t('CoreProgramCampaignSettingsPool.earnExchangeRate')" hide-details></v-text-field>
                <p class="no-wrap mb-0">{{ getCurrencyName }} {{$t('CoreProgramCampaignSettingsPool.perPurchase')}}</p>
              </div>
            </div>
            <div v-if="poolProgramMode === 'rate'">
              <div class="mt-3 pt-2 pb-3 px-3 input-details-radio">
                <label class="font-weight-medium">Ratio :</label>
                <div class="flex-div align-center">
                  <v-text-field type="number" class="mt-0 pt-0" v-model="poolProgramEarnExchangeRate_" :min="1" hide-details />
                  <span class="mx-2 no-wrap mb-0">{{ getCurrencyName }} {{ $t('common.for') }} <b>1</b>€</span> {{ $t('CoreProgramCampaignSettingsPool.spent') }}
                </div>
              </div>
              <div class="mt-3 pt-2 pb-3 px-3 input-details-radio">
                <label class="font-weight-medium">En pourcentage :</label>
                <v-text-field type="number" class="mt-0 pt-0 percentage-input" v-model="poolProgramEarnExchangeRateInPercentage_" :placeholder="$t('CoreProgramCampaignSettingsPool.earnExchangeRate')" hide-details />
                <p class="mb-0 mt-3">{{ $t('CoreProgramCampaignSettingsPool.percentageModeDescription') }} <b>{{ poolProgramEarnExchangeRateInPercentage_ }}</b> {{ getCurrencyName }}</p>
              </div>
            </div>
          </transition>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      copyReward: Object
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setPoolProgramMode',
      'setPoolProgramEarnExchangeRate',
      'setPoolProgramBurnExchangeRate',
      'setPayWithPool'
    ])
  },
  computed: {
    ...mapState({
      payWithPool: state => state.campaignBuilder.payWithPool,
      currencyName: state => state.campaignBuilder.campaign.loyalty_currency.name,
      currencyOther: state => state.campaignBuilder.campaign.loyalty_currency.other,
      poolProgramMode: state => state.campaignBuilder.campaign.pool_program.mode,
      poolProgramEarnExchangeRate: state => state.campaignBuilder.campaign.pool_program.earn_exchange_rate
    }),
    poolProgramMode_: {
      get() {
        return this.poolProgramMode
      },
      set(value) {
        this.setPoolProgramMode(value)
      }
    },
    poolProgramEarnExchangeRate_: {
      get() {
        return this.poolProgramEarnExchangeRate
      },
      set(value) {
        this.setPoolProgramEarnExchangeRate(value)
      }
    },
    poolProgramEarnExchangeRateInPercentage_: {
      get() {
        return (this.poolProgramEarnExchangeRate_ * 100).toFixed(0)
      },
      set(value) {
        this.setPoolProgramEarnExchangeRate(value / 100)
      }
    },
    poolProgramBurnExchangeRate_: {
      get() {
        return this.poolProgramBurnExchangeRate
      },
      set(value) {
        this.setPoolProgramBurnExchangeRate(value)
      }
    },
    payWithPool_: {
      get() {
        return this.payWithPool
      },
      set(value) {
        this.setPayWithPool(value)
      }
    },
    getCurrencyName() {
      return this.currencyName !== 'Other' ? this.currencyName : this.currencyOther
    }
  },
  watch: {
    poolProgramMode: {
      deep: true,
      handler() {
        if (this.poolProgramEarnExchangeRate !== 1) this.poolProgramEarnExchangeRate_ = 1
      }
    },
    payWithPool: {
      deep: true,
      handler() {
        if (this.payWithPool) {
          this.poolProgramBurnExchangeRate_ = 1
        } else {
          this.poolProgramBurnExchangeRate_ = null
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import "../stylesheets/coreProgram"
</style>
