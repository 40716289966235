import CampaignListState from '@/models/states/CampaignListState'
import initialState from '@/store/modules/campaignList/initialState'

enum Mode {
  Fidelity = 'fidelity',
  AdToStore = 'conquest',
  Both = 'both'
}

const FIDELITY_TABS = ['customs', 'communications']

const CONQUEST_TABS = ['created', 'inherited', 'tradeMarketing', 'publishing']

const selectedCampaigns = (state: any) => {
  const tabsName = state.mode === Mode.Fidelity ? FIDELITY_TABS : CONQUEST_TABS
  return state[state.mode][tabsName[state.tabIndex]]
}

const mutations = {
  RESET_STATE: (state: CampaignListState) => Object.assign(state, initialState()),
  SET_MODE: (state: CampaignListState, payload: Mode) => (state.mode = payload),
  SET_TAB_INDEX: (state: CampaignListState, payload: number) => (state.tabIndex = payload),

  CLEAR_CAMPAIGNS: (state: CampaignListState) => {
    selectedCampaigns(state).collection = []
    selectedCampaigns(state).count = 0
    selectedCampaigns(state).filters.page = 1
  },

  SET_CAMPAIGN_STATE: (state: CampaignListState, payload: { index: string; value: string }) => {
    selectedCampaigns(state).collection[payload.index].state = payload.value
  },

  SET_CAMPAIGN_STATUS: (state: CampaignListState, payload: { index: string; value: string }) => {
    selectedCampaigns(state).collection[payload.index].status = payload.value
  },

  SET_COMMISSION_CONTRACT: (state: CampaignListState, payload: { index: string; value: any }) => {
    const commissionContractIndex = selectedCampaigns(state).collection[payload.index].commission_contracts.findIndex(
      (e: any) => e.id === payload.value.id
    )
    selectedCampaigns(state).collection[payload.index].commission_contracts.splice(
      commissionContractIndex,
      1,
      payload.value
    )
  },

  DELETE_CAMPAIGN: (state: CampaignListState, payload: { index: string; value: string }) => {
    selectedCampaigns(state).collection.splice(payload, 1)
  },

  FILTER_BY_STATUS: (state: CampaignListState, payload: string) => {
    selectedCampaigns(state).filters.by_status = payload
  },

  FILTER_BY_STATE: (state: CampaignListState, payload: string) => {
    selectedCampaigns(state).filters.by_state = payload
  },

  INCREMENT_FILTERS_PAGE: (state: CampaignListState) => {
    selectedCampaigns(state).filters.page++
  },

  // Fidelity
  SET_SUBFIDELITY_COMMUNICATIONS: (state: CampaignListState, payload: any) => {
    state.fidelity.communications.collection.push(...payload.collection)
    state.fidelity.communications.count = payload.count
  },
  SET_SUBFIDELITY_COMMUNICATIONS_LOADING: (state: CampaignListState, payload: boolean) => state.fidelity.communications.loading = payload,


  SET_SUBFIDELITY_CUSTOMS: (state: CampaignListState, payload: any) => {
    state.fidelity.customs.collection.push(...payload.collection)
    state.fidelity.customs.count = payload.count
  },
  SET_SUBFIDELITY_CUSTOMS_LOADING: (state: CampaignListState, payload: boolean) => state.fidelity.customs.loading = payload,

  // Adtostore
  SET_CONQUEST_CREATED: (state: CampaignListState, payload: any) => {
    state.conquest.created.collection.push(...payload.collection)
    state.conquest.created.count = payload.count
  },
  SET_CONQUEST_CREATED_LOADING: (state: CampaignListState, payload: boolean) => state.conquest.created.loading = payload,

  SET_CONQUEST_INHERITED: (state: CampaignListState, payload: any) => {
    state.conquest.inherited.collection.push(...payload.collection)
    state.conquest.inherited.count = payload.count
  },
  SET_CONQUEST_INHERITED_LOADING: (state: CampaignListState, payload: boolean) => state.conquest.inherited.loading = payload,

  SET_CONQUEST_TRADE_MARKETING: (state: CampaignListState, payload: any) => {
    state.conquest.tradeMarketing.collection.push(...payload.collection)
    state.conquest.tradeMarketing.count = payload.count
  },
  SET_CONQUEST_TRADE_MARKETING_LOADING: (state: CampaignListState, payload: boolean) => state.conquest.tradeMarketing.loading = payload,

  SET_CONQUEST_PUBLISHING: (state: CampaignListState, payload: any) => {
    state.conquest.publishing.collection.push(...payload.collection)
    state.conquest.publishing.count = payload.count
  },
  SET_CONQUEST_PUBLISHING_LOADING: (state: CampaignListState, payload: boolean) => state.conquest.publishing.loading = payload
}

export default mutations
