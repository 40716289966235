<template>
  <v-container fluid grid-list-xl>
    <v-layout class="grid-simple-campaign" v-if="!loading">
      <v-flex>
        <div class="form-group" :class="{ 'form-group--error': $v.$error }">
          <v-card class="pa-3 default-heigth-com" style="position: relative">
            <v-card-title>
              <span class="title">{{ $t('createCampaign.generalInformations') }}</span>
            </v-card-title>
            <v-divider class="mb-2"></v-divider>
            <v-flex>
              <v-text-field
                v-model="campaign.name"
                :rules="nameRules"
                :label="
                  $t('createCampaign.campaignSettings.inputCampaignName.label')
                "
              />
            </v-flex>
            <v-flex>
              <DatePicker
                :initialValue="campaign.start_at"
                :label="$t('common.startAt')"
                :min="this.$moment().add(1, 'days').format('YYYY-MM-DD')"
                @update="campaign.start_at = $event"
              />
            </v-flex>
            <v-flex class="pb-4">
              <PointOfSalesMultipleSelect
                v-model="getPOS"
                :validation="$v.campaign.contracts"
              ></PointOfSalesMultipleSelect>
            </v-flex>
            <v-flex>
              <label class="font-weight-medium">Option d'envoi :</label>
              <div class="disp-flex">
                <v-radio-group v-model="publishing" class="justify-center" row>
                  <v-radio value="email_published" label="Email" />
                  <v-radio value="sms_published" label="SMS" />
                </v-radio-group>
              </div>
            </v-flex>
            <v-flex v-if="publishing === 'email_published'">
              <v-btn-toggle :value="isHtmlTemplate" @change="setTemplateComponents($event)" mandatory class="btn-group w-100 elevation-0">
                <v-btn small flat :value="false" class="grow" active-class="btn-selected">
                  Texte
                </v-btn>
                <v-btn small flat :value="true" class="grow" active-class="btn-selected">
                  Html
                </v-btn>
              </v-btn-toggle>
            </v-flex>
            <v-flex class="mb-4">
              <SegmentFilterSelection @updateSegments="updateSegment($event)" :no-store="true" :segment="this.campaign.segment"/>
            </v-flex>
            <div class="text-xs-center">
              <v-btn color="primary" @click="submit()">
                {{ editMode ? 'Mettre à jour' : 'Créer campagne' }}
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-flex>
      <v-flex>
        <div class="elevation-2" v-if="tabs.length > 0" style="background-color: #ececec">
          <v-card class="sub-border-card" elevation="0">
            <v-tabs height="70px" grow>
              <v-tabs-slider color="#3f51b5"></v-tabs-slider>
              <v-tab>
                {{ publishing === 'email_published' ? 'E-mail' : 'SMS' }}
              </v-tab>
            </v-tabs>
          </v-card>
          <div class="pa-3">
            <EventTemplating
              v-if="publishing === 'email_published' && campaign.templates.length > 0"
              :template="emailTemplate"
            />
            <SMSTemplate v-else-if="campaign.templates.length > 0" :template="smsTemplate" />
          </div>
        </div>
        <div v-else class="elevation-2 pa-5 text-xs-center">
          <v-icon style="font-size: 50px" color="red">report_problem</v-icon>
          <h3 class="subheading">
            Vous n'avez sélectionné aucune option d'envoi.
          </h3>
        </div>
      </v-flex>
    </v-layout>
    <DialogSaveCampaignDraft :edit-mode="editMode" :dialog="showDialog" @close="closeDialog()" @discard="discardChanges()" @save="saveChanges()" />
  </v-container>
</template>

<script>
import req from '@/packs/request'
import EventTemplating from '@/components/CampaignForm/Templating/EventTemplating'
import SegmentFilterSelection from '@/components/SegmentFilterSelection'
import SMSTemplate from '../components/SMSTemplate'
import PointOfSalesMultipleSelect from '../components/PointOfSalesMultipleSelect'
import SubFidelityService from '@/services/SubFidelityService'
import DialogSaveCampaignDraft from '@/components/DialogSaveCampaignDraft'
import DatePicker from '@/components/DatePicker'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'
import EventBus from '@/packs/event-bus'

export default {
  components: {
    EventTemplating,
    SegmentFilterSelection,
    SMSTemplate,
    PointOfSalesMultipleSelect,
    DialogSaveCampaignDraft,
    DatePicker
  },
  data() {
    return {
      campaign: {},
      showDialog: false,
      to: null,
      allowChangeRoute: false,
      submitStatus: null,
      startDateMenu: false,
      nameRules: [
        v =>
          !!v ||
          this.$t('createCampaign.campaignSettings.inputCampaignName.required'),
        v =>
          (v && v.length >= 3) ||
          this.$t(
            'createCampaign.campaignSettings.inputCampaignName.characters'
          )
      ],
      tab: null,
      tabs: ['E-mail'],
      loading: Boolean,
      participants: {},
      emailBaseTemplate: {}
    }
  },
  validations: {
    campaign: {
      start_at: {
        required
      },
      publishing: {
        required
      },
      contracts: {
        required
      }
    }
  },
  created() {
    this.fetchData()
  },
  beforeRouteLeave(to, from, next) {
    let shouldNext = false

    if (this.campaign.status !== 'draft' || to.path === '/signin') this.allowChangeRoute = true

    if (!this.allowChangeRoute) {
      if (this.to) shouldNext = true
      else {
        this.to = to
        this.showDialog = true
      }
    } else shouldNext = true

    if (shouldNext) {
      if (to?.params?.company) EventBus.$emit('refreshCompany', to.params.company)
      next()
    }
  },
  methods: {
    async submit() {
      if (this.campaign.segment && this.campaign.segment.id === null) this.campaign.segment = null

      this.$v.$touch()

      if (!this.$v.$invalid) {
        try {
          await SubFidelityService.update(this.campaign.id, { campaign: this.campaign })

          if (this.campaign.status === 'draft') await SubFidelityService.persist(this.campaign.id)
          this.allowChangeRoute = true
          this.$router.push({ path: '/core-program/campaigns?tabIndex=1' }).catch(err => err)
        } catch (e) {
          this.allowChangeRoute = false
        }
      }
    },
    closeDialog() {
      this.showDialog = false
      this.to = null
    },
    saveChanges() {
      this.showDialog = false
      this.allowChangeRoute = true
      if (this.to) this.$router.push(this.to)
      else this.$router.go(-1)
    },
    async discardChanges() {
      this.showDialog = false
      this.allowChangeRoute = true
      if (!this.editMode) await SubFidelityService.destroy(this.campaign.id)
      if (this.to) this.$router.push(this.to).catch(err => err)
      else this.$router.go(-1)
    },
    updateSegment(segment) {
      this.campaign.segment = segment
    },
    hasSegment() {
      if (!(this.campaign && this.campaign.segment && this.campaign.segment.id)) this.campaign.segment = { id: null }
    },
    async fetchData() {
      this.loading = true

      let res
      if (this.editMode) res = await SubFidelityService.get(this.$route.params.id)
      else res = await SubFidelityService.getDraft({ kind: 'communication' })
      this.campaign = res.data

      await this.fetchTemplates()

      this.loading = false
    },
    async fetchTemplates() {
      const emailTemplate = await req.get(`/email/templates?by_kind=communication&by_default=true`)
      const smsTemplate = await req.get(`/email/templates?by_kind=sms&by_default=true`)

      if (!this.editMode) this.campaign.templates.push(emailTemplate.data[0], smsTemplate.data[0])
      this.emailBaseTemplate = cloneDeep(emailTemplate.data[0])
    },
    setTemplateComponents(isHtml) {
      if (!isHtml) {
        if (this.emailTemplate.components.length > 2) return

        this.emailTemplate.components = []
        this.emailTemplate.components.push(...this.emailBaseTemplate.components)
        this.emailTemplate.components[1].label = 'Titre'
        this.emailTemplate.components[1].kind = 'title'
      } else {
        this.emailTemplate.components.splice(-3)
        this.emailTemplate.components[1].label = 'Html'
        this.emailTemplate.components[1].kind = 'html'
      }
    }
  },
  computed: {
    isHtmlTemplate() {
      return this.emailTemplate.components.find(e => e.kind === 'html') !== undefined
    },
    emailTemplate() {
      return this.campaign.templates?.find(e => e.kind === 'communication')
    },
    smsTemplate() {
      return this.campaign.templates?.find(e => e.kind === 'sms')
    },
    publishing: {
      get() {
        return this.campaign.publishing[0]
      },
      set(value) {
        this.campaign.publishing.splice(0, 1, value)
      }
    },
    emailPublishing: {
      get() {
        return this.campaign.publishing.includes('email_published')
      },
      set(value) {
        if (value) {
          this.tabs.push('E-mail')
          this.$v.campaign.publishing.$model.push('email_published')
        } else {
          this.tabs.splice(this.tabs.indexOf('E-mail'), 1)
          this.$v.campaign.publishing.$model = this.campaign.publishing.filter(i => i !== 'email_published')
        }
      }
    },
    smsPublishing: {
      get() {
        return this.campaign.publishing.includes('sms_published')
      },
      set(value) {
        if (value) {
          this.tabs.push('sms')
          this.$v.campaign.publishing.$model.push('sms_published')
        } else {
          this.tabs.splice(this.tabs.indexOf('sms'), 1)
          this.$v.campaign.publishing.$model = this.campaign.publishing.filter(i => i !== 'sms_published')
        }
      }
    },
    editMode() {
      return !(this.$route.params.id === 'new')
    },
    computedStartAt() {
      if (this.campaign.start_at) return this.campaign.start_at.split(' ')[0] || ''
      else return ''
    },
    getPOS: {
      get() {
        return this.campaign.contracts.map(e => e.point_of_sale_id)
      },
      set(value) {
        this.$v.campaign.contracts.$model = value.map(e => ({
          point_of_sale_id: e?.id || e
        }))
      }
    },
    dateErrors() {
      const errors = []
      if (!this.$v.campaign.start_at.$dirty) return errors
      !this.$v.campaign.start_at.required && errors.push(this.$t('createCampaign.error.startAT'))
      return errors
    },
    publishingErrors() {
      const errors = []
      if (!this.$v.campaign.publishing.$dirty) return errors
      !this.$v.campaign.publishing.required && errors.push(this.$t('createCampaign.error.publishing'))
      return errors
    }
  }
}
</script>

<style scoped lang="sass">
@import '@/stylesheets/campaignCustom'
</style>
