import AxiosWrapper from '@/services/AxiosWrapper'

const END_POINT = '/core_program/campaigns'

const getWelcome = () => AxiosWrapper.get(`${END_POINT}?by_kind=welcome`)
const getAnniversary = () => AxiosWrapper.get(`${END_POINT}?by_kind=event`)
const getCommunications = (params: any) => AxiosWrapper.get(`${END_POINT}?by_kind=communication`, params)
const getCustoms = (params: any) => AxiosWrapper.get(`${END_POINT}?by_kind=permanent,limited`, params)
const getDrafted = (params: any) => AxiosWrapper.get(`${END_POINT}/drafted`, params)
const getDraft = (params: any) => AxiosWrapper.post(`${END_POINT}/draft`, params)
const createConsumable = (id: string, params: any) => AxiosWrapper.post(`${END_POINT}/${id}/consumables`, params)
const updateConsumable = (id: string, consumableId: string, params: any) => AxiosWrapper.put(`${END_POINT}/${id}/consumables/${consumableId}`, params)
const deleteConsumable = (id: string, consumableId: string) => AxiosWrapper.delete(`${END_POINT}/${id}/consumables/${consumableId}`)
const createTrigger = (id: string, rewardId: string, params: any) => AxiosWrapper.post(`${END_POINT}/${id}/consumables/${rewardId}/triggers`, params)
const updateTriggerConsumable = (id: string, consumableId: string, triggerId: string, params: any) => AxiosWrapper.put(`${END_POINT}/${id}/consumables/${consumableId}/triggers/${triggerId}`, params)
const deleteTrigger = (id: string, consumableId: string, triggerId: string) => AxiosWrapper.delete(`${END_POINT}/${id}/consumables/${consumableId}/triggers/${triggerId}`)
const destroy = (id: string) => AxiosWrapper.delete(`${END_POINT}/${id}`)
const activate = (id: string) => AxiosWrapper.put(`${END_POINT}/${id}/activate`)
const deactivate = (id: string) => AxiosWrapper.put(`${END_POINT}/${id}/deactivate`)
const persist = (id: string) => AxiosWrapper.put(`${END_POINT}/${id}/persist`)
const update = (id: string, params: any) => AxiosWrapper.put(`${END_POINT}/${id}`, params)
const get = (id: string) => AxiosWrapper.get(`${END_POINT}/${id}`)
const getAvailableCustomers = (id: string) => AxiosWrapper.get(`${END_POINT}/${id}/available_customers`)
const getBalanceSplits = (id: string) => AxiosWrapper.get(`${END_POINT}/${id}/balance_splits`)
const allocateBalanceSplits = (id: string, params: any) => AxiosWrapper.post(`${END_POINT}/${id}/balance_splits/allocate`, params)
const freeBalanceSplits = (id: string, params: any) => AxiosWrapper.post(`${END_POINT}/${id}/balance_splits/free`, params)

export default {
  getWelcome,
  getAnniversary,
  getCommunications,
  getCustoms,
  getDrafted,
  getDraft,
  updateConsumable,
  updateTriggerConsumable,
  createConsumable,
  createTrigger,
  destroy,
  activate,
  deactivate,
  persist,
  update,
  get,
  deleteConsumable,
  deleteTrigger,
  getAvailableCustomers,
  getBalanceSplits,
  allocateBalanceSplits,
  freeBalanceSplits
}
