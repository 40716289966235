<template>
  <div class="h-100 w-100 elevation-2">
    <div class="header-card header-btn">
      <h3 class="font-weight-medium">
        <v-icon small class="align-icon">table_chart</v-icon>
        {{ $t('customers.rfmCrm') }}
      </h3>
      <v-btn
        dense
        small
        color="primary"
        class="ma-0"
        @click="newCampaign()"
        :disabled="Object.keys(rfm).length === 0"
      >
        {{ $t('customers.createCampaign') }}
      </v-btn>
    </div>
    <v-container fluid class="rfm-analitic pb-0">
      <v-layout row class="no-gutters">
        <v-flex xs6>
          <v-layout row class="no-gutters">
            <v-flex xs6 v-if="returnOneSegmentRFM('Not to lose')">
              <v-tooltip top>
                <v-card
                  slot="activator"
                  dark
                  tile
                  flat
                  color="red lighten-4"
                  :class="{ selected: notToLoseSegment.id === this.rfm.id }"
                  @click="selectSegment(notToLoseSegment)"
                >
                  <v-card-text class="no-break">
                    {{ $t('customers.filters.not_to_lose') }} ({{
                      percentageBase(
                        notToLoseSegment.statistics.customers_count
                      )
                    }}%)
                  </v-card-text>
                </v-card>
                <CustomersSegmentsFiltersTooltip
                  :rfm="notToLoseSegment"
                  :info="$t('customers.tooltip.notLose')"
                  :total="totalCustomers"
                ></CustomersSegmentsFiltersTooltip>
              </v-tooltip>
            </v-flex>
            <v-flex xs6 v-if="returnOneSegmentRFM('Below average')">
              <v-tooltip top>
                <v-card
                  slot="activator"
                  dark
                  tile
                  flat
                  color="yellow lighten-4"
                  :class="{ selected: belowAverageSegment.id === this.rfm.id }"
                  @click="selectSegment(belowAverageSegment)"
                >
                  <v-card-text class="no-break">
                    {{ $t('customers.filters.below_average') }} ({{
                      percentageBase(
                        belowAverageSegment.statistics.customers_count
                      )
                    }}%)
                  </v-card-text>
                </v-card>
                <CustomersSegmentsFiltersTooltip
                  :rfm="belowAverageSegment"
                  :info="$t('customers.tooltip.belowAverage')"
                  :total="totalCustomers"
                ></CustomersSegmentsFiltersTooltip>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout row class="no-gutters">
            <v-flex v-if="returnOneSegmentRFM('Loyal')" xs12 class="relative">
              <v-tooltip top>
                <v-card
                  slot="activator"
                  dark
                  tile
                  flat
                  color="green lighten-4"
                  :class="{ selected: loyalSegment.id === this.rfm.id }"
                  @click="selectSegment(loyalSegment)"
                >
                  <v-card-text class="text-no-wrap">
                    {{ $t('customers.filters.loyal') }} ({{
                      percentageBase(loyalSegment.statistics.customers_count)
                    }}%)
                  </v-card-text>
                </v-card>
                <CustomersSegmentsFiltersTooltip
                  :rfm="loyalSegment"
                  :info="$t('customers.tooltip.loyal')"
                  :total="totalCustomers"
                ></CustomersSegmentsFiltersTooltip>
              </v-tooltip>
              <v-flex class="champions">
                <v-layout
                  v-if="returnOneSegmentRFM('Champions')"
                  column
                  my-0
                  mx-0
                  class="h-100"
                >
                  <v-tooltip top>
                    <v-card
                      slot="activator"
                      dark
                      tile
                      flat
                      color="green lighten-2"
                      :class="{ selected: championsSegment.id === this.rfm.id }"
                      @click="selectSegment(championsSegment)"
                    >
                      <v-card-text class="text-no-wrap">
                        {{ $t('customers.filters.champions') }} ({{
                          percentageBase(
                            championsSegment.statistics.customers_count
                          )
                        }}%)
                      </v-card-text>
                    </v-card>
                    <CustomersSegmentsFiltersTooltip
                      :rfm="championsSegment"
                      :total="totalCustomers"
                    ></CustomersSegmentsFiltersTooltip>
                  </v-tooltip>
                </v-layout>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row class="no-gutters">
        <v-flex xs6>
          <v-layout row class="no-gutters">
            <v-flex v-if="returnOneSegmentRFM('Hibernating')" xs12>
              <v-tooltip top>
                <v-card
                  slot="activator"
                  dark
                  tile
                  flat
                  color="blue-grey lighten-5"
                  :class="{ selected: hibernatingSegment.id === this.rfm.id }"
                  @click="selectSegment(hibernatingSegment)"
                >
                  <v-card-text class="no-break">
                    {{ $t('customers.filters.hibernating') }} ({{
                      percentageBase(
                        hibernatingSegment.statistics.customers_count
                      )
                    }}%)
                  </v-card-text>
                </v-card>
                <CustomersSegmentsFiltersTooltip
                  :rfm="hibernatingSegment"
                  :info="$t('customers.filters.below_average')"
                  :total="totalCustomers"
                ></CustomersSegmentsFiltersTooltip>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout row class="no-gutters">
            <v-flex
              v-if="returnOneSegmentRFM('Large and unfrequent spenders')"
              xs12
              class="relative"
            >
              <v-tooltip top>
                <v-card
                  slot="activator"
                  dark
                  tile
                  flat
                  color="purple lighten-4"
                  :class="{
                    selected: largeUnfrequentSegment.id === this.rfm.id,
                  }"
                  @click="selectSegment(largeUnfrequentSegment)"
                >
                  <v-card-text class="no-break">
                    {{ $t('customers.filters.large_and_unfrequent_spenders') }}
                    ({{
                      percentageBase(
                        largeUnfrequentSegment.statistics.customers_count
                      )
                    }}%)
                  </v-card-text>
                </v-card>
                <CustomersSegmentsFiltersTooltip
                  :rfm="largeUnfrequentSegment"
                  :info="$t('customers.tooltip.UnfrequentSegment')"
                  :total="totalCustomers"
                ></CustomersSegmentsFiltersTooltip>
              </v-tooltip>
              <v-flex class="new-customers">
                <v-layout mx-0 my-0 column class="h-100">
                  <v-flex v-if="returnOneSegmentRFM('New')" xs6>
                    <v-tooltip top>
                      <v-card
                        slot="activator"
                        dark
                        tile
                        flat
                        color="blue lighten-4"
                        :class="{
                          selected: newCustomersSegment.id === this.rfm.id,
                        }"
                        @click="selectSegment(newCustomersSegment)"
                      >
                        <v-card-text class="text-no-wrap">
                          {{ $t('customers.filters.new') }} ({{
                            percentageBase(
                              newCustomersSegment.statistics.customers_count
                            )
                          }}%)
                        </v-card-text>
                      </v-card>
                      <CustomersSegmentsFiltersTooltip
                        :rfm="newCustomersSegment"
                        :info="$t('customers.tooltip.new')"
                        :total="totalCustomers"
                      ></CustomersSegmentsFiltersTooltip>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import CustomersSegmentsFiltersTooltip from './CustomersSegmentsFilterRFMTooltip'
import eventBus from '@/packs/event-bus'

export default {
  components: {
    CustomersSegmentsFiltersTooltip,
  },
  props: {
    segmentsRFM: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {
      rfm: Object,
      totalCustomers: 0,
    }
  },
  computed: {
    championsSegment() {
      return this.returnOneSegmentRFM('Champions')
    },
    notToLoseSegment() {
      return this.returnOneSegmentRFM('Not to lose')
    },
    belowAverageSegment() {
      return this.returnOneSegmentRFM('Below average')
    },
    hibernatingSegment() {
      return this.returnOneSegmentRFM('Hibernating')
    },
    largeUnfrequentSegment() {
      return this.returnOneSegmentRFM('Large and unfrequent spenders')
    },
    loyalSegment() {
      return this.returnOneSegmentRFM('Loyal')
    },
    newCustomersSegment() {
      return this.returnOneSegmentRFM('New')
    },
  },
  methods: {
    returnOneSegmentRFM(name) {
      return this.segmentsRFM.find(e => e.name === name)
    },
    percentageBase(val) {
      if (this.totalCustomers > 0) {
        const calc = (val / this.totalCustomers) * 100
        if (calc <= 100) return Math.round(calc * 10) / 10
        else return '-'
      } else return 0
    },
    selectSegment(rfm) {
      if (this.rfm.id === rfm.id) this.rfm = {}
      else this.rfm = rfm
      eventBus.$emit('manualSelectRFM', this.rfm)
    },
    newCampaign() {
      this.$router.push({ path: 'campaign/custom/new?segment=' + this.rfm.id })
    },
  },
  created() {
    eventBus.$on('getTotalCustomers', value => {
      this.totalCustomers = value
    })
  },
}
</script>

<style scoped lang="css">
.header-card {
  background: #eceff1;
  border-bottom: 1px solid #cfd8dc;
  color: #37474f;
}

.align-icon {
  font-size: 16px;
  vertical-align: middle;
  padding-bottom: 4px;
}

.header-btn {
  display: flex;
  justify-content: space-between;
  padding: 14px !important;
}
</style>
