<template>
  <div>
    <v-btn flat @click.stop="onClick()" style="min-width: 120px" class="text-capitalize ma-0 h-100">
      <v-icon class="material-icons-round mr-2" style="font-size: 20px" :style="{ color: campaign.status === 'running' ? '#ff7285' : '#4ad991' }">
        {{ campaign.status === 'running' ? 'pause_circle' : 'not_started' }}
      </v-icon>
      <span :style="{ color: colorBtn }">{{ campaign.status === 'running' ? 'Pause' : 'Démarrer' }}</span>
    </v-btn>
  </div>
</template>

<script>
import req from '@/packs/request.js'

export default {
  props: {
    campaign: {
      type: Object,
      required: true
    },
    colorBtn: {
      type: String,
      required: false,
      default: 'black'
    }
  },
  methods: {
    async onClick() {
      const res = await (this.campaign.status === 'running' ? this.stop() : this.start())

      this.$emit('update', res.data.status)
    },
    start() {
      return req.put(`${this.baseUrl()}/start`)
    },
    stop() {
      return req.put(`${this.baseUrl()}/stop`)
    },
    baseUrl() {
      return this.campaign.type === 'fidelity' ? '/core_program' : `/core_program/campaigns/${this.campaign.id}`
    }
  }
}
</script>
