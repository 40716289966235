import CampaignBuilderState from '@/models/states/CampaignBuilderState'

const getters = {
  hasAward: (state: CampaignBuilderState) => (index: number) => {
    return state.lines[index].actions.find((e: any) => e.card_type === 'award')
  },
  hasReward: (state: CampaignBuilderState) => {
    for (const line of state.lines)
      if (line.actions.find((e: any) => e.card_type === 'reward' || e.card_type === 'pool')) return true
    return false
  },
  hasRewardVoucher: (state: CampaignBuilderState) => {
    for (const line of state.lines)
      if (line.actions.find((e: any) => e.voucher === true)) return true
    return false
  },
  hasTrigger: (state: CampaignBuilderState) => (li = -1) => {
    li = li === -1 ? state.li : li
    return state.lines[li].actions.find((e: any) => e.card_type === 'trigger')
  },
  getFirstReward: (state: CampaignBuilderState) => {
    for (const line of state.lines)
      return line.actions.find((e: any) => e.card_type === 'reward' || e.card_type === 'pool')
  },
  isAwardTrigger: (state: CampaignBuilderState) => {
    return (state.lines[state.li].actions[state.ai].card_type === 'trigger' &&
      state.lines[state.li].actions[state.ai + 1]?.card_type === 'award')
  },
  hasAlreadyTriggerCustom: (state: CampaignBuilderState) => (index: number, action: any) => {
    const actionLength = state.lines[index].actions?.length
    return (actionLength > 0 && state.lines[index].actions.indexOf(action))
  },
  editMode: (state: CampaignBuilderState) => () => {
    return state.editMode
  },
  viewMode: (state: CampaignBuilderState) => {
    return state.campaign.status === 'running' || state.campaign.status === 'finished'
  },
  hasSegment: (state: CampaignBuilderState) => {
    return !!state.campaign.segment
  }
}

export default getters
