<template>
  <div style="max-width: 850px" class="ml-auto mr-auto mt-4 mb-5">
    <label class="font-weight-medium subheading align-self-center">Définissez l'unité de fidélité de votre programme</label>
    <hr class="mt-3 mb-5" style="border-color: #ffffff52"/>
    <v-layout wrap row>
      <v-flex xs12 class="d-flex">
        <div style="width: 33%">
          <label class="font-weight-medium">{{ $t('CoreProgramCampaignSettingsCurrency.loyaltyCurrency') }} :</label><br><br>
          <label class="body-1">
            {{ $t('CoreProgramCampaignSettingsCurrency.chooseLoyaltyCurrency') }}
          </label>
        </div>
        <div style="width: 66%" class="pl-5">
          <v-radio-group hide-details class="mt-0" v-model="currencyName_">
            <v-radio label="Points" value="Points"></v-radio>
            <v-radio label="Euros" value="Euros"></v-radio>
            <v-radio label="Miles" value="Miles"></v-radio>
            <v-radio :label="$t('CoreProgramCampaignSettingsCurrency.other')" value="Other"></v-radio>
          </v-radio-group>
          <transition name="slide-y-transition" v-if="currencyName === 'Other'">
            <div class="mt-3 pt-2 pb-3 px-3 input-details-radio">
              <label class="font-weight-medium">{{$t('CoreProgramCampaignSettingsCurrency.customLoyaltyCurrency')}} :</label>
              <v-text-field class="mt-0 pt-0" v-model="currencyOther_" :placeholder="$t('CoreProgramCampaignSettingsCurrency.loyaltyCurrencyName')" hide-details></v-text-field>
            </div>
          </transition>
        </div>
      </v-flex>

      <v-flex xs12 class="mt-5 d-flex">
        <div style="width: 33%">
          <label class="font-weight-medium">{{$t('CoreProgramCampaignSettingsCurrency.expirePolicy')}} :</label><br><br>
        </div>
        <div style="width: 66%" class="pl-5">
          <v-radio-group hide-details class="mt-0" v-model="expirationMode_">
            <v-radio :label="$t('CoreProgramCampaignSettingsCurrency.neverExpire')" value="permanent"></v-radio>
            <v-radio :label="$t('CoreProgramCampaignSettingsCurrency.staticExpire')" value="static"></v-radio>
            <v-radio :label="$t('CoreProgramCampaignSettingsCurrency.monthlyExpire')" value="monthly"></v-radio>
            <v-radio :label="$t('CoreProgramCampaignSettingsCurrency.inactivityExpire')" value="inactivity"></v-radio>
            <v-radio :label="$t('CoreProgramCampaignSettingsCurrency.calendarYearExpire')" value="calendar_year"></v-radio>
          </v-radio-group>
          <transition name="slide-y-transition">
            <div v-if="expirationMode === 'static'" class="mt-3 pt-2 pb-3 px-3 input-details-radio">
              <label class="font-weight-medium">{{$t('CoreProgramCampaignSettingsCurrency.expirationDate')}} :</label>
              <v-menu ref="expirationDate" class="mt-0 pt-0" :close-on-content-click="true" v-model="expirationDate" :nudge-right="40" :return-value.sync="expirationDate" lazy transition="scale-transition" offset-y full-width>
                <v-text-field slot="activator" v-model="expirationAt" prepend-icon="event" readonly class="mt-0 pt-0" placeholder="Date" hide-details></v-text-field>
                <v-date-picker v-model="expirationValue_" @input="$refs.expirationDate.save(expirationValue_)" :min="$moment().add(1,'days').format('YYYY-MM-DD')"></v-date-picker>
              </v-menu>
            </div>
            <div v-else-if="expirationMode === 'monthly'" class="mt-3 pt-2 pb-3 px-3 input-details-radio">
              <label class="font-weight-medium">{{$t('CoreProgramCampaignSettingsCurrency.consecutiveMonths')}} :</label>
              <v-text-field type="number" class="mt-0 pt-0" v-model="expirationValue_" :placeholder="$t('CoreProgramCampaignSettingsCurrency.numberOfMonths')" hide-details></v-text-field>
            </div>
            <div v-else-if="expirationMode === 'inactivity'" class="mt-3 pt-2 pb-3 px-3 input-details-radio">
              <label class="font-weight-medium">{{$t('CoreProgramCampaignSettingsCurrency.inactivityMonths')}} :</label>
              <v-text-field type="number" class="mt-0 pt-0" v-model="expirationValue_" :placeholder="$t('CoreProgramCampaignSettingsCurrency.numberOfMonths')" hide-details></v-text-field>
            </div>
          </transition>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      expirationDate: null
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setLoyaltyCurrencyName',
      'setLoyaltyCurrencyOther',
      'setLoyaltyCurrencyExpirationValue',
      'setLoyaltyCurrencyExpirationMode'
    ])
  },
  computed: {
    ...mapState({
      currencyName: state => state.campaignBuilder.campaign.loyalty_currency.name,
      currencyOther: state => state.campaignBuilder.campaign.loyalty_currency.other,
      expirationMode: state => state.campaignBuilder.campaign.loyalty_currency.expiration_mode,
      expirationValue: state => state.campaignBuilder.campaign.loyalty_currency.expiration_value,
      expirationOther: state => state.campaignBuilder.campaign.loyalty_currency.other,

    }),
    currencyName_: {
      get() {
        return this.currencyName
      },
      set(value) {
        if (value !== 'Other') this.setLoyaltyCurrencyOther(null)
        this.setLoyaltyCurrencyName(value)
      }
    },
    currencyOther_: {
      get() {
        return this.currencyOther
      },
      set(value) {
        this.setLoyaltyCurrencyOther(value)
      }
    },
    expirationValue_: {
      get() {
        return this.expirationValue
      },
      set(value) {
        this.setLoyaltyCurrencyExpirationValue(value)
      }
    },
    expirationMode_: {
      get() {
        return this.expirationMode
      },
      set(value) {
        if (this.expirationValue) this.setLoyaltyCurrencyExpirationValue(null)
        this.setLoyaltyCurrencyExpirationMode(value)
      }
    },
    expirationAt() {
      if (this.expirationValue) return this.expirationValue.split(' ')[0] || ''
      else return ''
    }
  }
}
</script>

<style scoped lang="sass">
@import "../stylesheets/coreProgram"
</style>
