var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"max-width":"900"},on:{"input":function($event){return _vm.$emit('input', false)}}},[_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{attrs:{"xs2":"","my-3":""}},[_c('h2',{staticClass:"title mt-1"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("store")]),_vm._v(_vm._s(_vm.$t('campaignListItem.seeParticipatingPOS'))+" ")],1)]),_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.contracts,"loading":_vm.loading,"no-data-text":_vm.loading ? _vm.$t('common.loading') : _vm.$t('common.noData'),"rows-per-page-text":_vm.$t('common.rowsPerPage')},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.point_of_sale.name))]),_c('td',[_vm._v(_vm._s(props.item.point_of_sale.siret))]),_c('td',[_vm._v(_vm._s(props.item.point_of_sale.code))]),_c('td',[_vm._v(" "+_vm._s(props.item.point_of_sale.location ? props.item.point_of_sale.location.line_1 : '-')+" ")]),_c('td',[(props.item.mine && !_vm.isBroadcasterCampaign)?_c('v-switch',{attrs:{"true-value":"accepted","false-value":"refused","height":"10","hide-details":"","color":props.item.status == 'accepted'
                      ? 'green'
                      : props.item.status == 'pending'
                      ? 'yellow darken-3'
                      : 'red',"label":_vm.$t(("status." + (props.item.status)))},on:{"click":function($event){$event.stopPropagation();return _vm.changePosStatus(props)}},model:{value:(props.item.status),callback:function ($$v) {_vm.$set(props.item, "status", $$v)},expression:"props.item.status"}}):_c('v-chip',{attrs:{"color":props.item.status == 'accepted'
                      ? 'green'
                      : props.item.status == 'pending'
                      ? 'yellow darken-3'
                      : 'red',"small":"","disabled":"","outline":""}},[_c('v-avatar',[_c('v-icon',[_vm._v(_vm._s(_vm.returnIcon(props.item.status)))])],1),_vm._v(" "+_vm._s(_vm.$t(("status." + (props.item.status))))+" ")],1)],1)]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" "+_vm._s(_vm.$t('pointsOfSale.close'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }