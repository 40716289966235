<template>
  <v-card class="px-4 py-4 tile-dashboard">
    <v-layout row>
      <v-flex grow>
        <div class="flex-div flex-column">
          <h3 class="title-info text-uppercase font-weight-regular mb-3">
            {{ stats.title }}
          </h3>
          <div class="flex-div">
            <animated-number
              :value="stats.data.value"
              :duration="500"
              class="info-content title font-weight-black"
              :formatValue="stats.type === 'price' ? formatPrice : formatValue"
            />
            <span
              :class="defineClass(stats.data.trend)"
              class="text--darken-1 font-weight-bold ml-2"
              >(
              <v-icon
                v-if="isPositive(stats.data.trend)"
                small
                class="arrow-icon green--text text--darken-1"
              >
                arrow_upward
              </v-icon>
              <v-icon v-else small class="arrow-icon red--text text--darken-1">
                arrow_downward
              </v-icon>
              {{ stats.data.trend }}%)
            </span>
          </div>
        </div>
      </v-flex>
      <v-flex shrink>
        <div
          :class="stats.css"
          class="icon-shape accent-4 flex-div align-center justify-center"
        >
          <v-icon dark>{{ stats.icon }}</v-icon>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'

export default {
  components: {
    AnimatedNumber,
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isPositive(value) {
      if (value >= 0) return true
      else return false
    },
    formatValue(value) {
      const number = `${Number(value).toFixed(0)}`
      const format = Intl.NumberFormat('fr-FR').format(number)
      return format
    },
    formatPrice(value) {
      return Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(value)
    },
    defineClass(value) {
      if (this.isPositive(value)) return 'green--text'
      else return 'red--text'
    },
  },
}
</script>
