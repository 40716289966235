<template>
  <v-menu open-on-hover top offset-y>
    <template v-slot:activator="{ on }">
      <div class="mt-0 mr-0 caption cursor-pointer" v-on="on">
        <v-icon small>emoji_events</v-icon>
        Voir toutes les récompenses
      </div>
    </template>
    <div class="tooltip-reward">
      <CampaignRewardMessage v-for="(reward, index) in rewards" :reward="reward" :index="index" :key="reward.id" icon-color="white" />
    </div>
  </v-menu>
</template>

<script>
import CampaignRewardMessage from '@/components/CampaignRewardMessage'

export default {
  components: {
    CampaignRewardMessage
  },
  props: {
    rewards: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="css">
.tooltip-reward {
  font-size: 15px;
  color: white;
  background: #616161;
  border-radius: 3px;
  padding: 12px;
}
</style>
