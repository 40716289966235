<template>
  <v-autocomplete
    clearable
    v-model="category"
    :items="categories"
    single-line
    :search-input.sync="search"
    item-text="name"
    class="ml-4"
    return-object
    label="Catégorie"
  >
    <template v-slot:no-data>
      <v-list-tile>
        <v-list-tile-title> Rechercher une catégorie </v-list-tile-title>
      </v-list-tile>
    </template>
  </v-autocomplete>
</template>

<script>
import req from '@/packs/request'
import EventBus from '@/packs/event-bus'

export default {
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      categories: [],
      category: null,
      search: null
    }
  },
  watch: {
    category: {
      handler() {
        this.$emit('category', this.category)
      }
    },
    search: {
      handler(val) {
        if (val && val.length > 0) {
          clearTimeout(this.debounceId)
          this.debounceId = setTimeout(() => {
            this.fetch()
          }, 500)
        } else {
          this.categories = []
        }
      }
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const byName = this.search && this.search.length > 0 ? `&by_name=${this.search}` : ''
      const res = await req.get(
        `/targets/categories?page=${this.pagination.page}&per_page=${this.pagination.rowsPerPage}${byName}`
      )
      this.categories = res.data.collection
      this.loading = false
    }
  }
}
</script>