<template>
  <v-card class="pa-3 w-100" style="min-height: calc(100vh - 350px)">
    <v-card-title class="mb-2">
      <div class="text-xs-center headline w-100 mb-2">
        Assistant de création de campagne
      </div>
      <div class="text-xs-center font-weight-light title w-100">
        {{ $t('createCampaign.generalInformations') }}
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <div style="max-width: 600px" class="ml-auto mr-auto mt-5 mb-3">
      <div class="mt-4">
        <v-text-field
          v-model="name"
          :rules="nameRules"
          :label="$t('createCampaign.campaignSettings.inputCampaignName.label')"
        />
      </div>
      <div class="mt-4 disp-flex">
        <label class="font-weight-medium subheading mr-4">{{ $t('createCampaign.campaignSettings.campaignType') }}</label>
        <v-radio-group :value="campaign.kind" @change="setKind($event)" class="kind-radio-group" row hide-details>
          <v-radio :label="$t('createCampaign.campaignSettings.permanent')" value="permanent" />
          <v-radio :label="$t('createCampaign.campaignSettings.limited')" value="limited" />
        </v-radio-group>
      </div>
      <div class="mt-4 disp-flex">
        <DatePicker
          :initialValue="campaign.start_at"
          :label="$t('common.startAt')"
          :min="this.$moment().add(1, 'days').format('YYYY-MM-DD')"
          @update="setStartAt($event)"
        />
        <v-spacer></v-spacer>
        <DatePicker
          :disabled="campaign.kind === 'permanent'"
          :initialValue="campaign.end_at"
          label="End at"
          :min="this.$moment().add(2, 'days').format('YYYY-MM-DD')"
          @update="setEndAt($event)"
        />
      </div>
      <div class="mt-4">
        <label class="font-weight-medium subheading">Options avancées :</label>
        <div class="disp-flex">
          <v-checkbox v-model="inheritable" :label="$t('createCampaign.campaignSettings.pushChilds')" class="mt-2" hide-details></v-checkbox>
          <v-checkbox v-model="happyHour" label="Happy hour" class="mt-2" hide-details></v-checkbox>
        </div>
        <TimeSlotSelection v-if="happyHour" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import DatePicker from '@/components/DatePicker'
import TimeSlotSelection from '@/components/TimeSlotSelection'

export default {
  components: {
    DatePicker,
    TimeSlotSelection
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      happyHour: false,
      nameRules: [
        v =>
          !!v ||
          this.$t('createCampaign.campaignSettings.inputCampaignName.required'),
        v =>
          (v && v.length >= 3) ||
          this.$t(
            'createCampaign.campaignSettings.inputCampaignName.characters'
          )
      ]
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setName',
      'setKind',
      'setStartAt',
      'setEndAt',
      'setInheritable'
    ])
  },
  computed: {
    ...mapState({
      editMode: state => state.campaignBuilder.editMode
    }),
    name: {
      get() {
        return this.campaign.name
      },
      set(value) {
        this.setName(value)
      }
    },
    inheritable: {
      get() {
        return this.campaign.inheritable
      },
      set(value) {
        this.setInheritable(value)
      }
    }
  }
}
</script>
