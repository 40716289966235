<template>
  <div>
    <div class="d-flex mb-4">
      <label class="font-weight-medium subheading align-self-center">Création des statuts :</label>
      <v-btn outline small color="primary" class="ma-0" style="max-width: 190px" @click="addLevel()" :disabled="statusSystemLevels.length === 4">
        Ajouter un statut
      </v-btn>
    </div>
    <template v-for="(level, index) in statusSystemLevels">
      <div class="w-100" :key="level.name + index">
        <CoreProgramCampaignSettingsStatusListLevel :level="level" :index="index" @deleteLevel="deleteLevel($event)" @updateLevel="updateLevel($event)"></CoreProgramCampaignSettingsStatusListLevel>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CoreProgramCampaignSettingsStatusListLevel from './CoreProgramCampaignSettingsStatusListLevel'
import Level from '@/models/Level'

export default {
  components: {
    CoreProgramCampaignSettingsStatusListLevel
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'createStatusSystemLevel',
      'deleteStatusSystemLevel',
      'updateStatusSystemLevel',
      'deleteStatusSystemAllLevel'
    ]),
    updateLevel(level) {
      this.updateStatusSystemLevel({ index: level.index, level: level.data })
    },
    deleteLevel(index) {
      this.deleteStatusSystemLevel(index)
    },
    addLevel() {
      if (this.statusSystemLevels.length < 4) this.createStatusSystemLevel({ ...new Level() })
    },
    createBasicLevels() {
      this.deleteStatusSystemAllLevel()
      for (let i = 0; i < 2; i++) this.createStatusSystemLevel({ ...new Level() })
    }
  },
  computed: {
    ...mapState({
      statusSystemLevels: state => state.campaignBuilder.campaign.status_system.levels,
      statusSystemCounter: state => state.campaignBuilder.campaign.status_system.counter,
    })
  },
  created() {
    if (!this.statusSystemLevels?.length > 0) this.createBasicLevels()
  },
  watch: {
    statusSystemCounter: {
      deep: true,
      handler() {
        this.createBasicLevels()
      }
    }
  }
}
</script>
