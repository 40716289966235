<template>
  <v-container v-if="loaded" fluid fill-height>
    <v-layout v-if="!company.cgu" row justify-center>
      <v-dialog :value="true" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">
            <v-icon color="warning">warning</v-icon>Oh wait !
          </v-card-title>
          <v-card-text>{{ $t('registerMerchant.noCGUWarning') }}</v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row wrap align-center justify-center>
      <v-flex xs12 sm10 md7>
        <v-layout justify-center>
          <v-flex class="mb-2 langage-selection">
            <img
              @click="$i18n.locale = 'fr'"
              src="@/assets/images/standard/france_flag.png"
              width="30px"
              class="mx-1"
            />
            <img
              @click="$i18n.locale = 'en'"
              src="@/assets/images/standard/united-kingdom_flag.png"
              width="30px"
              class="mx-1"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="py-2">
              <v-card-text class="justify-content-center">
                <img
                  class="d-block mx-auto image-auto-100"
                  :src="company.parent ? company.parent.logo_url : company.logo_url"
                  :alt="company.parent ? company.parent.name : company.name"
                  width="40%"
                />
              </v-card-text>
              <v-card-text v-if="company.parent_advertiser" class="justify-content-center">
                <img
                  class="d-block mx-auto image-auto-100"
                  :src="company.parent_advertiser.logo_url"
                  :alt="company.parent_advertiser.name"
                  width="40%"
                />
              </v-card-text>
              <v-card-text>
                <v-alert color="info" outline type="info" :value="!accountCompleted">
                  <span>
                    {{ $t('registerMerchant.accountCompletedMessage') }}<b>{{ $route.query.account }}</b>
                  </span>
                </v-alert>
              </v-card-text>
              <v-card-text class="px-4">
                <form>
                  <v-layout lazy-validation row wrap>
                    <template v-if="account">
                      <v-flex xs12 mb-2>
                        <h2 class="font-weight-bold">
                          {{ $t('registerMerchant.compte') }}
                        </h2>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          disabled
                          hide-details
                          v-model="account.email"
                          label="Email*"
                          prepend-icon="person"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex md6 xs12>
                        <v-text-field
                          v-model="account.password"
                          :error-messages="passwordErrors"
                          @input="$v.account.password.$touch()"
                          @blur="$v.account.password.$touch()"
                          label="Mot de passe*"
                          prepend-icon="lock"
                          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                          @click:append="showPassword = !showPassword"
                          :type="showPassword ? 'text' : 'password'"
                        />
                      </v-flex>
                      <v-flex md6 xs12>
                        <v-text-field
                          v-model="account.password_confirmation"
                          :error-messages="passwordConfirmationErrors"
                          @input="$v.account.password_confirmation.$touch()"
                          @blur="$v.account.password_confirmation.$touch()"
                          label="Confirmer mot de passe*"
                          prepend-icon="lock"
                          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                          @click:append="showPassword = !showPassword"
                          :type="showPassword ? 'text' : 'password'"
                          class="pl-3"
                        />
                      </v-flex>
                      <v-flex md6 xs12>
                        <v-text-field
                          v-model="account.firstname"
                          :error-messages="firstnameErrors"
                          @input="$v.account.firstname.$touch()"
                          @blur="$v.account.firstname.$touch()"
                          label="Nom*"
                          prepend-icon="person"
                        />
                      </v-flex>
                      <v-flex md6 xs12>
                        <v-text-field
                          v-model="account.lastname"
                          :error-messages="lastnameErrors"
                          @input="$v.account.lastname.$touch()"
                          @blur="$v.account.lastname.$touch()"
                          label="Prénom*"
                          prepend-icon="person"
                        />
                      </v-flex>
                    </template>
                    <v-flex xs12 my-3>
                      <h2 class="font-weight-bold">
                        {{ $t('registerMerchant.society') }}
                      </h2>
                    </v-flex>
                    <v-flex xs4 sm8 md12>
                      <v-text-field
                        disabled
                        v-model="company.name"
                        label="Nom de la société*"
                        prepend-icon="business"
                      />
                      <v-text-field
                        disabled
                        v-model="company.siren"
                        :mask="sirenMask"
                        prepend-icon="business"
                        label="SIREN*"
                      />
                      <v-text-field
                        disabled
                        v-model="company.siret"
                        :mask="siretMask"
                        prepend-icon="business"
                        label="SIRET*"
                      />
                      <v-text-field
                        disabled
                        v-if="company.payment_information"
                        v-model="company.payment_information.iban"
                        :mask="ibanMask"
                        prepend-icon="swap_horiz"
                        label="IBAN*"
                      />
                      <v-text-field
                        v-if="company.location"
                        prepend-icon="map"
                        v-model="company.location.line_1"
                        label="Adresse ligne 1*"
                      />
                      <v-text-field
                        v-if="company.location"
                        v-model="company.location.line_2"
                        label="Adresse ligne 2"
                      />
                      <v-layout row wrap>
                        <v-flex md6 xs12>
                          <v-text-field
                            v-if="company.location"
                            disabled
                            v-model="company.location.zipcode"
                            label="Code Postal*"
                          />
                        </v-flex>
                        <v-flex md6 xs12>
                          <v-text-field
                            v-if="company.location"
                            disabled
                            v-model="company.location.city"
                            label="Ville"
                            class="pl-3"
                          />
                        </v-flex>
                        <v-layout row wrap>
                          <v-flex md12 xs12 py-0>
                            <v-text-field
                              v-if="company.location"
                              disabled
                              prepend-icon="flag"
                              v-model="company.location.state"
                              label="Pays"
                            />
                          </v-flex>
                          <v-flex md6 xs12 py-0>
                            <v-text-field
                              v-model="company.phone"
                              :mask="phoneMask"
                              prepend-icon="phone"
                              label="Téléphone"
                            />
                          </v-flex>
                          <v-flex md6 xs12>
                            <v-text-field
                              v-model="company.website_url"
                              prepend-icon="search"
                              label="Site Web"
                              class="pl-3"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="company.point_of_sales.length > 0" row wrap>
                          <v-flex xs12 my-3>
                            <h2 class="font-weight-bold">
                              {{ $t('registerMerchant.pointsOfSale') }}
                            </h2>
                          </v-flex>
                          <v-flex>
                            <PointOfSaleList
                              :pointOfSales="company.point_of_sales"
                              :compan="company"
                              :fromEnrollement="true"
                              :loading="loaded"
                            />
                          </v-flex>
                        </v-layout>
                      </v-layout>
                      <v-layout row wrap mt-2>
                        <template v-if="directDebitAuthorizationPresent">
                          <v-flex xs12 my-3>
                            <h2 class="font-weight-bold">
                              Contrats et ordre de prélèvement signés
                            </h2>
                          </v-flex>
                          <v-flex xs12 class="flex-div">
                            <v-btn
                              v-if="directDebitAuthorizationPresent"
                              :href="company.payment_information.sdd_mandate.direct_debit_authorization_url"
                              target="_blank"
                              flat
                              color="primary"
                            >
                              <v-icon class="mr-2">picture_as_pdf</v-icon>
                              {{ fileNameFromUrl(company.payment_information.sdd_mandate.direct_debit_authorization_url) }}
                            </v-btn>
                          </v-flex>
                        </template>
                        <v-flex v-if="!company.completed_at" class="mt-2">
                          <v-list>
                            <v-list-tile class="accept-cgu-checkbox">
                              <v-list-tile-action>
                                <v-checkbox
                                  color="primary"
                                  v-model="checkCGU"
                                  :error-messages="checkCGUErrors"
                                  @change="$v.checkCGU.$touch()"
                                  @blur="$v.checkCGU.$touch()"
                                />
                              </v-list-tile-action>
                              <v-list-tile-content @click="checkCGU = !checkCGU">
                                <span>
                                  En cochant ici vous indiquez avoir lu et approuvé
                                  <a class="link" @click.stop="cguDialog = true">les conditions générales</a>.
                                </span>
                              </v-list-tile-content>
                            </v-list-tile>
                          </v-list>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-card-actions class="mb-3 column flex-div justify-center">
                      <v-btn @click="submit" large color="indigo darken-2" class="mt-3 white--text">
                        <v-icon left small>arrow_forward</v-icon>Valider
                      </v-btn>
                    </v-card-actions>
                  </v-layout>
                </form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <CguDialog v-if="company.cgu" :value="cguDialog" :content="company.cgu.content" @input="cguDialog = false" />
  </v-container>
</template>

<script>
import EventBus from '@/packs/event-bus'
import req from '@/packs/request.js'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import PointOfSaleList from '@/components/PointsOfSale/PointOfSaleList'
import CguDialog from './CguDialog'

export default {
  components: {
    PointOfSaleList,
    CguDialog
  },
  data() {
    return {
      loaded: false,
      account: null,
      company: null,
      sirenMask: '### ### ###',
      siretMask: '### ### ### #####',
      ibanMask: 'AA## #### #### #### #### #### ###',
      phoneMask: '## ## ## ## ##',
      checkCGU: false,
      showPassword: false,
      cguDialog: false
    }
  },
  validations() {
    const accountValidations = this.account
      ? {
          account: {
            firstname: { required },
            lastname: { required },
            password: { required, minLength: minLength(8) },
            password_confirmation: {
              required,
              sameAsPassword: sameAs('password')
            }
          }
        }
      : {}

    const cguValidations = this.company.completed_at
      ? {}
      : {
          checkCGU: {
            checked(val) {
              return val
            }
          }
        }

    return { ...accountValidations, ...cguValidations }
  },
  created() {
    if (!this.$route.query.account) this.fetchIncompleteAccount()
    this.fetchIncompleteCompany()
  },
  computed: {
    directDebitAuthorizationPresent() {
      return (
        this.company.payment_information &&
        this.company.payment_information.sdd_mandate &&
        this.company.payment_information.sdd_mandate.direct_debit_authorization_url
      )
    },
    firstnameErrors() {
      const errors = []
      if (!this.$v.account.firstname.$dirty) return errors
      !this.$v.account.firstname.required &&
        errors.push('Firstname is required.')
      return errors
    },
    lastnameErrors() {
      const errors = []
      if (!this.$v.account.lastname.$dirty) return errors
      !this.$v.account.lastname.required && errors.push('Lastname is required.')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.account.password.$dirty) return errors
      !this.$v.account.password.required && errors.push('Password is required.')
      !this.$v.account.password.minLength &&
        errors.push(`Password must be at most ${this.$v.account.password.$params.minLength.min} characters long.`)
      return errors
    },
    passwordConfirmationErrors() {
      const errors = []
      if (!this.$v.account.password_confirmation.$dirty) return errors
      !this.$v.account.password_confirmation.required && errors.push('Password Confirmation is required.')
      !this.$v.account.password_confirmation.sameAsPassword && errors.push('Passwords must be identical.')
      return errors
    },
    checkCGUErrors() {
      const errors = []
      if (!this.$v.checkCGU.$dirty) return errors
      !this.$v.checkCGU.checked && errors.push('You need to accept CGU')
      return errors
    },
    accountCompleted() {
      if (!this.$route.query.account_completed) return true
      return this.$route.query.account_completed === 'true'
    }
  },
  methods: {
    fileNameFromUrl(url) {
      return url.split('/').pop()
    },
    async fetchIncompleteAccount() {
      const account = await req.get(`/incomplete_account?complete_token=${this.$route.query.complete_token}`)

      this.account = account.data
    },
    async fetchIncompleteCompany() {
      try {
        const account = await req.get(`/incomplete_company?complete_token=${this.$route.query.complete_token}`)

        this.company = account.data
        this.loaded = true
      } catch (err) {
        this.$router.push('/signin').catch(err => err)
      }
    },

    async updateIncompleteCompany() {
      const company = { company: this.company }

      const res = await req.put(`/incomplete_company?complete_token=${this.$route.query.complete_token}`, company)
      Object.assign(this.company, res.data)
    },
    async updateIncompleteAccount() {
      const account = { account: this.account }

      const res = await req.put(`/incomplete_account?complete_token=${this.$route.query.complete_token}`, account)
      Object.assign(this.account, res.data)
    },
    async sign_up() {
      const account = {
        account: { email: this.account.email, password: this.account.password }
      }

      await req.login(account)

      this.$store.dispatch('fetchAccount')
      this.$store.dispatch('fetchCompany')
      this.$store.dispatch('fetchCapabilities')
      this.$store.dispatch('setTokenPresence', req.token_presence())
    },
    scrollTop() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    showInvalidFormMessage() {
      EventBus.$emit('showSnackbar', { text: this.$t('message.formError'), color: 'error' })
      this.scrollTop()
    },
    async submit() {
      if (this.account) {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          await this.updateIncompleteCompany()
          await this.updateIncompleteAccount()
          await this.sign_up()

          this.$router.replace({ path: '/dashboard' }).catch(err => err)
        } else {
          this.showInvalidFormMessage()
        }
      } else {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          await this.updateIncompleteCompany()

          this.$router
            .replace({
              path: '/signin',
              query: {
                account: this.$route.query.account,
                account_completed: this.$route.query.account_completed
              }
            })
            .catch(err => err)
        } else {
          this.showInvalidFormMessage()
        }
      }
    }
  }
}
</script>
