<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap mb-3>
      <v-flex>
        <h2 class="headline">{{ $t('childs.title') }}</h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap mb-3>
      <v-flex>
        <v-btn :loading="reminding" :disabled="reminding" @click="remind()">{{ $t('childs.remind') }}</v-btn>
      </v-flex>
    </v-layout>
    <ChildList
      :childs="childs"
      :childsCount="childsCount"
      :loading="!loaded"
      @pagination="fetchChilds($event.page, $event.rowsPerPage)"
    />
  </v-container>
</template>

<script>
import req from '@/packs/request.js'
import ChildList from '@/components/Childs/ChildList'

export default {
  components: {
    ChildList,
  },
  data() {
    return {
      childs: [],
      childsCount: 0,
      loaded: false,
      reminding: false
    }
  },
  methods: {
    async remind() {
      this.reminding = true
      await req.post('/company/companies/send_completion_emails')
      this.reminding = false
      this.fetchChilds(1, 10)
    },
    async fetchChilds(page, per_page) {
      const res = await req.get(
        '/company/companies?page=' + page + '&per_page=' + per_page
      )

      this.childs = res.data.collection
      this.childsCount = res.data.count
      this.loaded = true
    }
  }
}
</script>
