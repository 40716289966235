<template>
  <div>
    <v-text-field
      v-model="segment.name"
      type="text"
      required
      :label="$t('customers.dialog.segmentName')"
      class="mt-0 pt-0"
      hide-details
    ></v-text-field>
    <div class="text-xs-right mt-2">
      <v-btn color="primary" dense small @click="createSegment()">{{
        $t('common.create')
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import req from '@/packs/request.js'
import eventBus from '@/packs/event-bus'
import customersFiltersService from '@/services/customers_filters'

export default {
  props: {
    customer_base: {
      type: String,
    },
    filters: {
      type: Object,
    },
  },
  data: function() {
    return {
      segment: {
        name: 'Nouveau segment',
        description: '',
        filters: customersFiltersService.fetchFilters({ ...this.filters }),
      },
      stateSegment: { ...this.segment },
    }
  },
  methods: {
    async createSegment() {
      await req.post(
        '/customer_bases/' + this.customer_base + '/segments',
        this.segment
      )
      eventBus.$emit('refreshSegments')
    },
  },
}
</script>
