<template>
  <div>
    <div class="header-card pa-3">
      <h3 class="font-weight-medium">
        <v-icon small class="align-icon">work</v-icon>
        {{ $t('settings.company.title') }}
      </h3>
    </div>
    <v-layout row wrap class="pt-3 px-5">
      <v-flex sm12 md6 lg5>
        <h3 class="font-weight-medium">{{ $t('settings.company.companyLogo') }}</h3>
        <image-loader v-model="company.logo" :src="company.logo_url" height="150"></image-loader>
        <v-text-field :label="$t('settings.company.companyName')" v-model="company.name" class="mt-3"></v-text-field>
      </v-flex>
      <v-flex sm12 md6 lg7>
        <template v-if="company.location">
          <h3 class="font-weight-medium mb-3">{{ $t('settings.location') }}</h3>
          <v-text-field :label="$t('settings.address1')" v-model="company.location.line_1"></v-text-field>
          <v-text-field :label="$t('settings.address2')" v-model="company.location.line_2"></v-text-field>
          <v-text-field :label="$t('settings.zipCode')" v-model="company.location.zipcode"></v-text-field>
          <v-text-field :label="$t('settings.city')" v-model="company.location.city"></v-text-field>
        </template>
        <v-text-field :label="$t('settings.phone')" v-model="company.phone"></v-text-field>
        <v-text-field :label="$t('settings.website')" v-model="company.website_url"></v-text-field>
      </v-flex>
    </v-layout>
    <div class="px-5 pb-3">
      <v-btn color="primary" @click="saveCompany()" class="ml-0">
        <v-icon small class="mr-2">save</v-icon>
        {{ $t('settings.saveChanges') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import ImageLoader from '@/components/Base/ImageLoader'
import SettingsLocation from '@/models/SettingsLocation'
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'

export default {
  props: {
    user: {
      type: Object
    },
    company: {
      type: Object
    }
  },
  components: {
    ImageLoader
  },
  data() {
    return {
      loaded: false
    }
  },
  methods: {
    async saveCompany() {
      if (this.company.location && this.company.location.line_1) {
        const res = await req.put('/api/user/company', this.company)
        if (!res.data.location) this.company.location = new SettingsLocation()
        Object.assign(this.company, res.data)
      } else EventBus.$emit('showSnackbar', { text: 'Location is needed', color: 'error' })
    }
  },
  mounted() {
    if (this.company.location) this.company.location = new SettingsLocation()
  }
}
</script>
