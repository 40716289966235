<template>
  <v-container fluid class="transactions">
    <TransactionsTable :api-url="transactionsUrl" :see-customer="true"></TransactionsTable>
  </v-container>
</template>

<script>
import TransactionsTable from '@/components/TransactionsTable'

export default {
  components: {
    TransactionsTable
  },
  data() {
    return {
      transactionsUrl: '/core_program/transactions'
    }
  }
}
</script>
