<template>
  <v-card class="mt-4">
    <div class="header-card pa-3">
      <div class="disp-flex align-center">
        <h3 class="font-weight-medium">
          <v-icon small class="align-icon">supervisor_account</v-icon>
          {{ $t('customers.customers') }}
        </h3>
        <v-spacer></v-spacer>
        <CustomersTableExport :base_id="customer_base.id" :filters="filters" />
      </div>
    </div>
    <v-layout row wrap class="px-3 pb-3 pt-2" style="background: #f1f1f1ad">
      <v-flex xs12 md6 lg2>
        <v-text-field
          :class="{ 'pt-0 mt-0': $vuetify.breakpoint.lgAndUp }"
          class="mx-1"
          v-model="searchFields.lastname"
          :label="$t('customers.table.lastName')"
          single-line
          hide-details
          :loading="loading"
          @input="fetchCustomersDebounced()"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6 lg2>
        <v-text-field
          :class="{ 'pt-0 mt-0': $vuetify.breakpoint.lgAndUp }"
          class="mx-1"
          v-model="searchFields.firstname"
          :label="$t('customers.table.firstName')"
          single-line
          hide-details
          :loading="loading"
          @input="fetchCustomersDebounced()"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12 md6 lg3>
        <v-text-field
          :class="{ 'pt-0 mt-0': $vuetify.breakpoint.lgAndUp }"
          v-model="searchFields.email"
          :label="$t('customers.table.email')"
          class="mx-1"
          single-line
          hide-details
          :loading="loading"
          @input="fetchCustomersDebounced()"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12 md6 lg2>
        <v-text-field
          :class="{ 'pt-0 mt-0': $vuetify.breakpoint.lgAndUp }"
          class="mx-1"
          v-model="searchFields.zipcode"
          :label="$t('customers.table.zipcode')"
          single-line
          hide-details
          :loading="loading"
          @input="fetchCustomersDebounced()"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12 md6 lg3>
        <v-text-field
          :class="{ 'pt-0 mt-0': $vuetify.breakpoint.lgAndUp }"
          class="mx-1"
          v-model="searchFields.identifier"
          label="Numéro de carte"
          single-line
          hide-details
          :loading="loading"
          @input="fetchCustomersDebounced()"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-data-table
      :headers="headersCustomers"
      :total-items="totalCustomers"
      :pagination.sync="pagination"
      :items="customers"
      :loading="loading"
      :rows-per-page-text="$t('common.rowsPerPage')"
      item-key="id"
      class="w-100"
    >
      <template slot="headerCell" slot-scope="props">
        {{ $t(props.header.text) }}
      </template>
      <template slot="items" slot-scope="props">
        <tr
          @click.prevent="
            $router.push({
              name: 'customersData',
              params: { id: props.item.id }
            })
          "
          class="line-customer"
        >
          <td>{{ gender(props.item.gender) }}</td>
          <td class="text-uppercase">{{ props.item.lastname }}</td>
          <td>{{ props.item.firstname }}</td>
          <td>{{ props.item.email }}</td>
          <td>{{ props.item.order_count }}</td>
          <td>{{ counter(props.item, 'monetary_cumulative_amount') }}€</td>
          <td>{{ counter(props.item, 'monetary_average_amount') }}€</td>
          <td>{{ counter(props.item, 'frequency') }}</td>
          <td>
            {{
              props.item.counters && props.item.counters.recency
                ? `${recency(props.item.counters.recency)} jour(s)`
                : '-'
            }}
          </td>
          <td>{{ wallet(props.item) }}</td>
          <td>
            <span
              v-if="
                props.item.segments &&
                  props.item.segments[0] &&
                  props.item.segments[0].name &&
                  !props.item.segments[0].custom
              "
              >{{ props.item.segments[0].name }}</span
            >
            <span v-else>-</span>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <div class="pa-3 text-xs-center">{{ $t('common.errorSelect') }}</div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import eventBus from '@/packs/event-bus'
import req from '@/packs/request.js'
import customersFiltersService from '@/services/customers_filters'
import CustomersTableExport from './CustomersTableExport'

export default {
  components: {
    CustomersTableExport
  },
  props: {
    customer_base: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      searchFields: {
        firstname: '',
        lastname: '',
        email: '',
        zipcode: '',
        identifier: ''
      },
      headersCustomers: [
        {
          text: 'customers.table.gender',
          value: 'gender',
          sortable: false
        },
        {
          text: 'customers.table.lastName',
          value: 'lastname',
          sortable: false
        },
        {
          text: 'customers.table.firstName',
          value: 'firstname',
          sortable: false
        },
        {
          text: 'customers.table.email',
          value: 'email',
          width: '120',
          sortable: false
        },
        {
          text: 'customers.table.orders',
          value: 'orders',
          sortable: false
        },
        {
          text: 'customers.table.income',
          value: 'counters.monetary_cumulative_amount',
          sortable: false
        },
        {
          text: 'customers.table.averageBasket',
          value: 'counters.monetary_average_amount',
          sortable: false
        },
        {
          text: 'customers.table.frequency',
          value: 'counters.frequency',
          sortable: false
        },
        {
          text: 'customers.table.recency',
          value: 'counters.recency',
          sortable: false
        },
        {
          text: 'customers.table.balance',
          value: '',
          sortable: false
        },
        {
          text: 'customers.table.rfm',
          value: 'segments',
          sortable: false
        }
      ],
      totalCustomers: 0,
      customers: [],
      filters: {},
      loading: false,
      debounceId: null
    }
  },
  methods: {
    recency(value) {
      return this.$moment().diff(this.$moment.unix(value), 'days')
    },
    gender(value) {
      if (value === 'male') return 'M'
      else if (value === 'female') return 'F'
      else return '-'
    },
    fetchCustomersDebounced() {
      clearTimeout(this.debounceId)

      this.debounceId = setTimeout(() => {
        this.fetchCustomers()
      }, 300)
    },
    async fetchCustomers() {
      this.loading = true
      const composition = 'wallets,counters,segments,revenue,workplace'
      let url = `/customer_bases/${this.customer_base.id}/customers/filter?composition=${composition}&page=${this.pagination.page}&per_page=${this.pagination.rowsPerPage}`
      if (this.searchFields.firstname.length > 0) url += `&by_firstname=${this.searchFields.firstname}`
      if (this.searchFields.lastname.length > 0) url += `&by_lastname=${this.searchFields.lastname}`
      if (this.searchFields.zipcode.length > 0) url += `&by_zipcode=${this.searchFields.zipcode}`
      if (this.searchFields.email.length > 0) url += `&by_email=${this.searchFields.email}`
      if (this.searchFields.identifier.length > 0) url += `&by_identifier=${this.searchFields.identifier}`

      const res = await req.post(url, { filters: customersFiltersService.fetchFilters(this.filters) })
      this.totalCustomers = res.data.count
      this.customers = res.data.collection
      eventBus.$emit('getTotalCustomers', this.totalCustomers)
      this.loading = false
    },
    counter(customer, name) {
      if (customer.counters) {
        if (name.indexOf('amount') >= 0) return customer.counters[name] ? customer.counters[name].toCurrency() : 0
        else if (name === 'monetary_cumulative_amount')
          return customer.counters['monetary_cumulative_amount']
            ? customer.counters['monetary_cumulative_amount'].toCurrency()
            : 0
        else return customer.counters[name] ? customer.counters[name] : '-'
      } else return name.indexOf('amount') >= 0 ? 0 : '-'
    },
    wallet(customer) {
      if (customer.wallets && customer.wallets.length > 0)
        return customer.wallets[0].balance + ' ' + customer.wallets[0].loyalty_currency_units
      else return '-'
    }
  },
  created() {
    this.fetchCustomers()
    eventBus.$on('applyFilters', value => {
      this.filters = { ...value.filters }
      this.fetchCustomers()
    })
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.fetchCustomers()
      }
    }
  }
}
</script>

<style scoped lang="css">
.header-card {
  background: #eceff1;
  border-bottom: 1px solid #cfd8dc;
  color: #37474f;
}

.subtitle-color {
  color: #717171;
}

.align-icon {
  font-size: 16px;
  vertical-align: middle;
  padding-bottom: 4px;
}
</style>
