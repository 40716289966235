<template>
  <v-layout justify-center row mt-2>
    <v-flex xs12 sm6 offset-sm3>
      <h2 class="mt-5">{{ $t('nav.groups') }}</h2>
      <v-layout justify-end>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="createGroup()">
              <v-icon color="grey darken-1">add</v-icon>
            </v-btn>
          </template>
          <span>Add Group</span>
        </v-tooltip>
      </v-layout>
      <v-card v-if="loaded">
        <v-list
          v-if="groups.length > 0"
          style="max-height: 565px"
          class="scroll-y"
          dense
          two-line
        >
          <template v-for="(group, index) in groups">
            <v-list-tile :key="group.id" avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="group.name"></v-list-tile-title>
                <!--                <v-list-tile-sub-title>Minimum campaign to accept: {{group.minimum_campaign_to_accept}}</v-list-tile-sub-title>-->
              </v-list-tile-content>

              <v-list-tile-action>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      ripple
                      @click="$router.push(`groups/${group.id}/edit`)"
                    >
                      <v-icon small color="grey">settings</v-icon>
                    </v-btn>
                  </template>
                  <span>Settings</span>
                </v-tooltip>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      ripple
                      @click="deleteGroup(group, index)"
                    >
                      <v-icon small color="red lighten-1">delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider
              v-if="index + 1 != groups.length"
              :key="`divider.${index}`"
            ></v-divider>
          </template>
        </v-list>
        <v-list v-else>
          <v-layout justify-center align-center>
            <p class="body-1 font-weight-thin ">No groups</p>
          </v-layout>
        </v-list>
      </v-card>
      <v-card v-else>
        <v-layout align-center justify-center row fill-height>
          <v-progress-circular
            indeterminate
            color="primary"
            :width="3"
            class="mt-2 mb-2"
          ></v-progress-circular>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex>
      <router-view @refresh="refresh($event)" />
    </v-flex>
  </v-layout>
</template>

<script>
import req from '@/packs/request'

export default {
  data() {
    return {
      loaded: false,
      groups: [],
    }
  },
  created() {
    this.fetchGroups()
  },
  methods: {
    async fetchGroups() {
      this.loaded = false
      const res = await req.get('/company/groups')

      this.groups.push(...res.data)
      this.loaded = true
    },
    async createGroup() {
      const params = {
        group: {
          name: 'New Group',
        },
      }

      const res = await req.post('/company/groups', params)
      this.groups.push(res.data)
    },
    async deleteGroup(group, index) {
      const res = req.delete(`/company/groups/${group.id}`)

      this.groups.splice(index, 1)
    },
    refresh(group) {
      const groupIndex = this.groups.findIndex(e => e.id == group.id)
      this.groups.splice(groupIndex, 1, group)
    },
  },
}
</script>

<style></style>
