<template>
  <v-select :items="kindItems" :value="value" @input="$emit('input', $event)" disabled class="pt-0 mt-0 mx-1 input-id" style="max-width: 80px" hide-details>
    <template v-slot:item="{ item }">{{ item | capitalize }}</template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      kindItems: ['extern']
    }
  }
}
</script>
