<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="time"
        :label="label"
        append-icon="access_time"
        :disabled="disabled"
        readonly
        clearable
        solo
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker v-if="menu2" v-model="time" full-width format="24hr" @click:minute="save()"></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: 'Picker in menu'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      time: null,
      menu2: false
    }
  },
  methods: {
    save() {
      this.$refs.menu.save(this.time)
      this.$emit('update', `${this.time}:00`)
    }
  }
}
</script>
