<template>
  <v-flex px-4 py-3 mt-2 class="flex-div align-center">
    <animated-number
      :value="pdfBatchesCount"
      :duration="500"
      :formatValue="formatValue"
      class="info-content title font-weight-black"
    />
    <span class="ml-2">{{ $t('dashboard.voucherManually') }}</span>
    <v-btn
      v-if="pdf.id != null"
      color="primary"
      class="ml-3 mr-0"
      @click="downloadPDF()"
    >
      <v-icon class="mr-2">picture_as_pdf</v-icon>
      {{ $t('dashboard.dlVoucher') }}
    </v-btn>
    <v-btn small color="primary" flat to="/batches">{{
      $t('dashboard.seeHistory')
    }}</v-btn>
  </v-flex>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'
import req from '@/packs/request.js'

export default {
  components: {
    AnimatedNumber,
  },
  data: function() {
    return {
      pdf: {
        id: null,
      },
    }
  },
  methods: {
    formatValue(value) {
      const number = `${Number(value).toFixed(0)}`
      const format = Intl.NumberFormat('fr-FR').format(number)
      return format
    },
    downloadPDF() {
      req.download(
        '/pdf_batches/' + this.pdf.id,
        'pdf-batches-' + this.pdf.date + '.pdf'
      )
      this.pdf.downloaded = true
    },
  },
  computed: {
    pdfBatchesCount() {
      return this.pdf.downloaded ? 0 : this.pdf.size
    },
  },
  mounted() {
    req.get('/pdf_batches/last').then(res => {
      if (res.status !== 204) {
        this.pdf = res.data
      }
    })
  },
}
</script>
