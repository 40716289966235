<template>
  <div>
    <v-layout justify-space-around class="mb-4">
      <BoxWithIcon
        :header="$t('communications.sended')"
        :value="sum('sended')"
        icon="send"
        color="black"
      />
      <BoxWithIcon
        :header="$t('communications.delivered')"
        :value="sum('delivered')"
        icon="check_circle"
        color="success"
      />
      <BoxWithIcon
        :header="$t('communications.opened')"
        :value="sum('opened')"
        icon="email"
        color="info"
      />
      <BoxWithIcon
        :header="$t('communications.failed')"
        :value="sum('failed')"
        icon="cancel"
        color="red"
      />
      <BoxWithIcon
        :header="$t('communications.complained')"
        :value="sum('complained')"
        icon="pan_tool"
        color="black"
      />
    </v-layout>

    <v-data-table hide-actions class="elevation-1 datatable" :headers="headers" :items="this.usageHistory">
      <template slot="headerCell" slot-scope="props">
        {{ $t(props.header.text) }}
      </template>
      <template v-slot:items="props">
        <td class="text-xs-left">{{ props.item.name }}</td>
        <td class="text-xs-left">{{ props.item.kind | capitalize }}</td>
        <td class="text-xs-left">
          <v-chip :color="props.item.status === 'running' ? 'green' : 'orange'" small text-color="white" class="font-weight-bold">
            {{ $t(`filters.${props.item.status}`) }}
          </v-chip>
        </td>
        <td class="text-xs-left">{{ props.item.communication_history.sended }}</td>
        <td class="text-xs-left">{{ props.item.communication_history.delivered }}</td>
        <td class="text-xs-left">{{ props.item.communication_history.opened }}</td>
        <td class="text-xs-left">{{ props.item.communication_history.failed }}</td>
        <td class="text-xs-left">{{ props.item.communication_history.complained }}</td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import BoxWithIcon from '@/components/Communications/BoxWithIcon.vue';
import API from '@/API';
import { sumProperty } from '@/store/helpers';

import CompanyService from '@/services/CompanyService'

@Component({
  components: { BoxWithIcon }
})
export default class UsageHistory extends Vue {

  async mounted() {
    await this.fetchCommunicationHistory()
  }

  usageHistory = [];

  async fetchCommunicationHistory() {
    const res = await CompanyService.getCommunicationHistories()
    this.usageHistory = res.data
  }

  sum(property) {
    let sum = 0
    this.usageHistory.forEach(history => {
      sum += history.communication_history[property]
    })

    return sum
  }

  headers = [
    {
      text: 'communications.campaigns',
      value: 'campaign',
      sortable: false
    },
    {
      text: 'Type',
      sortable: false
    },
    {
      text: 'communications.status',
      sortable: false
    },
    {
      text: 'communications.sended',
      sortable: false
    },
    {
      text: 'communications.delivered',
      sortable: false
    },
    {
      text: 'communications.opened',
      sortable: false
    },
    {
      text: 'communications.failed',
      sortable: false
    },
    {
      text: 'communications.complained',
      sortable: false
    }
  ];
}
</script>

<style lang="scss" scoped></style>
