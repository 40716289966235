<template>
  <iframe :srcdoc="src" title="Html Preview" width="630" height="800"></iframe>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>
