<template>
  <v-layout row justify-left>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon ripple @click="dialog = !dialog">
          <v-icon small color="red lighten-1">delete</v-icon>
        </v-btn>
      </template>
      <span>Supprimer</span>
    </v-tooltip>
    <v-dialog :value="dialog" @input="$router.go(-1)" max-width="450px">
      <v-card>
        <v-card-title class="title grey lighten-2" primary-title>
          <v-icon class="mr-2">delete_forever</v-icon>
          <span v-if="view === 'products'">Supprimer un produit</span>
          <span v-if="view === 'categories'">Supprimer une catégorie</span>
        </v-card-title>
        <v-card-text>
          <v-card-text class="subheading">
            {{ $t('message.deleteSegment') }} <b>{{ target.name }}</b> ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="onConfirm(target.id)">{{ $t('common.boolYes') }}</v-btn>
            <v-btn color="primary" flat @click="dialog = false">{{ $t('common.boolNo') }}</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import req from '@/packs/request.js'
import eventBus from '@/packs/event-bus'

export default {
  props: {
    view: {
      type: String,
      required: true
    },
    target: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    onConfirm(targetId) {
      this.$emit('dialog-closed', { targetId: targetId })
      this.dialog = false
    }
  }
}
</script>
