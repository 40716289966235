import axios from 'axios'
import store from '@/store/index'
import jwt_helper from 'vue-jwt-decode'

import req from '@/packs/request'
import EventBus from '@/packs/event-bus'

const AxiosWrapper = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	headers: {
			"Content-Type": "application/json",
	}
})

const getToken = (): string | null => {
	return sessionStorage.getItem('bearer')
}

const setToken = (token: string): void => {
	sessionStorage.setItem('bearer', token)
	req.rest.defaults.headers.common['Authorization'] = token //should be remove when request.js will be no longer used
}

const clearToken = (): void => {
	// sessionStorage.removeItem('bearer')
	sessionStorage.clear()
	delete req.rest.defaults.headers.common['Authorization'] //should be remove when request.js will be no longer used
}

const getTokenPresence = (): boolean => {
	const bearer = getToken()
	if (bearer === undefined || bearer == '' || bearer == null) return false

	const jwt = jwt_helper.decode(bearer.split(' ')[1])
	if (jwt.exp * 1000 <= Date.now()) {
		clearToken()
		return false
	} else {
		return true
	}
}

const authInterceptor = (config: any) => {
	config.headers['Authorization'] = getToken()
	return config;
}

AxiosWrapper.interceptors.request.use(authInterceptor);

const errorInterceptor = (err: any) => {
	const res = err.response

	if (res && res.status === 401 && !res.request.responseURL.includes('sign_in')) {
		if (getTokenPresence()) {
			store.dispatch('clearUser')
			clearToken()
		}
		EventBus.$emit('forbidden')
	} else if (res.status != 404) {
		let messages
		if (Array.isArray(res.data.errors))
			messages = res.data.errors.join(', ')
		else messages = res.data.error
		EventBus.$emit('showSnackbar', {
			text: messages,
			color: 'error',
		})
	}

	return Promise.reject(err)
}

AxiosWrapper.interceptors.response.use(
	(res: any) => {return res},
	(err: any) => errorInterceptor(err)
)

export {
	AxiosWrapper as default,
	setToken,
	clearToken,
	getTokenPresence
}
