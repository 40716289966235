<template>
  <v-dialog :value="true" @input="$router.push('/signin').catch()" max-width="580">
    <v-card class="py-2">
      <v-btn
        icon
        class="close-icon-btn"
        @click.native="$router.push('/signin').catch()"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-title class="title flex-div flex-column align-center py-3">
        <h2 class="font-weight-light text-xs-center my-3">
          {{ $t('forgotPassword.title') }}
        </h2>
      </v-card-title>
      <v-card-text>
        <p class="text-xs-center subheading my-3">
          {{ $t('forgotPassword.text') }}<br />
        </p>
      </v-card-text>
      <v-card-text class="align-center py-3">
        <v-form @keypress.enter.native.prevent="sendEmail()" v-model="valid">
          <v-text-field
            v-model="email"
            :rules="loginRules"
            prepend-icon="email"
            name="login"
            label="Votre adresse email"
            type="email"
            color="primary"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-div flex-column align-center py-3">
        <v-btn
          class="mt-4"
          @click="sendEmail()"
          :disabled="!valid"
          color="primary"
        >
          {{ $t('forgotPassword.send') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'

export default {
  data() {
    return {
      valid: true,
      email: '',
      loginRules: [
        v => !!v || `L'adresse email est requise`,
        v => /.+@.+/.test(v) || `L'adresse email indiquée n'est pas valide`,
      ],
    }
  },
  methods: {
    async sendEmail() {
      const params = { email: this.email }

      await req.post('/accounts/password', params)

      EventBus.$emit('showSnackbar', {
        text: 'Un email vous a été envoyé, vérifiez votre boite mail',
        color: 'success',
      })

      this.$router.push('/signin').catch()
    },
  },
}
</script>
