<template>
  <div class="mt-3" v-if="campaign && campaign.templates && campaign.templates.length > 0">
    <label class="subheading">E-mail template :</label>
    <div class="w-100 mt-3">
      <v-expansion-panel>
        <v-expansion-panel-content v-for="(template, index) in campaign.templates" :key="index" class="template">
          <template v-slot:header>
            <div class="title font-weight-light">
              {{ template.kind | capitalize }} template
            </div>
          </template>
          <v-card>
            <v-card-text>
              <EventTemplating :template="template"></EventTemplating>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </div>
  </div>
</template>

<script>
import EventTemplating from '@/components/CampaignForm/Templating/EventTemplating'

export default {
  components: {
    EventTemplating
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
.template
  background-color: #f5f5f5 !important
</style>
