<template>
  <div class="text-xs-center">
    <v-dialog :value="value" @input="$emit('input')" width="800">
      <v-card>
        <v-card-title
          class="headline grey lighten-2 justify-center"
          primary-title
        >
          Conditions générales
        </v-card-title>

        <v-card-text>
          <div v-html="content" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="$emit('input')">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    content: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>
