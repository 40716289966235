<template>
  <div>
    <v-tabs left dark color="indigo" slider-color="white" align-with-title>
      <v-tab active-class="active-tab" @click="switchView('products')"> Produits </v-tab>
      <v-tab active-class="active-tab" @click="switchView('categories')"> Catégories </v-tab>
    </v-tabs>
    <v-layout row wrap>
      <v-text-field
        @input="refresh"
        v-model="filters.name"
        label="Nom"
        class="ml-4"
        single-line
        hide-details
      ></v-text-field>
      <ProductsCategoriesFilter
        v-if="view === 'products'"
        v-on:category="onFilterByCategory"
      ></ProductsCategoriesFilter>
      <v-text-field
        @input="refresh"
        v-model="filters.identifier"
        label="Identifiant"
        class="ml-4"
        single-line
        v-if="view === 'products'"
        hide-details
      ></v-text-field>
      <v-flex class="mt-sm-3">
        <div class="text-sm-center text-md-right text-lg-right">
          <v-btn round color="primary" dark to="/targets/products/create" v-if="view === 'products'">
            Produits
            <v-icon right dark>add</v-icon>
          </v-btn>
          <v-btn round color="success" dark to="/targets/categories/create" v-if="view === 'categories'">
            Catégories
            <v-icon right dark>add</v-icon>
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="targets[view]"
      :total-items="itemsCount"
      item-key="item.name"
      :pagination.sync="pagination"
      :rows-per-page-text="$t('common.rowsPerPage')"
      :loading="loading"
      :no-data-text="loading ? 'Chargement ...' : 'Aucune données'"
    >
      <template v-slot:items="props">
        <td>{{ props.item.name }}</td>
        <td v-if="view === 'products'">
          {{ props.item.categories.join(', ') }}
        </td>
        <td v-else-if="view === 'categories'">
          <ProductsCategoriesListDialog :category="props.item" />
        </td>
        <td v-else>
          <div class="circle-operations">
            <span style="color: #ffca83">5</span>
          </div>
        </td>
        <template v-if="view !== 'categories'">
          <td>{{ props.item.identifier ? props.item.identifier : '-' }}</td>
          <td>{{ props.item.base_price ? $n(props.item.base_price.toCurrency(), 'currency') : '-' }}</td>
        </template>
        <td class="justify-center layout px-0">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon ripple @click="$router.push(`/targets/${view}/${props.item.id}/edit`)">
                <v-icon small color="grey">create</v-icon>
              </v-btn>
            </template>
            <span>Editer</span>
          </v-tooltip>
          <DialogProductCategorieDelete
            :view="view"
            :target="props.item"
            @dialog-closed="onConfirmDelete"
          ></DialogProductCategorieDelete>
        </td>
      </template>
      <template v-slot:pageText="props">
        Lignes {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
    <router-view @refresh="refresh()" />
  </div>
</template>

<script>
import ProductsCategoriesListDialog from './ProductsCategoriesListDialog'
import DialogProductCategorieDelete from './DialogProductCategorieDelete'
import ProductsCategoriesFilter from './Filters/ProductsCategoriesFilter'
import req from '@/packs/request'

export default {
  components: {
    ProductsCategoriesListDialog,
    DialogProductCategorieDelete,
    ProductsCategoriesFilter
  },
  data() {
    return {
      view: 'products',
      filters: {
        name: null,
        identifier: null,
        category: null
      },
      targets: {
        products: [],
        categories: [],
        brands: []
      },
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      itemsCount: 0,
      loading: false,
      search: null,
      debounceId: null
    }
  },
  watch: {
    search: {
      handler() {
        clearTimeout(this.debounceId)

        this.debounceId = setTimeout(() => {
          this.refresh()
        }, 500)
      }
    },

    pagination: {
      deep: true,
      handler() {
        this.refresh()
      }
    }
  },
  computed: {
    headers() {
      const baseHeaders = [
        { text: 'Nom', value: 'name' },
        { text: this.defineColumn(), value: 'type', sortable: false },
        { text: 'Identifiant', value: 'identifier', sortable: false },
        { text: 'Prix', value: 'base_price', sortable: false },
        { text: 'Actions', value: 'name', width: '50px', sortable: false }
      ]

      if (this.view === 'categories') {
        baseHeaders.splice(2, 2)
      }
      return baseHeaders
    }
  },
  methods: {
    switchView(view) {
      this.view = view
      this.debounceId = setTimeout(() => {
        this.filters = {
          name: null,
          identifier: null
        }
        this.refresh()
      }, 500)
    },
    refresh() {
      this.fetchTargets(this.view)
    },
    async fetchTargets(view) {
      this.loading = true
      const byName = this.filters && this.filters.name ? `&by_name=${this.filters.name}` : ''
      const byIdentifier = this.filters && this.filters.identifier ? `&by_identifier=${this.filters.identifier}` : ''

      let res = null
      if (this.filters && this.filters.category) {
        res = await req.get(
          `/targets/categories/${this.filters.category}/products?page=${this.pagination.page}&per_page=${this.pagination.rowsPerPage}${byName}${byIdentifier}`
        )
      } else {
        res = await req.get(
          `/targets/${view}?page=${this.pagination.page}&per_page=${this.pagination.rowsPerPage}${byName}${byIdentifier}`
        )
      }
      if (res) {
        this.targets[view] = res.data.collection
        this.itemsCount = res.data.count
      }
      this.loading = false
    },
    async deleteTarget(id) {
      await req.delete(`/targets/${this.view}/${id}`)
      this.refresh()
    },
    setBrand(brand) {
      const index = this.targets.indexOf(brand.item)
      this.targets[index].brand_id = brand.updateBrand.identifier
      this.targets[index].type = brand.updateBrand.name
    },
    removeBrand(item) {
      const index = this.targets.indexOf(item)
      this.targets[index].brand_id = null
      this.targets[index].type = 'product'
    },
    defineColumn() {
      if (this.view === 'products') return 'Catégorie'
      else if (this.view === 'categories') return 'Produits de la catégorie'
      else return 'Nombre de produits'
    },
    onConfirmDelete(event) {
      this.deleteTarget(event.targetId)
    },
    onFilterByCategory(event) {
      this.filters.category = event ? event.id : null
      this.refresh()
    }
  }
}
</script>

<style lang="css">
.active-tab {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  opacity: 1 !important;
}
.tooltip-custom {
  font-size: 15px;
  color: white;
  background: rgba(0, 0, 0, 0.69);
  border-radius: 3px;
  padding: 12px 0px 12px 12px;
}
.v-small-dialog {
  width: fit-content;
}
.circle-operations {
  height: 35px;
  width: 35px;
  background: #fff4e5;
  position: relative;
  border-radius: 50%;
}
.circle-operations span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
</style>
