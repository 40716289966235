<template>
  <v-layout pt-3 mb-3 row justify-center>
    <v-flex md8 sm10>
      <v-card>
        <v-card-text>
          <v-layout row justify-center py-3>
            <v-flex md4 sm6 xs8>
              <v-img
                :src="require('@/assets/images/logo/logo_color.svg')"
                class="ml-2 mr-2"
              ></v-img>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <div class="text-xs-center headline">Conditions Générales</div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text v-if="loaded">
          <div v-html="cg.content" />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import req from '@/packs/request.js'

export default {
  data() {
    return {
      cg: null,
      loaded: false,
    }
  },
  created() {
    this.fetchCg()
  },
  methods: {
    async fetchCg() {
      const subdomain = window.location.hostname.split('.')[0]
      const param = subdomain == 'campaign' ? '' : `?by_subdomain=${subdomain}`

      try {
        const res = await req.get(`/cgu${param}`)

        this.cg = res.data
        this.loaded = true
      } catch (err) {
        if (err.response.status === 404) {
          const res = await req.get(`/cgu`)

          this.cg = res.data
          this.loaded = true
        }
      }
    },
  },
}
</script>
