<template>
  <v-btn flat dark color="blue-grey darken-1" @click="seeTransactions()">
    <v-icon left>attach_money</v-icon>{{ $t('campaignListItem.seeTransactions') }}
  </v-btn>
</template>

<script>
export default {
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  methods: {
    seeTransactions() {
      let path = ''
      if (this.campaign.type === 'fidelity') path = '/core-program'
      else
        path =
          this.campaign.type === 'subfidelity'
            ? `/core-program/campaigns/${this.campaign.id}`
            : `/campaigns/${this.campaign.id}`

      this.$router.push(`${path}/transactions`).catch(err => err)
    }
  }
}
</script>
