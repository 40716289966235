<template>
  <v-container fluid grid-list-xl>
    <CampaignBuilderLineList :campaign="campaign" />
  </v-container>
</template>

<script>
import CampaignBuilderLineList from '@/components/CampaignBuilderLineList'

export default {
  components: {
    CampaignBuilderLineList
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  }
}
</script>
