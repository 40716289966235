<template>
  <div v-if="!loading">
    <div class="header-card pa-3 disp-flex">
      <h3 class="font-weight-medium">
        <v-icon small class="align-icon">format_align_justify</v-icon>
        {{ $t('settings.termsConditions.title') }}
      </h3>
    </div>
    <div class="pt-3 px-5">
      <h3 class="font-weight-medium mb-2">{{ $t('settings.termsConditions.termOfUse') }} :</h3>
      <BgEditor v-model="companySettings.term_of_use" class="w-100" />
      <h3 class="font-weight-medium mb-2">{{ $t('settings.termsConditions.legalNotice') }} :</h3>
      <BgEditor v-model="companySettings.legal_notice" class="w-100" />
      <div class="pb-3">
        <v-btn color="primary" @click="save()" class="mx-0">
          <v-icon small class="mr-2">save</v-icon>
          {{ $t('settings.saveChanges') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import req from '@/packs/request.js'
import BgEditor from '@/components/Editor/BgEditor.vue'

export default {
  components: {
    BgEditor
  },
  props: {
    user: {
      type: Object
    }
  },
  data: function() {
    return {
      companySettings: {},
      loading: false
    }
  },
  methods: {
    async fetchCompanySettings() {
      this.loading = true

      const res = await req.get('/user/company/setting')
      this.companySettings = res.data

      this.loading = false
    },
    async save() {
      const res = await req.put('/user/company/setting', {
        company_settings: {
          term_of_use: this.companySettings.term_of_use,
          legal_notice: this.companySettings.legal_notice
        }
      })
      this.companySettings = res.data
    }
  },
  created() {
    this.fetchCompanySettings()
  }
}
</script>
