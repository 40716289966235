<template>
  <v-container grid-list-xl>
    <v-layout class="text-md-center purchase-table-container">
      <v-flex grow>
        <div class="slider__controls">
          <h3 class="slider__header">{{ $t('communications.orderSMS') }} :</h3>
        </div>
        <v-slider
          :disabled="true"
          :min="smsMin"
          :max="smsMax"
          :thumb-size="48"
          :tick-size="smsStep"
          :step="smsStep"
          v-model="sms"
          thumb-label
        />
        <SliderLegend :max="smsMax" :min="smsMin" />

        <PricingTable
          :loading="loading"
          class="mt-5"
          :headers="smsOrderHeaders"
          :items="smsOrder"
        />
      </v-flex>
      <v-flex grow>
        <div class="slider__controls">
          <h3 class="slider__header">
            {{ $t('communications.orderEmail') }} :
          </h3>
        </div>
        <v-slider
          v-model="emails"
          :rules="sliderRules"
          :disabled="loading || lastStepBeforePayment"
          :min="emailsMin"
          :max="emailsMax"
          :tick-size="emailsStep"
          :step="emailsStep"
          :thumb-size="48"
          thumb-label
        />
        <SliderLegend :max="emailsMax" :min="emailsMin" />

        <PricingTable
          :loading="loading"
          class="mt-5"
          :headers="emailOrderHeaders"
          :items="emailOrder"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import SliderLegend from '@/components/Communications/SliderLegend.vue'
import PricingTable from '@/components/Communications/tables/PricingTable.vue'
import PurchaseService from '@/services/PurchaseService'
import API from '@/API'

@Component({
  components: {
    PricingTable,
    SliderLegend
  },
  data() {
    return {
      sliderRules: [v => v === 0 || v >= 20000 || "Minimum d'achat: 20 000 mails"]
    }
  }
})
export default class Purchase extends Vue {
  @Getter('order/selectedEmails') selectedEmails
  @Getter('order/selectedSms') selectedSms
  @Getter('order/emailOrder') emailOrder
  @Getter('order/smsOrder') smsOrder

  @Getter('order/smsMax') smsMax
  @Getter('order/smsMin') smsMin
  @Getter('order/smsStep') smsStep

  @Getter('order/emailsMin') emailsMin
  @Getter('order/emailsMax') emailsMax
  @Getter('order/emailsStep') emailsStep

  @Getter('order/lastStepBeforePayment') lastStepBeforePayment

  @Action('order/calculateEmailOrder') calculateEmailOrder
  @Action('order/calculateSmsOrder') calculateSmsOrder
  @Action('order/selectEmails') selectEmail
  @Action('order/selectSms') selectSms

  @Action('order/setLoading') setLoading
  @Getter('order/loading') loading
  @Action('order/setPricing') setPricing

  @Action('order/updateSmsMax') updateSmsMax
  @Action('order/updateEmailMax') updateEmailMax

  mounted() {
    this.fetchRates()
  }

  async fetchRates() {
    this.setLoading(true)
    const smsPricing = await this.fetchSmsRate()
    const emailPricing = await this.fetchEmailRate()
    this.setPricing({ sms: smsPricing, emails: emailPricing })
    this.setLoading(false)
  }

  async fetchSmsRate() {
    const res = await PurchaseService.getSmsRate()
    const sms = []

    if (res.data.rates.length > 0) {
      res.data.rates.forEach(rate => {
        let amount = rate.max - rate.min + 1

        if (rate.max === this.smsMax) amount = this.smsMax
        sms.push({
          quantity: '< ' + this.$n(rate.max, 'decimal'),
          unitCost: rate.unit_price / 1000,
          amount: amount,
          orderQuantity: 0,
          cost: 0
        })
      })
    }
    return sms
  }

  async fetchEmailRate() {
    const res = await PurchaseService.getEmailRate()
    const emails = []

    if (res.data.rates.length > 0) {
      res.data.rates.forEach(rate => {
        let amount = rate.max - rate.min + 1

        if (rate.max === this.emailsMax) amount = this.emailsMax
        emails.push({
          quantity: '< ' + this.$n(rate.max, 'decimal'),
          unitCost: rate.unit_price.toCurrency() / 1000,
          amount: amount,
          orderQuantity: 0,
          cost: 0
        })
      })
    }
    return emails
  }

  async fetchOrderPricing() {
    this.setLoading(true)
    const pricing = await API.fetchOrderPricing()
    this.setPricing(pricing)
    this.setLoading(false)
  }

  get emails() {
    return this.selectedEmails
  }

  set emails(emails) {
    this.selectEmail(parseInt(emails) ? parseInt(emails) : 0)
  }

  get sms() {
    return this.selectedSms
  }

  set sms(sms) {
    this.selectSms(parseInt(sms) ? parseInt(sms) : 0)
  }

  emailOrderHeaders = [
    {
      text: 'communications.purchaseHistory.emailQuantity',
      sortable: false
    },
    {
      text: 'communications.purchaseHistory.costPerEmail',
      sortable: false
    },
    {
      text: 'communications.quantity',
      sortable: false
    },
    {
      text: 'communications.cost',
      sortable: false
    }
  ]

  get smsOrderHeaders() {
    return this.emailOrderHeaders.map(header => {
      return {
        ...header,
        text: header.text.replace('Email', 'SMS').replace('email', 'SMS')
      }
    })
  }

  @Watch('sms')
  smsWatcher(current) {
    this.calculateSmsOrder(current)
  }

  @Watch('emails')
  emailsWatcher(current) {
    this.calculateEmailOrder(current)
  }
}
</script>

<style lang="scss" scoped>
.purchase-table-container {
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.slider {
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input {
    width: 75px !important;
    flex-grow: 0;
  }
}
</style>
