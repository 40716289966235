<template>
  <v-container grid-list-lg fluid>
    <v-layout row wrap justify-center>
      <v-flex lg5 sm6 xs12 mt-3>
        <v-card class="py-2">
          <v-card-title class="title py-3 flex-div flex-column align-center">
            <h2 class="font-weight-light text-xs-center">
              {{ $t('resetPassword.title') }}
            </h2>
          </v-card-title>
          <v-card-text class="align-center">
            <v-form @keypress.enter.native.prevent="resetPassword()">
              <v-text-field
                v-model="$v.password.$model"
                :error-messages="passwordErrors"
                :label="$t('settings.account.newPassword')"
                class="mx-md-4"
                color="primary"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
              >
              </v-text-field>
              <v-text-field
                v-model="$v.password_confirmation.$model"
                :error-messages="passwordConfirmationErrors"
                :label="$t('settings.account.confirmPassword')"
                color="primary"
                :type="showPassword ? 'text' : 'password'"
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-3 flex-div flex-column align-center">
            <v-btn class="mt-4 text-xs-center" @click="resetPassword()" color="primary">
              {{ $t('resetPassword.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService'
import EventBus from '@/packs/event-bus'

import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  data: () => ({
    password: '',
    password_confirmation: '',
    showPassword: false
  }),
  validations: {
    password: { required, minLength: minLength(8) },
    password_confirmation: { required, sameAsPassword: sameAs('password') }
  },
  methods: {
    async resetPassword() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        try {
          await AuthService.resetPassword(this.password, this.password_confirmation, this.$route.query.token)

          AuthService.logout()
          EventBus.$emit('showSnackbar', {
            text: 'Votre mot de passe à été modifié avec succès',
            color: 'success'
          })

          this.$router.push('/signin').catch()
        } catch (err) {
          EventBus.$emit('showSnackbar', {
            text: err.response.data.errors[0],
            color: 'error'
          })
        }
      }
    }
  },
  computed: {
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required.')
      !this.$v.password.minLength &&
        errors.push(`Password must be at most ${this.$v.password.$params.minLength.min} characters long.`)
      return errors
    },
    passwordConfirmationErrors() {
      const errors = []
      if (!this.$v.password_confirmation.$dirty) return errors
      !this.$v.password_confirmation.required && errors.push('Password Confirmation is required.')
      !this.$v.password_confirmation.sameAsPassword && errors.push('Passwords must be identical.')
      return errors
    }
  }
}
</script>
