<template>
  <v-layout row fluid justify-center>
    <v-dialog
      v-model="displayCustomerModal"
      @input="$router.go(-1)"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile class="customer-sheet">
        <v-toolbar card dark color="primary">
          <v-btn icon dark @click="$router.go(-1)">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('customers.data.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="deleteAccount()">
              <v-icon small class="mr-2">delete</v-icon>
              Archiver le compte
            </v-btn>
            <v-btn dark flat @click="refresh()">
              <v-icon small class="mr-2">refresh</v-icon>
              {{ $t('customers.data.refresh') }}
            </v-btn>
            <v-btn dark flat @click="edit()">
              <v-icon small class="mr-2">{{ editCustomer ? 'save' : 'edit' }}</v-icon>
              {{ editCustomer ? $t('common.save') : $t('common.edit') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="px-0" v-if="!loading && customer">
          <v-layout row wrap py-3 px-4>
            <v-flex md6>
              <v-layout row wrap>
                <v-flex xs12>
                  <h3>Identification</h3>
                </v-flex>
                <v-flex xs6>
                  <v-list-tile-content class="pr-3 pb-3">
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                        {{ $t('customers.data.firstName') }}
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <v-text-field
                        v-if="editCustomer"
                        type="text"
                        v-model="customer.firstname"
                        class="pt-0 mt-0"
                        :placeholder="$t('customers.data.add_firstName')"
                        hide-details
                      />
                      <div v-else class="py-1">{{ customer.firstname || '-' | capitalize }}</div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
                <v-flex xs6>
                  <v-list-tile-content class="pr-3 pb-3">
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                        {{ $t('customers.data.lastName') }}
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <v-text-field
                        v-if="editCustomer"
                        type="text"
                        v-model="customer.lastname"
                        :placeholder="$t('customers.data.add_lastName')"
                        class="pt-0 mt-0"
                        hide-details
                      />
                      <div v-else class="py-1">{{ customer.lastname || '-' | capitalize }}</div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
                <v-flex xs6>
                  <v-list-tile-content class="pr-3 pb-3">
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                        {{ $t('customers.data.gender') }}
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <v-radio-group row v-if="editCustomer" v-model="customer.gender" class="py-1 mt-0" hide-details>
                        <v-radio value="male" :label="$t('common.male')" />
                        <v-radio value="female" :label="$t('common.female')" />
                      </v-radio-group>
                      <div v-else class="py-1">
                        {{ (customer.gender ? $t(`common.${customer.gender}`) : '-') | capitalize }}
                      </div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
                <v-flex xs6>
                  <v-list-tile-content class="pr-3 pb-3">
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                        {{ $t('customers.data.birthday') }}
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <v-menu
                        v-if="editCustomer"
                        v-model="dateMenuEdit"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="customer.birthdate"
                            readonly
                            v-on="on"
                            hide-details
                            :placeholder="$t('customers.data.add_birthday')"
                            class="pt-0 mt-0"
                          />
                        </template>
                        <v-date-picker
                          v-model="customer.birthdate"
                          @input="dateMenuEdit = false"
                          :first-day-of-week="1"
                          :max="new Date().toISOString().substr(0, 10)"
                        ></v-date-picker>
                      </v-menu>
                      <div v-else class="py-1">{{ customer.birthdate || '-' }}</div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
                <v-flex xs12 md6>
                  <v-list-tile-content class="pr-3 pb-3">
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                        {{ $t('customers.data.address') }}
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <div class="disp-flex align-center">
                        <div class="caption mr-2">{{ $t('settings.address1') }} :</div>
                        <v-text-field
                          v-if="editCustomer"
                          type="text"
                          :value="customerAddress_.line_1"
                          @input="setAddress({ line_1: $event })"
                          class="pt-0 mt-0"
                          hide-details
                          :placeholder="$t('customers.data.add_address1')"
                        />
                        <div v-else class="py-1">{{ customerAddress_.line_1 || '-' }}</div>
                      </div>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <div class="disp-flex align-center">
                        <div class="caption mr-2">{{ $t('settings.address2') }} :</div>
                        <v-text-field
                          v-if="editCustomer"
                          type="text"
                          :value="customerAddress_.line_2"
                          @input="setAddress({ line_2: $event })"
                          class="pt-0 mt-0"
                          hide-details
                          :placeholder="$t('customers.data.add_address2')"
                        />
                        <div v-else class="py-1">{{ customerAddress_.line_2 || '-' }}</div>
                      </div>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <div class="disp-flex align-center">
                        <div class="caption mr-2">{{ $t('settings.zipCode') }} :</div>
                        <v-text-field
                          v-if="editCustomer"
                          type="number"
                          :value="customerAddress_.zipcode"
                          @input="setAddress({ zipcode: $event })"
                          class="pt-0 mt-0"
                          hide-details
                          :placeholder="$t('customers.data.add_zipcode')"
                        />
                        <div v-else class="py-1">{{ customerAddress_.zipcode || '-' }}</div>
                      </div>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <div class="disp-flex align-center">
                        <div class="caption mr-2">{{ $t('settings.city') }} :</div>
                        <v-text-field
                          v-if="editCustomer"
                          type="text"
                          :value="customerAddress_.city"
                          @input="setAddress({ city: $event })"
                          class="pt-0 mt-0"
                          hide-details
                          :placeholder="$t('customers.data.add_city')"
                        />
                        <div v-else class="py-1">{{ customerAddress_.city || '-' }}</div>
                      </div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
                <v-flex xs12 md6>
                  <v-list-tile-content class="pr-3 pb-3" style="justify-content: flex-start">
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                        {{ $t('customers.data.family') }}
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <span v-if="!customer.family">-</span>
                      <div
                        v-else
                        v-for="family of customer.family_members"
                        :key="family.id"
                        class="mt-2 disp-flex align-center"
                      >
                        <v-chip
                          disabled
                          small
                          style="width: fit-content"
                          color="primary"
                          text-color="white"
                          class="ma-0"
                        >
                          <span
                            v-for="(attribute, index) of family.custom_attributes"
                            :key="index"
                            class="body-1 font-weight-bold"
                          >
                            {{ attribute | capitalize }}
                            <span v-if="index < 1 && Object.keys(family.custom_attributes).length >= 2" class="mr-1"
                              >:</span
                            >
                          </span>
                        </v-chip>
                        <div class="font-weight-medium ml-2">{{ family.name | capitalize }}</div>
                        <div v-if="family.birthdate" class="ml-1 caption">- {{ family.birthdate }}</div>
                      </div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md6>
              <v-layout row wrap :class="{ 'mt-4': $vuetify.breakpoint.xs }">
                <v-flex xs12>
                  <h3>{{ $t('customers.data.contactInfo') }}</h3>
                </v-flex>
                <v-flex xs12>
                  <v-list-tile-content
                    class="pr-3"
                    :class="{
                      'pb-3': !(
                        (customer.unconfirmed_email && customer.confirmation_link_sent_at && !editCustomer) ||
                        (customer.email_status === 'email_invalid' && customer.confirmation_link_sent_at)
                      ),
                      'pb-0':
                        ((customer.unconfirmed_email && customer.confirmation_link_sent_at) ||
                          (customer.email_status === 'email_invalid' && customer.confirmation_link_sent_at)) &&
                        !editCustomer
                    }"
                  >
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2 disp-flex align-center">
                        E-mail
                        <template v-if="!editCustomer">
                          <template v-if="(customer.email_status !== 'email_invalid' || customer.unconfirmed_email_status !== 'email_invalid')">
                            -
                            <span
                              v-if="customer.first_visit && !customer.unconfirmed_email"
                              @click="startEnrollment()"
                              class="caption mx-1 primary--text cursor-pointer"
                            >
                              Lancer l'enrôlement
                            </span>
                            <span v-else @click="startEnrollment()" class="caption mx-1 primary--text cursor-pointer">
                              Relancer la confirmation
                            </span>
                          </template>
                          <template v-if="!customer.confirmed_at && customer.unconfirmed_email">
                            -
                            <span @click="revalidateEmail()" class="caption mx-1 primary--text cursor-pointer">
                              Retester la validité du mail du compte
                            </span>
                          </template>
                        </template>
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <v-text-field
                        v-if="editCustomer"
                        type="text"
                        v-model="customer.email"
                        class="pt-0 mt-0"
                        hide-details
                      />
                      <div v-else class="pt-1 disp-flex">
                        {{ customer.email ? customer.email : '-' }}
                        <v-chip v-if="customer.email" disabled small style="height: 24px !important;" class="ma-0 ml-2" :text-color="(customer.email_status === 'email_valid' || customer.confirmed_at) && customer.email_status !== 'email_invalid' ? '#4ad991' : customer.email_status === 'email_pending' ? '#ffca83' : '#fc394b'"  :color="(customer.email_status === 'email_valid' || customer.confirmed_at) && customer.email_status !== 'email_invalid' ? '#daf7e8' : customer.email_status === 'email_pending' ? '#fff4e5' : '#ffa7af'">
                          <span class="font-weight-bold">
                            {{ $t(`customers.data.${customer.email_status}`) }}
                            <template class="ml-1" v-if="customer.email_status === 'email_invalid' || customer.email_status === 'email_unknown'">
                              (Changer l'email pour relancer les procédures)
                            </template>
                          </span>
                          <span class="mx-1" v-if="customer.email_status !== 'email_invalid' && customer.email_status !== 'email_unknown'">
                            -
                          </span>
                          <span v-if="customer.confirmed_at && customer.email_status !== 'email_invalid'" class="caption">
                            {{ customer.confirmed_at ? `Confirmé le ${$moment(customer.confirmed_at).format('DD-MM-YYYY')}` : 'Date de confirmation indisponible' }}
                          </span>
                          <span v-else-if="((!customer.confirmed_at || customer.unconfirmed_email) && customer.confirmation_link_sent_at && customer.email_status !== 'email_invalid') || (customer.confirmation_link_sent_at && customer.email_status === 'email_pending')" class="caption">
                            {{ customer.confirmation_link_sent_at ? `Envoyé le ${$moment(customer.confirmation_link_sent_at).format('DD-MM-YYYY')} (En attente de confirmation)` : `Date d'envoi indisponible` }}
                          </span>
                          <span v-else-if="(customer.email_status === 'email_pending' || customer.email_status === 'email_valid') && !customer.confirmation_link_sent_at" class="caption">
                            Aucun email d'enrôlement n'a encore été envoyé
                          </span>
                        </v-chip>
                      </div>
                      <div v-if="(customer.unconfirmed_email_status === 'email_pending' || customer.unconfirmed_email_status === 'email_valid') && customer.unconfirmed_email && !editCustomer" class="caption orange--text darken-1 disp-flex align-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon small class="mr-1 orange--text darken-1 cursor-pointer" v-on="on">info</v-icon>
                          </template>
                          <span>
                            Cette email est en cours de confirmation, elle ne sera actif sur le compte qu'une fois confirmé.
                          </span>
                        </v-tooltip>
                        En attente de confirmation : {{ $t(`customers.data.${customer.unconfirmed_email_status}`) }} ({{ customer.unconfirmed_email ? customer.unconfirmed_email : 'Indisponible ' }})
                        <span v-if="customer.confirmation_link_sent_at" class="caption ml-1">
                          - {{ customer.confirmation_link_sent_at ? `Envoyé le ${$moment(customer.confirmation_link_sent_at).format('DD-MM-YYYY')}` : `Date d'envoi indisponible` }}
                        </span>
                      </div>
                      <div v-if="customer.email_status === 'email_invalid' && customer.confirmation_link_sent_at && !customer.unconfirmed_email && !editCustomer" class="caption red--text darken-1">
                        Tentative le : {{ $moment(customer.confirmation_link_sent_at).format('DD-MM-YYYY') }}
                      </div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
                <v-flex xs12 md6 lg6>
                  <v-list-tile-content class="pr-3 pb-3">
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                        {{ $t('customers.data.phone') }}
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <v-text-field
                        v-if="editCustomer"
                        type="number"
                        v-model="customer.phone"
                        class="pt-0 mt-0"
                        hide-details
                        :placeholder="$t('customers.data.add_phone')"
                      />
                      <div v-else class="py-1">{{ customer.phone ? customer.phone : '-' }}</div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
                <v-flex xs12 md6 lg6>
                  <v-list-tile-content class="pr-3 pb-3">
                    <v-list-tile-title>
                      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                        {{ $t('customers.data.mobile') }}
                      </h3>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <v-text-field
                        v-if="editCustomer"
                        type="number"
                        v-model="customer.mobile"
                        class="pt-0 mt-0"
                        hide-details
                        :placeholder="$t('customers.data.add_mobile')"
                      />
                      <div v-else class="py-1">{{ customer.mobile ? customer.mobile : '-' }}</div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-flex>
                <v-flex xs12 md6>
                  <v-checkbox
                    :disabled="!editCustomer"
                    v-model="customer.accept_mail_contact"
                    @change="updateContact()"
                    :label="$t('customers.data.acceptMail')"
                  />
                </v-flex>
                <v-flex xs12 md6>
                  <v-checkbox
                    :disabled="!editCustomer"
                    v-model="customer.accept_postal_contact"
                    @change="updateContact()"
                    :label="$t('customers.data.acceptPostMail')"
                  />
                </v-flex>
                <v-flex xs12 md6>
                  <v-checkbox
                    :disabled="!editCustomer"
                    v-model="customer.accept_sms_contact"
                    @change="updateContact()"
                    :label="$t('customers.data.acceptSMS')"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <div class="py-4 my-2 grey lighten-4">
            <v-layout row wrap px-4>
              <v-flex xs12 md9>
                <v-layout>
                  <v-flex xs12>
                    <h3>{{ $t('customers.data.loyaltyProgram') }}</h3>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs6 md3>
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.entryDate') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <div class="py-1">{{ customer.created_at ? customer.created_at.split(/[ ,]+/)[0] : '-' }}</div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs6 md3 v-if="customer.wallets && customer.wallets.length > 0">
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.poolBalance') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <div class="py-1 font-weight-bold primary--text">{{ wallet(customer) }}</div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.status') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <div class="py-1">{{ customer.status_level ? customer.status_level.name : '-' }}</div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.RFMsegments') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <div v-if="customer.segments && customer.segments.length > 0" class="py-1">
                          <template v-for="segment in customer.segments">
                            <div :key="segment.id">{{ segment.custom ? '' : segment.name }}</div>
                          </template>
                        </div>
                        <div v-else class="py-1">
                          -
                        </div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.frequency') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <div class="py-1">{{ counter(customer, 'frequency') }}</div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.recency') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <div class="py-1">{{ recency(counter(customer, 'recency')) }} jour(s)</div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.revenues') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <div class="py-1">{{ counter(customer, 'monetary_cumulative_amount') }}</div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.averageBasket') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <div class="py-1">{{ counter(customer, 'monetary_average_amount') }}</div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs12 md6>
                    <CustomersDataSheetListIdentifier
                      v-if="customer.identifiers"
                      :identifiers="customer.identifiers"
                      :customer_id="customer.id"
                      @update="fetchCustomer()"
                    />
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-list-tile-content class="pr-3 pb-3" v-if="pointOfSales.length > 0">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.attachedPOS') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title class="flex-div flex-column">
                        <div class="py-1">
                          <b>{{ getPOS }}</b> ({{ customer.point_of_sale_code }})
                        </div>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md3>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md12>
                    <v-list-tile-content class="pr-3 pb-3">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          {{ $t('customers.data.commercialGesture') }}
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title class="text-xs-center text-sm-left">
                        <v-btn class="mt-2 ml-0" color="primary" @click="grantPointDialog = true">
                          {{ $t('customers.data.updateBalance') }}
                        </v-btn>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                  <v-flex xs12 sm6 md12>
                    <v-list-tile-content class="justify-start">
                      <v-list-tile-title>
                        <h3 class="font-weight-regular blue-grey--text text--lighten-2">
                          Vérification balance
                        </h3>
                      </v-list-tile-title>
                      <v-list-tile-sub-title class="text-xs-center text-sm-left">
                        <v-btn class="mt-2 ml-0" color="primary" @click="checkBalance()">
                          Check
                        </v-btn>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
          <v-layout px-4 py-3>
            <v-flex xs12>
              <h3>{{ $t('customers.data.transactionsHistory') }}</h3>
            </v-flex>
          </v-layout>
          <v-layout py-2 px-4 column class="transactions">
            <v-flex xs12 py-2 px-0>
              <TransactionsTable :api-url="transactionsUrl" :per-page="5"></TransactionsTable>
            </v-flex>
          </v-layout>
          <DialogGrantPoints
            :display-modal="grantPointDialog"
            :id="customer.id"
            @refresh="fetchCustomer()"
            @close="grantPointDialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'
import TransactionsTable from '@/components/TransactionsTable'
import DialogGrantPoints from '@/components/DialogGrantPoints'
import CustomersDataSheetListIdentifier from '@/components/CustomersDataSheetListIdentifier'

export default {
  components: {
    TransactionsTable,
    DialogGrantPoints,
    CustomersDataSheetListIdentifier
  },
  props: {
    id: {
      type: String
    },
    displayCustomerModal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      customer: {},
      dateMenuEdit: false,
      grantPointDialog: false,
      editCustomer: false,
      transactionsUrl: `/customers/${this.id}/transactions`,
      pointOfSales: []
    }
  },
  computed: {
    ...mapState({
      loyaltyCurrency: state => state.user.loyaltyCurrency
    }),
    customerAddress_() {
      return this.customer.location || ''
    },
    getPOS() {
      if (this.pointOfSales && this.pointOfSales.length > 0) {
        const res = this.pointOfSales.find(e => e.code === this.customer.point_of_sale_code)
        if (res) return res.name
      }
      return ''
    }
  },
  methods: {
    async fetchPOS() {
      const res = await req.get('/point_of_sales?with_discarded=true')
      this.pointOfSales = res.data
    },
    async fetchCustomer() {
      const composition = 'wallets,identifiers,segments,location,offers'

      const res = await req.get(`/customers/${this.id}?with_statistics=true&composition=${composition}`)
      this.assignValue(res.data)
    },
    async checkBalance() {
      await req.post(`/customers/${this.id}/out_store_offers`)
      await this.fetchCustomer()
      EventBus.$emit('refreshTransactions')
      EventBus.$emit('showSnackbar', {
        text: this.$t('customers.data.messages.update'),
        color: 'success'
      })
    },
    async edit() {
      const composition = 'wallets,identifiers,segments,location,offers'
      this.editCustomer = !this.editCustomer

      if (!this.editCustomer) {
        const res = await req.put(`/customers/${this.id}?with_statistics=true&composition=${composition}`, {
          customer: this.customer
        })
        this.assignValue(res.data)
        EventBus.$emit('showSnackbar', {
          text: this.$t('customers.data.messages.update'),
          color: 'success'
        })
      }
    },
    async refresh() {
      await this.fetchCustomer()
      EventBus.$emit('showSnackbar', {
        text: this.$t('customers.data.messages.refresh'),
        color: 'success'
      })
    },
    recency(value) {
      if (value) return this.$moment().diff(this.$moment.unix(value), 'days')
      else return '-'
    },
    counter(customer, name) {
      if (customer.counters) {
        if (name.indexOf('amount') >= 0) return customer.counters[name] ? `${customer.counters[name].toCurrency()}€` : 0
        else return customer.counters[name] ? customer.counters[name] : '-'
      } else return '-'
    },
    wallet(customer) {
      if (customer.wallets && customer.wallets.length > 0)
        return customer.wallets[0].balance + ' ' + this.loyaltyCurrency.name
      else return '-'
    },
    assignValue(customer) {
      this.customer = customer
      this.customer.location_attributes = this.customer.location || {}
    },
    setAddress(key) {
      Object.assign(this.customer.location_attributes, key)
    },
    async startEnrollment() {
      await req.post(`/customers/${this.id}/resend_emails`)
      await this.fetchCustomer()
      EventBus.$emit('showSnackbar', {
        text: this.$t('customers.data.messages.enrollment'),
        color: 'success'
      })
    },
    async revalidateEmail() {
      await req.post(`/customers/${this.id}/revalidate_email`)
      await this.fetchCustomer()
      EventBus.$emit('showSnackbar', {
        text: this.$t('customers.data.messages.revalidate'),
        color: 'success'
      })
    },
    async deleteAccount() {
      this.$swal
        .fire({
          title: 'Êtes vous sûr ?',
          text: `Vous ne pourrez pas revenir en arrière`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'red',
          cancelButtonText: 'Annuler',
          confirmButtonColor: 'green',
          confirmButtonText: 'Archiver'
        })
        .then(async result => {
          if (result.isConfirmed) {
            await req.delete(`/customers/${this.id}`)
            this.$swal.fire('Votre compte a bien été archivé', '', 'success').then(() => {
              this.$router.go(-1)
            })
          }
        })
    },
    async updateContact() {
      await req.put(`/customers/${this.id}/optins`, {
        customers: {
          accept_mail_contact: this.customer.accept_mail_contact,
          accept_sms_contact: this.customer.accept_sms_contact,
          accept_postal_contact: this.customer.accept_postal_contact
        }
      })
    }
  },
  async created() {
    this.loading = true
    await this.fetchPOS()
    await this.fetchCustomer()
    this.loading = false
  }
}
</script>

<style lang="css">
.customer-sheet .v-list__tile__sub-title {
  font-size: 16px;
}
</style>
