import AxiosWrapper, { setToken, getTokenPresence, clearToken } from '@/services/AxiosWrapper'
import * as Sentry from '@sentry/browser'
import store from '@/store/index'

const END_POINT = '/accounts'

export default {
  async login(email: string, password: string): Promise<any> {
    const res = await AxiosWrapper.post(`${END_POINT}/sign_in`, {
      account: {
        email: email,
        password: password
      }
    })

    setToken(res.headers.authorization)

    Sentry.configureScope(scope => {
      scope.setUser({
        id: res.data.id,
        email: res.data.email,
        brand: res.data.brand,
        distributor: res.data.distributor
      })
    })

    store.dispatch('fetchAccount')
    store.dispatch('fetchCompany')
    store.dispatch('fetchCapabilities')
    store.dispatch('fetchLoyaltyCurrency')
    store.dispatch('setTokenPresence', getTokenPresence())

    return res.data
  },

  async logout(): Promise<any> {
    const res = await AxiosWrapper.delete(`${END_POINT}/sign_out`)
    clearToken()

    store.dispatch('clearNotificationsInterval')
    store.dispatch('setTokenPresence', getTokenPresence())

    return res.data
  },

  async resetPassword(password: string, passwordConfirmation: string, resetPasswordToken: string): Promise<any> {
    const res = await AxiosWrapper.put(`${END_POINT}/password`, {
      account: {
        password: password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken
      }
    })

    return res.data
  }
}
