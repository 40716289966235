<template>
  <div>
    <v-card style="height: fit-content">
      <transition name="fade" mode="out-in" :duration="500">
        <div v-if="!expandAcceptAsPublisher">
          <div class="px-3 pt-3 pb-1 disp-flex" style="min-height: 120px">
            <div class="disp-flex align-center mr-3">
              <img v-if="campaign.logo_url > 0" :src="campaign.logo_url" class="campaign-img elevation-2" />
              <img v-else-if="campaign.advertiser && campaign.advertiser.logo_url" :src="campaign.advertiser.logo_url" class="campaign-img elevation-2" />
              <img v-else src="@/assets/images/standard/default-image.png" class="campaign-img elevation-2" />
            </div>
            <div class="w-100 overflow-hidden">
              <div class="disp-flex mb-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <h2 class="font-weight-medium ellipsis pr-3" v-on="on">{{ campaign.name }}</h2>
                  </template>
                  <span>{{ campaign.name }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-chip
                  v-if="commissionContract && isBroadcasterCampaign"
                  small
                  disabled
                  class="mx-0"
                  outline
                  :color="
                    commissionContract.status === 'accepted'
                      ? '#4ad991'
                      : commissionContract.status === 'pending'
                      ? 'yellow darken-3'
                      : '#ff7285'
                  "
                >
                  <v-avatar>
                    <v-icon>{{ stateIcon }}</v-icon>
                  </v-avatar>
                  {{ $t(`status.${commissionContract.status}`) }}
                </v-chip>
                <v-chip
                  v-else
                  small
                  disabled
                  class="mx-0"
                  outline
                  :color="
                    campaign.status === 'running'
                      ? '#4ad991'
                      : campaign.status === 'pending'
                      ? 'yellow darken-3'
                      : '#ff7285'
                  "
                >
                  <strong> {{ $t(`filters.${campaign.status}`) }} </strong>
                </v-chip>
              </div>
              <CampaignRewardMessage
                v-for="(reward, index) in rewards"
                :reward="reward"
                :index="index"
                :key="reward.id"
              />
              <CampaignListItemPublisherFees
                v-if="isBroadcasterCampaign && commissionContract"
                :commission-contract="commissionContract"
                :kind="campaign.kind"
              />
              <a
                v-if="isEmailPublishing"
                @click="expandCard = !expandCard"
                class="caption disp-flex align-center mt-2"
                style="color: #3f51b5"
              >
                Voir plus de détails
                <v-icon style="color: #3f51b5">{{ expandCard ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
              </a>
              <div v-else class="my-3"></div>
            </div>
          </div>
          <v-slide-y-transition>
            <v-card-text v-if="expandCard" class="pt-0">
              <v-divider class="mb-3"></v-divider>
              <PublishingInfo :campaign="campaign" />
            </v-card-text>
          </v-slide-y-transition>
          <div class="disp-flex blue-grey lighten-5" style="min-height: 40px">
            <div class="font-weight-medium mt-auto mb-auto ml-3 ellipsis">
              Temps restant : {{ campaignTimeleft ? campaignTimeleft : campaign.status === 'finished' ? 'Terminé' : 'N/A' }}
            </div>
            <template
              v-if="
                isBroadcasterCampaign &&
                  campaign.status !== 'finished' &&
                  commissionContract &&
                  commissionContract.status === 'pending'
              "
            >
              <v-spacer></v-spacer>
              <CampaignButtonAcceptAsPublisher
                :commission-contract-id="commissionContract.id"
                :kind="campaign.kind"
                @update="setCommissionContract({ index: index, value: $event })"
                @expand="expandAcceptAsPublisher = true"
              />
            </template>
            <template v-else-if="campaign.status !== 'finished'">
              <v-spacer></v-spacer>
              <template v-if="campaign.status !== 'draft'">
                <v-divider vertical></v-divider>
                <CampaignSwitchState :campaign="campaign" @update="setCampaignState({ index: index, value: $event })" />
              </template>
              <template v-if="showCampaignButtonStatus">
                <v-divider vertical></v-divider>
                <CampaignButtonStatus
                  :campaign="campaign"
                  @update="setCampaignStatus({ index: index, value: $event })"
                />
              </template>
            </template>
            <v-spacer v-else></v-spacer>
            <v-divider vertical></v-divider>
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-btn flat icon class="ma-0" color="#696969" style="height: auto; width: 50px" v-on="on">
                  <v-icon class="material-icons-round" style="font-size: 20px">
                    settings
                  </v-icon>
                </v-btn>
              </template>
              <div class="white text-align-selector disp-flex flex-column">
                <CampaignButtonSeeTransactions
                  class="ma-0"
                  v-if="showCampaignButtonSeeTransactions"
                  :campaign="campaign"
                />
                <CampaignButtonParticipantPos
                  v-if="!fidelity && campaign.status !== 'draft'"
                  :id="campaign.id"
                  :isBroadcasterCampaign="isBroadcasterCampaign"
                />
                <CampaignButtonPublishers
                  v-if="!fidelity && !isBroadcasterCampaign && campaign.status !== 'draft'"
                  :id="campaign.id"
                />
                <v-btn
                  v-if="
                    (isBroadcasterCampaign &&
                      campaign.state === 'active' &&
                      campaign.status === 'running' &&
                      commissionContract &&
                      commissionContract.status === 'accepted') ||
                      (campaign.kind === 'communication' && campaign.status === 'pending')
                  "
                  flat
                  dark
                  color="blue-grey darken-1"
                  class="ma-0 w-100"
                  @click="emailDialog = true"
                >
                  <v-icon left>email</v-icon>
                  Test e-mail
                </v-btn>
                <v-btn
                  v-if="campaign.mine && !isBroadcasterCampaign"
                  @click="editCampaign()"
                  flat
                  dark
                  color="blue-grey darken-1"
                  class="ma-0"
                >
                  <v-icon left>edit</v-icon>
                  {{ $t('common.edit') }}
                </v-btn>
                <CampaignButtonDelete
                  v-if="campaign.mine && ['pending', 'draft'].includes(campaign.status)"
                  :campaign="campaign"
                  @onDelete="deleteCampaign(index)"
                />
              </div>
            </v-menu>
          </div>
        </div>
        <CampaignExpandAcceptAsPublisher
          v-else
          :kind="campaign.kind"
          :commission-contract="commissionContract"
          @update="setCommissionContract({ index: index, value: $event })"
          @close="expandAcceptAsPublisher = false"
        />
      </transition>
    </v-card>
    <DialogDemoEmail v-model="emailDialog" :campaign-id="campaign.id" :campaign-kind="campaign.kind" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import CampaignRewardMessage from '@/components/CampaignRewardMessage'
import CampaignSwitchState from '@/components/CampaignSwitchState'
import CampaignButtonStatus from '@/components/CampaignButtonStatus'
import CampaignButtonSeeTransactions from '@/components/CampaignButtonSeeTransactions'
import CampaignButtonParticipantPos from '@/components/CampaignButtonParticipantPos'
import CampaignButtonPublishers from '@/components/CampaignButtonPublishers'
import CampaignButtonDelete from '@/components/CampaignButtonDelete'
import CampaignListItemPublisherFees from '@/components/CampaignListItemPublisherFees'
import DialogDemoEmail from '@/components/DialogDemoEmail.vue'
import CampaignExpandAcceptAsPublisher from '@/components/CampaignExpandAcceptAsPublisher.vue'
import CampaignButtonAcceptAsPublisher from '@/components/CampaignButtonAcceptAsPublisher'
import PublishingInfo from '@/components/Communications/PublishingInfo'

export default {
  components: {
    CampaignRewardMessage,
    CampaignSwitchState,
    CampaignButtonStatus,
    CampaignButtonSeeTransactions,
    CampaignButtonParticipantPos,
    CampaignButtonPublishers,
    CampaignButtonDelete,
    CampaignListItemPublisherFees,
    DialogDemoEmail,
    CampaignExpandAcceptAsPublisher,
    CampaignButtonAcceptAsPublisher,
    PublishingInfo
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      emailDialog: false,
      acceptStatus: '',
      expandAcceptAsPublisher: false,
      expandCard: false
    }
  },
  computed: {
    ...mapState({
      mode: state => state.campaignList.mode,
      tabIndex: state => state.campaignList.tabIndex,
      company: state => state.user.company
    }),
    rewards() {
      return this.campaign.rewards.filter(reward => reward.type !== 'core')
    },
    fidelity() {
      return this.mode === 'fidelity'
    },
    showCommunicationDetails() {
      return (
        (['limited', 'permanent'].includes(this.kind) || this.kind === 'communication') &&
        this.fidelity && this.status !== 'draft' && this.publishing.includes('email_published')
      )
    },
    isBroadcasterCampaign() {
      return this.campaign.type === 'conquest'
    },
    commissionContract() {
      return this.isBroadcasterCampaign
        ? this.campaign.commission_contracts.find(e => e.publisher.company.id === this.company.id)
        : null
    },
    stateIcon() {
      const icons = {
        accepted: 'check_circle',
        pending: 'watch_later',
        refused: 'remove_circle'
      }
      return icons[this.commissionContract.status]
    },
    campaignTimeleft() {
      if (this.campaign.status === 'pending') return this.$moment(this.campaign.start_at).fromNow()
      else if (this.campaign.status === 'running') {
        return this.campaign.end_at ? this.$moment(this.campaign.end_at).fromNow() : 'Permanent'
      } else return ''
    },
    isEmailPublishing() {
      return this.campaign.publishing && this.campaign.publishing.includes('email_published')
    },
    showCampaignButtonStatus() {
      return (
        (this.campaign.mine && this.campaign.status !== 'draft' && this.campaign.kind !== 'communication') ||
        (this.campaign.kind === 'communication' && this.campaign.status === 'pending')
      )
    },
    showCampaignButtonSeeTransactions() {
      return this.campaign.status !== 'draft' && this.campaign.kind !== 'communication'
    }
  },
  methods: {
    ...mapActions('campaignList', ['setCampaignState', 'setCampaignStatus', 'deleteCampaign', 'setCommissionContract']),
    editCampaign() {
      let path = ''

      if (this.fidelity) {
        if (['limited', 'permanent'].includes(this.campaign.kind)) path = `/campaigns/custom/${this.campaign.id}`
        else if (this.campaign.kind === 'communication') path = `/campaigns/communication/${this.campaign.id}`
        else path = `/campaigns/event/${this.campaign.id}`
      } else path = `/campaigns/${this.campaign.id}`

      this.$router.push(path)
    }
  }
}
</script>
