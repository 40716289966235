<template>
  <v-layout row wrap class="py-2">
    <v-flex xs12>
      <div class="white elevation-1 w-100">
        <v-layout class="px-3 pt-2">
          <v-flex xs12>
            <h2>New workplace group</h2>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="px-3" align-center>
          <v-flex md3>
            <h3>Type</h3>
            <v-radio-group row v-model="type">
              <v-radio color="primary" value="special" label="Special"></v-radio>
              <v-radio color="primary" value="hierarchical" label="Hierarchical"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex md2>
            <h3>Group name</h3>
            <v-text-field label="Name" v-model="name"></v-text-field>
          </v-flex>
          <v-flex grow>
            <v-alert class="mx-4" :value="true" type="info" outline>
              <h3 class="my-0">{{ type | capitalize }} group :</h3>
              <span v-if="type === 'special'">For exemple : DJ, Tour opérateur, etc..</span>
              <span v-else>For exemple : Bronze/Silver/Gold</span>
            </v-alert>
          </v-flex>
          <v-flex shrink ml-auto class="flex-div justify-end">
            <v-btn @click="close()" outline color="error"> <v-icon class="mr-2">close</v-icon>Cancel </v-btn>
            <v-btn @click="addSegment()" color="primary"> <v-icon class="mr-2">check</v-icon>Ok </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import EventBus from '@/packs/event-bus'

export default {
  data: () => {
    return {
      name: '',
      type: 'special'
    }
  },
  props: {
    segmentsWorkplace: Array
  },
  methods: {
    addSegment() {
      const segment = {
        id: '',
        name: this.name
      }
      this.segmentsWorkplace.push(segment)
      EventBus.$emit('showSnackbar', {
        text: 'A new workplace group named ' + segment.name + ' has been created',
        color: 'success'
      })
      this.$emit('closeEvent', false)
    },
    close() {
      this.$emit('closeEvent', false)
    }
  }
}
</script>
