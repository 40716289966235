<template>
  <div class="disp-flex" style="min-height: 250px">
    <div class="align-self-center">
      <v-btn @click="tab--" icon class="ma-0 white--text" color="#3f51b5" :disabled="action.gift || tab === 0">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
    </div>
    <v-tabs-items v-model="tab" class="w-100">
      <v-tab-item :key="1" class="h-100 disp-flex justify-center">
        <TriggerWelcome
          v-if="!hasAlreadyTriggerCustom(li, action) && campaign_type === 'welcome'"
          :threshold="action.threshold"
        />
        <Scope
          v-else
          :scope="action.scope"
          :quantity="action.quantity"
          :target_id="action.target_id"
          :minimum_value="action.minimum_value"
          :threshold="action.threshold"
          :action_type="action.card_type"
          :type="action.type"
          :kind="action.kind"
        />
      </v-tab-item>
    </v-tabs-items>
    <div class="align-self-center">
      <v-btn @click="tab++" icon class="ma-0 white--text" color="#3f51b5" :disabled="action.gift || tab === 0">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import TriggerWelcome from '@/components/Actions/Triggers/TriggerWelcome'
import Scope from '@/components/Actions/Common/Scope'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    TriggerWelcome,
    Scope
  },
  props: {
    action: {
      type: Object,
      required: true
    },
    campaign_type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tab: null
    }
  },
  computed: {
    ...mapState({ li: state => state.campaignBuilder.li }),
    ...mapGetters('campaignBuilder', ['hasAlreadyTriggerCustom'])
  }
}
</script>
