export default {
  toERB(text) {
    let new_text = text
      .replace(/^<p>/i, '')
      .replace(/<p>/gi, '<br/>')
      .replace(/<\/p>/gi, '')

    new_text = new_text.replace(/href="@(\w+)"/gi, 'href="<%= @$1 %>"')
    new_text = new_text.replace(
      /<span\s+class="mention"\s+(contenteditable="\w+"\s+)?data-mention-id="\d">@(\w+)<\/span>/gi,
      '<%= @$2 %>'
    )
    new_text = new_text.replace(/\s{2,}/gi, ' ')

    return new_text
  },
  toEditor(text, wrapper) {
    if (typeof wrapper !== 'boolean') {
      wrapper = false
    }
    let new_text = wrapper ? '<p>' + text + '</p>' : text

    new_text = text.replace(/href="<%=\s@(\w+)\s%>"/gi, 'href="@$1"')
    new_text = new_text.replace(
      /<%=\s@(\w+)\s%>/gi,
      '<span class="mention" contenteditable="false" data-mention-id="1">@$1</span>'
    )

    return new_text
  },
}
