<template>
  <div class="h-100">
    <v-window v-model="window" class="h-100">
      <v-window-item v-if="canCreateAward" :value="0" class="h-100">
        <div class="pa-3 text-xs-center h-100 disp-flex flex-column">
          <v-icon small style="font-size: 70px" class="mb-3 mt-3">golf_course</v-icon>
          <div class="title mb-3" style="color: #0000008a">{{ $t('common.award') }}</div>
          <div v-html="'Créez un challenge'" class="body-1 grey--text mb-3"/>
          <div class="h-100 disp-flex" style="align-items: flex-end">
            <v-btn color="primary" class="mx-0 w-100" @click.prevent="createAward()">Créer</v-btn>
          </div>
        </div>
      </v-window-item>

      <v-window-item :value="canCreateAward ? 1 : 0" class="h-100">
        <div class="pa-3 text-xs-center h-100 disp-flex flex-column">
          <v-icon small style="font-size: 70px" class="mb-3 mt-3">emoji_events</v-icon>
          <div class="title mb-3" style="color: #0000008a">Offre</div>
          <div v-html="'Créez une offre'" class="body-1 grey--text mb-3" />
          <div class="h-100 disp-flex" style="align-items: flex-end">
            <v-btn color="primary" class="mx-0 w-100" @click.prevent="window = canCreateAward ? 2 : 1">Créer</v-btn>
          </div>
        </div>
      </v-window-item>

      <v-window-item :value="canCreateAward ? 2 : 1" class="h-100">
        <div class="pa-3 text-xs-center h-100 disp-flex flex-column">
          <div class="h-100 disp-flex flex-column">
            <v-icon small style="font-size: 70px" class="mb-3 mt-3">help_outline</v-icon>
            <div class="title mb-3" style="color: #0000008a">Choisissez le type d'offre</div>
            <div class="h-100 disp-flex flex-column" style="justify-content: flex-end">
              <v-btn color="primary" class="mx-0 w-100" @click="type = 'pool'; window = canCreateAward ? 3 : 2">
                Cagnottage
              </v-btn>
              <v-btn color="primary" class="mx-0 w-100" @click="type = 'reward'; window = canCreateAward ? 3 : 2">
                Remise
              </v-btn>
            </div>
          </div>
        </div>
      </v-window-item>

      <v-window-item :value="canCreateAward ? 3 : 2" class="h-100">
        <div class="pa-3 text-xs-center h-100 disp-flex flex-column">
          <div class="h-100 disp-flex flex-column">
            <v-icon small style="font-size: 70px" class="mb-3 mt-3">help_outline</v-icon>
            <div class="title mb-3" style="color: #0000008a">Condition(s) obtention</div>
            <div class="h-100 disp-flex flex-column" style="justify-content: flex-end">
              <v-btn color="primary" class="mx-0 w-100" @click.prevent="createReward()">
                Sans
              </v-btn>
              <v-btn color="primary" class="mx-0 w-100" @click.prevent="createReward(true)">
                Avec
              </v-btn>
            </div>
          </div>
        </div>
      </v-window-item>

      <v-btn absolute dark fab left small outline color="primary" @click="window--" :disabled="(window === 0 || window >= 2) || !canCreateAward" style="top: 100px">
        <v-icon class="d-inline-flex">keyboard_arrow_left</v-icon>
      </v-btn>
      <v-btn absolute dark fab right small outline color="primary" @click="window++" :disabled="disableRightArrow" style="top: 100px">
        <v-icon class="d-inline-flex">keyboard_arrow_right</v-icon>
      </v-btn>
    </v-window>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    line: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      window: 0,
      type: ''
    }
  },
  methods: {
    createAward() {
      this.$emit('createAction', { cardType: 'award', withTrigger: true })
    },
    createReward(withTrigger = false) {
      this.$emit('createAction', { cardType: this.type, withTrigger: withTrigger })
    }
  },
  computed: {
    ...mapState({
      mode: state => state.campaignBuilder.mode,
      campaignKind: state => state.campaignBuilder.campaign.kind
    }),
    isCustomCampaign() {
      return this.mode === 'subfidelity' && (this.campaignKind === 'limited' || this.campaignKind === 'permanent')
    },
    canCreateAward() {
      if (this.isCustomCampaign) return false
      if (this.mode === 'conquest') return false
      return !this.line.actions.find(e => e.card_type === 'award')
    },
    disableRightArrow() {
      return (this.canCreateAward && this.window === 1 || this.window >= 2) || (!this.canCreateAward && this.window === 0 || this.window === 1)
    }
  }
}
</script>

<style lang="sass">
div .v-window
  .v-window__container
    height: 100%
</style>
