import CampaignBuilderState from '@/models/states/CampaignBuilderState'
import initialState from '@/store/modules/campaignBuilder/initialState'

import LoyaltyCurrency from '@/models/LoyaltyCurrency'
import PoolProgram from '@/models/PoolProgram'


const mutations = {
  /* ------------------------------------------------------------------------------------------
                                    CAMPAIGN SETTINGS MUTATIONS
  --------------------------------------------------------------------------------------------*/

  SET_DRAFT_LOADING: (state: CampaignBuilderState, payload: boolean) => state.loading = payload,
  SET_SAVE_LOADING: (state: CampaignBuilderState, payload: boolean) => state.loadingSave = payload,
  SET_DESTROY_LOADING: (state: CampaignBuilderState, payload: boolean) => state.loadingDestroy = payload,
  SET_DRAFT_CAMPAIGN: (state: CampaignBuilderState, payload: any) => state.campaign = payload,
  SET_NAME: (state: CampaignBuilderState, payload: string) => state.campaign.name = payload,
  SET_START_AT: (state: CampaignBuilderState, payload: string) => state.campaign.start_at = payload,
  SET_END_AT: (state: CampaignBuilderState, payload: string) => state.campaign.end_at = payload,
  SET_INHERITABLE: (state: CampaignBuilderState, payload: boolean) => state.campaign.inheritable = payload,
  SET_KIND: (state: CampaignBuilderState, payload: string) => state.campaign.kind = payload,
  SET_LINE_INDEX: (state: CampaignBuilderState, payload: number) => state.li = payload,
  SET_ACTION_INDEX: (state: CampaignBuilderState, payload: number) => state.ai = payload,
  SET_MODE: (state: CampaignBuilderState, payload: string) => state.mode = payload,
  SET_SEGMENT: (state: CampaignBuilderState, payload: any) => state.campaign.segment = payload,

  SET_EVENT_ANTICIPATION: (state: CampaignBuilderState, payload: number) => state.campaign.event_anticipation = payload,

  ADD_TIME_SLOT: (state: CampaignBuilderState, payload: any) => state.campaign.time_slots.push(payload),
  DELETE_TIME_SLOT: (state: CampaignBuilderState, payload: number) => state.campaign.time_slots.splice(payload, 1),
  ADD_DAY_TIME_SLOT: (state: CampaignBuilderState, payload: { index: number, day: number }) => state.campaign.time_slots[payload.index].days.push(payload.day),
  DELETE_DAY_TIME_SLOT: (state: CampaignBuilderState, payload: { index: number, dayIndex: number }) => state.campaign.time_slots[payload.index].days.splice(payload.dayIndex, 1),
  SET_FULL_WEEK_TIME_SLOT: (state: CampaignBuilderState, payload: { index: number, isFullWeek: boolean, resetDay?: boolean }) => {
    state.campaign.time_slots[payload.index].fullWeek = payload.isFullWeek
    if (payload.isFullWeek) state.campaign.time_slots[payload.index].days = [1, 2, 3, 4, 5, 6, 7]
    else if (payload.resetDay) state.campaign.time_slots[payload.index].days = []
  },
  SET_FULL_DAY_TIME_SLOT: (state: CampaignBuilderState, payload: { index: number, isFullDay: boolean }) => state.campaign.time_slots[payload.index].fullDay = payload.isFullDay,
  SET_START_TIME_SLOT: (state: CampaignBuilderState, payload: { index: number, hour: any }) => {
    state.campaign.time_slots[payload.index].start_hour = payload.hour.dhour
    state.campaign.time_slots[payload.index].start_minutes = payload.hour.dminutes
  },
  SET_END_TIME_SLOT: (state: CampaignBuilderState, payload: { index: number, hour: any }) => {
    state.campaign.time_slots[payload.index].end_hour = payload.hour.dhour
    state.campaign.time_slots[payload.index].end_minutes = payload.hour.dminutes
  },

  SET_TRIGGER_SCOPE: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].scope = payload,
  SET_TRIGGER_THRESHOLD: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].threshold = payload,
  SET_TRIGGER_MINIMUM_VALUE: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].minimum_value = payload,
  SET_TRIGGER_TARGET_ID: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].target_id = payload,

  SET_REWARD_SCOPE: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].scope = payload,
  SET_REWARD_TYPE: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].type = payload,
  SET_REWARD_AMOUNT: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].amount = payload,
  SET_REWARD_AMOUNT_KIND: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].amount_kind = payload,
  SET_REWARD_QUANTITY: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].quantity = payload,
  SET_REWARD_TARGET_ID: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].target_id = payload,
  SET_REWARD_AVAILABILITY_DURATION: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].availability_duration = payload,
  SET_REWARD_NUMBER_OF_USE: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].number_of_use = payload,
  SET_REWARD_NUMBER_OF_USE_PER_TRANSACTION: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].number_of_use_per_transaction = payload,
  SET_REWARD_GIFT: (state: CampaignBuilderState, payload: boolean) => state.lines[state.li].actions[state.ai].gift = payload,
  SET_REWARD_CONSUMPTION_MODE: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].consumption_mode = payload,
  SET_REWARD_CLAIM_METHOD: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].claim_method = payload,
  SET_REWARD_REQUIRE_ACTIVATION: (state: CampaignBuilderState, payload: boolean) => state.lines[state.li].actions[state.ai].require_activation = payload,
  SET_REWARD_VOUCHER: (state: CampaignBuilderState, payload: boolean) => state.lines[state.li].actions[state.ai].voucher = payload,
  SET_REWARD_VOUCHER_NAME: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].voucher_name = payload,

  SET_AWARD_TRANSFORMATION: (state: CampaignBuilderState, payload: string) => state.lines[state.li].actions[state.ai].transformation = payload,
  SET_AWARD_NUMBER_OF_USE: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].number_of_use = payload,
  SET_AWARD_NUMBER_OF_USE_PER_TRANSACTION: (state: CampaignBuilderState, payload: number) => state.lines[state.li].actions[state.ai].number_of_use_per_transaction = payload,

  ADD_CONTRACTS: (state: CampaignBuilderState, payload: any[]) => state.campaign.contracts.push(...payload),
  CLEAR_CONTRACTS: (state: CampaignBuilderState) => state.campaign.contracts = [],
  DELETE_CONTRACT: (state: CampaignBuilderState, payload: number) => state.campaign.contracts.splice(payload, 1),

  ADD_CAMPAIGN_PARTICIPANTS: (state: CampaignBuilderState, payload: any[]) => state.campaign.campaign_participants.push(...payload),
  CLEAR_CAMPAIGN_PARTICIPANTS: (state: CampaignBuilderState) => state.campaign.campaign_participants = [],
  DELETE_CAMPAIGN_PARTICIPANT: (state: CampaignBuilderState, payload: number) => state.campaign.campaign_participants.splice(payload, 1),

  ADD_PUBLISHERS: (state: CampaignBuilderState, payload: any[]) => state.campaign.commission_contracts.push(...payload),
  CLEAR_PUBLISHERS: (state: CampaignBuilderState) => state.campaign.commission_contracts = [],
  DELETE_PUBLISHER: (state: CampaignBuilderState, payload: number) => state.campaign.commission_contracts.splice(payload, 1),
  SET_PUBLISHER_RATE_CARD: (state: CampaignBuilderState, payload: { index: number, value: string }) => state.campaign.commission_contracts[payload.index].rate_card_id = payload.value,

  ADD_PUBLISHING: (state: CampaignBuilderState, payload: string) => state.campaign.publishing.push(payload),
  DELETE_PUBLISHING: (state: CampaignBuilderState, payload: number) => state.campaign.publishing.splice(payload, 1),
  PUSH_TEMPLATE: (state: CampaignBuilderState, payload: any) => state.campaign.templates.push(payload),
  CLEAR_TEMPLATES: (state: CampaignBuilderState) => state.campaign.templates = [],

  /* ------------------------------------------------------------------------------------------
                                    CORE PROGRAM MUTATIONS
  --------------------------------------------------------------------------------------------*/

  SET_LOADING_CORE_PROGRAM: (state: CampaignBuilderState, payload: boolean) => state.loading = payload,

  CREATE_LOYALTY_CURRENCY: (state: CampaignBuilderState) => state.campaign.loyalty_currency = new LoyaltyCurrency(),
  CLEAR_LOYALTY_CURRENCY: (state: CampaignBuilderState) => state.campaign.loyalty_currency = null,
  CREATE_POOL_PROGRAM: (state: CampaignBuilderState) => state.campaign.pool_program = new PoolProgram(),
  CLEAR_POOL_PROGRAM: (state: CampaignBuilderState) => state.campaign.pool_program = null,
  SET_LOYALTY_CURRENCY_NAME: (state: CampaignBuilderState, payload: string | null) => state.campaign.loyalty_currency.name = payload,
  SET_LOYALTY_CURRENCY_OTHER: (state: CampaignBuilderState, payload: string | null) => state.campaign.loyalty_currency.other = payload,
  SET_LOYALTY_EXPIRATION_VALUE: (state: CampaignBuilderState, payload: number | null) => state.campaign.loyalty_currency.expiration_value = payload,
  SET_LOYALTY_EXPIRATION_MODE: (state: CampaignBuilderState, payload: string) => state.campaign.loyalty_currency.expiration_mode = payload,
  SET_POOL_PROGRAM_MODE: (state: CampaignBuilderState, payload: string) => state.campaign.pool_program.mode = payload,

  SET_POOL_PROGRAM_EARN_EXCHANGE_RATE: (state: CampaignBuilderState, payload: number) => state.campaign.pool_program.earn_exchange_rate = payload,
  SET_POOL_PROGRAM_BURN_EXCHANGE_RATE: (state: CampaignBuilderState, payload: number | null) => state.campaign.pool_program.burn_exchange_rate = payload,
  SET_PAY_WITH_POOL: (state: CampaignBuilderState, payload: boolean) => state.payWithPool = payload,

  SET_STATUS_SYSTEM: (state: CampaignBuilderState, payload: any) => state.campaign.status_system = payload,
  SET_STATUS_SYSTEM_NAME: (state: CampaignBuilderState, payload: string) => state.campaign.status_system.name = payload,
  SET_STATUS_SYSTEM_COUNTER: (state: CampaignBuilderState, payload: string) => state.campaign.status_system.counter = payload,
  SET_STATUS_SYSTEM_CALCULATION_PERIOD: (state: CampaignBuilderState, payload: string) => state.campaign.status_system.calculation_period = payload,
  SET_STATUS_SYSTEM_CALCULATION_PERIOD_VALUE: (state: CampaignBuilderState, payload: number) => state.campaign.status_system.calculation_period_value = payload,
  SET_STATUS_SYSTEM_COUNTER_FREQUENCY: (state: CampaignBuilderState, payload: string) => state.campaign.status_system.counter_frequency = payload,
  SET_STATUS_SYSTEM_COUNTER_FREQUENCY_VALUE: (state: CampaignBuilderState, payload: number) => state.campaign.status_system.counter_frequency_value = payload,

  PUSH_STATUS_SYSTEM_LEVEL: (state: CampaignBuilderState, payload: any) => state.campaign.status_system.levels.push(payload),
  SPLICE_STATUS_SYSTEM_LEVEL: (state: CampaignBuilderState, payload: number) => state.campaign.status_system.levels.splice(payload, 1),
  DELETE_STATUS_SYSTEM_ALL_LEVEL: (state: CampaignBuilderState) => {state.campaign.status_system.levels = []},
  UPDATE_STATUS_SYSTEM_LEVEL: (state: CampaignBuilderState, payload: { index: number, level: any }) => state.campaign.status_system.levels.splice(payload.index, 1, payload.level),

  SET_DESCRIPTION_CORE_PROGRAM: (state: CampaignBuilderState, payload: string) => state.campaign.description = payload,
  SET_IMAGE_DESCRIPTION_URL_CORE_PROGRAM: (state: CampaignBuilderState, payload: string) => state.campaign.image_description_url = payload,
  SET_IMAGE_DESCRIPTION_TEXT_CORE_PROGRAM: (state: CampaignBuilderState, payload: string) => state.campaign.image_description_text = payload,

  SET_ECARD_CORE_PROGRAM: (state: CampaignBuilderState, payload: boolean) => state.campaign.ecard = payload,
  ADD_NOTIFICATION_CORE_PROGRAM: (state: CampaignBuilderState, payload: string) => state.campaign.notifications.push(payload),
  DELETE_NOTIFICATION_CORE_PROGRAM: (state: CampaignBuilderState, payload: number) => state.campaign.notifications.splice(payload, 1),

  /* ------------------------------------------------------------------------------------------
                                    COMPONENTS MUTATIONS
  --------------------------------------------------------------------------------------------*/

  RESET_LINES: (state: CampaignBuilderState) => state.lines = [],
  PUSH_LINE: (state: CampaignBuilderState) => state.lines.push({ title: "Configuration de l'offre #", actions: [], canCreateOtherAction: true }),
  PUSH_ACTION: (state: CampaignBuilderState, payload: { index: number, value: any }) => {state.lines[payload.index].actions.push(payload.value)},
  SPLICE_ACTION: (state: CampaignBuilderState, payload: { lineIndex: number, actionIndex: number, deleteCount: number, value: any }) => {state.lines[payload.lineIndex].actions.splice(payload.actionIndex, payload.deleteCount, payload.value)},
  SET_CAN_CREATE_OTHER_ACTION: (state: CampaignBuilderState, payload: { index: number, value: boolean }) => {state.lines[payload.index].canCreateOtherAction = payload.value},
  SET_EDIT_MODE: (state: CampaignBuilderState, payload: boolean) => state.editMode = payload,
  SET_CREATE_CAMPAIGN: (state: CampaignBuilderState, payload: boolean) => state.create = payload,
  DELETE_ACTION: (state: CampaignBuilderState, payload: { lineIndex: number, actionIndex: number }) => {
    if (
      state.lines[payload.lineIndex].actions[payload.actionIndex].card_type === 'reward' ||
      state.lines[payload.lineIndex].actions[payload.actionIndex].card_type === 'pool'
    )
      state.lines[payload.lineIndex].canCreateOtherAction = true
    state.lines[payload.lineIndex].actions.splice(payload.actionIndex, 1)
  },
  DELETE_LINE: (state: CampaignBuilderState, payload: number) => state.lines.splice(payload, 1),

  RESET_STATE: (state: CampaignBuilderState) => Object.assign(state, initialState())
}

export default mutations
