<template>
  <v-container fluid>
    <v-layout class="grid-customers" v-if="!loading && customerBaseExist">
      <v-flex class="w-100">
        <CustomersSelectCustomersBase
          v-if="customers_base.length > 1"
          :customers_base="customers_base"
          @UpdateBase="setCustomersBase($event)"
        ></CustomersSelectCustomersBase>
        <CustomersGeneralFilter :segments="segments" :customer_base="base"></CustomersGeneralFilter>
      </v-flex>
      <v-flex class="w-100">
        <CustomersSegmentsFilter :segments="segments" :customer_base="base"></CustomersSegmentsFilter>
        <CustomersTable :customer_base="base"></CustomersTable>
      </v-flex>
    </v-layout>
    <v-layout v-if="!loading && !customerBaseExist" class="h-100">
      <v-flex class="w-100 text-center my-auto">
        <v-icon style="font-size: 80px" class="red--text darken-2">report</v-icon>
        <h2 class="red--text darken-2 font-weight-medium">Aucune base client n'a été créée</h2>
      </v-flex>
    </v-layout>
    <v-flex>
      <router-view />
    </v-flex>
  </v-container>
</template>

<script>
import eventBus from '@/packs/event-bus'
import req from '@/packs/request.js'
import CustomersGeneralFilter from '../components/CustomersGeneralFilter'
import CustomersSegmentsFilter from '../components/CustomersSegmentsFilter'
import CustomersSelectCustomersBase from '../components/CustomersSelectCustomersBase'
import CustomersTable from '../components/CustomersTable'

export default {
  components: {
    CustomersSegmentsFilter,
    CustomersGeneralFilter,
    CustomersSelectCustomersBase,
    CustomersTable
  },
  data: function() {
    return {
      customers_base: Array,
      segments: [],
      base: Object,
      loading: Boolean,
      customerBaseExist: true
    }
  },
  methods: {
    async setCustomersBase(base) {
      this.base = base
      await this.fetchSegmentsFromBases()
    },
    async fetchCustomerBases() {
      try {
        const res = await req.get('/customer_bases')
        this.customers_base = res.data
        this.base = { ...this.customers_base[0] }
        await this.fetchSegmentsFromBases()
      } catch (e) {
        this.customerBaseExist = false
        this.loading = false
      }
    },
    async fetchSegmentsFromBases() {
      try {
        const res = await req.get(`/customer_bases/${this.base.id}/segments?with_discarded=true`)
        this.segments = res.data
        this.loading = false
      } catch (e) {
        this.customerBaseExist = false
        this.loading = false
      }
    }
  },
  created() {
    this.loading = true
    this.fetchCustomerBases()
    eventBus.$on('refreshSegments', () => {
      this.fetchCustomerBases()
    })
  }
}
</script>

<style scoped lang="css">
@media screen and (min-width: 907px) {
  .grid-customers {
    display: grid;
    grid-template-columns: minmax(350px, 450px) minmax(480px, 1fr);
    column-gap: 30px;
  }
}

@media screen and (max-width: 906px) {
  .grid-customers {
    display: grid;
    row-gap: 10px;
    grid-template-columns: minmax(0px, 1fr);
  }
}
</style>
