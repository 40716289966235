<template>
  <v-card class="w-100 elevation-0 mt-3">
    <v-layout row wrap class="px-3 py-2" style="background-color: #f1f1f178">
      <v-flex xs12 class="header-btn">
        <label class="font-weight-medium subheading">Filtres :</label>
        <v-btn class="ma-0" color="error" fab small dark dense @click="cleanFilters()">
          <v-icon>delete_forever</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs12 md12 lg6 class="flex-div flex-column mt-3">
        <label class="font-weight-medium">{{ $t('customers.advancedFilters.gender') }}</label>
        <div class="flex-div">
          <v-checkbox
            v-model="filters.by_gender"
            @change="applyFilters()"
            :label="$t('common.male')"
            value="male"
            color="primary"
            hide-details
            class="cancel-flex mr-3 mt-0 pt-0"
          ></v-checkbox>
          <v-checkbox
            v-model="filters.by_gender"
            @change="applyFilters()"
            :label="$t('common.female')"
            value="female"
            color="primary"
            hide-details
            class="cancel-flex mr-3 mt-0 pt-0"
          ></v-checkbox>
        </div>
      </v-flex>
      <!--      <v-flex xs12 class="mt-3 mb-3">-->
      <!--        <v-select-->
      <!--          :items="status"-->
      <!--          v-model="statusSelected"-->
      <!--          @input="applyFilters()"-->
      <!--          item-value="id"-->
      <!--          item-text="name"-->
      <!--          class="mt-0 pt-0"-->
      <!--          hide-details-->
      <!--          menu-props="offsetY"-->
      <!--          :label="$t('customers.filters.status')"-->
      <!--        >-->
      <!--          <template v-slot:no-data>-->
      <!--            <div class="pa-3">Pas encore de statut</div>-->
      <!--          </template>-->
      <!--        </v-select>-->
      <!--      </v-flex>-->
      <v-flex xs12 class="mt-3 mb-3">
        <PointOfSalesMultipleSelect v-model="getPOS" @input="applyFilters()"></PointOfSalesMultipleSelect>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="px-3 py-2 mt-1" style="background-color: #f1f1f178">
      <v-flex xs12 class="mt-3">
        <label class="font-weight-medium subheading subtitle-color">Profil RFM prédéfini :</label>
      </v-flex>
      <v-flex xs12 class="mt-3">
        <v-tooltip top :disabled="!selectorProfilRFM">
          <v-select
            :items="profilRFM"
            v-model="rfm"
            @input="applyFilters()"
            :disabled="selectorProfilRFM"
            item-value="id"
            item-text="name"
            label="Profil RFM"
            class="mt-0 pt-0"
            hide-details
            menu-props="offsetY"
            return-object
            slot="activator"
          >
            <template v-slot:no-data>
              <div class="pa-3">{{ $t('customers.segmentError') }}</div>
            </template>
          </v-select>
          <div>
            Incompatible avec RFM sur mesure.
          </div>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 class="mt-3">
        <label class="font-weight-medium subheading subtitle-color">RFM sur mesure :</label><br />
      </v-flex>
      <v-flex xs12 class="mt-3">
        <div class="flex-div flex-column">
          <label class="font-weight-medium"
            >{{ $t('customers.advancedFilters.frequency') }}
            <span class="caption">({{ $t('customers.advancedFilters.perYear') }})</span>
            :</label
          >
          <v-tooltip top :disabled="!customRFMProfil">
            <div class="flex-div" slot="activator">
              <v-text-field
                v-model="filters.by_frequency_gt"
                @input="RFMDebounced()"
                type="number"
                min="0"
                :label="$t('common.from')"
                solo
                class="mx-1"
                hide-details
                :disabled="customRFMProfil"
              ></v-text-field>
              <v-text-field
                v-model="filters.by_frequency_lt"
                @input="RFMDebounced()"
                type="number"
                min="0"
                :label="$t('common.to')"
                solo
                class="mx-1"
                hide-details
                :disabled="customRFMProfil"
              ></v-text-field>
            </div>
            <div>
              Incompatible avec profil RFM.
            </div>
          </v-tooltip>
        </div>
      </v-flex>
      <v-flex xs12 class="mt-3">
        <div class="flex-div flex-column">
          <label class="font-weight-medium"
            >{{ $t('customers.advancedFilters.recency') }}
            <span class="caption">({{ $t('customers.advancedFilters.days') }})</span>
            :</label
          >
          <v-tooltip top :disabled="!customRFMProfil">
            <div class="flex-div" slot="activator">
              <v-text-field
                v-model="byRecencyLt"
                @input="RFMDebounced()"
                type="number"
                min="0"
                :label="$t('common.from')"
                solo
                class="mx-1"
                hide-details
                :disabled="customRFMProfil"
              ></v-text-field>
              <v-text-field
                v-model="byRecencyGt"
                @input="RFMDebounced()"
                type="number"
                min="0"
                :label="$t('common.to')"
                solo
                class="mx-1"
                hide-details
                :disabled="customRFMProfil"
              ></v-text-field>
            </div>
            <div>
              Incompatible avec profil RFM.
            </div>
          </v-tooltip>
        </div>
      </v-flex>
      <v-flex xs12 class="mt-3">
        <div class="flex-div flex-column">
          <label class="font-weight-medium">
            {{ $t('customers.advancedFilters.monetary') }}
            <span class="caption text-no-wrap">({{ $t('customers.advancedFilters.averageBasket') }})</span>
            :
          </label>
          <v-tooltip top :disabled="!customRFMProfil">
            <div class="flex-div" slot="activator">
              <v-text-field
                v-model.number="byMonetaryAverageGt"
                @input="RFMDebounced()"
                type="number"
                min="0"
                :label="$t('common.from')"
                solo
                class="mx-1"
                hide-details
                :disabled="customRFMProfil"
              ></v-text-field>
              <v-text-field
                v-model.number="byMonetaryAverageLt"
                @input="RFMDebounced()"
                type="number"
                min="0"
                :label="$t('common.to')"
                solo
                class="mx-1"
                hide-details
                :disabled="customRFMProfil"
              ></v-text-field>
            </div>
            <div>
              Incompatible avec profil RFM.
            </div>
          </v-tooltip>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import req from '@/packs/request.js'
import eventBus from '@/packs/event-bus'
import filtersService from '@/services/customers_filters'
import PointOfSalesMultipleSelect from '@/components/PointOfSalesMultipleSelect'

export default {
  components: {
    PointOfSalesMultipleSelect
  },
  props: {
    selected: {
      type: Object,
      required: true
    },
    status: {
      type: Array,
      required: true
    },
    customer_base: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filters: {
        by_frequency_gt: null,
        by_frequency_lt: null,
        by_recency_gt: null,
        by_recency_lt: null,
        by_monetary_avg_gt: null,
        by_monetary_avg_lt: null,
        by_gender: null,
        by_point_of_sale_id: null
      },
      stateFilters: Object,
      rfm: {},
      profilRFM: [],
      statusSelected: '',
      segmentsSelected: Object,
      create: false,
      debounceId: null
    }
  },
  methods: {
    RFMDebounced() {
      clearTimeout(this.debounceId)

      this.debounceId = setTimeout(() => {
        this.applyFilters()
      }, 500)
    },
    fetchDataFromSegment() {
      if (this.segmentsSelected && this.segmentsSelected.filters) {
        for (const each of this.segmentsSelected.filters) {
          if (each.kind === 'custom') for (const key of Object.keys(each.values)) this.filters[key] = each.values[key]
          else if (each.kind === 'status') this.statusSelected = each.id
          else if (each.kind === 'rfm') this.rfm = each
        }
      }
    },
    applyFilters() {
      eventBus.$emit('applyFilters', { filters: this.getFilters })
      this.create = true
      this.canCreateSegment()
    },
    cleanFilters() {
      eventBus.$emit('cleanCustomSegment')
      this.cleanStateFilters()
    },
    cleanStateFilters(applyFilters = true) {
      this.filters = { ...this.stateFilters }
      this.rfm = {}
      this.statusSelected = ''
      this.create = false
      if (applyFilters) this.applyFilters()
    },
    async fetchFiltersProfilRFM() {
      const res = await req.get('/customer_bases/' + this.customer_base.id + '/filters')
      for (const each of res.data) if (each.kind === 'rfm') this.profilRFM.push(each)
    },
    canCreateSegment() {
      if (
        this.create &&
        filtersService.checkChange(this.getFilters) &&
        filtersService.fetchFilters(this.getFilters).length > 0
      )
        this.$emit('canCreateSegment', {
          can: true,
          base: this.customer_base,
          filters: this.getFilters
        })
      else {
        this.create = false
        this.$emit('canCreateSegment', { can: false })
      }
    }
  },
  computed: {
    byMonetaryAverageGt: {
      get() {
        return this.filters.by_monetary_avg_gt ? Number(this.filters.by_monetary_avg_gt).toCurrency() : null
      },
      set(value) {
        this.filters.by_monetary_avg_gt = value ? value.toCents() : null
      }
    },
    byMonetaryAverageLt: {
      get() {
        return this.filters.by_monetary_avg_lt ? Number(this.filters.by_monetary_avg_lt).toCurrency() : null
      },
      set(value) {
        this.filters.by_monetary_avg_lt = value ? value.toCents() : null
      }
    },
    byRecencyGt: {
      get() {
        return this.filters.by_recency_gt
          ? this.$moment().diff(this.$moment.unix(this.filters.by_recency_gt)._d, 'days')
          : null
      },
      set(value) {
        this.filters.by_recency_gt = value
          ? this.$moment(this.$moment().add(-Math.abs(value), 'days'))
              .startOf('day')
              .format('X')
          : null
      }
    },
    byRecencyLt: {
      get() {
        return this.filters.by_recency_lt
          ? this.$moment().diff(this.$moment.unix(this.filters.by_recency_lt)._d, 'days')
          : null
      },
      set(value) {
        this.filters.by_recency_lt = value
          ? this.$moment(this.$moment().add(-Math.abs(value), 'days'))
              .startOf('day')
              .format('X')
          : null
      }
    },
    getFilters: {
      get() {
        return {
          rfm: this.rfm,
          custom: this.filters,
          segment: this.segmentsSelected,
          status: this.statusSelected.length > 0 ? { id: this.statusSelected } : {}
        }
      }
    },
    customRFMProfil() {
      return !!Object.keys(this.rfm).length > 0
    },
    getPOS: {
      get() {
        return this.filters.by_point_of_sale_id
      },
      set(value) {
        this.filters.by_point_of_sale_id = value.length > 0 ? value : null
      }
    },
    selectorProfilRFM() {
      return !!(
        this.filters.by_frequency_gt ||
        this.filters.by_frequency_lt ||
        this.filters.by_recency_gt ||
        this.filters.by_recency_lt ||
        this.filters.by_monetary_avg_gt ||
        this.filters.by_monetary_avg_lt
      )
    }
  },
  async created() {
    await this.fetchFiltersProfilRFM()
    this.stateFilters = { ...this.filters }
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        this.cleanStateFilters(false)
        this.segmentsSelected = { ...this.selected }
        this.fetchDataFromSegment()
        this.applyFilters()
      }
    },
    selectorProfilRFM: {
      deep: true,
      handler: function(newVal) {
        if (newVal) this.rfm = {}
      }
    }
  }
}
</script>

<style scoped lang="css">
.subtitle-color {
  color: #717171;
}

.header-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-btn button {
  height: 30px;
  width: 30px;
}

@media screen and (min-width: 1264px) {
  .padding-responsive {
    padding-left: 30px;
  }
}
</style>
