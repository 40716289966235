<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="650px">
    <v-card>
      <v-card-text>
        <v-container grid-list-md>
          <h2 class="headline mb-4">
            {{
              editMode
                ? $t('ratecards.editRatecard')
                : $t('ratecards.newRatecard')
            }}
          </h2>
          <v-layout column>
            <v-form ref="form" v-model="valid" :lazy-validation="true">
              <v-flex xs12 class="py-0">
                <v-layout>
                  <v-flex md5 class="pt-0">
                    <v-text-field
                      :label="$t('ratecards.ratecardName')"
                      v-model="rate_card.name"
                      class="pt-3"
                      :rules="name"
                    >
                    </v-text-field>
                  </v-flex>
                  <div class="pl-5 md6 flex-div align-center pt-0">
                    <span class="mr-3 pb-1 flex-div grow"
                      >{{ $t('ratecards.pricingModel') }}:</span
                    >
                    <v-select
                      v-model="rate_card.pricing_model"
                      :items="pricingModels"
                    ></v-select>
                  </div>
                </v-layout>
              </v-flex>
              <v-layout row wrap>
                <v-flex xs6 py-0>
                  <v-checkbox
                    color="primary"
                    v-model="rate_card.default"
                    :label="$t('ratecards.defaultRatecard')"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    label="Montant minimum de la générosité"
                    v-model.number="minimumGenerosityAmount"
                    :rules="name"
                  />
                </v-flex>
              </v-layout>
              <!-- FindersFee -->
              <v-flex mt-3 xs12 class="flex-div align-center py-0">
                <v-radio-group
                  @change="findersFeeTypeChange"
                  v-model="findersFeeType"
                  row
                  class="mt-1"
                >
                  <v-radio
                    :label="$t('ratecards.fixedFindersFee')"
                    value="fixed"
                    color="primary"
                  ></v-radio>
                  <v-radio
                    :label="$t('ratecards.thresholds')"
                    value="thresholds"
                    color="primary"
                  ></v-radio>
                </v-radio-group>
                <v-flex v-if="findersFeeType === 'fixed'" class="pt-0">
                  <v-text-field
                    :rules="amount"
                    v-model.number="rate_card.commission_rules[0].amount"
                    type="number"
                    class="py-0"
                    :label="$t('ratecards.findersFeeVat')"
                  ></v-text-field>
                </v-flex>
              </v-flex>
              <transition name="slide-y-transition">
                <v-flex
                  xs12
                  v-if="findersFeeType === 'thresholds'"
                  class="pt-0"
                >
                  <v-layout>
                    <v-flex md7 class="flex-div align-center">
                      <h3 class="mb-2">
                        {{ $t('ratecards.salesCounterPer') }}
                      </h3>
                      <v-select
                        @change="changeTresholdDuration"
                        heigth="30"
                        class="ml-2 mt-0 pt-0 mb-2 text-number-mid"
                        :items="frequency"
                        v-model="rate_card.commission_rules[0].duration"
                        :item-text="frequency.text"
                        :item-value="frequency.value"
                        hide-details
                        browser-autocomplete="false"
                      ></v-select>
                    </v-flex>
                    <v-flex md5>
                      <h3 class="mb-2">{{ $t('ratecards.findersFee') }} (%)</h3>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    py-2
                    row
                    wrap
                    v-for="(commission, index) in rate_card.commission_rules"
                    :key="index"
                  >
                    <v-flex md7 py-0>
                      <div class="flex-div align-center justify-start">
                        <span
                          v-if="index <= 0"
                          class="min-w-60 text-xs-center font-weight-medium"
                          >1</span
                        >
                        <span
                          v-else
                          class="min-w-60 text-xs-center font-weight-medium"
                          >{{
                            rate_card.commission_rules[index - 1].sales_count
                              ? parseInt(
                                  rate_card.commission_rules[index - 1]
                                    .sales_count
                                ) + 1
                              : '-'
                          }}</span
                        >
                        <span class="mx-3">{{ $t('common.to') }}</span>
                        <v-text-field
                          v-model="commission.sales_count"
                          class="text-number-mid"
                          :min="
                            index > 0
                              ? rate_card.commission_rules[index - 1]
                                  .sales_count + 1
                              : 0
                          "
                          label="Max"
                          type="number"
                          solo
                          hide-details
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex md5 py-0 class="flex-div justify-end flex-column">
                      <v-text-field
                        class="text-number-mid"
                        :rules="amount"
                        v-model.number="commission.amount"
                        type="number"
                        hint="VAT included"
                        solo
                        hide-details
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-btn
                        small
                        depressed
                        @click="addThreshold()"
                        color="primary"
                      >
                        <v-icon class="mr-2">add</v-icon
                        >{{ $t('ratecards.addThreshold') }}
                      </v-btn>
                      <v-btn
                        small
                        depressed
                        @click="removeThreshold()"
                        :disabled="rate_card.commission_rules.length <= 1"
                        flat
                        color="error"
                      >
                        <v-icon class="mr-2">remove</v-icon
                        >{{ $t('ratecards.removeThreshold') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </transition>
            </v-form>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-flex xs12 class="flex-div align-center pt-0">
          <div class="flex-div align-center justify-end">
            <v-btn @click="close" color="primary" flat>{{
              $t('common.close')
            }}</v-btn>
            <v-btn
              :disabled="!valid"
              @click="createEdit()"
              color="primary"
              class="mr-0"
            >
              {{ editMode ? $t('common.save') : $t('common.create') }}</v-btn
            >
          </div>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'
export default {
  data() {
    return {
      // New Ratecard
      valid: true,
      editMode: false,
      pricingModels: ['cpa', 'cpm', 'cpc', 'cpma'],
      frequency: [
        {
          text: this.$t('ratecards.month'),
          value: 'month',
        },
        {
          text: this.$t('ratecards.trimester'),
          value: 'trimester',
        },
        {
          text: this.$t('ratecards.year'),
          value: 'year',
        },
      ],
      findersFeeType: 'fixed',
      rate_card: {
        pricing_model: 'cpa',
        default: false,
        minimum_generosity_amount: 0,
        name: '',
        commission_rules: [
          {
            amount: null,
            amount_kind: 'percentage',
          },
        ],
      },
      name: [v => !!v || this.$t('ratecards.ratecardNameRequired')],
      amount: [v => !!v || this.$t('ratecards.findersFeeRequired')],
    }
  },
  props: {
    value: Boolean,
    ratecardEdit: Object,
  },
  computed: {
    minimumGenerosityAmount: {
      get() {
        return this.rate_card.minimum_generosity_amount.toCurrency()
      },
      set(value) {
        this.rate_card.minimum_generosity_amount = value.toCents()
      }
    }
  },
  methods: {
    defaultFixedThreshold() {
      const simple = {
        amount: null,
        amount_kind: 'percentage',
      }
      return simple
    },
    defaultThresholdRateCard() {
      const thresh = {
        amount: null,
        amount_kind: 'percentage',
        sales_count: null,
        duration: this.rate_card.commission_rules.length
          ? this.rate_card.commission_rules[0].duration
          : '',
      }
      return thresh
    },
    close() {
      this.$emit('input')
      this.$emit('close')
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$refs.form.resetValidation()
    },
    defaultRatecard() {
      const freshRatecard = {
        pricing_model: 'cpa',
        default: false,
        name: '',
        commission_rules: [
          {
            amount: null,
            amount_kind: 'percentage',
          },
        ],
      }
      return freshRatecard
    },
    changeTresholdDuration(val) {
      this.rate_card.commission_rules.forEach(com => (com.duration = val))
    },
    findersFeeTypeChange(val) {
      if (val === 'fixed') {
        this.rate_card.commission_rules = []
        this.rate_card.commission_rules.push(this.defaultFixedThreshold())
      } else {
        this.rate_card.commission_rules = []
        this.rate_card.commission_rules.push(this.defaultThresholdRateCard())
      }
    },
    createEdit() {
      if (this.editMode) this.editRatecard()
      else this.createRatecard()
    },
    createRatecard() {
      if (this.$refs.form.validate()) {
        const rateCard = JSON.parse(JSON.stringify(this.rate_card))
        // this.rate_card = this.defaultRatecard()
        this.$refs.form.resetValidation()
        rateCard.commission_rules.forEach(
          commission => (commission.amount = commission.amount * 100)
        )

        req
          .post('/rate_cards', {
            rate_card: rateCard,
          })
          .then(response => {
            EventBus.$emit('showSnackbar', {
              text: this.$t('message.ratecardCreated'),
              color: 'success',
            })
          })
          .finally(() => {
            this.$emit('input')
            this.$emit('refresh')
            setTimeout(this.$emit('close'), 300)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    editRatecard() {
      if (this.$refs.form.validate()) {
        const rateCard = JSON.parse(JSON.stringify(this.rate_card))

        rateCard.commission_rules.forEach(
          commission => (commission.amount = commission.amount * 100)
        )
        req
          .put('/rate_cards/' + rateCard.id, {
            rate_card: rateCard,
          })
          .then(() => {
            EventBus.$emit('showSnackbar', {
              text: this.$t('message.ratecardUpdated'),
              color: 'success',
            })
          })
          .finally(() => {
            this.$emit('refresh')
            this.$emit('input')
            setTimeout(this.$emit('close'), 300)
          })
          .catch(error => {
            EventBus.$emit('showSnackbar', {
              text: 'A ratecard is already defined by default',
              color: 'error',
            })
            this.$emit('input')
          })
      }
    },
    addThreshold() {
      this.rate_card.commission_rules.push(this.defaultThresholdRateCard())
    },
    removeThreshold() {
      this.rate_card.commission_rules.pop()
    },
  },
  mounted() {
    if (this.ratecardEdit.name) {
      this.editMode = true
      this.rate_card = Object.assign({}, this.ratecardEdit)
      this.$refs.form.resetValidation()
      if (this.ratecardEdit.commission_rules.length > 1)
        this.findersFeeType = 'thresholds'
      this.rate_card.pricing_model = this.rate_card.pricing_model.kind
      this.rate_card.commission_rules.forEach(
        commission => (commission.amount = commission.amount / 100)
      )
    }
  },
}
</script>
