import AxiosWrapper from '@/services/AxiosWrapper'

const END_POINT = '/commission_contracts'

const accept = (id: string, params?: any) => AxiosWrapper.put(`${END_POINT}/${id}/accept`, params)
const acceptAsPool = (id: string, params?: any) => AxiosWrapper.put(`${END_POINT}/${id}/accept_as_pool`, params)
const refuse = (id: string) => AxiosWrapper.put(`${END_POINT}/${id}/refuse`)

export default {
  accept,
  acceptAsPool,
  refuse
}

