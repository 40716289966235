import {
  initialOrderState,
  defaultPaymentId,
  usageHistory
} from './store/initialState';

class API {
  async fetchOrderPricing() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          sms: initialOrderState,
          emails: initialOrderState
        });
      }, 500);
    });
  }

  async fetchPaymentId() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(defaultPaymentId);
      }, 800);
    });
  }

  async fetchUsageHistory() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(usageHistory);
      }, 800);
    });
  }
}

export default new API();
