<template>
  <div>
    <div class="editor elevation-2">
      <editor-menu-bubble
        v-if="!isHtml"
        class="menububble"
        :editor="editor"
        @hide="hideLinkMenu"
        v-slot="{ commands, isActive, getMarkAttrs, menu }"
      >
        <div
          class="menububble"
          :class="{ 'is-active': menu.isActive }"
          :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
        >
          <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
            <input
              class="menububble__input"
              type="text"
              v-model="linkUrl"
              placeholder="https://"
              ref="linkInput"
              @keydown.esc="hideLinkMenu"
            />
            <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
              <icon name="remove" />
            </button>
          </form>

          <template v-else>
            <button
              class="menububble__button"
              @click="showLinkMenu(getMarkAttrs('link'))"
              :class="{ 'is-active': isActive.link() }"
            >
              <span>{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
              <icon name="link" />
            </button>
          </template>
        </div>
      </editor-menu-bubble>

      <editor-menu-bar :editor="editor" style="background-color: rgb(236, 236, 236)" class="pa-2 mb-0">
        <div class="menubar" slot-scope="{ commands, isActive }">
          <template v-if="!sms">
            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <icon name="bold" />
            </button>

            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <icon name="italic" />
            </button>

            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.strike() }"
              @click="commands.strike"
            >
              <icon name="strike" />
            </button>

            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <icon name="underline" />
            </button>

            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })"
            >
              <div class="icon">
                H1
              </div>
            </button>

            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })"
            >
              <div class="icon">
                H2
              </div>
            </button>

            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })"
            >
              <div class="icon">
                H3
              </div>
            </button>

            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
            >
              <icon name="ul" />
            </button>

            <button
              v-if="!isHtml"
              class="menubar__button"
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
            >
              <icon name="ol" />
            </button>

            <button v-if="!isHtml" class="menubar__button" @click="commands.horizontal_rule">
              <icon name="hr" />
            </button>

            <button class="menubar__button" @click="commands.undo">
              <icon name="undo" />
            </button>

            <button class="menubar__button" @click="commands.redo">
              <icon name="redo" />
            </button>
          </template>

          <button class="menubar__button" @click="triggerSuggestion()">
            <icon name="mention" />
          </button>
        </div>
      </editor-menu-bar>
      <editor-content class="editor__content pa-3" :editor="editor" />
      <!-- <div class="editor__raw">
        <v-textarea v-if="viewSource"
          class="textarea"
          :value="rawContent"
          @input="editor.setContent($event, true)"
        ></v-textarea>
        <button @click="viewSource = !viewSource" v-html="viewSource ? 'Show WYSIWYG' : 'Source Code'"></button>
      </div> -->
    </div>
    <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedUserIndex === index }"
          @click="selectUser(user)"
        >
          {{ user.name }}
        </div>
      </template>
      <div v-else class="suggestion-list__item is-empty">
        No users found
      </div>
    </div>
  </div>
</template>

<script>
import Format from '@/services/formatter'
import he from 'he'
import tippy from 'tippy.js'
import Icon from './Icon'
import { Editor, EditorContent, EditorMenuBubble, EditorMenuBar } from 'tiptap'
import { DOMParser } from 'prosemirror-model'

import {
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Mention
} from 'tiptap-extensions'
import { insertText } from 'tiptap-commands'

import '@/stylesheets/editor/editor.scss'
import '@/stylesheets/editor/menubar.scss'
import '@/stylesheets/editor/mention.scss'
import '@/stylesheets/editor/menububble.scss'

export default {
  model: {
    prop: 'rawContent',
    event: 'change'
  },
  props: {
    rawContent: {
      type: String
    },
    variables: {
      type: Array,
      default: () => {
        return []
      }
    },
    sms: {
      type: Boolean,
      default: false
    },
    isHtml: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
    Icon
  },
  data() {
    return {
      editor: null,
      viewSource: this.isHtml,
      linkUrl: null,
      linkMenuIsActive: false,
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {
        return {}
      }
    }
  },
  mounted() {
    this.editor = new Editor(this.getEditorOptions())
  },
  watch: {
    isHtml: {
      handler(newV, oldV) {
        if (oldV !== newV) {
          this.editor.destroy()
          this.editor = new Editor(this.getEditorOptions())
          this.editor.setContent('', true)
        }
      }
    }
  },
  computed: {
    hasResults() {
      return this.filteredUsers.length
    },
    showSuggestions() {
      return this.query || this.hasResults
    }
  },
  methods: {
    htmlToEditor() {
      let html = he.escape(this.rawContent)
      html = html.replace(
        /&lt;%=\s@(\w+)\s%&gt;/gi,
        '<span class="mention" contenteditable="false" data-mention-id="1">@$1</span>'
      )
      return html
    },
    elementFromString(value) {
      const element = document.createElement('div')
      element.innerHTML = value.trim()

      return element
    },

    insertHTML({ state, view }, value) {
      const { selection } = state
      const element = this.elementFromString(value)
      const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
      const transaction = state.tr.insert(selection.anchor, slice.content)

      view.dispatch(transaction)
    },
    getEditorOptions() {
      const options = {
        extensions: [
          new History(),
          new Mention({
            // a list of all suggested items
            items: this.usableVariables,
            // is called when a suggestion starts
            onEnter: ({ items, query, range, command, virtualNode }) => {
              this.query = query
              this.filteredUsers = items
              this.suggestionRange = range
              this.renderPopup(virtualNode)
              // we save the command for inserting a selected mention
              // this allows us to call it inside of our custom popup
              // via keyboard navigation and on click
              this.insertMention = command
            },
            // is called when a suggestion has changed
            onChange: ({ items, query, range, virtualNode }) => {
              this.query = query
              this.filteredUsers = items
              this.suggestionRange = range
              this.navigatedUserIndex = 0
              this.renderPopup(virtualNode)
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              // reset all saved values
              this.query = null
              this.filteredUsers = []
              this.suggestionRange = null
              this.navigatedUserIndex = 0
              this.destroyPopup()
            },
            // is called on every keyDown event while a suggestion is active
            onKeyDown: ({ event }) => {
              // pressing up arrow
              if (event.keyCode === 38) {
                this.upHandler()
                return true
              }
              // pressing down arrow
              if (event.keyCode === 40) {
                this.downHandler()
                return true
              }
              // pressing enter
              if (event.keyCode === 13) {
                this.enterHandler()
                return true
              }
              return false
            },
            // is called when a suggestion has changed
            // this function is optional because there is basic filtering built-in
            // you can overwrite it if you prefer your own filtering
            // in this example we use fuse.js with support for fuzzy search
            onFilter: (its, _query) => {
              return its
            }
          })
        ],
        content: this.isHtml ? this.htmlToEditor() : Format.toEditor(this.rawContent, true),
        onUpdate: this.sendModifications
      }
      if (!this.isHtml)
        options.extensions.push(
          ...[
            new Link(),
            new BulletList(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new HorizontalRule(),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Bold(),
            new Italic(),
            new Strike(),
            new Underline()
          ]
        )
      return options
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    sendModifications(current) {
      let content = current.getHTML()

      if (this.isHtml) {
        content = content.replaceAll('</p><p>', '')
        content = content.replace(
          /<span\s+class="mention"\s+(contenteditable="\w+"\s+)?data-mention-id="\d">@(\w+)<\/span>/gi,
          '<%= @$2 %>'
        )
        content = he.unescape(content.slice(3, -4))
      } else content = Format.toERB(content)

      this.$emit('change', content)
    },
    usableVariables() {
      return this.variables.map((e, idx) => {
        return { id: idx, name: e }
      })
    },
    triggerSuggestion() {
      this.editor.focus()
      const { view } = this.editor
      // inserts char programmatically
      insertText('@')(view.state, view.dispatch, view)
      // give focus back to editor
      this.editor.focus()
    },
    // navigate to the previous item
    // if it's the first item, navigate to the last one
    upHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + this.filteredUsers.length - 1) % this.filteredUsers.length
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length
    },
    enterHandler() {
      const user = this.filteredUsers[this.navigatedUserIndex]
      if (user) this.selectUser(user)
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectUser(user) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.id,
          label: `${user.name} `
        }
      })
      this.editor.focus()
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderPopup(node) {
      if (this.popup) return

      this.popup = tippy(node, {
        content: this.$refs.suggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200],
        showOnInit: true,
        arrow: true,
        arrowType: 'round'
      })
    },
    destroyPopup() {
      if (this.popup) {
        this.popup.destroy()
        this.popup = null
      }
    }
  },
  beforeDestroy() {
    if (this.editor) this.editor.destroy()
  }
}
</script>

<style scoped>
.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.3rem;
  fill: currentColor;
  top: -0.3rem;
}

.editor__content {
  overflow-y: scroll;
  max-height: 200px;
}
</style>
