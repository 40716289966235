var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"max-width":"400"},on:{"input":function($event){return _vm.$emit('input')}}},[_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v("Email list")]),_c('v-tabs',{attrs:{"flat":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.emailType),function(type,index){return _c('v-tab',{key:index,attrs:{"href":'#tab-' + type}},[_vm._v(" "+_vm._s(type)+" ")])}),_c('v-tabs-items',_vm._l((_vm.emailType),function(type,index){return _c('v-tab-item',{key:index,attrs:{"value":'tab-' + type}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-0"},[_c('div',{staticClass:"flex-div align-center"},[_c('v-text-field',{attrs:{"prepend-inner-icon":type === 'domains' ? '@' : '',"label":'New ' + (type === 'domains' ? 'domain' : 'address')},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addElem(_vm.newEmail, type)}},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=$$v},expression:"newEmail"}}),_c('v-btn',{attrs:{"icon":"","flat":"","color":"primary"},on:{"click":function($event){return _vm.addElem(_vm.newEmail, type)}}},[_c('v-icon',[_vm._v("add")])],1)],1),(
                    type === 'domains'
                      ? _vm.domains.length > 0
                      : _vm.emails.length > 0
                  )?_c('v-list',[_vm._l((type === 'domains'
                      ? _vm.domains
                      : _vm.emails),function(elem,index){return [_c('div',{key:index},[_c('v-list-tile',[_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s('@' + elem)}})],1),_c('v-list-tile-action',[_c('v-icon',{on:{"click":function($event){type === 'domains'
                                ? _vm.domains.splice(index, 1)
                                : _vm.emails.splice(index, 1)}}},[_vm._v(" delete")])],1)],1),_c('v-divider')],1)]})],2):[_c('v-alert',{attrs:{"outline":"","icon":"info","value":true,"color":"info"}},[_vm._v("Email list is empty, please add at least one domain or specific email.")])]],2)],1)],1)}),1)],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","flat":""},on:{"click":function($event){return _vm.$emit('input')}}},[_vm._v("Ok")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }