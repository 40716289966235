import AxiosWrapper from '@/services/AxiosWrapper'

const END_POINT = '/advertisers'

const getBalances = (id: string) => AxiosWrapper.get(`${END_POINT}/${id}/balances`)
const getPurchaseHistory = (id: string, perPage: number, page: number) => AxiosWrapper.get(`${END_POINT}/${id}/orders?per_page=${perPage}&page=${page}`)

const getOrderNumber = (id: string) => AxiosWrapper.get(`${END_POINT}/${id}/orders/order_number`)
const createOrder = (id: string, internOrder: any) => AxiosWrapper.post(`${END_POINT}/${id}/orders`, internOrder)
const deleteOrder = (advertiserId: string, orderId: string) => AxiosWrapper.delete(`${END_POINT}/${advertiserId}/orders/${orderId}`)

export default {
	getBalances,
	getPurchaseHistory,
	createOrder,
	deleteOrder,
	getOrderNumber
}
