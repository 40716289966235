<template>
  <v-card>
    <v-container
      :class="{
        'mx-0': $vuetify.breakpoint.mdAndDown,
        'px-0': $vuetify.breakpoint.mdAndDown,
      }"
    >
      <clip-loader
        class="my-4"
        :loading="!loaded"
        color="#78909c"
        width="60px"
        height="60px"
      ></clip-loader>
      <v-toolbar dark fixed color="secondary" v-if="loaded && offer">
        <v-toolbar-title v-if="offer.consumable">Coupon </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-layout row wrap justify-center pt-5 v-if="loaded">
        <v-flex xs12 md6 lg4>
          <v-card-text>
            <div class="d-flex justify-space-between px-3">
              <template
                v-if="advertiser.company && advertiser.company.logo_url"
              >
                <img
                  :src="advertiser.company.logo_url"
                  class="cancel-flex logo-company-offer mt-3"
                />
              </template>

              <template
                v-if="campaign && campaign.creator && campaign.creator.logo_url"
              >
                <img
                  :src="campaign.creator.logo_url"
                  class="cancel-flex logo-company-offer mt-3"
                />
              </template>
            </div>
          </v-card-text>

          <div class="mt-5 flex-div">
            <h2
              class="mb-3 text-xs-center w-100 font-weight-regular"
              v-if="offer && offer.consumable"
            >
              -30% sur les colorations l'Oréal
              <!-- {{ messageGenerate(offer) }} -->
            </h2>
          </div>
          <div
            v-if="
              campaign.reward &&
                campaign.rewards[0].target &&
                campaign.rewards[0].target.logo_url
            "
            class="text-xs-center"
          >
            <img :src="campaign.rewards[0].target.logo_url" height="100" />
          </div>
          <div
            v-else-if="
              campaign.rewards[0].triggers[0] &&
                campaign.rewards[0].triggers[0].target &&
                campaign.rewards[0].triggers[0].target.logo_url
            "
            class="text-xs-center"
          >
            <img
              :src="campaign.rewards[0].triggers[0].target.logo_url"
              height="100"
            />
          </div>
          <div v-else class="text-xs-center">
            <img src="@/assets/images/icons/shopping-cart.svg" height="100" />
          </div>
          <v-card-text
            v-if="offer.voucher"
            class="flex-div flex-column align-center mt-3"
          >
            <img
              width="90%"
              :src="offer.voucher.images.barcode_svg"
              alt="barCode"
            />
            <h3 class="my-0 py-0">{{ offer.voucher.formatted_identifier }}</h3>
            <span v-if="offer && offer.expire_at" class="grey--text mt-3">
              {{ $t('customer_app.dashboard.expire') }}
              {{ offer.expire_at.split(/[ ,]+/)[0] }}
            </span>
          </v-card-text>

          <v-card-text
            v-else-if="!offer.voucher && customer"
            class="flex-div flex-column align-center mt-3"
          >
            <img
              v-if="customer && customer.identifiers[0]"
              width="90%"
              :src="customer.identifiers[0].images.barcode_svg"
              alt="barCode"
            />
            <h3 v-if="customer && customer.identifiers[0]" class="my-0 py-0">
              {{ customer.identifiers[0].formatted_identifier }}
            </h3>
            <span v-if="offer && offer.expire_at" class="grey--text mt-3">
              {{ $t('customer_app.dashboard.expire') }}
              {{ offer.expire_at.split(/[ ,]+/)[0] }}
            </span>
          </v-card-text>

          <v-card-text>
            <div class="grey lighten-5 px-3 py-3 ">
              <div class="mb-2">
                <v-icon small class="mr-2">store</v-icon>
                <h3 class="d-inline">
                  Point{{ pos.length > 1 ? 's' : '' }} de vente participant{{
                    pos.length > 1 ? 's' : ''
                  }}
                  :
                </h3>
              </div>
              <span v-for="(onePos, index) in pos" :key="onePos.id"
                >{{ onePos.name | capitalize
                }}<span v-if="index < pos.length - 1">, </span></span
              >
            </div>
          </v-card-text>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
import req from '@/packs/request.js'
import axios from 'axios'

export default {
  data: () => ({
    loaded: false,
    offer: {},
    advertiser: {},
    customer: {},
    campaign: {},
    pos: [],
    client: axios.create({
      baseURL: req.base_url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }),
  }),
  methods: {
    messageGenerate(offer) {
      const target =
        offer.consumable.scope == 'cart'
          ? 'le panier'
          : offer.consumable.target.name
      const kind = offer.consumable.amount_kind == 'value' ? '€' : '%'
      const amount = offer.remaining_amount
        ? offer.remaining_amount
        : offer.consumable.amount
      return amount / 100 + kind + ' offert sur ' + target
    },
  },
  mounted() {
    this.client
      .get(
        '/account/offers/' +
          this.$route.params.id +
          '?customer_key=' +
          this.$route.params.key
      )
      .then(function(response) {
        this.offer = response.data
      })

    this.client
      .get(
        '/account/offers/' +
          this.$route.params.id +
          '/campaign?customer_key=' +
          this.$route.params.key
      )
      .then(function(response) {
        this.campaign = response.data
      })

    this.client
      .get(
        '/account/offers/' +
          this.$route.params.id +
          '/point_of_sales?customer_key=' +
          this.$route.params.key
      )
      .then(function(response) {
        this.pos = response.data
      })

    this.client
      .get('/account/owner?customer_key=' + this.$route.params.key)
      .then(function(owner) {
        this.advertiser = owner.data
        this.$vuetify.theme.secondary = this.advertiser.company.main_color
      })

    this.client
      .get('/account/customer?customer_key=' + this.$route.params.key)
      .then(function(response) {
        this.customer = response.data
        this.loaded = true
      })
  },
}
</script>
