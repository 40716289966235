<template>
  <v-container fluid grid-list-xl>
    <v-card class="px-3 py-4 mb-4">
      <label class="font-weight-light subheading align-self-center">
        Configuration des
        <b class="font-weight-medium">point(s) de vente(s)</b> de votre campagne
      </label>
    </v-card>

    <v-card class="w-100">
      <div class="py-2 px-4 justify-space-between disp-flex">
        <h4 class="align-self-end font-weight-medium">
          Vos point(s) de vente(s) :
          <span class="font-weight-light ml-3">{{ `${contracts ? contracts.length : 0} selectionné(s)` }}</span>
        </h4>
        <v-text-field
          v-model="search"
          class="pt-0"
          append-icon="search"
          label="Rechercher un point de vente"
          :loading="loading"
          single-line
          hide-details
          clearable
          style="max-width: 300px"
        ></v-text-field>
      </div>
      <v-divider class="my-3"></v-divider>
      <v-data-table
        ref="dataTable"
        :headers="headers"
        :items="pointOfSales"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        :rows-per-page-text="$t('common.rowsPerPage')"
        item-key="name"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th width="30">
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ma-0 pt-0 mt-0"
                    hide-details
                    flat
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon small>keyboard_arrow_down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-tile @click="allPointOfSalesIsSelected ? deselectAll() : selectAll()">
                    <v-list-tile-title>
                      {{ allPointOfSalesIsSelected ? $t('common.deselectAll') : $t('common.selectAll') }}
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="selectFiltered()">
                    <v-list-tile-title>{{ $t('common.selectPage') }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </th>
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="text-xs-left"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr
            :active="isSelected(props.item.id)"
            @click="addOrDeleteContracts(props.item.id)"
          >
            <td>
              <v-checkbox
                @click.stop="addOrDeleteContracts(props.item.id)"
                :input-value="isSelected(props.item.id)"
                hide-details
              ></v-checkbox>
            </td>
            <td>{{ props.item.name | capitalize }}</td>
            <td>
              {{
                (props.item.legal_form ? props.item.legal_form : '-')
                  | capitalize
              }}
            </td>
            <td>{{ props.item.kind | capitalize }}</td>
            <td>
              {{
                (props.item.location ? props.item.location.city : '-')
                  | capitalize
              }}
              {{ props.item.location ? props.item.location.zipcode : '-' }}
            </td>
          </tr>
        </template>
        <template v-slot:pageText="props">
          {{ $t('common.line') }} {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }} {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CompanyService from '@/services/CompanyService'

export default {
  data() {
    return {
      loading: false,
      pointOfSales: [],
      search: null,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      headers: [
        {
          text: this.$t('pointsOfSale.name'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('pointsOfSale.legalForm'),
          value: 'legal_form',
          sortable: false
        },
        {
          text: this.$t('pointsOfSale.kind'),
          value: 'kind',
          sortable: false
        },
        {
          text: this.$t('pointsOfSale.city'),
          value: 'city',
          sortable: false
        }
      ]
    }
  },
  created() {
    this.fetchPointOfSales()
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'addContracts',
      'deleteContract',
      'clearContracts'
    ]),
    async fetchPointOfSales() {
      this.loading = true
      const res = await CompanyService.getPointOfSales()
      this.pointOfSales = res.data
      this.loading = false
    },
    addOrDeleteContracts(id) {
      this.isSelected(id) ? this.deleteContract(id) : this.addContracts(id)
    },
    isSelected(posId) {
      if (this.contracts?.length > 0)
        return !!this.contracts.find(e => e.point_of_sale_id === posId)
      else return false
    },
    selectAll() {
      this.addContracts(this.pointOfSales.map(e => e.id))
    },
    deselectAll() {
      this.clearContracts()
    },
    selectFiltered() {
      this.addContracts(this.filteredPointOfSales.map(e => e.id))
    }
  },
  computed: {
    ...mapState({
      contracts: state => state.campaignBuilder.campaign.contracts
    }),
    filteredPointOfSales() {
      return this.$refs.dataTable.filteredItems
    },
    allPointOfSalesIsSelected() {
      return this.pointOfSales?.length === this.contracts?.length
    }
  }
}
</script>
