<template>
  <v-layout class="slider__legend" justify-space-between>
    <span class="pr-4">{{ min }}</span>
    <span class="pr-3">{{ $n(max * 0.25, 'decimal') }}</span>
    <span>{{ $n(max * 0.5, 'decimal') }}</span>
    <span>{{ $n(max * 0.75, 'decimal') }}</span>
    <span>{{ $n(max, 'decimal') }}</span>
  </v-layout>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SliderLegend extends Vue {
  @Prop(Number) max
  @Prop(Number) min
}
</script>

<style lang="scss" scoped>
.slider {
  &__legend {
    width: 100%;

    & span:last-child {
      margin-right: -15px;
    }
  }
}
</style>
