<template>
  <div>
    <v-menu offset-y nudge-bottom="20">
      <template v-slot:activator="{ on }">
        <v-btn flat icon>
          <v-badge class="badge" :value="count > 0" rigth overlap color="red">
            <template v-slot:badge>
              <span>{{ count }}</span>
            </template>
            <v-icon medium v-on="on">notifications</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <!-- <NotificationsList @notSeenCount="count = $event" /> -->
    </v-menu>
  </div>
</template>

<script>
//import NotificationsList from '@/components/NotificationsList'

export default {
  data() {
    return {
      count: null,
    }
  },
  components: {
    //NotificationsList,
  },
}
</script>
<style scoped>
.badge .v-badge__badge span {
  font-size: 12px;
}
</style>
