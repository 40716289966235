<template>
  <div>
    <v-toolbar flat color="indigo" class="elevation-2">
      <v-list class="pa-0">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title class="white--text" v-if="account.firstname">
              {{ account.lastname }} {{ account.firstname }}
            </v-list-tile-title>
            <v-list-tile-title class="white--text" v-else-if="company.advertisers.length > 0">
              {{ company.advertisers[0].name | capitalize }}
            </v-list-tile-title>
            <label class="white--text caption">{{ company.name }}</label>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-toolbar>
    <v-list class="pt-0 mt-3" v-if="company">
      <template v-for="(item, index) in menu">
        <template v-if="item.submenu.length === 0">
          <v-list-tile v-if="item.display" @click="to(item.link)" :key="index">
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <template v-else>
          <v-list-group v-if="item.display" :prepend-icon="item.icon" :key="index">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile>
            </template>

            <template v-for="(submenu, index) in item.submenu">
              <template v-if="submenu.items.length === 0">
                <v-list-tile v-if="submenu.display" @click="to(submenu.link)" :key="`submenu_${index}`">
                  <v-list-tile-title>{{ submenu.title }}</v-list-tile-title>
                </v-list-tile>
              </template>
              <template v-else>
                <v-list-group no-action sub-group class="pl-0" :disabled="submenu.display" :key="`submenu_${index}`">
                  <template v-slot:activator>
                    <v-list-tile>
                      <v-list-tile-title>{{ submenu.title }}</v-list-tile-title>
                    </v-list-tile>
                  </template>
                  <template v-for="(item, index) in submenu.items">
                    <v-list-tile v-if="item.display" @click="to(item.link)" :key="`submenu_items_${index}`">
                      <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                    </v-list-tile>
                  </template>
                </v-list-group>
              </template>
            </template>
          </v-list-group>
        </template>
      </template>

      <v-divider></v-divider>

      <v-list-tile @click="logoutUser()">
        <v-list-tile-action>
          <v-icon>settings_power</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ $t('nav.signOut') }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthService from '@/services/AuthService'

export default {
  methods: {
    canCreateCampaign() {
      if (this.$store.state.role) return this.$store.state.role.can_create_campaign
      else return true
    },
    to(link) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push(link).catch(() => {
      })
    },
    async logoutUser() {
      await AuthService.logout()
      this.$router.push({ path: '/signin' }).catch()
    }
  },
  computed: {
    ...mapGetters(['hasCoreProgram']),
    account() {
      return this.$store.state.user.account
    },
    company() {
      return this.$store.state.user.company
    },
    advertiser() {
      return this.$store.state.user.capabilities.advertiser
    },
    publisher() {
      return this.$store.state.user.capabilities.publisher
    },
    menu() {
      return [
        {
          title: this.$t('nav.dashboard'),
          icon: 'dashboard',
          link: '/dashboard',
          display: true,
          submenu: []
        },
        {
          title: this.$t('nav.company'),
          icon: 'apartment',
          link: '',
          display: this.advertiser,
          submenu: [
            {
              title: this.$t('nav.childCompanies'),
              link: '/childs',
              display: true,
              items: []
            },
            {
              title: this.$t('nav.pointsOfSale'),
              link: '/points_of_sale',
              display: true,
              items: []
            },
            {
              title: this.$t('nav.retailers'),
              link: '/retailers',
              display: this.$store.getters.getBrandAdvertiser,
              items: []
            }
          ]
        },
        {
          title: this.$t('nav.loyalty'),
          icon: 'dns',
          link: '',
          display: this.company.usages.includes('fidelity'),
          submenu: [
            {
              title: this.$t('nav.manageLoyaltyCampaigns'),
              link: '/core-program/campaigns',
              display: this.hasCoreProgram,
              items: []
            },
            {
              title: this.$t('nav.createCampaign'),
              link: '',
              display: !this.hasCoreProgram,
              items: [
                {
                  title: this.$t('nav.customCampaign'),
                  link: '/campaigns/custom/new',
                  display: this.hasCoreProgram
                },
                {
                  title: this.$t('nav.eventCampaign'),
                  link: '/campaigns/event/new/',
                  display: this.hasCoreProgram
                },
                {
                  title: 'Campagne communication',
                  link: '/campaigns/communication/new',
                  display: this.hasCoreProgram
                }
              ]
            },
            {
              title: this.$t('nav.retailers'),
              link: '/retailers',
              display: this.$store.getters.getBrandAdvertiser,
              items: []
            },
            {
              title: this.$t('nav.createCoreProgram'),
              link: '/core-program/new/',
              display: !this.hasCoreProgram,
              items: []
            },
            {
              title: this.$t('nav.seeTransactions'),
              link: '/core-program/transactions',
              display: true,
              items: []
            },
            {
              title: this.$t('nav.customers'),
              link: '/customers',
              display: true,
              items: []
            },
            {
              title: this.$t('nav.targets'),
              link: '/targets',
              display: true,
              items: []
            },
            {
              title: this.$t('nav.donations'),
              link: '/donations',
              display: this.company.usages.includes('fidelity') && this.company.donor,
              items: []
            },
            {
              title: this.$t('nav.communications'),
              link: '/communications',
              display: true,
              items: []
            }
          ]
        },
        {
          title: 'Ad-to-store',
          icon: 'dns',
          link: '',
          display: this.company.usages.includes('conquest') && this.advertiser,
          submenu: [
            {
              title: this.$t('nav.campaigns'),
              link: '',
              display: false,
              items: [
                {
                  title: this.$t('nav.manageCampaigns'),
                  link: '/campaigns',
                  display: true
                },
                {
                  title: this.$t('nav.createCampaign'),
                  link: '/campaigns/new/',
                  display: this.canCreateCampaign()
                }
              ]
            },
            {
              title: this.$t('nav.ratecards'),
              link: '/ratecards',
              display: this.canCreateCampaign(),
              items: []
            },
            {
              title: this.$t('nav.groups'),
              link: '/groups',
              display: true,
              items: []
            }
          ]
        },
        {
          title: this.$t('nav.campaigns'),
          icon: 'settings',
          link: '/campaigns',
          display: !this.advertiser && this.publisher,
          submenu: []
        },
        {
          title: this.$t('nav.settings'),
          icon: 'settings',
          link: '/settings',
          display: true,
          submenu: []
        }
      ]
    }
  }
}
</script>

<style lang="css">
aside .v-list__group__header--sub-group .v-list__group__header__prepend-icon {
  padding: 0 0 0 10px !important;
  min-width: 35px !important;
}
</style>
