import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/store/modules/user.store'

import { history } from './modules/history';
import { order } from './modules/order';
import { payment } from './modules/payment';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    history,
    order,
    payment
  },
  state: {
    role: null
  },
  mutations: {
    storeRole(state, role) {
      state.role = role
    }
  }
})
