<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="retailers"
      select-all
      :loading="loading"
      :no-data-text="loading ? 'Loading ...' : 'No data to display'"
      item-key="name"
      class="elevation-1"
      :rows-per-page-text="$t('common.rowsPerPage')"
    >
      <template slot="headers" slot-scope="props">
        <tr>
          <th v-for="header in props.headers" :key="header.text">
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <td>
          <img style="width: 80px; height: auto;" :src="props.item.logo_url" />
        </td>
        <td>{{ props.item.name | capitalize }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    retailers: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      headers: [
        { text: '', value: 'logo' },
        { text: 'Name', value: 'name' },
      ],
    }
  },
}
</script>
