import CampaignBuilderState from '@/models/states/CampaignBuilderState'

export default function(): CampaignBuilderState {
  return {
    campaign: {},
    payWithPool: false,
    lines: [],
    li: -1,
    ai: -1,
    loading: false,
    loadingSave: false,
    loadingDestroy: false,
    editMode: false,
    create: false,
    mode: 'conquest'
  }
}