<template>
  <div>
    <div class="header-card pa-3">
      <h3 class="font-weight-medium">
        <v-icon small class="align-icon">business_center</v-icon>
        {{ $t('settings.personalData.title') }}
      </h3>
    </div>
    <div class="pt-3 px-5 h-100">
      <v-layout row wrap v-if="!loading">
        <v-flex xs12>
          <h3 class="font-weight-medium">{{ $t('settings.personalData.familyAndPets') }}</h3>
          <v-radio-group row v-model="family" @change="activeAttributes()" hide-details>
            <v-radio :label="$t('common.boolYes')" :value="true" color="primary"></v-radio>
            <v-radio :label="$t('common.boolNo')" :value="false" color="primary"></v-radio>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 v-if="family">
          <v-divider class="my-2"></v-divider>
          <div class="disp-flex align-center">
            <h3 class="font-weight-medium">{{ $t('settings.personalData.customAttributes') }}</h3>
            <v-spacer></v-spacer>
            <v-btn @click="addAttr()" :disabled="custom_attributes.length > 4" color="primary" class="mx-0" outline small>
              <v-icon class="primary--text">add</v-icon>
              {{ $t('settings.personalData.addCustomAttributes') }}
            </v-btn>
          </div>
          <v-layout row wrap my-2>
            <v-flex sm12 lg6 v-for="(attribute, index) in custom_attributes" :key="index">
              <v-card class="pa-3 relative h-100">
                <v-icon @click="deleteAttr(index)" color="red" style="position: absolute; top: 10px; right: 10px">clear</v-icon>
                <v-text-field v-model="attribute.label" :label="$t('settings.personalData.attributesName')" hide-details></v-text-field>
                <v-radio-group v-model="attribute.type" row hide-details>
                  <label class="font-weight-medium body-2 mr-3">Options :</label>
                  <v-radio :label="$t('common.text')" value="text"></v-radio>
                  <v-radio :label="$t('common.number')" value="number"></v-radio>
                  <v-radio :label="$t('common.values')" value="values"></v-radio>
                  <v-radio :label="$t('common.date')" value="date"></v-radio>
                </v-radio-group>
                <div v-if="attribute.type === 'values'" class="grey lighten-4 flex-div align-center mt-3">
                  <v-flex xs3 v-for="(value, indexVal) in attribute.custom_values" :key="indexVal" class="flex-div align-center">
                    <v-text-field v-model="attribute.custom_values[indexVal]" @click:append="deleteValue(index, indexVal)" class="mt-0 pt-0" append-icon="close" hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-btn :disabled="attribute.custom_values.length > 2" small @click="addValue(index)">
                      <v-icon>add</v-icon>
                      {{ $t('common.add') }}
                    </v-btn>
                  </v-flex>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <div class="pb-3">
        <v-btn color="primary" @click="saveCompany()" class="ml-0">
          <v-icon small class="mr-2">save</v-icon>
          {{ $t('settings.saveChanges') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import req from '@/packs/request.js'
import _ from 'lodash'

export default {
  props: {
    user: {
      type: Object
    },
    company: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      tagsCategories: [],
      family: false,
      tags: [],
      custom_attributes: this.company.custom_attributes
    }
  },
  methods: {
    async fetchTags() {
      const res = await req.get('/tags')
      this.tags = res.data
    },
    activeAttributes() {
      this.custom_attributes = []
      if (this.family)
        this.custom_attributes.push({ key: 0, label: '', type: '', custom_values: [] })
    },
    addAttr() {
      if (this.custom_attributes.length < 5)
        this.custom_attributes.push({ key: this.custom_attributes.length, label: '', type: 'text', custom_values: [] })
    },
    deleteAttr(index) {
      this.custom_attributes.splice(index, 1)
      this.custom_attributes.forEach((e, index) => { e.key = index })
    },
    addValue(index) {
      if (this.custom_attributes[index].custom_values.length >= 3) return
      this.custom_attributes[index].custom_values.push('vide')
    },
    deleteValue(index, indexVal) {
      this.custom_attributes[index].custom_values.splice(indexVal, 1)
    },
    async saveCompany() {
      const company = { ...this.company }
      company.custom_attributes = this.custom_attributes

      await req.put('/user/company', { company })
    }
  },
  computed: {
    orderedTagsCategories() {
      return _.orderBy(this.tags, 'title')
    }
  },
  created() {
    this.fetchTags()
    this.family = !!(this.company && this.company.custom_attributes && this.company.custom_attributes[0] && this.company.custom_attributes[0].label);
    this.loading = false
  }
}
</script>
