<template>
  <v-container fluid>
    <v-card class="elevation-1 mt-4">
      <ProductsTableList />
    </v-card>
  </v-container>
</template>

<script>
import ProductsTableList from '@/components/Products/ProductsTableList'

export default {
  components: {
    ProductsTableList
  }
}
</script>
