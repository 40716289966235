<template>
  <v-container fluid grid-list-xl>
    <v-layout class="grid-simple-campaign">
      <v-flex class="w-100">
        <v-card class="pa-3 default-heigth">
          <v-card-title>
            <span class="title">{{ $t('CoreProgramCampaignStep4.coreProgramDescription') }}</span>
          </v-card-title>
          <v-divider class="mb-2"></v-divider>
          <v-flex>
            <v-textarea :label="$t('CoreProgramCampaignStep4.describeCoreProgram')" v-model="description_" rows="4" hide-details no-resize></v-textarea>
          </v-flex>
          <v-flex>
            <label class="font-weight-medium">{{ $t('CoreProgramCampaignStep4.imageDescription') }} <span class="caption">(optionel)</span> :</label>
            <ImageLoader height="150" v-model="imageDescriptionUrl_" :src="imageDescriptionUrl_"></ImageLoader>
          </v-flex>
          <v-flex>
            <v-textarea v-model="imageDescriptionText_" :label="$t('CoreProgramCampaignStep4.imageDescriptionText')" hint="Describe your core program in case there is no image" rows="4" no-resize></v-textarea>
          </v-flex>
        </v-card>
      </v-flex>
      <v-flex class="w-100">
        <v-card class="pa-3">
          <div class="mb-4">
            <label class="title">{{ $t('campaignCreate.publishingTitle') }} :</label><br>
            <label class="subheading">Sélectionnez des canaux de diffusion</label>
            <v-divider class="mt-3"></v-divider>
          </div>
          <selectOfferPublishing :publishing="campaign.publishing"></selectOfferPublishing>
          <CoreProgramCampaignStep4ListTemplate :campaign="campaign"></CoreProgramCampaignStep4ListTemplate>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CoreProgramCampaignStep4ListTemplate from '@/components/CoreProgramCampaignStep4ListTemplate'
import selectOfferPublishing from '@/components/selectOfferPublishing'
import ImageLoader from '@/components/Base/ImageLoader'
import req from '@/packs/request'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    CoreProgramCampaignStep4ListTemplate,
    selectOfferPublishing,
    ImageLoader
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      emailCheck: false,
      hasWelcome: false
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setDescriptionCoreProgram',
      'setImageDescriptionUrlCoreProgram',
      'setImageDescriptionTextCoreProgram',
      'setEcardCoreProgram',
      'checkNotificationSelected'
    ]),
    newTemplate(val) {
      if (val === true && !this.hasReward) this.freshTemplate('reward')
      else this.campaign.templates = this.campaign.templates.filter(rew => {return rew.kind !== 'reward'})
    },
    async freshTemplate(type) {
      const res = await req.get(`/email/templates?by_kind=${type}&by_default=true`)
      this.campaign.templates.push(res.data[0])
    }
  },
  computed: {
    ...mapState({
      currencyName: state => state.campaignBuilder.campaign.loyalty_currency.name,
      currencyOther: state => state.campaignBuilder.campaign.loyalty_currency.other,
      earnExchangeRate: state => state.campaignBuilder.campaign.pool_program.earn_exchange_rate,
      burnExchangeRate: state => state.campaignBuilder.campaign.pool_program.burn_exchange_rate,
    }),
    description_: {
      get() {
        return this.campaign.description
      },
      set(value) {
        this.setDescriptionCoreProgram(value)
      }
    },
    imageDescriptionUrl_: {
      get() {
        return this.campaign.image_description_url
      },
      set(value) {
        this.setImageDescriptionUrlCoreProgram(value)
      }
    },
    imageDescriptionText_: {
      get() {
        return this.campaign.image_description_text
      },
      set(value) {
        this.setImageDescriptionTextCoreProgram(value)
      }
    },
    ecard_: {
      get() {
        return this.campaign.ecard
      },
      set(value) {
        this.setEcardCoreProgram(value)
      }
    },
    getCurrencyName() {
      return this.currencyName !== 'Other' ? this.currencyName : this.currencyOther
    },
    voucher() {
      return this.campaign.rewards && this.campaign.rewards.length > 0 && this.campaign.rewards[0] ? this.campaign.rewards[0].voucher : false
    }
  }
}
</script>

<style scoped lang="sass">
@import "../stylesheets/campaignCustom"
@import "../stylesheets/coreProgram"
</style>
