<template v-slot:items="props" slot-scope="props">
  <v-layout row justify-left>
    <v-btn round @click="showProductsByCategory()" style="left: -27px"> Liste des produits </v-btn>
    <v-dialog v-model="dialog" persistent width="60%">
      <v-card class="px-4 pt-2 pb-3">
        <v-card-title class="headline">
          <span>{{ category.name }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="dialog = !dialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-layout row wrap>
          <v-flex sm12 md6 lg4>
            <div class="text-sm-center text-md-right text-lg-right new-product">
              <v-autocomplete
                clearable
                v-model="selectedProduct"
                :loading="loadingNewProduct"
                :items="productsToAdd"
                single-line
                :search-input.sync="searchProductToAdd"
                item-text="name"
                class="ml-4"
                item-value="id"
                label="Rechercher un nouveau produit à ajouter"
              >
                <template v-slot:no-data>
                  <v-list-tile>
                    <v-list-tile-title> Rechercher un nouveau produit à ajouter dans la catégorie </v-list-tile-title>
                  </v-list-tile>
                </template>
              </v-autocomplete>
              <v-btn round color="primary" @click="addProductToCategory(selectedProduct)" :disabled="!selectedProduct">
                Ajouter
                <v-icon right dark>add</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex sm12 md6 lg4>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Rechercher un produit dans la catégorie"
              class="ml-4 products-category-filter"
              single-line
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-data-table
          :headers="headers"
          :items="products"
          item-key="item.name"
          :loading="loading"
          :pagination.sync="pagination"
          :rows-per-page-text="$t('common.rowsPerPage')"
          :no-data-text="loading ? 'Chargement ...' : 'Aucune données'"
          :total-items="itemsCount"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.name }}</td>
            <td class="justify-center">
              <span>{{ props.item.identifier ? props.item.identifier : '-' }}</span>
            </td>
            <td>{{ props.item.base_price ? $n(props.item.base_price.toCurrency(), 'currency') : '-' }}</td>
            <td class="justify-center layout px-0">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon ripple @click="removeProductFromCategory(props.item)">
                    <v-icon small color="red lighten-1">delete</v-icon>
                  </v-btn>
                </template>
                <span>Retirer le produit de la catégorie</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:pageText="props">
            Lignes {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import req from '@/packs/request'
import EventBus from '@/packs/event-bus'

export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogm1: '',
      dialog: false,
      products: [],
      productsToAdd: [],
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      itemsCount: 0,
      loading: false,
      loadingNewProduct: false,
      search: null,
      selectedProduct: null,
      searchProductToAdd: null
    }
  },
  watch: {
    search: {
      handler() {
        clearTimeout(this.debounceId)
        if (this.dialog) {
          this.debounceId = setTimeout(() => {
            this.fetchProducts()
          }, 500)
        }
      }
    },
    searchProductToAdd: {
      handler(val) {
        if (val && val.length > 0) {
          clearTimeout(this.debounceId)
          this.debounceId = setTimeout(() => {
            this.fetchProduct()
          }, 500)
        } else {
          this.productsToAdd = []
        }
      }
    },
    pagination: {
      deep: true,
      handler() {
        if (this.dialog) {
          this.debounceId = setTimeout(() => {
            this.fetchProducts()
          }, 500)
        }
      }
    }
  },
  computed: {
    headers() {
      return [
        { text: 'Nom', value: 'name' },
        { text: 'Identifiant', value: 'identifier', sortable: false },
        { text: 'Prix', value: 'base_price', sortable: false },
        { text: 'Retirer de la catégorie', value: 'name', width: '50px', sortable: false }
      ]
    }
  },
  methods: {
    showProductsByCategory() {
      this.dialog = true
      this.fetchProducts()
    },
    async fetchProducts() {
      this.loading = true
      const byName = this.search && this.search.length > 0 ? `&by_name=${this.search}` : ''
      const res = await req.get(
        `/targets/categories/${this.category.id}/products?page=${this.pagination.page}&per_page=${this.pagination.rowsPerPage}${byName}`
      )
      this.products = res.data.collection
      this.itemsCount = res.data.count
      this.loading = false
    },
    async addProductToCategory(product) {
      await req
        .post(`/targets/categories/${this.category.id}/products`, {
          product_ids: [product]
        })
        .then((result) => {
          if (result) {
            EventBus.$emit('showSnackbar', {
              text: this.$t('message.productAddedToCategory', {
                product: result.data[0].name,
                category: this.category.name
              }),
              color: 'success'
            })
            this.fetchProducts()
          }
        })
      this.selectedProduct = null
    },
    async removeProductFromCategory(product) {
      await req.delete(`/targets/categories/${this.category.id}/products?product_ids=${product.id}`)
      this.fetchProducts()
    },
    async fetchProduct() {
      this.loadingNewProduct = true
      const byName =
        this.searchProductToAdd && this.searchProductToAdd.length > 0 ? `&by_name=${this.searchProductToAdd}` : ''
      const res = await req.get(
        `/targets/products?page=${this.pagination.page}&per_page=${this.pagination.rowsPerPage}${byName}`
      )
      this.productsToAdd = res.data.collection
        ? res.data.collection.filter((product) => {
            return !product.categories.find((category) => category === this.category.name)
          })
        : []
      this.loadingNewProduct = false
    }
  }
}
</script>
<style scoped>
.tooltip-custom {
  font-size: 15px;
  color: white;
  background: rgba(0, 0, 0, 0.69);
  border-radius: 3px;
  padding: 12px 0px 12px 12px;
}
.new-product {
  flex-direction: row;
  align-items: center;
  width: 40rem;
  display: flex;
}
.products-category-filter {
  width: 40rem;
  margin-bottom: 2em;
}
.products-add {
  width: 35rem;
}
</style>