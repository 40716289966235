<template>
  <nav class="mb-4">
    <v-tabs class="tabs" v-model="active">
      <v-tab class="tab" v-for="tab in tabs" :key="tab">
        {{ $t(`communications.purchaseHistory.${tab}`) }}
      </v-tab>

      <v-tab-item class="mt-4" v-for="tab in tabs" :key="tab">
        <slot :name="tab" />
      </v-tab-item>
    </v-tabs>
  </nav>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Navigation extends Vue {
  @Prop(Array) tabs;
  active = null;
}
</script>

<style lang="scss">
.v-tabs {
  &__item--active {
    font-weight: bold;
  }
  &__wrapper {
    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      height: 2px;
      background: #0000006b;
      bottom: -1px;
      z-index: 1;
    }
  }
}
.tab {
  margin-right: 50px;
  padding-right: 15px;
  padding-left: 15px;
}
</style>
