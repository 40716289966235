<template>
  <div>
    <DashboardFidFilters
      :pos="this.filters.pos"
      @updatePOS="updatePOS($event)"
      @updateDateRange="updateDateRange($event)"
    />
    <div class="mt-4" v-if="cardData">
      <v-layout row wrap>
        <DashboardStats :card-data="cardData"></DashboardStats>
      </v-layout>
      <v-layout row wrap>
        <v-flex md6>
          <DashboardTransactions />
        </v-flex>
        <v-flex md6>
          <DashboardCharts :data-charts="cardData"></DashboardCharts>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import DashboardFidFilters from '@/components/Dashboard/DashboardFidFilters'
import DashboardTransactions from '@/components/Dashboard/DashboardFidTransactions'
import DashboardStats from '@/components/Dashboard/DashboardFidStats'
import DashboardCharts from '@/components/Dashboard/DashboardFidCharts'
import req from '@/packs/request.js'

export default {
  components: {
    DashboardFidFilters,
    DashboardStats,
    DashboardTransactions,
    DashboardCharts
  },
  data() {
    return {
      cardData: null,
      filters: {
        range: null,
        pos: []
      }
    }
  },
  methods: {
    updatePOS(pos) {
      this.filters.pos = pos
      this.fillData()
    },
    updateDateRange(range) {
      this.filters.range = range
      this.fillData()
    },
    async fillData() {
      const stats = await req.get(`/statistics${this.getFilters()}`)
      this.cardData = stats.data
    },
    getFilters() {
      const dateRange = this.filters.range
        ? `start_at=${this.filters.range.start}&end_at=${this.filters.range.end}`
        : ''
      const pos = this.filters.pos?.length > 0
          ? `&by_point_of_sale_id=${this.filters.pos.join(',')}`
          : ''
      return dateRange || pos ? `?${dateRange}${pos}` : ''
    }
  }
}
</script>
