<template>
  <CgViewer />
</template>

<script>
import CgViewer from '@/components/CgViewer'

export default {
  components: {
    CgViewer,
  },
}
</script>
