var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":"","row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":"blue-grey lighten-5"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pointOfSales,"total-items":_vm.pointOfSalesCount,"pagination":_vm.pagination,"select-all":"","loading":_vm.loading,"no-data-text":_vm.loading ? _vm.$t('common.loading') : _vm.$t('common.noData'),"item-key":"name","rows-per-page-text":_vm.$t('common.rowsPerPage')},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
                'column sortable',
                'text-xs-left',
                _vm.pagination.descending ? 'desc' : 'asc',
                header.value === _vm.pagination.sortBy ? 'active' : '' ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")])],1)}),0)]}},{key:"items",fn:function(props){return [_c('tr',{attrs:{"active":props.item.selected}},[_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_c('div',{staticClass:"flex-div align-center"},[_c('span',[_vm._v(_vm._s(props.item.phone ? props.item.phone : '-'))]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(props.item, 'phone')}}},[_vm._v("edit")])],1)]),_c('td',{staticClass:"flex-div align-center"},[(props.item.location && props.item.location.line_1)?_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"20rem"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")((props.item.location ? props.item.location.line_1 + props.item.location.line_2 : '')))+" "+_vm._s(props.item.location ? props.item.location.zipcode : '')+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('pointsOfSale.noLocation')))]),_c('div',{staticClass:"ml-2"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(props.item, 'address')}}},[_vm._v("edit")])],1)]),(
                props.item.affiliate_contract_url ||
                  (_vm.company && _vm.company.affiliate_contract_url)
              )?_c('td',[(props.item.affiliate_contract_url)?_c('v-btn',{staticClass:"ml-0",attrs:{"small":"","href":props.item.affiliate_contract_url,"target":"_blank","flat":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("picture_as_pdf")]),_vm._v(_vm._s(_vm.fileNameFromUrl(props.item.affiliate_contract_url))+" ")],1):(_vm.company.affiliate_contract_url)?_c('v-btn',{staticClass:"ml-0",attrs:{"small":"","href":_vm.company.affiliate_contract_url,"target":"_blank","flat":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("picture_as_pdf")]),_vm._v(_vm._s(_vm.fileNameFromUrl(_vm.company.affiliate_contract_url))+" ")],1):_vm._e()],1):_c('td',[_vm._v("-")])])]}}])})],1)],1),(_vm.editDialog)?_c('PointOfSaleListEdit',{attrs:{"value":_vm.editDialog,"fromEnrollement":_vm.fromEnrollement,"point_of_sale":_vm.editedItem,"editType":_vm.editType},on:{"input":_vm.closeEdit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }