<template>
  <div>
    <div class="header-card pa-3">
      <h3 class="font-weight-medium">
        <v-icon small class="align-icon">account_circle</v-icon>
        {{ $t('settings.account.title') }}
      </h3>
    </div>
    <div class="pt-3 px-5">
      <v-layout row wrap>
        <v-flex xs12 md6>
          <v-text-field :label="$t('settings.account.lastName')" v-model="user.lastname"></v-text-field>
        </v-flex>
        <v-flex xs12 md6>
          <v-text-field :label="$t('settings.account.firstName')" v-model="user.firstname"></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field label="E mail" v-model="user.email"></v-text-field>
        </v-flex>
        <v-flex xs12 md6>
          <v-text-field :label="$t('settings.account.newPassword')" type="password" v-model="user.password" :rules="passwordRules"></v-text-field>
        </v-flex>
        <v-flex xs12 md6>
          <v-text-field :label="$t('settings.account.confirmPassword')" type="password" v-model="user.password_conformation" :rules="passwordRulesConf"></v-text-field>
        </v-flex>
      </v-layout>
    </div>
    <v-layout row wrap class="px-5 pb-3">
      <v-flex xs6 class="text-md-left text-xs-center disp-flex">
        <v-btn color="primary" @click="saveUser()" class="align-self-end mx-0">
          <v-icon small class="mr-2">save</v-icon>
          {{ $t('settings.saveChanges') }}
        </v-btn>
      </v-flex>
      <v-flex xs6>
        <h3 class="font-weight-medium mb-3">{{ $t('settings.account.langInterface') }} :</h3>
        <img @click="changeLocale('fr')" src="@/assets/images/standard/france_flag.png" width="30px" class="mx-1" alt="french_flag">
        <img @click="changeLocale('en')" src="@/assets/images/standard/united-kingdom_flag.png" width="30px" class="mx-1" alt="united-kingdom_flag">
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'

export default {
  data() {
    return {
      passwordRules: [
        v => !!v || 'Password is required',
        v => v && v.length > 7 || 'Password not long enough'
      ],
      passwordRulesConf: [
        v => !!v || 'Password confirmation is required',
        v => v === this.user.password || 'The passwords do not match'
      ]
    }
  },
  props: {
    user: {
      type: Object
    }
  },
  methods: {
    async saveUser() {
      await req.put('/user', { account: this.user })
      EventBus.$emit('showSnackbar', {
        text: 'Modifications has been saved',
        color: 'success'
      })
    },
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.$moment.locale(locale)
    }
  }
}
</script>
