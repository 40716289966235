<template>
  <v-card class="justify-center card-actions elevation-0" @click.self="create = true" :class="{'no-activate-actions': !create}">
    <v-icon v-if="!create" @click.self="create = true">add_circle_outline</v-icon>
    <div v-else class="h-100">
      <CampaignBuilderLineSelectActions :line="line" @createAction="$emit('createAction', $event); create = false"></CampaignBuilderLineSelectActions>
    </div>
  </v-card>
</template>

<script>
import CampaignBuilderLineSelectActions from '@/components/CampaignBuilderLineSelectActions'

export default {
  components: {
    CampaignBuilderLineSelectActions
  },
  props: {
    line: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      create: false
    }
  }
}
</script>
