<template>
  <v-data-table :loading="loading" hide-actions class="datatable" :headers="headers" :items="items" sortable="false">
    <template slot="headerCell" slot-scope="props">
      {{ $t(props.header.text) }}
    </template>
    <template v-slot:items="props">
      <td class="text-xs-left">{{ props.item.quantity }}</td>
      <td class="text-xs-left">{{ props.item.unitCost * 1000 }} €</td>
      <td class="text-xs-left">{{ $n(props.item.orderQuantity, 'decimal') }}</td>
      <td class="text-xs-left cost-row">{{ $n(props.item.cost.toFixed(2), 'currency') }}</td>
    </template>
  </v-data-table>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PricingTable extends Vue {
  @Prop(Array) headers;
  @Prop(Array) items;
  @Prop(Boolean) loading;
}
</script>

<style lang="scss" scoped>
.cost-row {
  width: 110px;
}
</style>
