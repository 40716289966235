<template>
  <v-card class="config elevation-0 py-3 px-4">
    <label class="font-weight-medium subheading">{{ $t('createCampaign.welcome.personnalData') }} :</label>
    <div class="w-100 mt-3">
      <v-btn-toggle v-model="registration" mandatory :disabled="viewMode" class="btn-group w-100 elevation-0">
        <v-btn flat :value="0" class="grow" active-class="btn-selected">
          {{ $t('createCampaign.welcome.basicMembership') }}
        </v-btn>
        <v-btn flat :value="1" class="grow" active-class="btn-selected">
          {{ $t('createCampaign.welcome.fullMembership') }}
        </v-btn>
      </v-btn-toggle>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    threshold: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('campaignBuilder', ['viewMode']),
    registration: {
      get() {
        return this.threshold
      },
      set(value) {
        this.setTriggerThreshold(value)
      }
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['setTriggerThreshold'])
  }
}
</script>
