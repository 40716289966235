<template>
  <v-card class="config elevation-0 px-4">
    <div class="font-weight-light title disp-flex align-center mb-4">
      <div class="font-weight-bold disp-flex align-center justify-center config-number mr-3">2</div>
      <span>L'offre est de <span class="font-weight-medium">type</span> :</span>
    </div>
    <div class="w-100" v-if="campaignKind !== 'welcome' && type !== 'pool'">
      <v-btn-toggle v-model="consumption_mode_" mandatory class="btn-group w-100 elevation-0">
        <v-btn flat :disabled="viewMode" value="instant_discount" class="instantDiscount grow" active-class="btn-selected">
          {{ $t('createCampaign.triggerReward.instantDiscount') }}
        </v-btn>
        <v-btn flat :disabled="disablePriceOverrideButton || viewMode" value="price_override" class="priceOverride grow" active-class="btn-selected">
          {{ $t('createCampaign.triggerReward.fixedPrice') }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <div class="disp-flex mt-3">
      <div class="font-weight-medium align-self-center">
        <template v-if="type === 'pool'">
          {{ amount_kind_ === 'percentage' ? 'Définissez le multiplicateur de points' : 'Définissez le gain de points' }} :
        </template>
        <template v-else>
          {{ consumption_mode_ === 'price_override' ? $t('createCampaign.triggerReward.newPrice') : $t('createCampaign.triggerReward.discountAmount') }} :
        </template>
      </div>
      <v-spacer></v-spacer>
      <v-text-field v-if="type === 'reward'" v-model.number="amount_" :disabled="viewMode" :min="0" type="number" class="text-number-mid" label="Montant" placeholder="10"></v-text-field>
      <v-text-field v-else v-model.number="amount_multiply_" :disabled="viewMode" :min="1" type="number" class="text-number-mid" label="Multiplicateur" placeholder="10"></v-text-field>
      <v-btn-toggle v-model="amount_kind_" mandatory class="btn-group elevation-0 align-center mb-1 ml-3">
        <v-btn :disabled="consumption_mode === 'price_override' || viewMode" flat value="percentage" class="percentage grow" active-class="btn-selected">
          <h2 class="px-1">{{ type === 'pool' ? 'x' : '%' }}</h2>
        </v-btn>
        <v-btn flat :disabled="disableValueButton || viewMode" value="value" class="value grow" active-class="btn-selected">
          <v-icon>{{ type === 'pool' ? 'control_point_duplicate' : 'euro_symbol' }}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  props: {
    consumption_mode: {
      type: String,
      required: true
    },
    amount_kind: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    scope: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setRewardConsumptionMode',
      'setRewardAmountKind',
      'setRewardAmount'
    ])
  },
  computed: {
    ...mapState({
      campaignKind: state => state.campaignBuilder.campaign.kind
    }),
    ...mapGetters('campaignBuilder', ['hasTrigger', 'viewMode']),
    disableValueButton() {
      return (!this.hasTrigger() && this.scope === 'cart') || (this.type === 'pool' && this.scope === 'cart')
    },
    disablePriceOverrideButton() {
      return this.scope === 'cart'
    },
    consumption_mode_: {
      get() {
        return this.consumption_mode
      },
      set(value) {
        this.setRewardConsumptionMode(value)
      }
    },
    amount_kind_: {
      get() {
        return this.amount_kind
      },
      set(value) {
        this.setRewardAmountKind(value)
      }
    },
    amount_: {
      get() {
        return this.amount ? this.amount.toCurrency() : 0
      },
      set(value) {
        this.setRewardAmount(value ? value.toCents() : 0)
      }
    },
    amount_multiply_: {
      get() {
        return this.amount ? (1 + (this.amount / 100)).toFixed(0) : 1
      },
      set(value) {
        this.setRewardAmount(value ? Number(((value - 1) * 100).toFixed(0)) : 1)
      }
    }
  }
}
</script>
