import AxiosWrapper from '@/services/AxiosWrapper'

const END_POINT = '/company'

const getCompany = () => AxiosWrapper.get(END_POINT)
const getCapabilities = () => AxiosWrapper.get(`${END_POINT}/capabilities`)
const getPointOfSales = () => AxiosWrapper.get(`${END_POINT}/point_of_sales`)
const getRetailers = () => AxiosWrapper.get(`${END_POINT}/retailers`)
const getPublishers = () => AxiosWrapper.get(`${END_POINT}/publishers`)
const getRateCards = () => AxiosWrapper.get(`/rate_cards`)
const getCommunicationHistory = () => AxiosWrapper.get(`${END_POINT}/communication_history`)
const getCommunicationHistories = () => AxiosWrapper.get(`company/campaigns/communication_histories`)

export default {
	getCompany,
	getCapabilities,
	getPointOfSales,
	getPublishers,
	getRetailers,
	getRateCards,
	getCommunicationHistory,
	getCommunicationHistories
}
