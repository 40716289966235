<template>
  <div
    class="icon"
    :class="[`icon--${name}`, `icon--${size}`, { 'has-align-fix': fixAlign }]"
  >
    <!-- <svg class="icon__svg">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="'#icon--' + name"></use>
    </svg> -->
    <img :src="img_path" class="icon__svg" />
  </div>
</template>

<script>
import bold from '@/assets/images/editor/bold.svg'
import checklist from '@/assets/images/editor/checklist.svg'
import hr from '@/assets/images/editor/hr.svg'
import image from '@/assets/images/editor/image.svg'
import italic from '@/assets/images/editor/italic.svg'
import link from '@/assets/images/editor/link.svg'
import mention from '@/assets/images/editor/mention.svg'
import ol from '@/assets/images/editor/ol.svg'
import paragraph from '@/assets/images/editor/paragraph.svg'
import redo from '@/assets/images/editor/redo.svg'
import remove from '@/assets/images/editor/remove.svg'
import strike from '@/assets/images/editor/strike.svg'
import ul from '@/assets/images/editor/ul.svg'
import underline from '@/assets/images/editor/underline.svg'
import undo from '@/assets/images/editor/undo.svg'

export default {
  props: {
    name: {},
    size: {
      default: 'normal',
    },
    modifier: {
      default: null,
    },
    fixAlign: {
      default: true,
    },
  },
  data() {
    return {
      img_data: {
        bold: bold,
        checklist: checklist,
        hr: hr,
        image: image,
        italic: italic,
        link: link,
        mention: mention,
        ol: ol,
        paragraph: paragraph,
        redo: redo,
        remove: remove,
        strike: strike,
        ul: ul,
        underline: underline,
        undo: undo,
      },
    }
  },
  computed: {
    img_path() {
      return this.img_data[this.name]
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.3rem;
  top: -0.05rem;
  fill: currentColor;

  &.has-align-fix {
    top: -0.1rem;
  }

  &__svg {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
// svg sprite
body > svg,
.icon use > svg,
symbol {
  path,
  rect,
  circle,
  g {
    fill: currentColor;
    stroke: none;
  }
  *[d='M0 0h24v24H0z'] {
    display: none;
  }
}
</style>
