<template>
  <v-card class="mt-4 pa-4 elevation-0">
    <v-container fluid grid-list-xl>
      <v-layout>
        <v-flex xs9>
          <CampaignBuilderLineActionEditTriggers v-if="action.card_type === 'trigger' || action.type === 'core'" :action="action" :campaign_type="campaign_type"/>
          <CampaignBuilderLineActionEditAwards v-if="action.card_type === 'award'" :action="action" :campaign_type="campaign_type"/>
          <CampaignBuilderLineActionEditRewards v-if="(action.card_type === 'reward' || action.card_type === 'pool') && action.type !== 'core'" :action="action" :campaign_type="campaign_type"/>
        </v-flex>
        <v-flex xs3 class="disp-flex" style="gap: 40px">
          <v-divider vertical></v-divider>
          <div>
            <div class="font-weight-light title mb-4">
              Configuration de votre <span class="font-weight-medium">{{ $t(`common.${action.card_type}`) }}</span>
            </div>
            <p class="body-1">
              {{ action.type === 'core' ? $t('campaignBuilderLineActionEdit.triggerCoreDescription') : isAwardTrigger ? $t('campaignBuilderLineActionEdit.triggerAwardDescription') : $t(`campaignBuilderLineActionEdit.${action.card_type}Description`) }}
              {{ hasTriggerBeforeReward || action.type === 'core' ? '' : $t('campaignBuilderLineActionEdit.rewardWithNoTrigger') }}
            </p>
            <template v-if="action.card_type === 'reward' || action.card_type === 'pool'">
              <template v-if="campaign.kind === 'welcome'">
                <div class="font-weight-medium align-self-center">L'offre permet d'acquerir :</div>
                <div>
                  <RewardType :type="action.type" />
                </div>
              </template>
              <div class="font-weight-medium align-self-center mt-3">{{ $t('campaignBuilderLineActionEdit.redemptionMode') }} :</div>
              <div class="w-100 mt-3">
                <v-btn-toggle v-model="claimMethod" mandatory class="btn-group w-100 elevation-0">
                  <v-btn small :disabled="viewMode" flat value="manual" active-class="btn-selected">
                    {{ $t('campaignBuilderLineActionEdit.redemptionManual') }}
                  </v-btn>
                  <v-btn small :disabled="viewMode" flat value="automatic" active-class="btn-selected">
                    {{ $t('campaignBuilderLineActionEdit.redemptionAutomatic') }}
                  </v-btn>
                </v-btn-toggle>
              </div>
              <template v-if="action.type !== 'core'">
                <div class="font-weight-medium align-self-center mt-3">{{ $t('campaignBuilderLineActionEdit.voucherMedia') }} :</div>
                <div class="w-100 mt-3">
                  <v-btn-toggle v-model="voucher" mandatory class="btn-group w-100 elevation-0">
                    <v-btn small :disabled="viewMode" flat :value="false" active-class="btn-selected">
                      {{ $t('coreProgram.linkedToTheCard') }}
                    </v-btn>
                    <v-btn small :disabled="viewMode" flat :value="true" active-class="btn-selected">
                      {{ $t('coreProgram.voucher') }}
                    </v-btn>
                  </v-btn-toggle>
                  <v-text-field class="mt-3" v-model="voucherName" :disabled="viewMode" v-show="voucher" label="Label du coupon" hide-details />
                  <v-checkbox v-model="requireActivation" :disabled="viewMode" label="L'offre doit être activé par le client" v-show="!voucher" hide-details />
                </div>
              </template>
            </template>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import RewardType from '@/components/Actions/Rewards/RewardType'
import CampaignBuilderLineActionEditTriggers from '@/components/CampaignBuilderLineActionEditTriggers'
import CampaignBuilderLineActionEditAwards from '@/components/CampaignBuilderLineActionEditAwards'
import CampaignBuilderLineActionEditRewards from '@/components/CampaignBuilderLineActionEditRewards'

export default {
  components: {
    RewardType,
    CampaignBuilderLineActionEditTriggers,
    CampaignBuilderLineActionEditAwards,
    CampaignBuilderLineActionEditRewards
  },
  props: {
    action: {
      type: Object,
      required: true
    },
    campaign_type: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setRewardVoucher',
      'setRewardClaimMethod',
      'setRewardVoucherName',
      'setRewardRequireActivation'
    ])
  },
  computed: {
    ...mapGetters('campaignBuilder', [
      'isAwardTrigger',
      'viewMode',
      'hasTrigger'
    ]),
    ...mapState({
      campaign: state => state.campaignBuilder.campaign,
      li: state => state.campaignBuilder.li
    }),
    hasTriggerBeforeReward() {
      return !!this.hasTrigger(this.li)
    },
    voucher: {
      get() {
        return this.action.voucher
      },
      set(value) {
        this.setRewardVoucher(value)
      }
    },
    claimMethod: {
      get() {
        return this.action.claim_method
      },
      set(value) {
        this.setRewardClaimMethod(value)
      }
    },
    voucherName: {
      get() {
        return this.action.voucher_name
      },
      set(value) {
        this.setRewardVoucherName(value)
      }
    },
    requireActivation: {
      get() {
        return this.action.require_activation
      },
      set(value) {
        this.setRewardRequireActivation(value)
      }
    }
  }
}
</script>
