<template>
  <div class="p-relative">
    <h3 class="font-weight-regular info-label disp-flex">
      <span class="ml-4 body-2">Panier :</span>
      <v-spacer></v-spacer>
      <router-link :to="`/customers/${identifier.customer_id}`" v-if="Object.keys(identifier).length > 0 && seeCustomer" class="see-customer body-2">Voir le client</router-link>
    </h3>

    <div v-if="this.data.items.length >= 1">
      <v-list class="pl-4 scroll-list custom-scrollbar mb-5">
        <template v-for="(item, index) in this.data.items">
          <v-list-tile :key="index">
            <v-list-tile-content>
              <v-list-tile-title class="body-1 font-regular">
                {{ item.target ? item.target.name : item.identifier }}
              </v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action v-for="discount in item.discounts" :key="discount.id" class="flex-unset">
              <v-chip disabled color="#8a63d2" v-if="discount.gift" :class="{'hover-transaction-gift': setHoveredTransaction(discount)}" text-color="#00000" class="px-1 h-20">
                Offert
              </v-chip>
              <v-chip disabled color="#FFCA83" v-else-if="discount.operation !== 'earn'" :class="{'hover-transaction': setHoveredTransaction(discount)}" text-color="#00000" class="px-1 h-20">
                -{{ discount.amount ? discount.amount.toCurrency() : '-' }}{{ getKind(discount.amount_kind) }}
                <span v-if="discount.amount_kind !== 'value'" class="ml-1">({{ percentageInValue(discount) }})</span>
              </v-chip>
            </v-list-tile-action>

            <v-list-tile-action class="size-unset">
              <v-list-tile-action-text class="body-1 font-regular">
                x{{ item.quantity ? item.quantity : '??' }}
              </v-list-tile-action-text>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider v-if="index + 1 < item.length" :key="index"></v-divider>
        </template>
      </v-list>
      <div v-if="fetchDiscounts.length >= 1" class="pl-4 ticket-discount">
        <v-flex>
          <h3 class="custom-grey body-2">
            Pied de ticket :
            <span v-for="discount in fetchDiscounts" :key="discount.id">
              <v-chip
                v-if="discount.amount_kind === 'value'"
                color="#FFCA83"
                disabled
                :class="{
                  'hover-transaction': setHoveredTransaction(discount),
                }"
                text-color="#00000"
                class="px-1 h-20 mt-0"
              >
                -{{ discount.amount ? discount.amount.toCurrency() : '-' }}{{ getKind(discount.amount_kind) }}
              </v-chip>
              <v-chip
                v-else
                color="#FFCA83"
                text-color="#000000"
                disabled
                :class="{
                  'hover-transaction': setHoveredTransaction(discount),
                }"
                class="px-1 h-20"
              >
                -{{ discount.amount ? discount.amount.toCurrency() : '-' }}{{ getKind(discount.amount_kind) }} ({{
                  percentageInValue(discount)
                }})
              </v-chip>
            </span>
          </h3>
        </v-flex>
      </div>
    </div>

    <div v-else class="text-center mt-6">
      <h4>
        <v-icon>warning</v-icon>
        Aucun item pour cette transaction
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    seeCustomer: {
      type: Boolean,
      required: true
    },
    hoveredTransaction: {
      type: Object,
      default: () => {
        return {}
      }
    },
    identifier: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      hover: {},
    }
  },
  methods: {
    getKind(kind) {
      if (kind === 'percentage') return '%'
      else return '€'
    },
    percentageInValue(discount) {
      return discount.computed_amount.toCurrency() + '€'
    },
    setHoveredTransaction(transaction) {
      return Object.keys(this.hoveredTransaction).length > 0 && this.hoveredTransaction.id === transaction.id
    }
  },
  computed: {
    fetchDiscounts() {
      const discount = []

      for (const each of this.data.discounts) {
        if (each.operation === 'reward' && each.scope === 'cart')
          discount.push(each)
      }
      return discount
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/stylesheets/Transactions/TransactionsItemCart"
</style>
