import FidelityService from '@/services/FidelityService'
import ConquestService from '@/services/ConquestService'
import SubFidelityService from '@/services/SubFidelityService'

import '@/types/extensions/string.extensions'

enum Mode {
  Fidelity = 'fidelity',
  AdToStore = 'conquest',
  Both = 'both'
}

const FIDELITY_TABS = ['customs', 'communications']
const CONQUEST_TABS = ['created', 'inherited', 'tradeMarketing', 'publishing']

const getCampaignsFilters = (getters: any) => {
  const filters = getters.campaignsByTabIndex.filters
  Object.entries(filters).forEach(e => {
    if (e[1] == 'all') delete filters[e[0]]
  })
  return { params: filters }
}

const actions = {
  setMode: ({ commit }: any, payload: Mode) => commit('SET_MODE', payload),
  setTabIndex: ({ commit }: any, payload: number) => commit('SET_TAB_INDEX', payload),
  setCoreProgram: ({ commit }: any, payload: any) => commit('SET_CORE_PROGRAM', payload),
  clearAllCampaigns: ({ commit }: any) => commit('CLEAR_ALL_CAMPAIGNS'),
  clearCampaigns: ({ commit }: any, payload: string) => commit('CLEAR_CAMPAIGNS', payload),
  setCampaignState: ({ commit }: any, payload: { index: string; value: string }) => {
    commit('SET_CAMPAIGN_STATE', payload)
    commit('SET_CAMPAIGN_STATUS', { index: payload.index, value: 'running' })
  },
  setCampaignStatus: ({ commit }: any, payload: { index: string; value: string }) => commit('SET_CAMPAIGN_STATUS', payload),
  setCommissionContract: ({ commit }: any, payload: { index: string; value: string }) => commit('SET_COMMISSION_CONTRACT', payload),
  deleteCampaign: ({ commit }: any, payload: number) => commit('DELETE_CAMPAIGN', payload),
  resetState: ({ commit }: any) => commit('RESET_STATE'),

  fetchAllCampaigns({ state, dispatch }: any) {
    const action = state.mode === Mode.Fidelity ? 'fetchFidelity' : 'fetchConquest'
    dispatch(action)
  },

  fetchCampaigns({ state, dispatch }: any) {
    const tabsName = state.mode === Mode.Fidelity ? FIDELITY_TABS : CONQUEST_TABS
    const action = `fetch${tabsName[state.tabIndex].capitalize()}`
    dispatch(action)
  },

  fetchConquest({ dispatch }: any) {
    dispatch('fetchCreated')
    dispatch('fetchInherited')
    dispatch('fetchTradeMarketing')
    dispatch('fetchPublishing')
  },

  fetchFidelity({ dispatch }: any) {
    dispatch('fetchCustoms')
    dispatch('fetchCommunications')
  },

  filterCampaignsByStatus({ commit, dispatch }: any, payload: string) {
    commit('CLEAR_CAMPAIGNS')
    commit('FILTER_BY_STATUS', payload)
    dispatch('fetchCampaigns')
  },

  filterCampaignsByState({ commit, dispatch }: any, payload: string) {
    commit('CLEAR_CAMPAIGNS')
    commit('FILTER_BY_STATE', payload)
    dispatch('fetchCampaigns')
  },

  fetchNextPage({ commit, dispatch }: any) {
    commit('INCREMENT_FILTERS_PAGE')
    dispatch('fetchCampaigns')
  },

  refresh({ commit, dispatch }: any) {
    commit('CLEAR_CAMPAIGNS')
    dispatch('fetchCampaigns')
  },

  // FIDELITY API

  async fetchCoreProgram({ commit }: any) {
    commit('SET_CORE_PROGRAM_LOADING', true)
    const res = await FidelityService.get()

    commit('SET_CORE_PROGRAM', res.data)
    commit('SET_CORE_PROGRAM_LOADING', false)
  },

  async fetchWelcome({ commit }: any) {
    commit('SET_SUBFIDELITY_WELCOME_LOADING', true)
    const res = await SubFidelityService.getWelcome()

    commit('SET_SUBFIDELITY_WELCOME', res.data)
    commit('SET_SUBFIDELITY_WELCOME_LOADING', false)
  },

  async fetchAnniversary({ commit }: any) {
    commit('SET_SUBFIDELITY_ANNIVERSARY_LOADING', true)
    const res = await SubFidelityService.getAnniversary()

    commit('SET_SUBFIDELITY_ANNIVERSARY', res.data)
    commit('SET_SUBFIDELITY_ANNIVERSARY_LOADING', false)
  },

  async fetchCustoms({ commit, getters }: any) {
    commit('SET_SUBFIDELITY_CUSTOMS_LOADING', true)
    const filters = getCampaignsFilters(getters)
    const res = await SubFidelityService.getCustoms(filters)

    commit('SET_SUBFIDELITY_CUSTOMS', res.data)
    commit('SET_SUBFIDELITY_CUSTOMS_LOADING', false)
  },

  async fetchCommunications({ commit, getters }: any) {
    commit('SET_SUBFIDELITY_COMMUNICATIONS_LOADING', true)
    const filters = getCampaignsFilters(getters)
    const res = await SubFidelityService.getCommunications(filters)

    commit('SET_SUBFIDELITY_COMMUNICATIONS', res.data)
    commit('SET_SUBFIDELITY_COMMUNICATIONS_LOADING', false)
  },

  // ADTOSTORE API

  async fetchCreated({ commit, getters }: any) {
    commit('SET_CONQUEST_CREATED_LOADING', true)
    const filters = getCampaignsFilters(getters)
    const res = await ConquestService.getCreated(filters)

    commit('SET_CONQUEST_CREATED', res.data)
    commit('SET_CONQUEST_CREATED_LOADING', false)
  },

  async fetchInherited({ commit, getters }: any) {
    commit('SET_CONQUEST_INHERITED_LOADING', true)
    const filters = getCampaignsFilters(getters)
    const res = await ConquestService.getInherited(filters)

    commit('SET_CONQUEST_INHERITED', res.data)
    commit('SET_CONQUEST_INHERITED_LOADING', false)
  },

  async fetchTradeMarketing({ commit, getters }: any) {
    commit('SET_CONQUEST_TRADE_MARKETING_LOADING', true)
    const filters = getCampaignsFilters(getters)
    const res = await ConquestService.getTradeMarketing(filters)

    commit('SET_CONQUEST_TRADE_MARKETING', res.data)
    commit('SET_CONQUEST_TRADE_MARKETING_LOADING', false)
  },

  async fetchPublishing({ commit, getters }: any) {
    commit('SET_CONQUEST_PUBLISHING_LOADING', true)
    const filters = getCampaignsFilters(getters)
    const res = await ConquestService.getPublishing(filters)

    commit('SET_CONQUEST_PUBLISHING', res.data)
    commit('SET_CONQUEST_PUBLISHING_LOADING', false)
  }
}

export default actions
