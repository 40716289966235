<template>
  <v-dialog :value="value" @input="$emit('input', false)" max-width="500px">
    <v-card>
      <div class="py-3 px-4">
        <v-card-title class="disp-flex justify-space-between">
          <h2 class="font-weight-medium">Envoyez un e-mail d'essai</h2>
          <v-icon @click="$emit('input', false)" style="font-size: 30px">close</v-icon>
        </v-card-title>
        <div class="px-3 mt-2 subheading" style="color: #909090">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-text-field v-model="email" :rules="emailRules" label="Adresse email"></v-text-field>
            </v-card-text>
          </v-form>
        </div>
      </div>
      <div class="pa-3 dialog-container-save">
        <v-btn @click.native="$emit('input')" outline color="error">Ne pas envoyer</v-btn>
        <v-btn :disabled="!valid" @click="validate()" color="primary">
          Envoyer
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import req from '@/packs/request.js'
import EventBus from '@/packs/event-bus'

export default {
  data() {
    return {
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail est requis',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail doit être valide'
      ]
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    campaignKind: {
      type: String,
      required: true
    }
  },
  computed: {
    isCommunication() {
      return this.campaignKind === 'communication'
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) this.sendDemoEmail()
    },
    sendDemoEmail() {
      const url = this.isCommunication
        ? `/core_program/campaigns/${this.campaignId}/send_fake_email`
        : `/campaigns/${this.campaignId}/demo_mail`

      req
        .post(url, { [this.isCommunication ? 'email' : 'email_address']: this.email })
        .then(() => {
          EventBus.$emit('showSnackbar', {
            text: `Un e-mail de test a été envoyé a cette adresse : ${this.email}`,
            color: 'success'
          })
          this.$emit('input', false)
        })
        .catch(error => {
          EventBus.$emit('showSnackbar', {
            text: req.build_messages(error.data),
            color: 'error'
          })
        })
    }
  }
}
</script>
