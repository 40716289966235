<template>
  <div>
    <v-btn flat dark color="red darken-1" class="ma-0 w-100" @click="deleteCampaign()">
      <v-icon left>delete</v-icon>{{ $t('common.delete') }}
    </v-btn>
  </div>
</template>

<script>
import ConquestService from '@/services/ConquestService'
import SubFidelityService from '@/services/SubFidelityService'

export default {
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  methods: {
    async deleteCampaign() {
      const service = this.campaign.type === 'conquest' ? ConquestService : SubFidelityService

      this.$swal
        .fire({
          title: 'Êtes vous sûr ?',
          text: `La suppression de ${this.campaign.name} est irreversible`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'red',
          cancelButtonText: 'Annuler',
          confirmButtonColor: 'green',
          confirmButtonText: 'Supprimer'
        })
        .then(async result => {
          if (result.isConfirmed) {
            await service.destroy(this.campaign.id)
            this.$swal.fire('Votre campagne a bien été supprimé', '', 'success').then(() => {
              this.$emit('onDelete', this.campaign.id)
            })
          }
        })
    }
  }
}
</script>
