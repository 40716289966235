<template>
  <v-dialog :value="true" @input="$router.go(-1)" max-width="500">
    <ProductDialogForm @change="refresh()"></ProductDialogForm>
  </v-dialog>
</template>

<script>
import ProductDialogForm from './ProductDialogForm'

export default {
  components: {
    ProductDialogForm
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>
