import AxiosWrapper from '@/services/AxiosWrapper'
import Account from '@/models/Account'
import { AxiosResponse } from 'axios'

const END_POINT = '/user'

const getUser = (): Promise<AxiosResponse<Account>> => AxiosWrapper.get(END_POINT)

export default {
	getUser
}