var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caption disp-flex",staticStyle:{"align-items":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1 cursor-pointer",style:({ color: _vm.iconColor }),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.rewardIcon)+" ")])]}}])},[_c('strong',[_vm._v(_vm._s(_vm.reward.voucher ? _vm.$t('coreProgram.voucher') : 'Lié à la carte'))])]),_c('v-tooltip',{attrs:{"open-delay":"700","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.reward && Object.keys(_vm.reward).length > 0)?_c('span',_vm._g({staticClass:"ml-1 ellipsis"},on),[_vm._v(" "+_vm._s(_vm.messagePerLocale ? _vm.messagePerLocale : 'Informations indisponible')+" ")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.messagePerLocale))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 cursor-pointer",style:({ color: _vm.iconColor }),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("info")])]}}])},[_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t('coreProgram.rewardType'))+": "),_c('strong',[_vm._v(_vm._s(_vm.consumptionModeLabel))])]),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t('coreProgram.usePerCustomer'))+": "),_c('strong',[_vm._v(_vm._s(_vm.numberOfUseLabel))])]),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t('coreProgram.repeatability'))+": "),_c('strong',[_vm._v(_vm._s(_vm.numberOfUsePerTransactionLabel))])]),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t('coreProgram.transformation'))+": "),_c('strong',[_vm._v(_vm._s(_vm.transformation))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }