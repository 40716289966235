<template>
  <v-layout row wrap class="pa-4 order-summary-container">
    <v-flex xs12 md6 xl6>
      <v-data-table class="summary-table" hide-actions :headers="headers" :items="orderSummary">
        <template slot="headerCell" slot-scope="props">
          {{ $t(props.header.text) }}
        </template>
        <template v-slot:items="props">
          <td class="text-xs-left font-weight-bold">{{ props.item.type | capitalize }}</td>
          <td class="text-xs-left">{{ $n(props.item.quantity, 'decimal') }}</td>
          <td class="text-xs-left">{{ $n(props.item.cost.toFixed(2), 'currency') }}</td>
        </template>

        <template v-slot:footer>
          <td :colspan="headers.length - 1">
            <strong>{{ $t('communications.purchaseHistory.totalAmount') }} :</strong>
          </td>
          <td>{{ $n(orderTotal.toFixed(2), 'currency') }}</td>
        </template>
      </v-data-table>
    </v-flex>
    <v-flex xs12 md6 xl6 align-self-center justify-content-center class="text-md-center">
      <v-btn v-if="!paymentInfoVisible"
        :disabled="isEnoughOrdered()"
        class="summary-table__validate"
        :dark="isEnoughOrdered()"
        color="white"
        large
        round
        @click="showPaymentInfo()"
        >{{ $t('common.validate') }}</v-btn
      >
      <PaymentInfo v-else :visible.sync="paymentInfoVisible" />
    </v-flex>
  </v-layout>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { State, Getter, Action } from 'vuex-class'
import PaymentInfo from '@/components/Communications/PaymentInfo.vue'
import AdvertiserService from '@/services/AdvertiserService'

@Component({
  components: {
    PaymentInfo
  }
})
export default class Summary extends Vue {
  @Prop(Number) sms
  @Prop(Number) emails

  @State(state => state.user.company) company

  @Getter('order/summary') orderSummary
  @Getter('order/total') orderTotal
  @Action('payment/setLoading') setLoading
  @Action('payment/setId') setPaymentId
  @Action('order/updateLastStepBeforePayment') updateLastStepBeforePayment

  paymentInfoVisible = false
  headers = [
    {
      text: 'communications.type',
      sortable: false
    },
    {
      text: 'communications.quantity',
      sortable: false
    },
    {
      text: 'communications.cost',
      sortable: false
    }
  ]

  async showPaymentInfo() {
    this.setLoading(true)
    this.updateLastStepBeforePayment(true)
    this.paymentInfoVisible = true
    try {
      const res = await AdvertiserService.getOrderNumber(
        this.company.advertisers[0].id
      )
      this.setPaymentId(res.data['order_number'])
    } catch (error) {
      console.log(error)
    }
    this.setLoading(false)
  }

  isOrderEmpty() {
    return this.orderTotal === 0
  }

  isEnoughOrdered() {
    return this.orderSummary[1].quantity < 20000
  }
}
</script>

<style lang="scss">
.summary-table {
  &__validate {
    width: 300px;

    &--disabled {
      color: white !important;
    }
  }

  .v-datatable.theme--light.v-table {
    background: none !important;
    border-color: white !important;
    color: white;

    th {
      color: white;
    }

    tr {
      border-color: white !important;
    }

    tbody tr:hover:not(.v-datatable__expand-row) {
      background: none !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.order-summary-container {
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}
</style>
