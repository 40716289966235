<template>
  <div class="flex-div align-center w-100 h-100 shadow size-container">
    <div class="w-100" style="position: relative">
      <vc-date-picker
        class="w-100 custom-date-picker"
        mode="range"
        v-model="range"
        @input="updateEvent($event)"
        :input-props="{
          style: 'border: 0; box-shadow: none; background-color: #fafafa',
          placeholder: $t('donations.periodFilter')
        }"
      />
      <v-icon
        v-if="range && clearable"
        class="reset-date"
        @click="clearEvent()"
        color="grey"
        >clear</v-icon
      >
    </div>
    <div
      class="h-100 flex-div align-center pr-2"
      style="background: #eceff1; pointer-events: none"
    >
      <v-icon class="ml-2">event</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initWith: {
      type: String,
      required: false,
      validator: value => {
        return ['month', 'day', 'year', 'week'].includes(value)
      },
      default: null
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      range: null
    }
  },
  created() {
    if (this.initWith) {
      this.range = {
        start: new Date(this.$moment().startOf(this.initWith).format('YYYY-MM-DD')),
        end: new Date(this.$moment().endOf(this.initWith).format('YYYY-MM-DD'))
      }
      this.updateEvent()
    }
  },
  methods: {
    updateEvent() {
      const range = this.range ? {
        start: this.$moment(this.range.start).format('YYYY-MM-DD'),
        end: this.$moment(this.range.end).format('YYYY-MM-DD')
      } : null

      this.$emit('update', range)
    },
    clearEvent() {
      this.range = null
      this.updateEvent()
    },
  },
}
</script>

<style lang="css" scoped>
.shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.custom-date-picker ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.87);
  opacity: 1; /* Firefox */
}

.custom-date-picker :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.87);
}

.custom-date-picker ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.87);
}

.reset-date {
  position: absolute;
  right: 20px;
  top: calc(50% - 12px);
}

.size-container {
  border-radius: 2px;
  height: 48px;
}
</style>
