import AxiosWrapper from '@/services/AxiosWrapper'

const END_POINT = '/campaigns'

const getCreated = (params: any) => AxiosWrapper.get(`${END_POINT}/created`, params)
const getInherited = (params: any) => AxiosWrapper.get(`${END_POINT}/inherited`, params)
const getTradeMarketing = (params: any) => AxiosWrapper.get(`${END_POINT}/trade_marketing`, params)
const getPublishing = (params: any) => AxiosWrapper.get(`${END_POINT}/publishing`, params)
const getDrafted = (params: any) => AxiosWrapper.get(`${END_POINT}/drafted`, params)
const getDraft = (params: any) => AxiosWrapper.post(`${END_POINT}/draft`, params)
const createConsumable = (id: string, params: any) => AxiosWrapper.post(`${END_POINT}/${id}/consumables`, params)
const createTrigger = (id: string, rewardId: string, params: any) => AxiosWrapper.post(`${END_POINT}/${id}/consumables/${rewardId}/triggers`, params)
const updateConsumable = (id: string, consumableId: string, params: any) => AxiosWrapper.put(`${END_POINT}/${id}/consumables/${consumableId}`, params)
const updateTriggerConsumable = (id: string, consumableId: string, triggerId: string, params: any) => AxiosWrapper.put(`${END_POINT}/${id}/consumables/${consumableId}/triggers/${triggerId}`, params)
const persist = (id: string) => AxiosWrapper.put(`${END_POINT}/${id}/persist`)
const destroy = (id: string) => AxiosWrapper.delete(`${END_POINT}/${id}`)
const activate = (id: string) => AxiosWrapper.put(`${END_POINT}/${id}/activate`)
const deactivate = (id: string) => AxiosWrapper.put(`${END_POINT}/${id}/deactivate`)
const update = (id: string, params: any) => AxiosWrapper.put(`${END_POINT}/${id}`, params)
const get = (id: string) => AxiosWrapper.get(`${END_POINT}/${id}`)
const deleteConsumable = (id: string, consumableId: string) => AxiosWrapper.delete(`${END_POINT}/${id}/consumables/${consumableId}}`)
const deleteTrigger = (id: string, consumableId: string, triggerId: string) => AxiosWrapper.delete(`${END_POINT}/${id}/consumables/${consumableId}/triggers/${triggerId}`)

export default {
	getCreated,
	getInherited,
	getTradeMarketing,
	getPublishing,
	getDrafted,
	getDraft,
	destroy,
	activate,
	deactivate,
	get,
	update,
	createConsumable,
	createTrigger,
	updateConsumable,
	updateTriggerConsumable,
	persist,
	deleteConsumable,
	deleteTrigger
}
