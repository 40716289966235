<template>
  <v-card>
    <v-card-title
      v-if="!this.modelTarget.id"
      class="headline grey lighten-2"
      primary-title
    >
      <v-icon class="mr-2">add_circle_outline</v-icon>
      Créer une nouvelle cible
    </v-card-title>
    <v-card-title v-else class="headline grey lighten-2" primary-title>
      <v-icon class="mr-2">edit</v-icon>
      Edition
    </v-card-title>
    <v-card-text class="px-5">
      <v-flex class="mt-2">
        <v-select
          :items="create"
          label="Type *"
          item-text="name"
          v-model="modelTarget.type"
          :disabled="!!this.modelTarget.id"
        ></v-select>
      </v-flex>
      <v-flex class="mt-1">
        <v-text-field
          v-model="modelTarget.name"
          label="Nom *"
        ></v-text-field>
      </v-flex>
      <v-flex class="mt-1">
        <v-text-field
          v-model="modelTarget.identifier"
          label="Identifier *"
        ></v-text-field>
      </v-flex>
      <v-flex v-if="modelTarget.kind === 'product'" class="mt-1">
        <v-text-field
          v-model.number="targetBasePrice"
          type="number"
          label="Prix *"
          append-icon="euro"
        ></v-text-field>
      </v-flex>
      <v-flex v-if="modelTarget.type === 'product'" class="mt-1">
        <BrandSelector @update="updateBrand($event)"></BrandSelector>
      </v-flex>
      <v-flex class="mt-1">
        <v-textarea
          v-model="modelTarget.description"
          no-resize
          label="Description"
        ></v-textarea>
      </v-flex>
    </v-card-text>
    <v-card-actions>
      <v-flex class="text-xs-center">
        <v-btn dense color="error" @click="$router.go(-1)">Annuler</v-btn>
        <v-btn
          v-if="!this.modelTarget.id"
          color="success"
          @click="createTarget()"
          >Créer</v-btn
        >
        <v-btn v-else color="success" @click="updateTarget()"
          >Mettre à jour</v-btn
        >
      </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>
import BrandSelector from '../custom/BrandSelector'
import req from '@/packs/request'

export default {
  components: {
    BrandSelector,
  },
  data() {
    return {
      create: [
        {
          name: 'Marque',
          value: 'brands'
        },
        {
          name: 'Catégorie',
          value: 'categories'
        },
        {
          name: 'Produit',
          value: 'products'
        },
      ],
      modelTarget: {
        type: this.$route.params.key,
        name: '',
        identifier: '',
        id: this.$route.params.id ? this.$route.params.id : '',
        description: '',
        base_price: 0
      }
    }
  },
  computed: {
    targetBasePrice: {
      get() {
        return this.modelTarget.base_price?.toCurrency()
      },
      set(value) {
        this.modelTarget.base_price = value ? value.toCents() : null
      }
    }
  },
  methods: {
    updateBrand(brand) {
      this.modelTarget.brand = brand
    },
    async fetchData() {
      if (this.modelTarget.id) {
        const res = await req.get(
          '/targets/' + this.modelTarget.type + '/' + this.modelTarget.id
        )
        this.modelTarget = res.data
        this.modelTarget.type = this.$route.params.key
      }
    },
    async updateTarget() {
      await req.put(
        '/targets/' + this.modelTarget.type + '/' + this.modelTarget.id,
        {
          parameters: this.modelTarget,
        }
      )

      this.$emit('change')
      this.$router.go(-1)
    },
    async createTarget() {
      await req.post('/targets/' + this.modelTarget.type, {
        parameters: this.modelTarget,
      })

      this.$emit('change')
      this.$router.go(-1)
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
