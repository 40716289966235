var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{class:{
      'mx-0': _vm.$vuetify.breakpoint.mdAndDown,
      'px-0': _vm.$vuetify.breakpoint.mdAndDown,
    }},[_c('clip-loader',{staticClass:"my-4",attrs:{"loading":!_vm.loaded,"color":"#78909c","width":"60px","height":"60px"}}),(_vm.loaded && _vm.offer)?_c('v-toolbar',{attrs:{"dark":"","fixed":"","color":"secondary"}},[(_vm.offer.consumable)?_c('v-toolbar-title',[_vm._v("Coupon ")]):_vm._e(),_c('v-spacer')],1):_vm._e(),(_vm.loaded)?_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":"","pt-5":""}},[_c('v-flex',{attrs:{"xs12":"","md6":"","lg4":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between px-3"},[(_vm.advertiser.company && _vm.advertiser.company.logo_url)?[_c('img',{staticClass:"cancel-flex logo-company-offer mt-3",attrs:{"src":_vm.advertiser.company.logo_url}})]:_vm._e(),(_vm.campaign && _vm.campaign.creator && _vm.campaign.creator.logo_url)?[_c('img',{staticClass:"cancel-flex logo-company-offer mt-3",attrs:{"src":_vm.campaign.creator.logo_url}})]:_vm._e()],2)]),_c('div',{staticClass:"mt-5 flex-div"},[(_vm.offer && _vm.offer.consumable)?_c('h2',{staticClass:"mb-3 text-xs-center w-100 font-weight-regular"},[_vm._v(" -30% sur les colorations l'Oréal ")]):_vm._e()]),(
            _vm.campaign.reward &&
              _vm.campaign.rewards[0].target &&
              _vm.campaign.rewards[0].target.logo_url
          )?_c('div',{staticClass:"text-xs-center"},[_c('img',{attrs:{"src":_vm.campaign.rewards[0].target.logo_url,"height":"100"}})]):(
            _vm.campaign.rewards[0].triggers[0] &&
              _vm.campaign.rewards[0].triggers[0].target &&
              _vm.campaign.rewards[0].triggers[0].target.logo_url
          )?_c('div',{staticClass:"text-xs-center"},[_c('img',{attrs:{"src":_vm.campaign.rewards[0].triggers[0].target.logo_url,"height":"100"}})]):_c('div',{staticClass:"text-xs-center"},[_c('img',{attrs:{"src":require("@/assets/images/icons/shopping-cart.svg"),"height":"100"}})]),(_vm.offer.voucher)?_c('v-card-text',{staticClass:"flex-div flex-column align-center mt-3"},[_c('img',{attrs:{"width":"90%","src":_vm.offer.voucher.images.barcode_svg,"alt":"barCode"}}),_c('h3',{staticClass:"my-0 py-0"},[_vm._v(_vm._s(_vm.offer.voucher.formatted_identifier))]),(_vm.offer && _vm.offer.expire_at)?_c('span',{staticClass:"grey--text mt-3"},[_vm._v(" "+_vm._s(_vm.$t('customer_app.dashboard.expire'))+" "+_vm._s(_vm.offer.expire_at.split(/[ ,]+/)[0])+" ")]):_vm._e()]):(!_vm.offer.voucher && _vm.customer)?_c('v-card-text',{staticClass:"flex-div flex-column align-center mt-3"},[(_vm.customer && _vm.customer.identifiers[0])?_c('img',{attrs:{"width":"90%","src":_vm.customer.identifiers[0].images.barcode_svg,"alt":"barCode"}}):_vm._e(),(_vm.customer && _vm.customer.identifiers[0])?_c('h3',{staticClass:"my-0 py-0"},[_vm._v(" "+_vm._s(_vm.customer.identifiers[0].formatted_identifier)+" ")]):_vm._e(),(_vm.offer && _vm.offer.expire_at)?_c('span',{staticClass:"grey--text mt-3"},[_vm._v(" "+_vm._s(_vm.$t('customer_app.dashboard.expire'))+" "+_vm._s(_vm.offer.expire_at.split(/[ ,]+/)[0])+" ")]):_vm._e()]):_vm._e(),_c('v-card-text',[_c('div',{staticClass:"grey lighten-5 px-3 py-3 "},[_c('div',{staticClass:"mb-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("store")]),_c('h3',{staticClass:"d-inline"},[_vm._v(" Point"+_vm._s(_vm.pos.length > 1 ? 's' : '')+" de vente participant"+_vm._s(_vm.pos.length > 1 ? 's' : '')+" : ")])],1),_vm._l((_vm.pos),function(onePos,index){return _c('span',{key:onePos.id},[_vm._v(_vm._s(_vm._f("capitalize")(onePos.name))),(index < _vm.pos.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})],2)])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }