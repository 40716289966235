<template>
  <div class="grid-channel">
    <v-card class="block-channel p-re" @click="addChannel('pkpass_published')" :class="{'select-channel': publishing.includes('pkpass_published')}">
      <div class="triangle" v-if="publishing.includes('pkpass_published')">
        <v-icon class="icon-check">check</v-icon>
      </div>
      <div class="title-channel">
        PKpass
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="font-size-icon" v-on="on">
              info
            </v-icon>
          </template>
          <span>
            A PKPASS file is a file used by Passbook for saving coupons and loyalty cards.
          </span>
        </v-tooltip>
      </div>
    </v-card>
    <!-- <v-card class="block-channel p-re" @click="addChannel('pwa_published')" :class="{'select-channel': publishing.includes('pwa_published')}">
      <div class="triangle" v-if="publishing.includes('pwa_published')">
        <v-icon class="icon-check">check</v-icon>
      </div>
      <div class="title-channel">
        PWA
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="font-size-icon" v-on="on">
              info
            </v-icon>
          </template>
          <span>
              A progressive web app is a website that looks and behaves as if
              it is a mobile app. PWAs are built to take advantage of native
              mobile device features.
            </span>
        </v-tooltip>
      </div>
    </v-card> -->
    <!-- <v-card class="block-channel disabled-channel p-re" @click="addChannel('gpay_published')" :class="{'select-channel': publishing.includes('gpay_published')}">
      <div class="triangle" v-if="publishing.includes('gpay_published')">
        <v-icon class="icon-check">check</v-icon>
      </div>
      <div class="title-channel">
        G pay
      </div>
    </v-card> -->
    <v-card class="block-channel p-re" @click="addChannel('email_published')" :class="{'select-channel': publishing.includes('email_published')}">
      <div class="triangle" v-if="publishing.includes('email_published')">
        <v-icon class="icon-check">check</v-icon>
      </div>
      <div class="title-channel">
        Email
      </div>
    </v-card>
    <v-card class="block-channel p-re" @click="addChannel('pdf_published')" :class="{'select-channel': publishing.includes('pdf_published'), 'disabled-channel': !hasRewardVoucher}">
      <div class="triangle" v-if="publishing.includes('pdf_published')">
        <v-icon class="icon-check">check</v-icon>
      </div>
      <div class="title-channel">
        PDF
      </div>
    </v-card>
    <!-- <v-card class="block-channel disabled-channel p-re" @click="addChannel('sms_published')" :class="{'select-channel': publishing.includes('sms_published')}">
      <div class="triangle" v-if="publishing.includes('sms_published')">
        <v-icon class="icon-check">check</v-icon>
      </div>
      <div class="title-channel">
        SMS
      </div>
    </v-card>
    <v-card class="block-channel disabled-channel p-re">
      <div class="title-channel">
        Print on receipt
      </div>
    </v-card> -->
  </div>
</template>

<script>
import EventBus from '@/packs/event-bus'
import { mapState, mapActions, mapGetters } from 'vuex'
import req from '@/packs/request'

export default {
  props: {
    publishing: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      campaign: state => state.campaignBuilder.campaign
    }),
    ...mapGetters('campaignBuilder', ['getFirstReward', 'hasRewardVoucher'])
  },
  methods: {
    ...mapActions('campaignBuilder', ['addPublishing', 'deletePublishing', 'addTemplate', 'clearTemplates']),
    async freshTemplate(type) {
      const res = await req.get(`/email/templates?by_kind=${type}&by_default=true`)
      this.addTemplate(res.data[0])
    },
    addChannel(channel) {
      if (this.campaign.rewards[0]?.length === 0) {
        EventBus.$emit('showSnackbar', {
          text: 'Ajoutez une offre pour pouvoir selectionner des canaux de diffusion',
          color: 'warning'
        })
        return
      }

      if (!this.publishing.includes(channel)) {
        this.addPublishing(channel)
        if (channel === 'email_published') this.freshTemplate(this.templateKind())
      } else {
        this.deletePublishing(this.publishing.indexOf(channel))
        if (channel === 'email_published') this.clearTemplates()
      }
    },
    templateKind() {
      const rewardType = this.getFirstReward.type

      if (this.campaign.kind === 'event') {
        return 'anniversary'
      } else if (this.campaign.kind === 'welcome') {
        if (rewardType === 'reward') return 'reward'
        else if (rewardType === 'pool') return 'loyalty_units'
      } else return 'reward'
    }
  }
}
</script>
