<template>
  <div class="mt-5">
    <div class="disp-flex align-center">
      <label class="font-weight-medium subheading">Vos horraire(s) :</label>
      <v-spacer></v-spacer>
      <v-btn @click="addNewTimeSlot()" class="ma-0" small outline color="#3f51b5">Ajouter une piste horraire</v-btn>
    </div>
    <v-card v-for="(timeSlot, indexSlot) in timeSlots" :key="indexSlot" class="px-2 py-3 mt-3">
      <div class="disp-flex align-center">
        <v-checkbox @change="setFullWeekTimeSlot({index: indexSlot, isFullWeek: $event, resetDay: true})" v-model="timeSlot.fullWeek" class="mt-0 pt-0" :label="$t('createCampaign.campaignSettings.happyHour.entireWeek')" hide-details></v-checkbox>
        <v-spacer></v-spacer>
        <v-radio-group @change="setFullDay({index: indexSlot, isFullDay: $event})" v-model="timeSlot.fullDay" class="mt-0 pt-0 disp-flex justify-end" row hide-details>
          <v-radio :label="$t('createCampaign.campaignSettings.happyHour.entireDay')" :value="true"></v-radio>
          <v-radio :label="$t('createCampaign.campaignSettings.happyHour.timeRange')" :value="false"></v-radio>
        </v-radio-group>
        <v-divider vertical class="mx-1"></v-divider>
        <v-btn @click="deleteTimeSlot({timeSlot})" class="ma-0" flat icon :disabled="indexSlot === 0">
          <v-icon style="color: red">delete_forever</v-icon>
        </v-btn>
      </div>

      <v-divider class="my-3"></v-divider>

      <div class="flex-div align-center">
        <v-flex v-for="(day, index) in days" :key="index" @click="setDayTimeSlot({index: indexSlot, day: day.value})" :class="{'daySelected': timeSlot.days.includes(day.value)}" class="one-day-card flex-div justify-center align-center blue-grey lighten-5">
          <span class="py-2 font-weight-light">{{ day.name }}</span>
        </v-flex>
      </div>
      <div v-if="!timeSlot.fullDay" style="gap: 30px" class="disp-flex justify-center mt-2">
        <TimePicker @timeChanged="setStartTimeSlot({index: indexSlot, hour: $event})" :hour="timeSlot.start_hour" :minutes="timeSlot.start_minutes" :label="$t('common.from')" />
        <TimePicker @timeChanged="setEndTimeSlot({index: indexSlot, hour: $event})" :hour="timeSlot.end_hour" :minutes="timeSlot.end_minutes" :startHour="timeSlot.start_hour" :label="$t('common.to')" />
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TimePicker from '@/components/Base/HappyHour/TimePicker'
import TimeSlot from '@/models/TimeSlot'

export default {
  components: {
    TimePicker
  },
  data() {
    return {
      days: [
        {
          name: this.$t('createCampaign.campaignSettings.happyHour.monday'),
          value: 1
        },
        {
          name: this.$t('createCampaign.campaignSettings.happyHour.tuesday'),
          value: 2
        },
        {
          name: this.$t('createCampaign.campaignSettings.happyHour.wednesday'),
          value: 3
        },
        {
          name: this.$t('createCampaign.campaignSettings.happyHour.thursday'),
          value: 4
        },
        {
          name: this.$t('createCampaign.campaignSettings.happyHour.friday'),
          value: 5
        },
        {
          name: this.$t('createCampaign.campaignSettings.happyHour.saturday'),
          value: 6
        },
        {
          name: this.$t('createCampaign.campaignSettings.happyHour.sunday'),
          value: 7
        }
      ]
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'addTimeSlot',
      'deleteTimeSlot',
      'setFullWeekTimeSlot',
      'setDayTimeSlot',
      'setStartTimeSlot',
      'setEndTimeSlot',
      'setFullDay'
    ]),
    addNewTimeSlot() {
      this.addTimeSlot(new TimeSlot())
    }
  },
  computed: {
    ...mapState({
      timeSlots: state => state.campaignBuilder.campaign.time_slots
    })
  },
  created() {
    if (this.timeSlots.length === 0) this.addNewTimeSlot()
  }
}
</script>
