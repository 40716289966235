<template>
  <v-container fluid grid-list-xl>
    <v-card class="pa-3 mb-5">
      <div class="disp-flex justify-space-between" style="flex-wrap: wrap">
        <div class="align-self-center">
          <label class="title">{{ $t('campaignCreate.publishingTitle') }} :</label><br>
          <label class="subheading">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="font-size-icon" v-on="on">
                  info
                </v-icon>
              </template>
              <span>
                {{ $t('campaignCreate.publishingInfo') }}
              </span>
            </v-tooltip>
            {{ $t('campaignCreate.publishingSubTitle') }}
          </label>
        </div>
      </div>
      <v-divider class="mt-3 mb-5"></v-divider>
      <selectOfferPublishing :publishing="campaign.publishing" />
    </v-card>

    <div class="mt-3" v-if="campaign && campaign.templates && campaign.templates.length > 0">
      <label class="subheading">E-mail template :</label>
      <div class="w-100 mt-3">
        <v-expansion-panel>
          <v-expansion-panel-content v-for="(template, index) in campaign.templates" :key="index" class="template">
            <template v-slot:header>
              <div class="title font-weight-light">{{ template.kind | capitalize }} template</div>
            </template>
            <v-card>
              <v-card-text>
                <EventTemplating :template="template"></EventTemplating>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import selectOfferPublishing from '@/components/selectOfferPublishing'
import EventTemplating from '@/components/CampaignForm/Templating/EventTemplating'

export default {
  components: {
    selectOfferPublishing,
    EventTemplating
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      mode: state => state.campaignBuilder.mode
    }),
    isCustomCampaign() {
      return (this.mode === 'subfidelity' && (this.campaign.kind === 'limited' || this.campaign.kind === 'permanent'))
    }
  }
}
</script>
