<template>
  <v-card class="transactions">
    <v-toolbar flat color="white" class="mb-3">
      <v-toolbar-title>{{ $t('dashboard.lastTransactions') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark to="core-program/transactions">
        {{ $t('dashboard.seeTransactions') }}
      </v-btn>
    </v-toolbar>
    <TransactionsTable
      :api-url="transactionsUrl"
      :d-pagination="false"
      :d-filters="false"
      :read-only="true"
      :per-page="5"
    ></TransactionsTable>
  </v-card>
</template>

<script>
import TransactionsTable from '@/components/TransactionsTable'

export default {
  components: {
    TransactionsTable
  },
  data() {
    return {
      transactionsUrl: '/transactions'
    }
  }
}
</script>
