<template>
  <v-card class="config elevation-0 px-4">
    <div class="font-weight-light title disp-flex align-center mb-4">
      <div v-if="action_type !== 'trigger' && type !== 'core'" class="font-weight-bold disp-flex align-center justify-center config-number mr-3">
        1
      </div>
      <span v-if="action_type === 'trigger' && mode !== 'fidelity'" v-html="$t('createCampaign.triggerReward.typeFid')" />
      <span v-else-if="action_type === 'trigger'" v-html="$t('createCampaign.triggerReward.typeTrigger')" />
      <span v-else-if="type === 'core'" v-html="$t('createCampaign.triggerReward.typeCore')" />
      <span v-else v-html="$t('createCampaign.triggerReward.type')" />
    </div>
    <div v-if="mode !== 'fidelity' || action_type === 'reward'" class="w-100">
      <v-btn-toggle v-model="scope_" mandatory class="btn-group w-100 elevation-0">
        <v-btn flat value="cart" :disabled="viewMode" class="grow" active-class="btn-selected">
          {{ $t('common.cart') }}
        </v-btn>
        <v-btn flat value="product" :disabled="viewMode || action_type === 'pool'" class="grow" active-class="btn-selected">
          {{ $t('common.product') }}
        </v-btn>
        <v-btn flat value="category" :disabled="viewMode || action_type === 'pool'" class="grow" active-class="btn-selected">
          {{ $t('common.category') }}
        </v-btn>
        <v-btn flat value="brand" :disabled="viewMode || action_type === 'pool'" class="grow" active-class="btn-selected">
          {{ $t('common.brand') }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <div v-if="rewardScopeIsItem" class="mt-3">
      <TargetSearch
        :targetId="target_id_"
        :scope="scope_"
        @update="target_id_ = $event"
        :disabled="viewMode"
        class="w-100"
      />
      <v-text-field
        v-model.number="minimum_value_"
        v-if="action_type === 'trigger'"
        type="number"
        :min="1"
        :disabled="scope_ === 'product' || viewMode"
        append-icon="euro_symbol"
        :label="$t('createCampaign.triggerReward.minimumAmountWait')"
      />
      <v-text-field
        v-model.number="quantity_"
        v-if="action_type === 'reward' || action_type === 'pool'"
        type="number"
        :min="1"
        placeholder="10"
        :disabled="viewMode"
        :label="$t('createCampaign.triggerReward.quantity')"
        hide-details
      ></v-text-field>
      <v-text-field
        v-model.number="threshold_"
        v-else
        type="number"
        :min="1"
        placeholder="10"
        :disabled="viewMode"
        :label="$t('createCampaign.triggerReward.quantity')"
      ></v-text-field>
    </div>
    <div v-else-if="action_type === 'trigger'" class="mt-3 disp-flex">
      <div class="font-weight-medium align-self-center">
        {{ isAwardTrigger ? $t('createCampaign.triggerAward.minimumAmount') : $t('createCampaign.triggerReward.minimumAmount') }}
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        v-model.number="threshold_"
        type="number"
        :min="0"
        :disabled="viewMode"
        class="text-number-mid"
        :label="$t('createCampaign.triggerReward.minimumAmountWait')"
      />
      <label v-if="this.checkThreshold" class="ml-2 align-self-center">
        {{ getCurrencyName }}
      </label>
      <v-icon v-else class="ml-2 align-self-center">euro_symbol</v-icon>
    </div>
  </v-card>
</template>

<script>
import TargetSearch from '@/components/TargetSearch'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    TargetSearch
  },
  props: {
    scope: {
      type: String,
      required: true
    },
    quantity: {
      type: Number
    },
    target_id: {
      type: String
    },
    minimum_value: {
      type: Number
    },
    threshold: {
      type: Number
    },
    action_type: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    kind: {
      type: String
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setRewardScope',
      'setRewardQuantity',
      'setRewardTargetId',
      'setTriggerScope',
      'setTriggerTargetId',
      'setTriggerMinimumValue',
      'setTriggerThreshold',
      'setRewardGift'
    ])
  },
  computed: {
    ...mapGetters('campaignBuilder', ['isAwardTrigger', 'viewMode']),
    ...mapState({
      mode: state => state.campaignBuilder.mode,
      currencyName: state => state.campaignBuilder.campaign.loyalty_currency.name,
      currencyOther: state => state.campaignBuilder.campaign.loyalty_currency.other
    }),
    scope_: {
      get() {
        return this.scope
      },
      set(value) {
        if (this.isTrigger) this.setTriggerScope(value)
        else this.setRewardScope(value)
      }
    },
    quantity_: {
      get() {
        return this.quantity
      },
      set(value) {
        this.setRewardQuantity(value)
      }
    },
    target_id_: {
      get() {
        return this.target_id
      },
      set(value) {
        if (this.isTrigger) this.setTriggerTargetId(value)
        else this.setRewardTargetId(value)
      }
    },
    threshold_: {
      get() {
        const threshold = this.scope === 'cart' && this.type !== 'customer' ? this.threshold.toCurrency() : this.threshold
        return threshold ? threshold : 0
      },
      set(value) {
        if (this.scope === 'cart') value = value.toCents()
        this.setTriggerThreshold(value ? value : 0)
      }
    },
    minimum_value_: {
      get() {
        return this.minimum_value ? this.minimum_value.toCurrency() : 0
      },
      set(value) {
        this.setTriggerMinimumValue(value ? value.toCents() : 0)
      }
    },
    isTrigger() {
      return this.action_type === 'trigger'
    },
    rewardScopeIsItem() {
      return this.scope_ === 'product' || this.scope_ === 'category' || this.scope_ === 'brand'
    },
    checkThreshold() {
      return this.type === 'customer' && this.kind && this.kind === 'pool'
    },
    getCurrencyName() {
      return this.currencyName !== 'Other' ? this.currencyName : this.currencyOther
    }
  }
}
</script>
