<template>
  <v-flex class="h-100">

    <v-card class="px-3 py-4 mb-4">
      <label class="font-weight-light subheading align-self-center">
        Configuration des <b class="font-weight-medium">offres</b> de votre campagne
      </label>
    </v-card>

    <template v-for="(line, index) in lines">
      <CampaignBuilderLine
        :campaign-kind="campaign.kind"
        :line="line"
        :line-index="index"
        :key="index"
        class="mt-4"
      ></CampaignBuilderLine>
    </template>

    <v-btn
      v-if="mode === 'fidelity'"
      :disabled="!canAddline"
      outline
      color="primary"
      class="w-100 mt-4 mx-0"
      @click="addLine"
    >
      <v-icon class="mr-3">add_circle_outline</v-icon>
      Ajouter une nouvelle configuration
    </v-btn>

  </v-flex>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CampaignBuilderLine from '@/components/CampaignBuilderLine'

export default {
  components: {
    CampaignBuilderLine
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      lines: state => state.campaignBuilder.lines,
      campaignName: state => state.campaignBuilder.campaign.name,
      mode: state => state.campaignBuilder.mode
    }),
    canAddline() {
      if (this.campaign.kind === 'welcome') return this.lines?.length < 2
      else if (this.campaign.kind === 'event') return this.line?.length < 1
      else return true
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['initActions', 'addLine', 'setName'])
  },
  created() {
    this.initActions()
  }
}
</script>
