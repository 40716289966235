<template>
  <div class="text-xs-center">
    <v-dialog :value="true" @input="$router.push('/childs')" max-width="700">
      <v-card class="child-modal">
        <v-card-title class="headline justify-center py-3">{{
          selectedChild.name
        }}</v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 py-3>
              <h2 class="title mt-1"><v-icon>info</v-icon> Informations</h2>
            </v-flex>
            <v-flex xs4>
              <h3>Siren</h3>
              <span>{{ selectedChild.siren || '-' }}</span>
            </v-flex>
            <v-flex xs4>
              <h3>Siret</h3>
              <span>{{ selectedChild.siret || '-' }}</span>
            </v-flex>
            <v-flex xs4>
              <h3>Phone number</h3>
              <span>{{ selectedChild.phone || '-' }}</span>
            </v-flex>
            <v-flex pt-3 xs6 v-if="selectedChild.affiliate_contract_url">
              <h3>Associated contract</h3>
              <v-btn
                small
                :href="selectedChild.affiliate_contract_url"
                target="_blank"
                class="ml-0"
                flat
                color="primary"
              >
                <v-icon class="mr-2">picture_as_pdf</v-icon>contract
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="selectedChild.payment_information">
            <v-flex xs12 py-3>
              <h2 class="title mt-1">
                <v-icon>payment</v-icon> Payment Informations
              </h2>
            </v-flex>
            <v-flex xs4>
              <h3>IBAN</h3>
              <span>{{ selectedChild.payment_information.iban || '-' }}</span>
            </v-flex>
            <v-flex xs4>
              <h3>BIC</h3>
              <span>{{ selectedChild.payment_information.bic || '-' }}</span>
            </v-flex>
            <v-flex xs4>
              <h3>Bank name</h3>
              <span>{{
                selectedChild.payment_information.bank_name || '-'
              }}</span>
            </v-flex>
            <template v-if="selectedChild.payment_information.sdd_mandate">
              <v-flex xs4 my-2>
                <h3>UMR</h3>
                <span>{{
                  selectedChild.payment_information.sdd_mandate.umr || '-'
                }}</span>
              </v-flex>
              <v-flex xs4 my-2>
                <h3>Signature Date</h3>
                <span>{{
                  selectedChild.payment_information.sdd_mandate
                    .signature_date || '-'
                }}</span>
              </v-flex>
              <v-flex
                xs4
                my-2
                v-if="
                  selectedChild.payment_information.sdd_mandate
                    .direct_debit_authorization_url
                "
              >
                <h3>SEPA Direct debit mandate</h3>
                <v-btn
                  small
                  :href="
                    selectedChild.payment_information.sdd_mandate
                      .direct_debit_authorization_url
                  "
                  target="_blank"
                  flat
                  color="primary"
                  class="ml-0"
                >
                  <v-icon class="mr-2">picture_as_pdf</v-icon
                  >{{
                    fileNameFromUrl(
                      selectedChild.payment_information.sdd_mandate
                        .direct_debit_authorization_url
                    )
                  }}
                </v-btn>
              </v-flex>
            </template>
          </v-layout>
          <v-flex my-3>
            <h2 class="title my-4">
              <v-icon class="mr-2">storefront</v-icon>Points of sale
            </h2>
            <PointOfSaleList
              class="mt-2"
              :pointOfSales="pointOfSales"
              :pointOfSalesCount="pointOfSales.length"
              :loading="!loaded"
            />
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import req from '@/packs/request.js'
import PointOfSaleList from '@/components/PointsOfSale/PointOfSaleList'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    childs: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PointOfSaleList,
  },
  data() {
    return {
      pointOfSales: [],
      loaded: false,
      ibanMask: 'AA## #### #### #### #### #### ###',
    }
  },
  created() {
    this.fetchPointOfSales()
  },
  computed: {
    selectedChild() {
      return this.childs.find(e => e.id === this.id)
    },
  },
  methods: {
    async fetchPointOfSales() {
      const res = await req.get(
        `/company/companies/${this.selectedChild.id}/point_of_sales`
      )

      this.pointOfSales = res.data
      this.loaded = true
    },
    fileNameFromUrl(url) {
      return url.split('/').pop()
    },
  },
}
</script>
