<template>
  <v-card class="w-100 pa-3">
    <v-card-title class="pt-0 pl-0 flex relative">
      <span class="subheading">{{ lineTitle }}</span>
      <!-- <span class="subheading mr-2">{{ $t('campaignCreate.composition') }} :</span>
      <div class="pt-0 pb-0 align-baseline disp-flex">
        <p class="font-weight-light mb-0">{{ numberOfTriggers }} {{$t('campaignCreate.compositionTrigger')}}</p>
        <div class="mx-2" style="color: #8e8e8e">&#9679;</div>
        <p class="font-weight-light mb-0">{{ numberOfAwards }} {{$t('campaignCreate.compositionAward')}}</p>
        <div class="mx-2" style="color: #8e8e8e">&#9679;</div>
        <p class="font-weight-light mb-0">{{ numberOfRewards }} {{$t('campaignCreate.compositionReward')}}</p>
      </div> -->
      <!-- SHOULD PUT CONSUMMABLE MESSAGE IN REAL TIME HERE IN THE FUTURE -->

      <v-spacer></v-spacer>

      <v-btn class="mx-0" flat icon :disabled="(editMode && status !== 'draft') || lineIndex < 1" @click="deleteLine(lineIndex)">
        <v-icon style="color: red">delete_forever</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider class="mb-3"></v-divider>
    <div class="py-4 h-100 container-actions">
      <div class="wrapper-actions px-4">
        <template v-for="(action, index) in line.actions">
          <CampaignBuilderLineAction
            :action="action"
            :lineIndex="lineIndex"
            :actionIndex="index"
            :key="`card-${index}`"
            :selected="lineIndex === li && index === ai"
            :campaign_type="campaignKind"
          />
          <div :key="`arrow-${index}`" class="disp-flex align-self-center justify-self-center">
            <v-icon v-if="index < 3 && action.card_type !== 'reward' && action.card_type !== 'pool'">
              keyboard_arrow_right
            </v-icon>
          </div>
        </template>
        <CampaignBuilderLineCreateActions
          v-if="line.canCreateOtherAction"
          :line="line"
          @createAction="createConsumable({ index: lineIndex, action: $event, actionKind: triggerKind })"
        />
      </div>
      <v-slide-y-transition mode="out-in" :hide-on-leave="true">
        <div v-if="lineIndex === li && ai >= 0" class="px-4" style="display: block">
          <CampaignBuilderLineActionEdit :action="line.actions[ai]" :campaign_type="campaignKind" />
        </div>
      </v-slide-y-transition>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import CampaignBuilderLineAction from '@/components/CampaignBuilderLineAction'
import CampaignBuilderLineCreateActions from '@/components/CampaignBuilderLineCreateActions'
import CampaignBuilderLineActionEdit from '@/components/CampaignBuilderLineActionEdit'

export default {
  components: {
    CampaignBuilderLineAction,
    CampaignBuilderLineCreateActions,
    CampaignBuilderLineActionEdit
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    lineIndex: {
      type: Number,
      required: true
    },
    campaignKind: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      li: state => state.campaignBuilder.li,
      ai: state => state.campaignBuilder.ai,
      kind: state => state.campaignBuilder.campaign.kind,
      editMode: state => state.campaignBuilder.editMode,
      status: state => state.campaignBuilder.campaign.status
    }),
    triggerKind() {
      if (this.line.actions.length === 1 && this.kind === 'welcome') return 'welcome'
      return undefined
    },
    numberOfTriggers() {
      return this.line.actions.filter(action => action.card_type === 'trigger').length
    },
    numberOfAwards() {
      return this.line.actions.filter(action => action.card_type === 'award').length
    },
    numberOfRewards() {
      return this.line.actions.filter(action => action.card_type === 'reward').length
    },
    checkLastAction() {
      return this.line?.actions[this.line.actions.length - 1]
    },
    isCoreConsumableLine() {
      return this.line?.actions[0]?.type === 'core'
    },
    lineTitle() {
      return this.isCoreConsumableLine
        ? this.$t('campaignCreate.coreConsumableConfiguration')
        : this.$t('campaignCreate.offerConfiguration')
    }
  },
  methods: {
    ...mapActions('campaignBuilder', ['createConsumable', 'setCantCreateOtherAction', 'deleteLine'])
  },
  created() {
    if (this.checkLastAction?.card_type === 'reward') this.setCantCreateOtherAction(this.lineIndex)
  }
}
</script>
