Number.prototype.toCurrency = function() {
  return this / 100
}

Number.prototype.toCents = function() {
  return parseInt(this * 100)
}

Number.prototype.truncate = function() {
  return parseInt(this)
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}
