<template>
  <v-card class="w-100 elevation-0 py-2" color="#f1f1f1ad">
    <v-layout row wrap class="px-3 pt-3">
      <v-flex xs12 class="mr-2 pb-3" v-if="!create.can">
        <v-select
          :items="segments"
          v-model="segmentActive"
          item-value="id"
          item-text="name"
          :label="$t('customers.segment')"
          class="mt-0 pt-0"
          hide-details
          menu-props="offsetY"
          return-object
        >
          <template v-slot:item="{ item }">
            <v-chip
              color="orange"
              v-if="item.custom && item.tags.includes('custom')"
              small
              text-color="white"
              class="mr-2"
              >Custom</v-chip
            >
            <v-chip color="success" v-else-if="!item.custom && item.tags.includes('rfm')" small text-color="white" class="mr-2"
              >RFM</v-chip
            >
            <v-chip color="success" v-else-if="!item.custom && item.tags.includes('status')" small text-color="white" class="mr-2"
              >Status</v-chip
            >
            <span>{{ item.name }}</span>
          </template>
          <template v-slot:no-data>
            <div class="pa-3">{{ $t('customers.segmentError') }}</div>
          </template>
        </v-select>
      </v-flex>
      <v-flex v-else xs12 class="mr-2">
        <CustomersGeneralFilterClassicSegmentsCreate
          :customer_base="create.base.id"
          :filters="create.filters"
        ></CustomersGeneralFilterClassicSegmentsCreate>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import eventBus from '@/packs/event-bus'
import CustomersGeneralFilterClassicSegmentsCreate from './CustomersGeneralFilterClassicSegmentsCreate'

export default {
  components: {
    CustomersGeneralFilterClassicSegmentsCreate,
  },
  props: {
    segments: {
      type: Array,
      required: true,
    },
    create: {
      type: Object,
      required: true,
      default: () => {
        return { can: false }
      },
    },
  },
  data: function() {
    return {
      segmentActive: {},
    }
  },
  methods: {
    sendSegment() {
      this.$emit('UpdateSegment', this.segmentActive)
    },
  },
  created() {
    eventBus.$on('manualSelectRFM', segmentRFMSelected => {
      this.segmentActive = segmentRFMSelected
    })
    eventBus.$on('cleanCustomSegment', () => {
      this.segmentActive = {}
    })
    eventBus.$on('manualSelectSegment', segmentSelected => {
      this.segmentActive = segmentSelected
    })
  },
  watch: {
    segments: {
      deep: true,
      handler() {
        if (!this.segments.find(e => e.id === this.segmentActive.id))
          this.segmentActive = {}
      },
    },
    segmentActive: {
      deep: true,
      handler() {
        this.sendSegment()
        eventBus.$emit(
          'selectSegment',
          this.segmentActive.custom ? this.segmentActive : {}
        )
      },
    },
  },
}
</script>
