import AxiosWrapper from '@/services/AxiosWrapper'

const END_POINT = '/core_program'

const get = () => AxiosWrapper.get(`${END_POINT}`)
const getPoolProgram = () => AxiosWrapper.get(`${END_POINT}/pool_program`)
const getDraft = (params: any) => AxiosWrapper.post(`${END_POINT}/draft`, params)
const updateConsumable = (id: string, consumableId: string, params: any) => AxiosWrapper.put(`${END_POINT}/consumables/${consumableId}`, params)
const updateTriggerConsumable = (id: string, consumableId: string, triggerId: string, params: any) => AxiosWrapper.put(`${END_POINT}/consumables/${consumableId}/triggers/${triggerId}`, params)
const createConsumable = (id: string, params: any) => AxiosWrapper.post(`${END_POINT}/consumables`, params)
const createTrigger = (id: string, rewardId: string, params: any) => AxiosWrapper.post(`${END_POINT}/consumables/${rewardId}/triggers`, params)
const destroy = (id: string) => AxiosWrapper.delete(`${END_POINT}`)
const persist = (id: string) => AxiosWrapper.put(`${END_POINT}/persist`)
const update = (id: string, params: any) => AxiosWrapper.put(`${END_POINT}`, params)
const deleteConsumable = (id: string, consumableId: string) => AxiosWrapper.delete(`${END_POINT}/consumables/${consumableId}`)
const deleteTrigger = (id: string, consumableId: string, triggerId: string) => AxiosWrapper.delete(`${END_POINT}/consumables/${consumableId}/triggers/${triggerId}`)

export default {
	get,
	getPoolProgram,
	getDraft,
	updateConsumable,
	updateTriggerConsumable,
	createConsumable,
	createTrigger,
	destroy,
	persist,
	update,
	deleteConsumable,
	deleteTrigger
}
