<template>
  <v-container class="transactions" fluid grid-list-lg>
    <v-layout row wrap>
      <v-flex>
        <v-btn class="mx-0" outline color="primary" @click="$router.go(-1)">
          <v-icon small>arrow_back_ios</v-icon> Back to campaigns
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout column wrap>
      <v-flex>
        <h2 class="headline mb-3">Résumé de la campagne</h2>
        <!-- <span>{{ $route.params.id }}</span> -->
      </v-flex>
      <clip-loader
        class="my-4"
        :loading="loading"
        color="#78909c"
        width="60px"
        height="60px"
      ></clip-loader>
      <v-flex xs12 mb-4 v-if="!loading">
        <v-card tile class="card-campaign">
          <!-- Campaign Informations -->
          <v-layout row wrap>
            <v-flex py-3 md2 sm4 xs12 d-flex justify-center align-center>
              <img
                src="@/assets/images/standard/default-image.png"
                height="auto"
                style="max-width: 140px"
              />
            </v-flex>
            <v-flex md10>
              <v-card-title class="pl-0">
                <div>
                  <h3 class="headline mb-2">{{ campaign.name }}</h3>
                  <CampaignRewardMessage :reward="campaign.rewards[0]" />
                </div>
              </v-card-title>
              <v-card-text class="campaign-infos my-3">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title class="font-weight-medium"
                      >Date de départ</v-list-tile-title
                    >
                    <v-list-tile-sub-title>{{
                      campaign.start_at
                    }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-content v-if="campaign.kind === 'limited'">
                    <v-list-tile-title class="font-weight-medium"
                      >Date de fin</v-list-tile-title
                    >
                    <v-list-tile-sub-title>{{
                      campaign.end_at
                    }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-content v-if="campaign.mine">
                    <v-list-tile-title class="font-weight-medium"
                      >Commerçants</v-list-tile-title
                    >
                    <v-list-tile-sub-title>
                      <template
                        v-for="(retailers, index) in campaign.participants"
                      >
                        <span :key="index"
                          >{{ retailers.name
                          }}<span
                            v-if="index < campaign.participants.length - 1"
                            >,
                          </span>
                        </span>
                      </template>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-content v-else-if="!campaign.mine">
                    <v-list-tile-title class="font-weight-medium"
                      >Creator</v-list-tile-title
                    >
                    <v-list-tile-sub-title>
                      <span>{{ campaign.company.name }}</span>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout column>
      <v-flex xs12>
        <h2 class="headline mb-3">Liste des transactions</h2>
      </v-flex>
      <TransactionsTable :api-url="transactionsApiUrl" :see-customer="campaign.type != 'conquest'" />
    </v-layout>
  </v-container>
</template>

<script>
import TransactionsTable from '@/components/TransactionsTable'
import CampaignRewardMessage from '@/components/CampaignRewardMessage'
import req from '@/packs/request.js'

export default {
  components: {
    CampaignRewardMessage,
    TransactionsTable
  },
  data() {
    return {
      transactionsApiUrl: `/campaigns/${this.$route.params.id}/transactions`,
      loading: true,
      campaign: {}
    }
  },
  methods: {
    async fetchCampaign() {
      const path = this.$route.path.includes('core-program') ? 'core_program/campaigns' : 'campaigns'
      const res = await req.get(`/${path}/${this.$route.params.id}`)

      this.campaign = res.data
      this.loading = false
    }
  },
  created() {
    this.fetchCampaign()
  }
}
</script>
