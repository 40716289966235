<template>
  <v-list-tile-content class="pr-3 pb-3">
    <v-list-tile-title>
      <h3 class="font-weight-regular blue-grey--text text--lighten-2">
        {{ $t('customers.data.meansIdentification') }}
      </h3>
    </v-list-tile-title>
    <v-list-tile-sub-title class="flex-div flex-column" style="width: fit-content">
      <div class="py-1" v-for="(id, index) in identifiers" :key="index">
        <CustomersDataSheetIdentifier
          :index="index"
          :can-delete="nbIdentifiers"
          :identifier="id"
          :customer_id="customer_id"
          @update="$emit('update')"
        />
        <v-divider class="mt-2"></v-divider>
      </div>
    </v-list-tile-sub-title>
    <v-list-tile-sub-title>
      <div v-if="create" class="disp-flex align-center pb-1 mt-2">
        <v-text-field type="number" v-model="identifier" :placeholder="$t('customers.data.identifier')" class="pt-0 mt-0 input-id" style="max-width: 150px" hide-details />
        <KindIdentifierSelector v-model="kind" />
        <v-icon style="color: #2e7d32" class="cursor-pointer" @click="createIdentifier()">check</v-icon>
        <v-icon style="color: red" class="cursor-pointer" @click="create = false">clear</v-icon>
      </div>
      <div class="primary--text caption cursor-pointer mt-2" style="width: fit-content" @click="create = true" v-if="!create">
        <v-icon small class="primary--text">add</v-icon> {{ $t('customers.data.createIdentifier') }}
      </div>
    </v-list-tile-sub-title>
  </v-list-tile-content>
</template>

<script>
import CustomersDataSheetIdentifier from '@/components/CustomersDataSheetIdentifier'
import KindIdentifierSelector from '@/components/KindIdentifierSelector'
import req from '@/packs/request'

export default {
  components: {
    CustomersDataSheetIdentifier,
    KindIdentifierSelector
  },
  props: {
    identifiers: {
      type: Array,
      required: true
    },
    customer_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      create: false,
      identifier: '',
      kind: 'extern'
    }
  },
  computed: {
    nbIdentifiers() {
      return this.identifiers.length > 1
    }
  },
  methods: {
    async createIdentifier() {
      await req.post(`/customers/${this.customer_id}/identifiers`, { identifier: { identifier: this.identifier, kind: this.kind } })
      this.identifier = ''
      this.create = false
      this.$emit('update')
    }
  }
}
</script>
