<template>
  <div class="disp-flex justify-center">
    <div class="device mt-5">
      <div class="device-notch">
        <div class="device-speaker"></div>
        <div class="device-camera"></div>
      </div>
      <div class="device-screen">
        <div class="device-from-header">
          {{ this.getCompany }}
        </div>
        <div class="device-messages">
          <div class="device-message" v-html="p1">
            Paragraph 1 STOP 36070
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Format from '@/services/formatter'

export default {
  data: function() {
    return {
      loaded: false
    }
  },
  props: {
    template: {
      type: Object
    }
  },
  computed: {
    components() {
      return this.template.components
    },
    p1() {
      const p = this.components.filter(e => {
        return e.kind === 'paragraph'
      })
      return p.length > 0 ? Format.toEditor(p[0].content) + ' STOP 36070' : null
    },
    getCompany() {
      return this.$store.state?.user?.company?.name || ''
    }
  },
  mounted() {
    if (this.template) this.loaded = true
  }
}
</script>

<style scoped lang="css">
.device {
  transform: scale(0.9);
  transform-origin: top left;
  background: transparent;
  height: 568px;
  box-sizing: content-box;
  margin-right: -30px;
  width: 280px;
  border: 20px solid #1e1e1e;
  border-bottom: 40px solid #1e1e1e;
  border-radius: 50px !important;
  background-color: #efefef;
  position: relative;
}

.device-notch {
  background: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  padding: 3%;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
  width: 50%;
  margin: 0 auto;
  z-index: 3;
}

.device-camera {
  background-color: aliceblue;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 90px;
  margin: 0 auto;
  z-index: 3;
}

.device-speaker {
  padding: 2% 0;
  width: 40%;
  margin-left: 30%;
  background-color: gray;
  z-index: 3;
}

.device-screen {
  font-family: Helvetica, arial, sans-serif;
  background-color: white;
  height: 99%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  z-index: -1;
}

.device-from-header {
  background: #efefef;
  text-align: center;
  height: 5%;
  padding-top: 10%;
  padding-bottom: 1%;
}

.device-from-header:before {
  border-left: 2px solid rgb(54, 127, 239);
  border-bottom: 2px solid rgb(54, 127, 239);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  left: 6%;
  top: 10%;
}

.device-messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.device-message {
  background: #efefef;
  width: auto;
  max-width: 70%;
  display: inline-block;
  word-break: break-all;
  padding: 5%;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 10px;
}
</style>
