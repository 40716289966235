<template>
  <div class="disp-flex align-center">
    <div v-if="isEmailPublishing" class="disp-flex flex-column">
      <div class="disp-flex">
        <label class="body-1">Nombre de mails réservé/requis :</label>
        <v-spacer class="mx-1"></v-spacer>
        <div class="body-1 text-xs-right" style="min-width: 90px">
          <span :class="enoughMailsReserved ? 'green--text' : 'red--text'">{{ emails.reserved }}</span> /
          {{ emails.required }}
          <v-icon small>move_to_inbox</v-icon>
        </div>
      </div>
      <div v-if="campaign.status === 'pending'" class="disp-flex">
        <label class="body-1">
          Nombre de mails disponibles :
        </label>
        <v-spacer class="mx-1"></v-spacer>
        <div class="body-1 text-xs-right" style="min-width: 90px">
          {{ emails.available }}
          <v-icon small>email</v-icon>
        </div>
      </div>
    </div>
    <v-divider v-if="isEmailPublishing && isSmsPublishing" vertical class="ml-4 mr-4"></v-divider>
    <div v-if="isSmsPublishing" class="disp-flex flex-column">
      <div class="disp-flex">
        <label class="body-1">Nombre de SMS réservé/requis :</label>
        <v-spacer></v-spacer>
        <div class="body-1 text-xs-right" style="min-width: 90px">
          <span :class="enoughMailsReserved ? 'green--text' : 'red--text'">{{ sms.reserved }}</span> /
          {{ sms.required }}
          <v-icon small>move_to_inbox</v-icon>
        </div>
      </div>
      <div class="disp-flex">
        <label class="body-1">
          Nombre de SMS disponibles :
        </label>
        <v-spacer class="mx-1"></v-spacer>
        <div class="body-1 text-xs-right" style="min-width: 90px">
          {{ sms.available }}
          <v-icon small>email</v-icon>
        </div>
      </div>
    </div>
    <div v-if="!enoughMailsAvailable" class="ml-auto mr-auto pl-4">
      <v-btn small outline round color="primary" class="mx-0 mt-2 px-3" @click="onClick()" :disabled="loading">
        {{ `Acheter ${emails.required} mails` }}
      </v-btn>
    </div>

    <v-dialog width="1200" v-model="showPurchase" @input="showPurchase = false">
      <v-card>
        <Purchase />
        <v-content class="white mt-5">
          <v-container class="blue-gradient">
            <OrderSummary @payment-done="afterPurchase()" />
          </v-container>
        </v-content>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AdvertiserService from '@/services/AdvertiserService'
import Purchase from '@/components/Communications/Purchase'
import OrderSummary from '@/components/Communications/OrderSummary'
import { getService } from '@/services/Helpers'

export default {
  components: {
    Purchase,
    OrderSummary
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPurchase: false,
      emails: {
        available: 0,
        required: 0,
        reserved: 0,
        toPurchase: 0
      },
      sms: {
        available: 0,
        required: 0,
        reserved: 0
      },
      loading: false
    }
  },
  mounted() {
    if (this.campaign.status === 'pending') this.fetchBalances()
    this.fetchAvailableCustomers()
    this.fetchBalanceSplits()
    if (this.emails.required > this.emails.available) {
      this.emails.toPurchase = this.emails.required - this.emails.available
    }
  },
  methods: {
    ...mapActions('order', ['updateEmailMin']),
    afterPurchase() {
      this.showPurchase = false
      this.fetchBalances()
    },
    async onClick() {
      this.loading = true
      this.updateEmailMin(this.requiredMails)
      this.showPurchase = true
      this.loading = false
    },
    async fetchBalances() {
      const res = await AdvertiserService.getBalances(this.company.advertisers[0].id)

      this.emails.available = res.data.email.available
      this.sms.available = res.data.sms.available
    },
    async fetchAvailableCustomers() {
      const res = await getService().getAvailableCustomers(this.campaign.id)
      this.emails.required = res.data.email
      this.sms.required = res.data.sms
    },
    async fetchBalanceSplits() {
      const res = await getService().getBalanceSplits(this.campaign.id)
      this.emails.reserved = res.data.email.reserved
      this.sms.reserved = res.data.sms.reserved
    }
  },
  computed: {
    ...mapState({
      company: state => state.user.company
    }),
    isEmailPublishing() {
      return this.campaign.publishing && this.campaign.publishing.includes('email_published')
    },
    isSmsPublishing() {
      return this.campaign.publishing && this.campaign.publishing.includes('sms_published')
    },
    enoughMailsAvailable() {
      return this.emails.available >= this.emails.required
    },
    enoughMailsReserved() {
      return this.emails.reserved >= this.emails.required
    }
  }
}
</script>
