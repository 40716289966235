<template>
  <v-dialog :value="value" @input="$emit('input', false)" max-width="900">
    <v-card>
      <v-card-text>
        <v-layout column>
          <v-flex xs2 my-3>
            <h2 class="title mt-1">
              <v-icon class="mr-2">store</v-icon
              >{{ $t('campaignListItem.seeParticipatingPOS') }}
            </h2>
          </v-flex>
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="contracts"
              class="elevation-1"
              :loading="loading"
              :no-data-text="
                loading ? $t('common.loading') : $t('common.noData')
              "
              :rows-per-page-text="$t('common.rowsPerPage')"
            >
              <template v-slot:items="props">
                <td>{{ props.item.point_of_sale.name }}</td>
                <td>{{ props.item.point_of_sale.siret }}</td>
                <td>{{ props.item.point_of_sale.code }}</td>
                <td>
                  {{
                    props.item.point_of_sale.location
                      ? props.item.point_of_sale.location.line_1
                      : '-'
                  }}
                </td>
                <td>
                  <v-switch
                    v-if="props.item.mine && !isBroadcasterCampaign"
                    v-model="props.item.status"
                    true-value="accepted"
                    false-value="refused"
                    @click.stop="changePosStatus(props)"
                    height="10"
                    hide-details
                    :color="
                      props.item.status == 'accepted'
                        ? 'green'
                        : props.item.status == 'pending'
                        ? 'yellow darken-3'
                        : 'red'
                    "
                    :label="$t(`status.${props.item.status}`)"
                  />
                  <v-chip
                    v-else
                    :color="
                      props.item.status == 'accepted'
                        ? 'green'
                        : props.item.status == 'pending'
                        ? 'yellow darken-3'
                        : 'red'
                    "
                    small
                    disabled
                    outline
                  >
                    <v-avatar>
                      <v-icon>{{ returnIcon(props.item.status) }}</v-icon>
                    </v-avatar>
                    {{ $t(`status.${props.item.status}`) }}
                  </v-chip>
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('input', false)">
          {{ $t('pointsOfSale.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import req from '@/packs/request.js'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    isBroadcasterCampaign: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      loading: true,
      contracts: [],
      headers: [
        {
          text: this.$t('pointsOfSale.name'),
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Siret',
          value: 'siret',
          sortable: false
        },
        {
          text: 'Code',
          value: 'code',
          sortable: false
        },
        {
          text: this.$t('pointsOfSale.address'),
          value: 'address',
          sortable: false
        },
        {
          text: this.$t('pointsOfSale.status'),
          value: 'status',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async fetchContracts() {
      const filter = this.isBroadcasterCampaign ? '?by_status=accepted' : ''
      const res = await req.get(`/campaigns/${this.id}/contracts${filter}`)

      this.contracts = res.data
      this.loading = false
    },
    async accept(index) {
      const res = await req.put(`/campaigns/${this.id}/contracts/${this.contracts[index].id}/accept`)
      this.contracts.splice(index, 1, res.data)
    },
    async refuse(index) {
      const res = await req.put(`/campaigns/${this.id}/contracts/${this.contracts[index].id}/refuse`)
      this.contracts.splice(index, 1, res.data)
    },
    changePosStatus(props) {
      if (['refused', 'pending'].includes(props.item.status)) this.accept(props.index)
      else if (['accepted', 'pending'].includes(props.item.status)) this.refuse(props.index)
    },
    returnIcon(state) {
      const icons = {
        accepted: 'check_circle',
        pending: 'watch_later',
        refused: 'remove_circle'
      }
      return icons[state]
    }
  },
  watch: {
    value: {
      handler(newV, oldV) {
        if (!oldV && newV) this.fetchContracts()
      }
    }
  }
}
</script>
