<template>
  <v-card class="pr-2 blue-grey lighten-5 w-100">
    <v-autocomplete
      :items="pointOfSales"
      :value="value"
      @input="$emit('input', $event) && validation && validation.$touch()"
      @blur="validation && validation.$touch()"
      item-value="id"
      item-text="name"
      class="mt-0 pt-0"
      solo
      flat
      multiple
      hide-details
      append-outer-icon="store"
      menu-props="offsetY"
      :label="$t('customers.filters.pos') | capitalize"
      :error-messages="POSErrors"
    >
      <template v-slot:prepend-item>
        <v-list-tile ripple @click="toggle" style="margin-bottom: -9px">
          <v-list-tile-action>
            <v-icon :color="value && value.length > 0 ? 'primary' : ''">{{ icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ checkAllPOS ? $t('common.deselectAll') : $t('common.selectAll') }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ item, index }">
        <template v-if="value.length === pointOfSales.length">
          <v-chip v-if="index === 0">
            <span>Tous les points de ventes</span>
          </v-chip>
        </template>
        <template v-else>
          <v-chip v-if="index === 0">
            <span>{{ item.name | truncate(25) }}</span>
          </v-chip>
          <span
            v-else-if="index === 1"
            class="grey--text text--darken-2 caption"
          >
            (+{{ value.length - 1 }} {{ $t('customers.filters.pos') }})
          </span>
        </template>
      </template>
      <template v-slot:no-data>
        <div class="pa-3">Pas encore de {{ $t('customers.filters.pos') }}</div>
      </template>
    </v-autocomplete>
  </v-card>
</template>

<script>
import req from '@/packs/request.js'

export default {
  props: {
    value: {
      validator: prop => Array || prop === null,
      required: true,
      default: () => []
    },
    children: {
      type: Boolean,
      required: false,
      default: false
    },
    preselectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    validation: {
      type: Object,
      required: false,
      default: null
    },
    withDiscarded: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      pointOfSales: []
    }
  },
  async created() {
    await this.fetchPointOfSales()
    if (this.preselectAll) this.$emit('input', this.pointOfSales.map(e => e.id))
  },
  methods: {
    async fetchPointOfSales() {
      const fetchUrl = this.children ?
        `/point_of_sales?by_completion=true&with_discarded=${this.withDiscarded.toString()}` :
        `/company/point_of_sales?by_completion=true&with_discarded=${this.withDiscarded.toString()}`

      const res = await req.get(fetchUrl)
      this.pointOfSales = res.data
    },
    toggle() {
      this.$nextTick(() => {
        if (this.checkAllPOS) {
          this.$emit('input', [])
        } else {
          this.$emit('input', this.pointOfSales.slice())
        }
      })
    }
  },
  computed: {
    checkAllPOS() {
      return this.value?.length === this.pointOfSales.length
    },
    checkSomePOS() {
      return this.value?.length > 0 && !this.checkAllPOS
    },
    icon() {
      if (this.checkAllPOS) return 'check_box'
      if (this.checkSomePOS) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    },
    POSErrors() {
      const errors = []
      if (this.validation) {
        if (!this.validation.$dirty) return errors
        !this.validation.required && errors.push(this.$t('createCampaign.error.pos'))
      }
      return errors
    }
  }
}
</script>
