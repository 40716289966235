<template>
  <v-autocomplete
    @change="update($event)"
    ref="autoComplete"
    :value="targetId"
    :items="targets[scope]"
    :search-input.sync="search"
    :loading="loading"
    :disabled="disabled"
    hide-selected
    item-text="name"
    item-value="id"
    :label="`${$t('createCampaign.triggerReward.searchA')} un ${$t('createCampaign.triggerReward.' + this.scope)}...`"
    solo
  >
    <template slot="no-data">
      <v-list-tile>
        <v-list-tile-title>
          {{ $t('common.noData') }}
        </v-list-tile-title>
      </v-list-tile>
    </template>
    <template slot="selection" slot-scope="{ item, selected }">
      <v-chip :selected="selected">
        <span v-text="`${item.name} ${targetBasePrice(item)}`"></span>
      </v-chip>
    </template>
    <template slot="item" slot-scope="{ item }">
      <v-list-tile-avatar v-if="item.logo_url" class="headline">
        <v-img :src="item.logo_url" aspect-ratio="1"></v-img>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title v-text="item.name"></v-list-tile-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script>
import req from '@/packs/request'

export default {
  props: {
    targetId: {
      type: String,
      required: false,
      default: null
    },
    scope: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      search: null,
      selected: null,
      loading: false,
      targets: {
        product: [],
        category: [],
        brand: []
      },
      debounceId: null
    }
  },
  methods: {
    async setInitialTarget() {
      const res = await req.get(`/targets/${this.$pluralize(this.scope)}/${this.targetId}`)
      this.targets[this.scope].push(res.data)
    },
    targetBasePrice(target) {
      return target.base_price == null ? '' : `(${target.base_price.toCurrency()} €)`
    },
    update(targetId) {
      this.$emit('update', targetId || null)
    },
    async fetchTargets() {
      let selectedTarget
      this.loading = true

      if (this.targetId) selectedTarget = this.targets[this.scope].find(e => e.id === this.targetId)
      const byName = this.search && this.search.length > 0 ? `&by_name=${this.search}` : ''
      const res = await req.get(`/targets/${this.$pluralize(this.scope)}?page=1&per_page=10${byName}`)

      this.targets[this.scope] = res.data.collection
      if (selectedTarget) this.targets[this.scope].push(selectedTarget)
      this.loading = false
    }
  },
  async created() {
    if (this.targetId) {
      await this.setInitialTarget()
      this.fetchTargets()
    } else this.fetchTargets()
  },
  watch: {
    search: {
      handler() {
        clearTimeout(this.debounceId)

        this.debounceId = setTimeout(() => {
          this.fetchTargets()
        }, 500)
      }
    },
    scope: {
      handler() {
        this.fetchTargets()
        this.$refs.autoComplete.lazyValue = null
      }
    }
  }
}
</script>
