<template>
  <v-dialog :value="true" @input="$router.go(-1)" max-width="450px">
    <v-card>
      <v-card-title class="title grey lighten-2" primary-title>
        <v-icon class="mr-2">delete_forever</v-icon>
        Supprimer un segment personnalisé
      </v-card-title>
      <v-card-text>
        <v-card-text class="subheading">
          {{ $t('message.deleteSegment') }} <b>{{ segment_name }}</b> ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="deleteSegment()">{{
            $t('common.boolYes')
          }}</v-btn>
          <v-btn color="primary" flat @click="$router.go(-1)">{{
            $t('common.boolNo')
          }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import req from '@/packs/request.js'
import eventBus from '@/packs/event-bus'

export default {
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    customer_base: {
      type: String,
    },
  },
  data: function() {
    return {
      segment_id: this.id,
      segment_name: this.name,
    }
  },
  methods: {
    async deleteSegment() {
      await req.delete(
        '/customer_bases/' +
          this.customer_base +
          '/segments/' +
          this.segment_id
      )

      eventBus.$emit('refreshSegments')
      eventBus.$emit('showSnackbar', {
        text: 'Segment deleted',
        color: 'success',
      })
      this.$router.go(-1)
    },
  },
}
</script>
