<template>
  <div>
    <div class="header-card pa-3">
      <h3 class="font-weight-medium">
        <v-icon small class="align-icon">smartphone</v-icon>
        {{ $t('settings.pass.title') }}
      </h3>
    </div>
    <div class="pt-3 px-5">
      <v-layout row wrap>
        <v-flex xs12 md4>
          <h3 class="font-weight-medium">{{ $t('settings.pass.ecardHomeLogo') }}</h3>
          <span class="caption">({{ $t('settings.pass.maxHeight') }} : 600 px)</span>
          <image-loader v-model="company.logo" :src="company.logo_url" height="150"></image-loader>
        </v-flex>
        <v-flex xs12 md4>
          <h3 class="font-weight-medium">{{ $t('settings.pass.homeFavicon') }}</h3>
          <span class="caption">({{ $t('settings.pass.size') }} : 48 x 48 px)</span>
          <image-loader v-model="company.favicon" :src="company.favicon_url" height="150"></image-loader>
        </v-flex>
        <v-flex xs12 md4>
          <h3 class="font-weight-medium">{{ $t('settings.pass.ecardBanner') }}</h3>
          <span class="caption">({{ $t('settings.pass.size') }} : 740 x 240 px)</span>
          <image-loader v-model="company.pass_banner" :src="company.pass_banner_url" height="150"></image-loader>
        </v-flex>
        <v-flex xs12 md4>
          <h3 class="font-weight-medium">{{ $t('settings.pass.defaultItemVoucher') }}</h3>
          <span class="caption">({{ $t('settings.pass.size') }} : 400 x 200 px)</span>
          <image-loader v-model="company.default_item_image" :src="company.default_item_image_url" height="150"></image-loader>
        </v-flex>
        <v-flex xs12 md4>
          <h3 class="font-weight-medium">{{ $t('settings.pass.defaultCartVoucher') }}</h3>
          <span class="caption">({{ $t('settings.pass.size') }} : 400 x 200 px)</span>
          <image-loader v-model="company.default_cart_image" :src="company.default_cart_image_url" height="150"></image-loader>
        </v-flex>
        <v-flex xs2 md4></v-flex>
        <v-flex xs12 md4>
          <h3 class="font-weight-medium">{{ $t('settings.mainColor') }}</h3>
          <div class="flex-div align-center">
            <div @click="changeColor = !changeColor" class="square-color mr-3" :style="`background-color: ${company.main_color}`"></div>
            <v-text-field @click="changeColor = !changeColor" v-model="company.main_color" :label="$t('settings.color')"  hide-details></v-text-field>
          </div>
          <v-card v-if="changeColor" class="color-picker-field flex-div flex-column">
            <sketch-picker v-model="company.main_color" @input="updateValue" class="mr-0"/>
            <v-btn class="my-2 ml-auto primary" @click="changeColor = false">close</v-btn>
          </v-card>
        </v-flex>
        <v-flex md4 xs12>
          <h3 class="font-weight-medium">{{ $t('settings.pass.theme') }}</h3>
          <v-switch color="primary" v-model="company.dark_mode" :label="company.dark_mode ? $t('settings.pass.darkMode') : $t('settings.pass.lightMode')"></v-switch>
        </v-flex>
      </v-layout>
      <div class="pb-3">
        <v-btn color="primary" @click="saveChanges()" class="mx-0">
          <v-icon small class="mr-2">save</v-icon>
          {{ $t('settings.saveChanges') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import req from '@/packs/request.js'
import ImageLoader from '@/components/Base/ImageLoader'
import { Sketch } from 'vue-color'

export default {
  components: {
    ImageLoader,
    'sketch-picker': Sketch
  },
  data() {
    return {
      changeColor: false,
      company: {}
    }
  },
  methods: {
    updateValue(value) {
      this.company.main_color = value.hex
    },
    async fetchCompany() {
      const res = await req.get('/user/company')
      this.company = { ...res.data }
    },
    async saveChanges() {
      const res = await req.put('/user/company', this.company)
      this.company = { ...res.data }
    }
  },
  created() {
    this.fetchCompany()
  }
}
</script>
