<template>
  <div style="background: #eee" class="disp-flex justify-center align-center w-100 h-100">
    <div>
      <img src="@/assets/images/logo/logo_color.svg" alt="B&GO Logo" width="170" />
      <v-card elevation="1" max-width="500px" min-width="400" class="w-100 mt-2 pa-3">
        <h2>Paramètres notifications</h2>
        <p class="mt-1">
          Avant de partir, il vaut peut-être la peine de vérifier toutes les options dont nous disposons pour fournir le
          contenu qui vous convient.
        </p>
        <v-checkbox :label="$t('customers.data.acceptMail')" hide-details />
        <v-checkbox :label="$t('customers.data.acceptPostMail')" hide-details />
        <v-checkbox :label="$t('customers.data.acceptSMS')" hide-details />
        <v-btn dark small color="primary" class="mx-0 mt-4"> <v-icon small left>save</v-icon> Sauvegarder </v-btn>
        <v-divider class="my-3"></v-divider>
        <h2>Désactiver votre compte</h2>
        <p class="mb-3">
          Nous sommes désolés d'apprendre que vous nous quittez.
        </p>
        <v-btn dark small color="red" class="mx-0" @click="verification()">
          <v-icon small left>delete</v-icon> Désactiver mon compte
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import req from '@/packs/request'

export default {
  data() {
    return {
      accept_mail_contact: false
    }
  },
  methods: {
    async saveOptins() {
      await req.put(`/customers/${this.id}/optins`, {
        customers: {
          accept_mail_contact: this.customer.accept_mail_contact,
          accept_sms_contact: this.customer.accept_sms_contact,
          accept_postal_contact: this.customer.accept_postal_contact
        }
      })
    },
    async verification() {
      this.$swal
        .fire({
          title: 'Êtes vous sûr ?',
          text: `Vous ne pourrez pas revenir en arrière`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'red',
          cancelButtonText: 'Annuler',
          confirmButtonColor: 'green',
          confirmButtonText: 'Désactiver'
        })
        .then(async result => {
          if (result.isConfirmed) this.$swal.fire('Votre compte a bien été désactivé', '', 'success')
          await req.delete(`/customers/${this.id}/optins`)
        })
    }
  }
}
</script>
