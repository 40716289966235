<template>
  <v-container fluid grid-list-lg>
    <v-card :elevation="2" class="pa-3 mb-3">
      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg8>
          <h3>{{ $t('donations.totalDonatedAmount') }} :</h3>
          <div class="donated-amount">{{ totalDonatedAmount }} €</div>
        </v-flex>
        <v-flex xs12 sm6 md6 lg4 class="mt-auto mb-auto">
          <DateRangePicker @update="updateDateRange($event)" />
        </v-flex>
      </v-layout>
    </v-card>
    <v-layout column>
      <v-flex row xs12>
        <v-data-table
          :headers="tableHeaders"
          :items="donations"
          :pagination.sync="pagination"
          item-key="name"
          :total-items="totalDonations"
          :rows-per-page-text="$t('common.rowsPerPage')"
          :loading="loading"
          class="elevation-1"
        >
          <template slot="headerCell" slot-scope="props">
            {{ $t(props.header.text) }}
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.created_at ? props.item.created_at : '-' }}</td>
            <td>{{ customerFullName(props.item) }}</td>
            <td class="text-xs">{{ company.name }}</td>
            <td class="text-xs">
              {{ props.item.donated_amount.toCurrency() }} €
            </td>
          </template>
          <template slot="no-data">
            <div class="text-center">
              {{ $t('donations.noResult') }}
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import req from '@/packs/request.js'
import DateRangePicker from '@/components/custom/DateRangePicker.vue'

export default {
  components: {
    DateRangePicker
  },
  data: () => {
    return {
      tableHeaders: [
        {
          text: 'donations.date',
          value: 'created_at'
        },
        {
          text: 'donations.customer',
          align: 'left',
          sortable: false,
          value: 'size'
        },
        {
          text: 'donations.compagny',
          value: 'downloaded'
        },
        {
          text: 'donations.donatedAmount',
          value: 'donated_amount'
        }
      ],
      company: [],
      donations: [],
      totalDonations: 0,
      totalDonatedAmount: 0,
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      loading: true,
      dateRange: null
    }
  },
  methods: {
    async fetchDonations() {
      this.loading = true
      const page = this.pagination.page
      const perPage = this.pagination.rowsPerPage

      let dateQuery = ''
      if (this.dateRange) dateQuery = `&start_date=${this.dateRange.start}&end_date=${this.dateRange.end}`

      const res = await req.get(`/user/company/donation_organization/donations?page=${page}&per_page=${perPage}${dateQuery}`)
      this.donations = res.data.collection
      this.totalDonations = res.data.count
      this.totalDonatedAmount = res.data.metadata.total_donated_amount.toCurrency()

      this.loading = false
    },
    async fetchOrganization() {
      const res = await req.get('/user/company/donation_organization')
      this.company = res.data
    },
    updateDateRange(dateRange) {
      this.dateRange = dateRange
      this.fetchDonations()
    },
    customerFullName(item) {
      return item.customer.firstname + ' ' + item.customer.lastname
    }
  },
  created() {
    this.fetchOrganization()
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.fetchDonations()
      }
    }
  }
}
</script>
