import CampaignListState from '@/models/states/CampaignListState'

enum Mode {
  Fidelity = 'fidelity',
  AdToStore = 'conquest',
  Both = 'both'
}

export default function(): CampaignListState {
  return {
    mode: Mode.Fidelity,
    tabIndex: 0,
    fidelity: {
      customs: {
        collection: [],
        count: 0,
        loading: false,
        filters: {
          page: 1,
          by_state: 'all',
          by_status: 'all',
          by_kind: 'all'
        }
      },
      communications: {
        collection: [],
        count: 0,
        loading: false,
        filters: {
          page: 1,
          by_state: 'all',
          by_status: 'all',
          by_kind: 'all'
        }
      }
    },
    conquest: {
      created: {
        collection: [],
        count: 0,
        loading: false,
        filters: {
          page: 1,
          by_state: 'all',
          by_status: 'all',
          by_kind: 'all'
        }
      },
      inherited: {
        collection: [],
        count: 0,
        loading: false,
        filters: {
          page: 1,
          by_state: 'all',
          by_status: 'all',
          by_kind: 'all'
        }
      },
      tradeMarketing: {
        collection: [],
        count: 0,
        loading: false,
        filters: {
          page: 1,
          by_state: 'all',
          by_status: 'all',
          by_kind: 'all'
        }
      },
      publishing: {
        collection: [],
        count: 0,
        loading: false,
        filters: {
          page: 1,
          by_state: 'all',
          by_status: 'all',
          by_kind: 'all'
        }
      }
    }
  }
}