<template>
  <div>
    <v-menu
      ref="from"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="picker"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      max-width="290px"
      min-width="290px"
    >
      <v-text-field
        :rules="[endRules]"
        slot="activator"
        v-model="picker"
        :label="label"
        prepend-inner-icon="access_time"
        readonly
        hide-details
      >
      </v-text-field>
      <v-time-picker
        format="24hr"
        v-if="menu"
        v-model="picker"
        full-width
        @click:minute="saveTime()"
      >
      </v-time-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: 'From',
    },
    hour: Number,
    minutes: Number,
    startHour: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      menu: false,
      returnValue: {
        dhour: 0,
        dminutes: 0,
      },
    }
  },
  computed: {
    hourComp() {
      return this.hour
    },
    minutesComp() {
      return this.minutes
    },
    picker: {
      get() {
        return (
          (this.returnValue.dhour < 10 ? '0' : '') +
          this.returnValue.dhour +
          ':' +
          (this.returnValue.dminutes < 10 ? '0' : '') +
          this.returnValue.dminutes
        )
      },
      set(val) {
        this.returnValue.dhour = Number(val.split(':')[0])
        this.returnValue.dminutes = Number(val.split(':')[1])
      },
    },
  },
  methods: {
    saveTime() {
      this.$refs.from.save(this.picker)
      this.$emit('timeChanged', this.returnValue)
    },
    endRules() {
      if (this.returnValue.dhour < this.startHour)
        return "End time cannot be before the time range's start"
      else return true
    },
  },
  watch: {
    hourComp() {
      this.returnValue.dhour = this.hourComp
    },
    minutesComp() {
      this.returnValue.dminutes = this.minutesComp
    },
  },
  mounted() {
    this.returnValue.dhour = this.hour || 0
    this.returnValue.dminutes = this.minutes || 0
  },
}
</script>
