<template>
  <div>
    <v-text-field
      solo
      label="Rechercher un point de vente"
      append-icon="search"
      v-model="searchField"
      hide-details
      class="relative"
    ></v-text-field>
    <v-card>
      <v-list two-line dense style="max-height: 565px" class="scroll-y mt-2">
        <template v-if="!loading && pointOfSales.length == 0"> Aucun points de ventes à compléter </template>
        <template v-if="!loading">
          <template v-for="(pos, index) in searchPos">
            <v-list-tile :key="pos.id" @click="$emit('click', pos)" :class="selectedPos.id === pos.id ? 'tile' : ''">
              <v-list-tile-content>
                <v-list-tile-title v-html="pos.name"></v-list-tile-title>
                <v-list-tile-sub-title>{{ pos.location ? pos.location.line_1 : '' }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider v-if="index < pointOfSales.length - 1" :key="index" :inset="true"></v-divider>
          </template>
        </template>
        <template v-else>
          <v-progress-circular center indeterminate color="primary"></v-progress-circular>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
      required: true
    },
    pointOfSales: {
      type: Array,
      default: () => [],
      required: true
    },
    selectedPos: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      searchField: ''
    }
  },
  computed: {
    searchPos() {
      return this.pointOfSales.filter((e) => e.name.match(new RegExp(this.searchField, 'i')))
    }
  }
}
</script>

<style scoped>
.tile {
  background: #eeeeee;
}
</style>
