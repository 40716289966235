<template>
  <v-layout>
    <v-flex xs12 sm6 offset-sm3>
      <v-card>
        <v-img src="https://dg1ds1ky7tt5.cloudfront.net/images/Point-of-sale.jpg" aspect-ratio="2.75"></v-img>

        <v-card-title primary-title>
          <div>
            <h3 class="headline">{{ pointOfSale_.name }}</h3>
            <span class="grey--text">Siret: {{ pointOfSale_.siret }} - Code: {{ pointOfSale_.code }}</span>
          </div>
        </v-card-title>

        <v-card-text>
          <v-layout row wrap>
            <template v-if="pointOfSale_.location">
              <v-flex xs6>
                <v-text-field label="Line 1" v-model="pointOfSale_.location.line_1"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field label="Line 2" v-model="pointOfSale_.location.line_2"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field label="City" v-model="pointOfSale_.location.city"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field label="Zipcode" v-model="pointOfSale_.location.zipcode"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field label="State" v-model="pointOfSale_.location.state"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field prepend-icon="phone" label="Phone" v-model="pointOfSale_.location.phone"></v-text-field>
              </v-flex>
              <v-flex xs12 v-if="pointOfSale_.affiliate_contract_url">
                <v-btn
                  small
                  :href="pointOfSale_.affiliate_contract_url"
                  target="_blank"
                  flat
                  color="primary"
                  class="ml-0"
                >
                  <v-icon class="mr-2">picture_as_pdf</v-icon>contract
                </v-btn>
              </v-flex>
            </template>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-btn class="mr-1" flat color="green" @click="complete(pointOfSale_)">Complete</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    pointOfSale: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    pointOfSale_() {
      return { ...this.pointOfSale }
    }
  },
  methods: {
    complete(pointOfSale) {
      this.$emit('onComplete', pointOfSale)
    }
  }
}
</script>
