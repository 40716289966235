<template>
  <v-layout class="grid-simple-campaign mx-1" row>
    <v-flex class="w-100">
      <v-card class="pa-3 default-heigth">
        <v-card-title>
          <span class="title">{{ $t('createCampaign.generalInformations') }}</span>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-flex>
          <v-text-field :value="campaignName" @input="setName($event)" :rules="nameRules" :label="$t('CoreProgramCampaignSettings.programName')" required></v-text-field>
        </v-flex>
        <v-flex>
          <label class="font-weight-medium">{{ $t('CoreProgramCampaignSettings.programLogo') }}<span class="caption"> (optionel)</span> :</label>
          <ImageLoader v-model="campaign.logo" :src="campaign.logo_url" height="150"/>
        </v-flex>
        <v-flex>
          <label class="font-weight-medium">L'engagement des clients :</label>
<!--          <v-checkbox v-model="settings.gradualApplies" disabled label="Enable gradual increase in generosity" hide-details></v-checkbox>-->
          <v-checkbox v-model="enableStatusSystem" :disabled="campaign.type === 'generic'" :label="$t('CoreProgramCampaignSettings.enableStatus')" hide-details></v-checkbox>
        </v-flex>
      </v-card>
    </v-flex>
    <v-flex class="w-100">
      <v-card>
        <v-tabs v-model="tab" @change="notification()" color="white" grow class="mb-3">
          <v-tabs-slider color="#3f51b5" height="3px"></v-tabs-slider>
          <v-tab v-for="(tab, index) in tabs" :key="index">
            {{ tab.title | capitalize }}<v-icon v-if="tab.change" color="#ffd600" class="ml-2">notification_important</v-icon>
          </v-tab>
        </v-tabs>
      </v-card>
      <v-card class="pa-3 default-height-core-settings" style="height: calc(100% - 65px)">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <component :is="tab.component" class="h-100"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-flex>
<!--    <v-flex class="text-xs-center d-flex justify-self-center" v-else>-->
<!--      <v-card class="align-self-center pa-5">-->
<!--        <v-icon style="font-size: 50px" class="mb-2" color="grey">info_outline</v-icon>-->
<!--        <h3 class="subheading">-->
<!--          Le type de campagne <b>"Generic"</b> ne permet pas<br>d'autre option de configuration.-->
<!--        </h3>-->
<!--      </v-card>-->
<!--    </v-flex>-->
  </v-layout>
</template>

<script>
import ImageLoader from './Base/ImageLoader'
import CoreProgramCampaignSettingsPool from './CoreProgramCampaignSettingsPool'
import CoreProgramCampaignSettingsCurrency from './CoreProgramCampaignSettingsCurrency'
import CoreProgramCampaignSettingsStatus from './CoreProgramCampaignSettingsStatus'
import StatusSystem from '@/models/StatusSystem'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ImageLoader,
    CoreProgramCampaignSettingsPool,
    CoreProgramCampaignSettingsCurrency,
    CoreProgramCampaignSettingsStatus
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      nameRules: [
        v => !!v || 'Program name is required',
        v => (v && v.length >= 3) || 'The program name must have more than 3 characters'
      ],
      tab: 0,
      tabs: [
        {
          title: this.$t('common.loyaltyCurrency'),
          change: false,
          component: CoreProgramCampaignSettingsCurrency
        },
        {
          title: this.$t('common.poolAward'),
          change: false,
          component: CoreProgramCampaignSettingsPool
        }
      ]
    }
  },
  methods: {
    ...mapActions('campaignBuilder', [
      'setName',
      'setStatusSystem',
      'createLoyaltyCurrency',
      'createPoolProgram'
    ]),
    manageTabs(newTab) {
      if (this.tabs.length === 2)
        this.tabs.push({ title: 'Status', change: newTab, component: CoreProgramCampaignSettingsStatus })
      else if (!this.enableStatusSystem) this.tabs.pop()
    },
    notification() {
      if (this.tabs[this.tab]?.change) this.tabs[this.tab].change = false
    }
  },
  computed: {
    ...mapState({
      campaignName: state => state.campaignBuilder?.campaign?.name,
      statusSystem: state => state.campaignBuilder?.campaign?.status_system
    }),
    enableStatusSystem: {
      get() {
        return !!this.campaign.status_system
      },
      set(value) {
        this.setStatusSystem(value ? new StatusSystem() : null)
      }
    }
  },
  created() {
    if (!this.campaign.loyalty_currency) this.createLoyaltyCurrency()
    if (!this.campaign.pool_program) this.createPoolProgram()
    if (this.campaign?.status_system) {
      this.manageTabs(false)
      this.setStatusSystem(this.campaign.status_system)
    }
  },
  watch: {
    enableStatusSystem: {
      deep: true,
      handler() {
        this.manageTabs(true)
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import "../stylesheets/campaignCustom"
</style>
