<template>
  <v-layout row wrap v-if="template">
    <v-flex md6>
      <v-layout row wrap>
        <v-flex xs12 v-if="showInfoVariables">
          <v-alert
            transition="scale-transition"
            :value="showInfoVariables"
            type="info"
            color="blue lighten-5 blue-grey--text text--darken-2"
          >
            <v-layout>
              <v-flex v-html="$t('campaigns.communication.help')"></v-flex>
              <v-flex>
                <v-icon @click="infoVariables = !infoVariables" color="blue-grey lighten-1">close</v-icon>
              </v-flex>
            </v-layout>
          </v-alert>
        </v-flex>
        <v-flex xs12>
          <v-card class="pa-3">
            <div v-for="component in template.components" :key="component.id">
              <div v-if="component.kind === 'image'">
                <h3 class="mb-2">{{ component.label | capitalize }}</h3>
                <div class="flex-div mb-2">
                  <image-loader
                    class="mt-1 mb-2"
                    v-model="component.image"
                    :src="
                      component.image_url
                        ? component.image_url
                        : require('@/assets/images/png/default-template-img.png')
                    "
                    @imageChanged="affectNewImage"
                  />
                </div>
              </div>
              <div v-else>
                <h3 class="mb-2">{{ component.label | capitalize }}</h3>
                <div class="flex-div mb-2">
                  <bg-editor
                    class="w-100 mt-1 mb-2"
                    :variables="template.available_variables"
                    v-model="component.content"
                    :is-html="component.kind === 'html'"
                  ></bg-editor>
                </div>
              </div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex md6 class="pb-1">
      <v-card>
        <center-offer
          v-if="template && template.components && !isHtmlTemplate"
          :template="template"
          :image="imageUrl"
        ></center-offer>
        <HtmlPreview v-if="isHtmlTemplate" :src="htmlComponent.content" />
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import BgEditor from '@/components/Editor/BgEditor'
import CenterOffer from '@/components/CampaignForm/Templating/Templates/CenterOffer'
import ImageLoader from '@/components/Base/ImageLoader'
import HtmlPreview from '@/components/HtmlPreview'

export default {
  components: {
    CenterOffer,
    BgEditor,
    ImageLoader,
    HtmlPreview
  },
  data() {
    return {
      title: 'Image Upload',
      imageName: '',
      imageFile: '',
      imageUrl: '',
      infoVariables: true
    }
  },
  props: {
    template: {
      type: Object
    }
  },
  computed: {
    htmlComponent() {
      return this.template.components.find(e => e.kind === 'html')
    },
    isHtmlTemplate() {
      return this.template.components.find(e => e.kind === 'html') != undefined
    },
    showInfoVariables() {
      return this.infoVariables && this.template.available_variables.length > 0
    }
  },
  methods: {
    affectNewImage(event) {
      this.imageUrl = event
    }
  }
}
</script>
