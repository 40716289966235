<template>
  <v-card class="pa-2 mx-5 mb-3" style="position: sticky; bottom: 30px;">
    <div style="display: flex" class="justify-space-between">
      <div>
        <v-btn color="primary" outline :disabled="step === 1" @click="$emit('update:step', step - 1)">
          <v-icon left dark>keyboard_arrow_left</v-icon>
          Retour
        </v-btn>
        <v-btn color="error" outline @click="$router.push({ name: 'CampaignList' })" class="ml-2">
          <v-icon left dark>logout</v-icon>
          Quitter
        </v-btn>
      </div>
      <v-btn color="primary" outline @click="onNext()" :disabled="(step === 2 && !hasReward) || (this.step === this.stepCount && campainStatus === 'finished')">
        {{ nextButtonLabel }}
        <v-icon right dark>keyboard_arrow_right</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    step: {
      type: Number,
      required: true
    },
    stepCount: {
      type: Number,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('campaignBuilder', ['hasReward']),
    ...mapState({
      campainStatus: state => state.campaignBuilder.campaign.status
    }),
    nextButtonLabel() {
      if (this.step === this.stepCount) return this.editMode ? 'Mettre à jour la campagne' : 'Créer la campagne'
      else return 'Suivant'
    }
  },
  methods: {
    onNext() {
      if (this.step < this.stepCount) this.$emit('update:step', this.step + 1)
      else this.$emit('submit')
    }
  }
}
</script>
