import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

//Common
import Login from '@/components/auth/Login.vue'

// New routes
import Campaigns from '@/views/Campaigns.vue'
import Childs from '@/views/Childs.vue'
import ChildListDetails from '@/views/ChildListDetails.vue'
import PointOfSales from '@/views/PointOfSales.vue'
import Retailers from '@/views/Retailers.vue'
import Cg from '@/views/Cg.vue'
import Groups from '@/views/Groups.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import CustomCampaignCreate from '@/views/CustomCampaignCreate.vue'
import ComCampaignCreate from '@/views/ComCampaignCreate.vue'
import CampaignTransactions from '@/views/CampaignTransactions.vue'

import AdToStoreCampaignCreate from '@/views/AdToStoreCampaignCreate.vue'
import CoreProgramCreate from '@/views/CoreProgramCampaignCreate.vue'
import EventsCampaignCreate from '@/views/EventsCampaignCreate.vue'
import GroupDialogEdit from '@/components/GroupDialogEdit.vue'
import ForgotPasswordDialog from '@/components/ForgotPasswordDialog.vue'
import ErrorNotFound from '@/views/ErrorNotFound.vue'

import Dashboard from '@/views/Dashboard.vue'

import Ratecards from '@/components/Advertiser/Ratecards/Ratecards.vue'
import ProCustomers from '@/components/Advertiser/Customers/ProCustomers.vue'

import Customers from '@/views/Customers.vue'
import DialogCustomersSegmentsFilterCustomDelete from '@/components/DialogCustomersSegmentsFilterCustomDelete.vue'
import CustomersDataSheet from '@/components/CustomersDataSheet.vue'

import Settings from '@/components/Settings/Settings.vue'
import EmailTemplatePage from '@/components/Advertiser/EmailTemplatePage.vue'

import TransactionsCoreProgram from '@/components/Advertiser/TransactionsCoreProgram.vue'

import BatchesHistory from '@/components/Advertiser/Batches/BatchesHistory.vue'
import Donations from '@/components/Advertiser/Donations/Donations.vue'

import Communications from '@/views/Communications.vue'

import CoreProgramPage from '@/views/CoreProgramPage.vue'

//Offer
import OfferAdToStore from '@/components/Offer/OfferAdToStore.vue'

//Register
import MerchantRegister from '@/components/Register/MerchantRegister.vue'

import Products from '@/views/Products.vue'
import ProductDialog from '@/components/Products/ProductDialog.vue'

import Unsubscribe from '@/views/Unsubscribe.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: Dashboard
  },
  { path: '/cgu', redirect: '/cg' },
  {
    path: '/reset_password',
    component: ResetPassword
  },
  {
    path: '/cg',
    component: Cg
  },
  {
    path: '/email_templates',
    component: EmailTemplatePage
  },
  {
    path: '/core-program/campaigns',
    name: 'CampaignList',
    component: CoreProgramPage
  },
  {
    path: '/signin',
    component: Login,
    children: [
      {
        path: 'forgot_password',
        name: 'forgotPassword',
        component: ForgotPasswordDialog
      }
    ]
  },
  {
    path: '/campaigns',
    component: Campaigns
  },
  {
    path: '/core-program/transactions',
    component: TransactionsCoreProgram
  },
  {
    path: '/core-program/campaigns/:id/transactions',
    component: CampaignTransactions
  },
  {
    path: '/campaigns/:id/transactions',
    component: CampaignTransactions
  },
  {
    path: '/childs',
    component: Childs,
    children: [{ path: ':id', component: ChildListDetails, props: true }]
  },
  {
    path: '/groups',
    component: Groups,
    children: [{ path: ':id/edit', component: GroupDialogEdit, props: true }]
  },
  {
    path: '/targets',
    component: Products,
    children: [
      { path: ':key/create', component: ProductDialog, props: true },
      { path: ':key/:id/edit', component: ProductDialog, props: true }
    ]
  },
  {
    path: '/points_of_sale',
    component: PointOfSales
  },
  {
    path: '/retailers',
    component: Retailers
  },
  {
    name: 'campaign',
    path: '/campaigns/:id?',
    component: AdToStoreCampaignCreate
  },
  {
    name: 'custom-campaign',
    path: '/campaigns/custom/:id?',
    component: CustomCampaignCreate
  },
  {
    name: 'communication-campaign',
    path: '/campaigns/communication/:id?',
    component: ComCampaignCreate
  },
  {
    name: 'core-program',
    path: '/core-program/:id?',
    component: CoreProgramCreate
  },
  {
    name: 'core-program-campaign',
    path: '/campaigns/event/:id?',
    component: EventsCampaignCreate
  },
  {
    path: '/ratecards',
    component: Ratecards
  },
  {
    path: '/procustomers',
    component: ProCustomers
  },
  {
    path: '/customers',
    component: Customers,
    children: [
      {
        path: ':id/delete',
        name: 'deleteSegments',
        component: DialogCustomersSegmentsFilterCustomDelete,
        props: true
      },
      {
        path: ':id',
        name: 'customersData',
        component: CustomersDataSheet,
        props: true
      }
    ]
  },
  {
    path: '/settings',
    component: Settings
  },
  {
    path: '/batches',
    component: BatchesHistory
  },
  {
    path: '/donations',
    component: Donations
  },
  {
    path: '/communications',
    component: Communications
  },
  {
    path: '/offer/:id?/:key?',
    component: OfferAdToStore
  },
  {
    path: '/unsubscribe',
    component: Unsubscribe
  },
  {
    path: '/register/merchant',
    component: MerchantRegister
  },
  {
    path: '*',
    component: ErrorNotFound
  }
]

import { getTokenPresence } from '@/services/AxiosWrapper'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPaths = [
    '/signin',
    '/donate',
    '/register',
    '/offer',
    '/cg',
    '/reset_password',
    '/unsubscribe'
  ]

  const isPublicPath = publicPaths.some(path => to.path.indexOf(path) == 0)

  if (isPublicPath) next()
  else getTokenPresence() ? next() : next('/signin')
})

export default router
