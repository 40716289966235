<template>
  <v-select
    :items="data"
    v-model="brand"
    label="Marque"
    item-text="name"
    return-object
    @change="update()"
  ></v-select>
</template>

<script>
import req from '@/packs/request'

export default {
  data: function() {
    return {
      brand: [],
      data: [],
    }
  },
  methods: {
    async fetchBrands() {
      const res = await req.get('/targets/brands')
      this.data = res.data
      console.log(this.data)
    },
    update() {
      this.$emit('update', this.brand)
      console.log(this.brand)
    },
  },
  created() {
    this.fetchBrands()
  },
}
</script>
