import FidelityService from '@/services/FidelityService'
import SubFidelityService from '@/services/SubFidelityService'
import ConquestService from '@/services/ConquestService'

enum Mode {
  Fidelity = 'fidelity',
  Subfidelity = 'subfidelity',
  Conquest = 'conquest'
}

const getService = (mode: string) => {
  return mode === Mode.Fidelity ? FidelityService : mode === Mode.Conquest ? ConquestService : SubFidelityService
}

export { getService }
