<template>
  <div class="disp-flex justify-center">
    <v-divider vertical></v-divider>
    <v-btn small flat @click="submit('accept')" class="ma-0 h-100" color="primary">
      {{ $t('campaignListItem.acceptCampaign') }}
    </v-btn>
    <v-divider vertical></v-divider>
    <v-btn small flat @click="submit('refuse')" class="ma-0 h-100" color="red lighten-1 white--text">
      {{ $t('campaignListItem.declineCampaign') }}
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CommissionContractService from '@/services/CommissionContractService'

export default {
  props: {
    commissionContractId: {
      type: String,
      required: true
    },
    kind: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      loyaltyCurrency: state => state.user.loyaltyCurrency
    }),
    shouldExpand() {
      return this.kind === 'permanent' || Object.keys(this.loyaltyCurrency).length > 0
    }
  },
  methods: {
    async submit(action) {
      if (action === 'refuse') {
        const res = await CommissionContractService.refuse(this.commissionContractId)

        this.$emit('update', res.data)
        this.$emit('close')
      } else {
        if (this.shouldExpand) this.$emit('expand')
        else {
          const res = await CommissionContractService.accept(this.commissionContractId)

          this.$emit('update', res.data)
          this.$emit('close')
        }
      }
    }
  }
}
</script>
